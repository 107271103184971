import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { ReferenceField, TextField } from 'react-admin';
import CleanerAffectationField from './CleanerAffectationField';

const styles = createStyles({
  item: {
    paddingLeft: 20,
    paddingTop: 20,
    paddingRight: 20,
  },
});

const CleanerAffectationItem = ({ cleanerId, basePath, classes }) => {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItem
        button
        onClick={handleClick}
        disableGutters
        className={classes.item}
      >
        <ListItemText>
          <ReferenceField
            link="show"
            basePath={basePath}
            source="cleaner_id"
            record={{ cleaner_id: cleanerId }}
            reference="cleaner"
          >
            <TextField source="fullname" variant="h6" />
          </ReferenceField>
        </ListItemText>
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto">
        <CleanerAffectationField
          basePath={basePath}
          record={{ cleaner_id: cleanerId }}
        />
      </Collapse>
      <Divider />
    </Fragment>
  );
};

CleanerAffectationItem.propTypes = {
  cleanerId: PropTypes.string,
  basePath: PropTypes.string,
  classes: PropTypes.object,
};

CleanerAffectationItem.defaultProps = {
  cleanerId: '',
  basePath: '',
  classes: {},
};

export default withStyles(styles)(CleanerAffectationItem);
