import React from 'react';
import { Edit } from 'react-admin';
import Form from './detail/Form';

const FormationEdit = (props) => {
  return (
    <Edit {...props}>
      <Form />
    </Edit>
  );
};

export default FormationEdit;
