import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/';

const useStyles = makeStyles((theme) => {
  return {
    hours: {
      fontSize: '14px',
    },
    days: {
      fontSize: '12px',
      color: theme.palette.text.secondary,
      textTransform: 'capitalize',
    },
  };
});

const buildDefaultRecordDays = (record, translate) => {
  return [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ].map((item) => {
    if (record[item]) {
      return (
        <span key={item}>{`${translate(`format.date.days.${item}`)} `}</span>
      );
    }
    return null;
  });
};

const buildSourceDays = (record, translate) => {
  return record.map((item) => {
    return (
      <span key={item}>{`${translate(`format.date.days.${item}`)} `}</span>
    );
  });
};

export const ScheduleField = ({ record, source, refStartTime, refEndTime }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const startTime = record[refStartTime];
  const endTime = record[refEndTime];

  const hours =
    startTime && endTime
      ? `${startTime.substring(0, 5)} - ${endTime.substring(0, 5)}`
      : null;

  const days = record[source];

  return (
    <div>
      <div className={classes.hours}>{hours}</div>
      <div className={classes.days}>
        {days
          ? buildSourceDays(days, translate)
          : buildDefaultRecordDays(record, translate)}
      </div>
    </div>
  );
};

ScheduleField.propTypes = {
  record: PropTypes.object,
  refEndTime: PropTypes.string,
  refStartTime: PropTypes.string,
  source: PropTypes.string,
};

ScheduleField.defaultProps = {
  record: {},
  refEndTime: 'end_time',
  refStartTime: 'start_time',
  source: '',
};

export default ScheduleField;
