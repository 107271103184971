import React from 'react';
import { EditButton } from 'react-admin';
import { ActionsContainer } from '../../../../components';

import ActionsMenu from './ActionsMenu';
import ContractMenu from './ContractMenu/ContractMenu';
import DownloadMenu from './DownloadMenu';
import UploadDocumentDialog from './UploadDocumentDialog';
import LRARButton from './LRARButton';
// import TeleverserButton from './TeleverserButton';
import TakenOverMenu from './TakenOverMenu';
import DismissalConvocationDialog from './DismissalConvocationDialog';
import AffectattentionDialog from './AffectattentionDialog';

const ShowActions = props => {
  return (
    <ActionsContainer {...props}>
      <EditButton />
      <ActionsMenu />
      <ContractMenu />
      <DownloadMenu />
      <TakenOverMenu />
      <UploadDocumentDialog />
      <LRARButton />
      <AffectattentionDialog />
      <DismissalConvocationDialog />
    </ActionsContainer>
  );
  /*
  return (
    <ActionsContainer {...props}>
      <EditButton />
      <ActionsMenu />
      <DownloadMenu />
      <TakenOverMenu />
      <TeleverserButton />
      <LRARButton />
      <AffectattentionDialog />
      <DismissalConvocationDialog />
    </ActionsContainer>
  );
    */
};

export default ShowActions;
