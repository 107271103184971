import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const SubtypeClientSelectInput = (props) => {
  const translate = useTranslate();

  return (
    <SelectInput
      {...props}
      label={translate('resources.client_document.fields.subtype')}
      source="subtype"
      choices={[
        {
          id: 'other',
          name: 'resources.client_document.subtype.other',
        },
        {
          id: 'endorsement',
          name: 'resources.client_document.subtype.endorsement',
        },
        {
          id: 'ponctual_contract',
          name: 'resources.client_document.subtype.ponctual_contract',
        },
        {
          id: 'recurrent_contract',
          name: 'resources.client_document.subtype.recurrent_contract',
        },
        {
          id: 'plan_prevention',
          name: 'resources.client_document.subtype.plan_prevention',
        },
        {
          id: 'provider_contract',
          name: 'resources.client_document.subtype.provider_contract',
        },
        {
          id: 'complaint_letter',
          name: 'resources.client_document.subtype.complaint_letter',
        },
        {
          id: 'suspension_request',
          name: 'resources.client_document.subtype.suspension_request',
        },
        {
          id: 'resignation_letter',
          name: 'resources.client_document.subtype.resignation_letter',
        },
        {
          id: 'purchase_order',
          name: 'resources.client_document.subtype.purchase_order',
        },
      ]}
    />
  );
};

export default SubtypeClientSelectInput;
