import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import RssFeed from '@material-ui/icons/RssFeed';
import Dialog from '@material-ui/core/Dialog';
import InviteForm from './InviteForm';

const InviteButton = ({ className, record }) => {
  const [open, setOpen] = React.useState(false);

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <Fragment>
      <Button
        className={className}
        variant="contained"
        label="resources.affectation.notify.invite"
        onClick={handleClickOpen}
      >
        <RssFeed />
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <InviteForm handleClose={handleClose} record={record} />
      </Dialog>
    </Fragment>
  );
};

InviteButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

InviteButton.defaultProps = {
  className: undefined,
  record: {},
};

export default InviteButton;
