import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useQuery } from 'react-admin';
import { getUser } from '../../../../../user';

const followingDayISO = (dateString) => {
  const date = new Date(Date.parse(dateString));
  date.setDate(date.getDate() + 1);
  return date.toJSON().substring(0, 10);
};

const RenewCDDButton = ({ className, record, formUrl }) => {
  const { data: affectations } = useQuery({
    type: 'getManyReference',
    resource: 'affectation',
    payload: {
      target: 'cleaner_id',
      id: record.id,
      sort: { field: 'id', order: 'DESC' },
      pagination: { page: 1, perPage: 5 },
      filter: { type: 'Permanent', active: true },
    },
  });

  if (!affectations)
    return (
      <MenuItem>
        <ListItemText primary="loading" />
      </MenuItem>
    );

  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${formUrl}context=renewal` +
                    `&cleaner_silae_id=${record.silae_id}` +
                    `&cleaner_id=${record.id}` +
                    `&cleaner_firstname=${record.firstname}` +
                    `&cleaner_lastname=${record.lastname}` +
                    `&cleaner_email=${record.email}` +
                    `&cleaner_phone=${record.mobile_phone}` +
                    `&cleaner_level=${record.level}` +
                    `&cleaner_contract_type=${record.contract_type}` +
                    `&cleaner_position=${record.function}` +
                    `&contract_start_date=${followingDayISO(
                      record.contract_end_date,
                    )}` +
                    `&manager_fullname=${user.fullname}` +
                    `&manager_email=${user.email}` +
                    `&manager_id=${user.id}` +
                    `&contract_worked_time=${record.contract_worked_time}`,
                );
              }}
            >
              <ListItemText primary="Prolonger le CDD" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

RenewCDDButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  formUrl: PropTypes.string,
};

RenewCDDButton.defaultProps = {
  className: undefined,
  record: undefined,
  formUrl: undefined,
};

export default RenewCDDButton;
