import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  TextField,
  Datagrid,
  SelectField,
  DateField,
  ArrayField,
  NumberField,
  EmailField,
} from 'react-admin';
import { GridCard } from '../../../components';

// import { Fields } from '../../../fr';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const ListDetail = ({ ...props }) => {
  const classes = useStyles();

  return (
    <GridCard {...props} classes={classes}>
      <TextField source="id" label="Identifiant" />
      <DateField source="created_at" label="Créé le" />
      <SelectField
        source="interaction"
        emptyText="∅"
        label="Interaction"
        choices={[
          { id: 'SANS', name: "Avec l'équipe" },
          { id: 'AVEC', name: 'Avec le client' },
          { id: 'Avec&SANS', name: "Avec le client et l'équipe" },
          { id: 'PHONE', name: 'Au téléphone' },
          { id: 'MAIL', name: 'Par email' },
          { id: 'SMS', name: 'Par SMS' },
        ]}
      />
      <ArrayField
        source="rooms"
        label="Détail Contrôle"
        emptyText="∅"
        textAlign="center"
      >
        <Datagrid>
          <TextField source="roomType" label={false} emptyText="∅" />
          <NumberField source="rating" label={false} emptyText="∅" />
        </Datagrid>
      </ArrayField>
      <TextField
        source="contact.fullname"
        label="Nom du contact"
        emptyText="∅"
        textAlign="center"
      />
      <TextField
        source="contact.phone"
        label="Numéra du contact"
        emptyText="∅"
        textAlign="center"
      />
      <EmailField
        source="contact.mail"
        label="Mail du contact"
        emptyText="∅"
        textAlign="center"
      />
    </GridCard>
  );
};

export default ListDetail;
