export default record => {
  if (!record) return 0;
  const start = new Date(`2023-06-15T${record.start_time}`);
  const end = new Date(`2023-06-15T${record.end_time}`);
  const duration = end.getTime() - start.getTime();
  const durationInHours = duration / (60 * 60 * 1000);
  const daysOfWeek = [
    record.monday,
    record.tuesday,
    record.thursday,
    record.wednesday,
    record.friday,
    record.saturday,
    record.sunday,
  ].reduce((sum, day) => {
    return sum + (day ? 1 : 0);
  }, 0);
  const factor = record.start_date === record.end_date ? 1 : daysOfWeek;
  return durationInHours * factor;
};
