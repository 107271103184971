import PropTypes from 'prop-types';
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import {
  // NumberField,
  // TextField,
  ReferenceManyField,
  useTranslate,
} from 'react-admin';
import FollowupCard from './FollowupCard';
import ServiceCertificateCard from './ServiceCertificateCard';
import { GridCard } from '../../../components';
import ClientDocumentsDrawerList from './ClientDocumentDrawerList';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
});

const TabFollowup = ({ accountId, match, ...props }) => {
  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <FollowupCard {...props} />
      <GridCard
        {...props}
        classes={classes}
        title={translate('resources.client_document.name')}
        xs={12}
      >
        <ReferenceManyField
          reference="client_document"
          target="account_id"
          addLabel={false}
          xs={12}
        >
          <ClientDocumentsDrawerList
            {...props}
            originRoute={`${match.url}/suivi`}
          />
        </ReferenceManyField>
      </GridCard>
      <ServiceCertificateCard {...props} />
    </Grid>
    /*
        <GridCard
          {...props}
          title={translate('resources.account.card_title.satisfaction')}
          xs={12}
        >
          <NumberField source="quality_controls_mean" />
          <NumberField source="quality_controls_evolution" />
          <TextField source="last_survey_date" />
          <TextField source="last_survey_rating" />
          <TextField source="post_start_appreciation" />
        </GridCard>
  */
  );
};

TabFollowup.propTypes = {
  match: PropTypes.object,
  accountId: PropTypes.string,
};

TabFollowup.defaultProps = {
  match: {},
  accountId: '',
};

export default TabFollowup;
