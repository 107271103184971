import React from 'react';
import PropTypes from 'prop-types';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const styles = createStyles({
  root: {
    minWidth: 300,
  },
});

const PlanningLine = ({ classes, text }) => {
  return (
    <Typography classes={classes} component="span" variant="body1">
      {text}
    </Typography>
  );
};

PlanningLine.propTypes = {
  classes: PropTypes.object,
  text: PropTypes.string,
};

PlanningLine.defaultProps = {
  classes: {},
  text: undefined,
};

export default withStyles(styles)(PlanningLine);
