import React from 'react';
import PropTypes from 'prop-types';
import ContractBlock from './ContractBlock';

const ContractDetail = ({ contractsPlanning, ...props }) => {
  return Object.keys(contractsPlanning).map((contract) => {
    return (
      <ContractBlock
        {...props}
        key={contract}
        contract={contract}
        planning={contractsPlanning[contract]}
      />
    );
  });
};

ContractDetail.propTypes = {
  contractsPlanning: PropTypes.object,
};

ContractDetail.defaultProps = {
  contractsPlanning: {},
};

export default ContractDetail;
