import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getUser } from '../../../../user';

const NewContract = ({ className, record }) => {
  const range1to5 = Array.from(new Array(5)).map((_, index) => {
    return `&affectation_${index + 1}_`;
  });
  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          const formUrl = 'https://forms.cleany.fr/demande-de-contrat?';
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${`${formUrl}manager_fullname=${user.fullname}` +
                    `&manager_email=${user.email}` +
                    `&manager_id=${user.id}`}${range1to5.map((prefix) => {
                    return (
                      `${prefix}account_name=${record.name}` +
                      `${prefix}site_address=${record.shipping_address}`
                    );
                  })}`,
                );
              }}
            >
              <ListItemText primary="Contrat cleaner" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

NewContract.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

NewContract.defaultProps = {
  className: undefined,
  record: undefined,
};

export default NewContract;
