import React from 'react';
import PropTypes from 'prop-types';
import { translate as translateComponent, Button } from 'react-admin';
import { openTypeform } from '../../../../helpers';
import { getUser } from '../../../../user';

const LRARButton = ({ className, record, translate }) => {
  const user = getUser();
  return (
    <Button
      className={className}
      color="primary"
      variant="contained"
      onClick={(event) => {
        event.stopPropagation();
        openTypeform(
          { ...record, owner_id: user.id, owner_name: user.fullname },
          'cleaner_lrar',
        );
      }}
      label={translate('resources.cleaner.actions.lrar')}
    />
  );
};

LRARButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  translate: PropTypes.func,
};

LRARButton.defaultProps = {
  className: undefined,
  record: {},
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(LRARButton);
