import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { translate as translateComponent } from 'react-admin';



const webHookParamsString = (record) => {
  return Object.entries(record).reduce((acc, [key, value], index) => 
    `${acc}&${encodeURIComponent(key)}=${encodeURIComponent(value)}`
  , '');
};


const DuplicateCleanerButton = ({ className, record, translate }) => {
  const webHookUrl = 'https://hooks.zapier.com/hooks/catch/3450616/3700lhm?';
  const webHookParams = webHookParamsString(record);
  return (
    <Fragment>
      <Route
        render={() => {
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => 
                {
                  if (window.confirm("Cette opération va créer une nouvelle fiche cleaner dans laquelle les informations de cet agent seront dupliquées.\nÊtes-vous sûr(e) de vouloir procéder à cette duplication?")) {
                    window.open(`${webHookUrl}${webHookParams}`);
                  }
                  
                }
              }
            >
              <ListItemText
                primary={`Dupliquer le Cleaner`}
              />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

DuplicateCleanerButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  translate: PropTypes.func,
};

DuplicateCleanerButton.defaultProps = {
  className: undefined,
  record: undefined,
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(DuplicateCleanerButton);
