import React from 'react';
import PropTypes from 'prop-types';
import { TextField, DateField, ReferenceField } from 'react-admin';
import { GridCard, TranslatedField } from '../../../components';

const LeaveInformationField = ({ basePath, record }) => {
  return (
    <GridCard basePath={basePath} record={record}>
      <ReferenceField source="cleaner_id" reference="cleaner" link="show">
        <TextField source="fullname" />
      </ReferenceField>
      <TranslatedField
        translationPath="components.field.absence_type"
        source="absence_type"
      />
      <DateField source="start_date" />
      <DateField source="end_date" />
    </GridCard>
  );
};

LeaveInformationField.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
};

LeaveInformationField.defaultProps = {
  basePath: '',
  record: {},
};

const LeaveInformation = ({ basePath, leaveId }) => {
  return (
    <ReferenceField
      basePath={basePath}
      link={false}
      source="leave_id"
      reference="leave"
      record={{ leave_id: leaveId }}
    >
      <LeaveInformationField />
    </ReferenceField>
  );
};

LeaveInformation.propTypes = {
  basePath: PropTypes.string,
  leaveId: PropTypes.string,
};

LeaveInformation.defaultProps = {
  basePath: '',
  leaveId: undefined,
};

export default LeaveInformation;
