import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormHelperText from '@material-ui/core/FormHelperText';
import { days } from '../../../helpers';
import TimedDayInput from '../../../components/input/TimedDayInput';

const useStyles = makeStyles({
  errorText: {
    marginTop: 0,
  },
});

const PlanningDaysWithTime = ({
  defaultDays,
  defaultTime,
  differentTime,
  id,
  setParentInputValue,
  source,
  ...props
}) => {
  const classes = useStyles();
  const translate = useTranslate();
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
  } = useField(source);

  useEffect(() => {
    const defaultValues = {};
    if (differentTime) {
      Object.keys(defaultTime).forEach((day) => {
        defaultValues[day] = [defaultTime[day]];
      });
      onChange(defaultValues);
    } else {
      Object.keys(defaultDays).forEach((day) => {
        if (defaultDays[day]) {
          defaultValues[day] = [defaultTime];
        }
      });
      onChange(defaultValues);
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [defaultDays, defaultTime, differentTime]);

  const helperText = touched && error && error.day ? error.day : '';
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const handleChange = (day) => {
    return (values, index = 0) => {
      const newValue = Object.assign({}, value);

      if (!newValue[day] || !newValue[day].length) {
        newValue[day] = [];
      }
      if (values) {
        newValue[day][index] = values;
        newValue[day] = [...newValue[day]];
      } else {
        newValue[day].splice(index, 1);
        if (!newValue[day].length) {
          delete newValue[day];
        }
      }
      setParentInputValue(newValue);
      onChange(newValue);
    };
  };

  const handleRemove = (day) => {
    return (index) => {
      const newValue = Object.assign({}, value);

      if (!newValue[day] || !newValue[day].length) {
        return;
      }
      newValue[day].splice(index, 1);
      onChange(newValue);
      setParentInputValue(value);
    };
  };

  return (
    <Fragment>
      <Grid container spacing={8}>
        {days.map((day) => {
          return (
            <Grid item key={day} xs={12} sm={4}>
              <TimedDayInput
                {...props}
                {...restInput}
                defaultDay={defaultDays[day]}
                defaultTime={differentTime ? defaultTime[day] : defaultTime}
                meta={{
                  touched,
                  error: error && error.time && error.time[day],
                }}
                onChange={handleChange(day)}
                onRemove={handleRemove(day)}
                source={day}
                translate={translate}
                value={value}
              />
            </Grid>
          );
        })}
      </Grid>
      {helperText && (
        <FormHelperText id={helperTextId} error className={classes.errorText}>
          {helperText}
        </FormHelperText>
      )}
    </Fragment>
  );
};

PlanningDaysWithTime.propTypes = {
  defaultDays: PropTypes.object,
  defaultTime: PropTypes.object,
  differentTime: PropTypes.bool,
  id: PropTypes.string,
  setParentInputValue: PropTypes.func,
  source: PropTypes.string,
};

PlanningDaysWithTime.defaultProps = {
  defaultDays: {},
  defaultTime: {},
  differentTime: false,
  id: undefined,
  setParentInputValue: () => {},
  source: undefined,
};

export default PlanningDaysWithTime;
