import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import { uploadCleanerDocument } from '../../../../action';
import {
  SubtypeSelectInput,
  RenderImageInput,
  UploadDialog,
} from '../../../../components';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['subtype', 'data'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

const UploadForm = ({ additionalActionOnSubmit, record }) => {
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(uploadCleanerDocument({ ...values, cleanerId: record.id }));
    additionalActionOnSubmit();
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form }) => {
        return (
          <form id="documentUploadForm" onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Field name="subtype" component={SubtypeSelectInput} />
              <Field name="data" source="data" component={RenderImageInput} />
            </div>
          </form>
        );
      }}
    />
  );
};

UploadForm.propTypes = {
  additionalActionOnSubmit: PropTypes.func,
  record: PropTypes.object,
};

UploadForm.defaultProps = {
  additionalActionOnSubmit: () => {},
  record: {},
};

const submitForm = () => {
  document
    .getElementById('documentUploadForm')
    .dispatchEvent(new Event('submit', { cancelable: true }));
};

const UploadDocumentDialog = ({ className, record }) => {
  const translate = useTranslate();

  return (
    <UploadDialog
      className={className}
      record={record}
      form={<UploadForm />}
      submitForm={submitForm}
      openButtonText={translate('resources.cleaner_document.create')}
      title={translate('resources.cleaner_document.dialog.title')}
      uploadButtonText={translate('resources.cleaner_document.dialog.button')}
    />
  );
};

UploadDocumentDialog.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

UploadDocumentDialog.defaultProps = {
  className: undefined,
  record: {},
};

export default UploadDocumentDialog;
