import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import {
  CreateButton,
  GridCard,
  TabbedLayout,
  Tab,
} from '../../../../components';

import ListAffectation from './ListAffectation';
import ListAffectationHistory from './ListAffectationHistory';
import { URLParamField } from '../../../../components';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: '0 !important',
  },
});

const AffectationCard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      {...sanitizeRestProps(props)}
      classes={classes}
      title={translate(
        'resources.account.card_title.recurring_contract_affectation',
      )}
      xs={12}
      action={
        <CreateButton
          pathname="/affectation/create"
          initialValues={{
            contract_id: props.record.recurring_contract_id,
            catalog_item_id: '01t0Y000001C4C7QAK',
          }}
        />
      }
    >
      <URLParamField
        url="https://qc-test-ol.bubbleapps.io/cahier_des_charges?account_id={{id}}"
        source="id"
        label=""
        textAlign="center"
        text="‎🧹🧽 Cahier des charges 🧽🧹"
        emptyText="∅"
      />
      <TabbedLayout xs={12}>
        <Tab label={translate('resources.account.tab.actives')}>
          <ListAffectation
            active
            filter={{
              'in(type)': [
                'Permanent',
                'Ponctuel',
                'Intercontrat',
                'Remplacement',
                // 'Maintien Partiel',
                'Remplacement Saisonnier',
                'Absence',
              ],
              // status_covid: 'Actif',
              catalog_item_family: 'Prestations horaires effectuées par Cleany',
            }}
          />
        </Tab>
        <Tab label={translate('resources.account.tab.history')}>
          <ListAffectationHistory
            active={false}
            filter={{
              'in(type)': [
                'Permanent',
                'Ponctuel',
                'Intercontrat',
                'Remplacement',
                // 'Maintien Partiel',
                'Remplacement Saisonnier',
                'Absence',
              ],
              catalog_item_family: 'Prestations horaires effectuées par Cleany',
            }}
            sort={{ field: 'start_date', order: 'DESC' }}
          />
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

AffectationCard.propTypes = {
  record: PropTypes.object,
};

AffectationCard.defaultProps = {
  record: {},
};

export default AffectationCard;
