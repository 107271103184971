import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ArrowRight from '@material-ui/icons/ArrowRightAlt';
import { DateField } from 'react-admin';

const styles = createStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  arrow: {
    marginLeft: 4,
    marginRight: 4,
  },
});

const StartToEndDatesField = ({ classes, record }) => {
  return (
    <div className={classes.container}>
      <DateField source="start_date" record={record} />
      <ArrowRight className={classes.arrow} />
      <DateField source="end_date" record={record} />
    </div>
  );
};

StartToEndDatesField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
};

StartToEndDatesField.defaultProps = {
  classes: {},
  record: {},
};

export default withStyles(styles)(StartToEndDatesField);
