import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceInput, SelectInput } from 'react-admin';

const DocumentReferenceInput = ({ record, type, source, ...props }) => {
  return (
    <ReferenceInput
      {...props}
      record={record}
      sort={{ field: 'upload_date', order: 'DESC' }}
      filter={{
        type,
        cleaner_id: record.id,
      }}
      perPage={10}
      source={source}
      reference="cleaner_document"
      allowEmpty
      emptyText="ra.action.clear_input_value"
    >
      <SelectInput
        optionText={(doc) => {
          return `${doc.subtype} ${doc.status} ${doc.upload_date}`;
        }}
      />
    </ReferenceInput>
  );
};

DocumentReferenceInput.propTypes = {
  record: PropTypes.object,
  type: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
};

DocumentReferenceInput.defaultProps = {
  record: {},
};

export default DocumentReferenceInput;
