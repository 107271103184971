import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { TextField } from 'react-admin';
import { GridSimpleShowLayout, NumberWithUnitField } from '../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const Details = ({ title, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <GridSimpleShowLayout {...props} classes={classes} spacing={2}>
        <TextField source="billing_address" sm={5} />
        <GridSimpleShowLayout sm={7} classes={{ content: classes.content }}>
          <NumberWithUnitField unit="€" source="cleaning_amount" />
          <NumberWithUnitField unit="€" source="glazzing_amount" />
          <NumberWithUnitField unit="€" source="consumable_amount" />
          <NumberWithUnitField unit="€" source="bin_amount" />
          <NumberWithUnitField unit="€" source="other_amount" />
        </GridSimpleShowLayout>
      </GridSimpleShowLayout>
    </>
  );
};

Details.propTypes = {
  title: PropTypes.string,
};

Details.defaultProps = {
  title: undefined,
};

export default Details;
