import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ContentAdd from '@material-ui/icons/Add';
import { Button } from 'react-admin';

const CreateButton = ({ pathname, initialValues, ...props }) => {
  /* eslint-disable no-param-reassign */
  if (props.record) {
    initialValues.contract_id = props.record.id;
    initialValues.start_date = props.record.start_date;
    initialValues.end_date = props.record.end_date;
  }
  /* eslint-disable no-param-reassign */
  return (
    <Button
      component={Link}
      label="ra.action.create"
      to={{
        pathname,
        state: { record: initialValues },
      }}
    >
      <ContentAdd />
    </Button>
  );
};

CreateButton.propTypes = {
  pathname: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
  record: PropTypes.object,
};

CreateButton.defaultProps = {
  initialValues: {},
  record: {},
};

export default CreateButton;
