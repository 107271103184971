import { call, put, takeLatest } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  showNotification,
  FETCH_START,
  FETCH_ERROR,
  FETCH_END,
} from 'react-admin';
import { GET_LEGAL_DOCUMENT } from '../action';
import fetchApi from './helpers/fetchApi';

export const understandableName = (documentType) => {
  switch (documentType) {
    case 'dismissalConvocation':
      return 'convocation_EPAL';
    case 'certificat':
      return 'attestation_de_travail';
    case 'takenOver':
      return 'dossier_sortant_annexe_7_';
    case 'takenOverCleanerLetter':
      return 'lettre_transfert_annexe_7';
    case 'authentificationRequest':
      return 'formulaire_authentification_visa';
    case 'planning':
      return 'Planning';
    case 'cahierDesCharge':
      return 'Cahier Des Charges';
    case 'trialPeriodRenewal':
      return 'renouvellement_periode_essai';
    case 'releveHeure29':
      return 'releve_heure29';
    case 'releveHeure28':
      return 'releve_heure28';
    case 'releveHeure30':
      return 'releve_heure30';
    case 'releveHeure31':
      return 'releve_heure31';
    case 'endOfTrialPeriod':
      return 'fin_periode_essai';
    case 'test':
      return 'test';

    default:
      throw new Error(`unexpected document type ${documentType}`);
  }
};

export const createLegalDocumentRequest = async (payload) => {
  let understandableNameFile = '';
  return fetchApi(
    { url: '/legalDocument/cleaner', payload },
    async (response) => {
      let res;
      if (response.status === 202) {
        res = await response.json();
        return res;
      }
      if (payload.documentType.substring(0, 11) === 'releveHeure') {
        const { dateMois } = payload;
        understandableNameFile =
          understandableName(payload.documentType) + dateMois.substring(2);
      } else {
        understandableNameFile = understandableName(payload.documentType);
      }
      const file = await response.blob();
      FileSaver.saveAs(
        file,
        `${payload.fullname}_${understandableNameFile}.pdf`,
      );
      res = {
        success: true,
        message: 'Document created',
      };

      return res;
    },
  );
};

export function* createLegalDocument({ payload }) {
  try {
    yield put({ type: FETCH_START });
    const res = yield call(createLegalDocumentRequest, payload);
    yield put({ type: FETCH_END });
    if (res.success === true) {
      yield put(
        showNotification('notification.legal_document.success', 'info'),
      );
    } else {
      yield put(
        showNotification('notification.legal_document.fail', 'warning', {
          messageArgs: { message: res.missingParameters.join(', ') },
        }),
      );
    }
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification('Error generating the document', 'warning'));
  }
}

export default function* createLegalDocumentSaga() {
  yield takeLatest(GET_LEGAL_DOCUMENT, createLegalDocument);
}
