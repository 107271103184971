import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import { Route } from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';

const RechercheCleanerButton = ({ className, record }) => {
  return (
    <Fragment>
      <Route
        render={() => {
          return (
            <Button
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  'https://stats.cleany.fr/question/908?debut=10%3A00&fin=14%3A00&nb_jour=5&Compte=' +
                    `${record.name}`,
                );
              }}
            >
              <div
                style={{ fontSize: 12, fontWeight: 600, fontFamily: 'Roboto' }}
              >
                <SearchIcon style={{ fontSize: 12 }} /> Cleaner
              </div>
            </Button>
          );
        }}
      />
    </Fragment>
  );
};

RechercheCleanerButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

RechercheCleanerButton.defaultProps = {
  className: undefined,
  record: undefined,
};

export default RechercheCleanerButton;
