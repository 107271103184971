import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import PayCard from './PayCard';

const PayTab = ({ cleanerId, match, ...props }) => {

  return (
    <Grid container spacing={2}>
      <PayCard {...props} />
    </Grid>
  );
};

PayTab.propTypes = {
  match: PropTypes.object,
  cleanerId: PropTypes.string,
};

PayTab.defaultProps = {
  match: {},
  cleanerId: '',
};

export default PayTab;
