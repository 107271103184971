import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { ReferenceManyField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { GridCard, Pagination } from '../../../../components';

import DrawerList from './DrawerList';
import Action from './Action';

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
});

const InvoiceIncubatorCard = ({ match, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [filter, setFilter] = useState({ month: moment().format('YYYY-MM') });

  const originRoute = `${match.url}/invoice`;

  return (
    <GridCard
      {...props}
      classes={classes}
      xs={12}
      action={
        <Action
          onChange={setFilter}
          initialValues={filter}
          originRoute={originRoute}
        />
      }
      title={translate('resources.account.card_title.invoice_incubator')}
    >
      <ReferenceManyField
        {...props}
        reference="invoice_incubator"
        target="account_id"
        filter={filter}
        sort={{ field: 'month, generated, price', order: 'DESC, DESC, DESC' }}
        addLabel={false}
        xs={12}
        perPage={25}
        pagination={<Pagination />}
      >
        <DrawerList {...props} filter={filter} originRoute={originRoute} />
      </ReferenceManyField>
    </GridCard>
  );
};

InvoiceIncubatorCard.propTypes = {
  match: PropTypes.object,
};

InvoiceIncubatorCard.defaultProps = {
  match: {},
};

export default InvoiceIncubatorCard;
