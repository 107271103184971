import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const PayrollConclusionField = ({ summary, hoursList }) => {
  const [open, setOpen] = React.useState(true);
  const handleClick = () => {
    setOpen(!open);
  };
  const showDetails = !!hoursList.length;
  const renderedHoursList = hoursList.map((hour) => {
    return (
      <ListItem
        key={hour}
        style={{ paddingTop: 0, paddingBottom: 0, marginLeft: 20 }}
      >
        <ListItemText primary={hour} />
      </ListItem>
    );
  });

  return (
    <Fragment>
      <ListItem button={showDetails} onClick={handleClick}>
        <ListItemText>{summary}</ListItemText>
        {showDetails && <>{open ? <ExpandLess /> : <ExpandMore />}</>}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>{showDetails && renderedHoursList}</List>
      </Collapse>
    </Fragment>
  );
};

PayrollConclusionField.propTypes = {
  hoursList: PropTypes.array,
  summary: PropTypes.string,
};

PayrollConclusionField.defaultProps = {
  hoursList: [],
  summary: '',
};

export default PayrollConclusionField;
