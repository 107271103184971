import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { ListItem, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { ExpandIcon } from '../icon';

const useStyles = makeStyles((theme) => {
  return {
    root: ({ level }) => {
      return {
        paddingLeft: level ? theme.spacing(2) : 0,
      };
    },
    line: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    collapse: {
      backgroundColor: theme.palette.grey[50],
      padding: theme.spacing(2),
    },
  };
});

const NestedListItem = ({
  action,
  children,
  collapse,
  defaultOpen,
  dense,
  level,
  ...props
}) => {
  const classes = useStyles({ level });
  const isNested = !!collapse;
  const [open, setOpen] = React.useState(defaultOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <div className={classes.line}>
        <ListItem button={isNested} dense={dense} onClick={handleClick}>
          {isNested && <ExpandIcon className={classes.icon} open={open} />}
          {Children.map(children, (field) => {
            return cloneElement(field, props);
          })}
        </ListItem>
        {action && cloneElement(Children.only(action), props)}
      </div>
      {isNested && (
        <Collapse
          in={open}
          timeout="auto"
          unmountOnExit
          classes={{ wrapper: classes.collapse }}
        >
          {cloneElement(Children.only(collapse), {
            ...props,
            level: level + 1,
          })}
        </Collapse>
      )}
    </div>
  );
};

NestedListItem.propTypes = {
  action: PropTypes.element,
  children: PropTypes.node,
  collapse: PropTypes.element,
  defaultOpen: PropTypes.bool,
  dense: PropTypes.bool,
  level: PropTypes.number,
};

NestedListItem.defaultProps = {
  action: null,
  children: null,
  collapse: null,
  defaultOpen: false,
  dense: false,
  level: 0,
};

export default NestedListItem;
