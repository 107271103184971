const frequencyReference = {
  1: '2x / jour',
  2: '7x / semaine',
  3: '6x / semaine',
  4: '5x / semaine',
  5: '4x / semaine',
  6: '3x / semaine',
  7: '2x / semaine',
  8: '1x / semaine',
  9: '2x / mois',
  10: '1x / mois',
  11: 'Un mois sur Deux',
  12: '1x / trimestre',
  13: '1x / semestre',
  14: '1x / an',
  15: 'Ponctuel',
};

export default frequencyReference;
