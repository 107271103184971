import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Typography from '@material-ui/core/Typography';

import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import { ResettableTextField } from 'react-admin';

const styles = (theme) => {
  return createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    field: {
      minWidth: 80,
    },
    spacer: {
      marginLeft: 20,
      marginRight: 20,
    },
    buttonClear: {
      color: theme.palette.error.light,
    },
  });
};

const TimeInput = ({
  classes,
  error,
  fieldArray,
  first,
  handleChange,
  index,
  onAdd,
  onRemove,
  values,
}) => {
  return (
    <div className={classes.root}>
      <ResettableTextField
        className={classes.field}
        error={error}
        type="time"
        onChange={handleChange('start_time')}
        value={values.start_time}
      />
      <Typography className={classes.spacer} component="span" variant="h5">
        -
      </Typography>
      <ResettableTextField
        className={classes.field}
        error={error}
        type="time"
        onChange={handleChange('end_time')}
        value={values.end_time}
      />
      {fieldArray &&
        (first ? (
          <IconButton aria-label="Delete" color="primary" onClick={onAdd}>
            <AddIcon />
          </IconButton>
        ) : (
          <IconButton
            className={classes.buttonClear}
            aria-label="Delete"
            onClick={() => {
              onRemove(index);
            }}
          >
            <ClearIcon />
          </IconButton>
        ))}
    </div>
  );
};

TimeInput.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.bool,
  fieldArray: PropTypes.bool,
  first: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  values: PropTypes.object,
};

TimeInput.defaultProps = {
  classes: {},
  error: false,
  fieldArray: false,
  first: false,
  index: undefined,
  onAdd: () => {},
  onRemove: () => {},
  values: {},
};

const TimeInputStyled = withStyles(styles)(TimeInput);

const StartEndTimeInput = ({
  addLabel,
  classes,
  defaultValue,
  fieldArray,
  first,
  hideError,
  index,
  label,
  meta: { error, submitFailed },
  onAdd,
  onRemove,
  source,
  ...props
}) => {
  const [values, setValues] = useState({
    start_time: defaultValue.start_time || '',
    end_time: defaultValue.end_time || '',
  });

  const handleChange = (prop) => {
    return (event) => {
      const newValues = { ...values, [prop]: event.target.value };
      setValues(newValues);
      props.onChange(newValues, index);
    };
  };

  const id = source ? `${source}-time` : 'time';
  const helperText = submitFailed && error ? error : '';
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <FormControl margin="none">
      {addLabel && (
        <InputLabel shrink htmlFor={id} error={!!helperText}>
          {label}
        </InputLabel>
      )}
      <Input
        disableUnderline
        inputComponent={TimeInputStyled}
        inputProps={{
          classes,
          error: !!helperText,
          fieldArray,
          first,
          handleChange,
          index,
          onAdd,
          onRemove,
          values,
        }}
      />
      {helperText && !hideError && (
        <FormHelperText id={helperTextId} error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

StartEndTimeInput.propTypes = {
  addLabel: PropTypes.bool,
  classes: PropTypes.object,
  source: PropTypes.string,
  defaultValue: PropTypes.object,
  fieldArray: PropTypes.bool,
  first: PropTypes.bool,
  hideError: PropTypes.bool,
  index: PropTypes.number,
  label: PropTypes.string,
  meta: PropTypes.object,
  onAdd: PropTypes.func,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

StartEndTimeInput.defaultProps = {
  addLabel: true,
  classes: {},
  source: undefined,
  defaultValue: {},
  fieldArray: false,
  first: false,
  hideError: false,
  index: undefined,
  label: undefined,
  meta: {},
  onAdd: () => {},
  onChange: () => {},
  onRemove: () => {},
};

export default StartEndTimeInput;
