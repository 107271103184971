import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import {
  Pagination,
  InvoiceStatusChipField,
  DownloadInvoiceButton,
} from '../../../../components';

import Expand from '../../../Invoice/shared/Expand';
import Details from './Details';

const useStyles = makeStyles((theme) => {
  return {
    secondText: {
      color: theme.palette.grey[600],
    },
  };
});

const ListInvoice = (props) => {
  const classes = useStyles();

  return (
    <ReferenceManyField
      {...props}
      reference="invoice"
      target="account_id"
      addLabel={false}
      sort={{ field: 'due_date', order: 'DESC' }}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid {...props} expand={<Expand details={<Details />} />}>
        <DateField source="due_date" />
        <TextField source="invoice_number" />
        <NumberField
          source="amount"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <NumberField
          className={classes.secondText}
          source="total_tax"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <NumberField
          source="total_amount"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <InvoiceStatusChipField source="status" />
        <DownloadInvoiceButton label="" source="id" variant="icon" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ListInvoice;
