const leaveSiteCleaners = (state = [], action) => {
  switch (action.type) {
    case 'SET_LEAVE_SITE_CLEANERS':
      return action.affectations.map((affectation) => {
        return affectation.cleaner_id;
      });
    case 'CLEAR_LEAVE_SITE_CLEANERS':
      return [];
    default:
      return state;
  }
};

export default leaveSiteCleaners;
