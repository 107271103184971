import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import {
  CreateButton,
  GridCard,
  TabbedLayout,
  Tab,
} from '../../../../components';

import ListLeave from './ListLeave';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: '0 !important',
  },
});

const AffectationCard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...sanitizeRestProps(props)}
      classes={classes}
      title={translate('resources.account.card_title.leave')}
      xs={12}
      action={<CreateButton pathname="/leave/create" />}
    >
      <TabbedLayout xs={12}>
        <Tab label={translate('resources.account.tab.actifs')}>
          <ListLeave active />
        </Tab>
        <Tab label={translate('resources.account.tab.history')}>
          <ListLeave active={false} />
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

export default AffectationCard;
