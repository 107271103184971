import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { Pagination, DirectDebitStatusChipField } from '../../../../components';

const DirectDebit = ({ title, ...props }) => {
  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <ReferenceManyField
        {...props}
        reference="gocardless_payment"
        target="invoice_number"
        source="invoice_number"
        addLabel={false}
        xs={12}
        perPage={10}
        pagination={<Pagination />}
      >
        <Datagrid {...props}>
          <TextField source="mandate_id" />
          <TextField source="description" />
          <DateField source="charge_date" />
          <DirectDebitStatusChipField source="status" />
          <NumberField
            source="amount"
            options={{ style: 'currency', currency: 'EUR' }}
          />
        </Datagrid>
      </ReferenceManyField>
    </>
  );
};

DirectDebit.propTypes = {
  title: PropTypes.string,
};

DirectDebit.defaultProps = {
  title: undefined,
};

export default DirectDebit;
