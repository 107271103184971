import React from 'react';
import { List } from 'react-admin';
import CustomList from './detail/CustomList';

export default (props) => {
  return (
    <List {...props} perPage={50} component="div">
      <CustomList />
    </List>
  );
};
