import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import FileSaver from 'file-saver';
import { useNotify, useTranslate } from 'react-admin';
import { Button, makeStyles, CircularProgress } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const Body = ({ icon, variant }) => {
  const translate = useTranslate();

  switch (variant) {
    case 'icon':
      return React.cloneElement(icon);
    case 'text':
      return translate('components.button.download.text');
    default:
      return (
        <>
          {React.cloneElement(icon)}
          {translate('components.button.download.text')}
        </>
      );
  }
};

Body.propTypes = {
  icon: PropTypes.element,
  variant: PropTypes.oneOf(['icon', 'text']),
};

Body.defaultProps = {
  icon: null,
  variant: undefined,
};

const downloadInvoice = async (payload) => {
  const response = await fetch('/bill/download', {
    headers: {
      Accept: 'application/pdf',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(payload),
  });

  if (response.status !== 200) {
    throw new Error(response.statusText);
  }

  const file = await response.blob();
  const month = moment(payload.date);
  const fileName = `Cleany-${month.format('YYYY-MM')}-${
    payload.invoice_number
  }`;
  FileSaver.saveAs(file, `${fileName}.pdf`);
};

const useStyles = makeStyles((theme) => {
  return {
    loadingIcon: {
      marginRight: theme.spacing(1) + 4,
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
  };
});

const DownloadInvoiceButton = ({ record, variant }) => {
  const classes = useStyles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);

  const handleClick = useCallback(
    async (event) => {
      try {
        event.stopPropagation();
        setLoading(true);
        await downloadInvoice(record);
        setLoading(false);
        notify('notification.invoice_download.success', 'info');
      } catch (error) {
        setLoading(false);
        notify('notification.invoice_download.fail', 'warning');
      }
    },
    [notify, record],
  );

  if (loading) {
    return (
      <Button color="primary" disabled variant="text">
        <Body
          variant={variant}
          icon={
            <CircularProgress
              className={classes.loadingIcon}
              color="inherit"
              size={20}
            />
          }
        />
      </Button>
    );
  }
  return (
    <Button color="primary" variant="text" onClick={handleClick}>
      <Body
        variant={variant}
        icon={<DownloadIcon className={classes.leftIcon} />}
      />
    </Button>
  );
};

DownloadInvoiceButton.propTypes = {
  record: PropTypes.object,
  variant: PropTypes.oneOf(['icon', 'text']),
};

DownloadInvoiceButton.defaultProps = {
  record: {},
  variant: undefined,
};

export default DownloadInvoiceButton;
