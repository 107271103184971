export default {
  menu: {
    action: {
      my_actions: 'Mes actions en cours',
    },
    affectation: {
      activeReplacement: 'Remplacements',
    },
    cleaner: {
      all: 'Tous',
      new: 'Vivier',
      docs: 'Documents',
      intercontract: 'Intercontrat',
    },
    default: {
      other: 'Autres',
      item: 'Item',
      menu: 'Menu',
    },
  },
  resources: {
    availability: {
      name: 'Disponibilité |||| Disponibilités',
      fields: {},
    },
    client_followup: {
      name: 'Suivi client',
      fields: {},
    },
    opportunity: {
      name: 'Opportunités',
      fields: {},
    },
    undefined: {
      fields: {
        planning: 'Planning',
      },
    },
    client_document: {
      dialog: {
        title: 'Téléverser un document',
        button: '♻️ Téléverser',
      },
      create: '♻️ Téléverser',
      name: 'Document Client',
      fields: {
        cleaner_id: 'Cleaner',
        comment: 'Commentaire',
        status: 'Statut',
        subtype: 'Type de document',
        type: 'Type',
        upload_date: 'Date de téléversement',
        provider: 'Fournisseur',
      },
      subtype: {
        complaint_letter: 'Courrier de plainte',
        other: 'Autres Correspondances',
        ponctual_contract: 'Contrat Ponctuel',
        recurrent_contract: 'Contrat Récurrent',
        plan_prevention: 'Plan de Prévention',
        endorsement: 'Avenant au contrat',
        provider_contract: 'Contrat fournisseur',
        resignation_letter: 'Lettre de résiliation',
        suspension_request: 'Demande de suspension des prestations',
        purchase_order: 'Bon de commande',
      },
      type: {
        contract_end: 'Fin de Contrat',
        provider: 'Fournisseur',
        contract: 'Contrat',
        purchase_order: 'Bon de commande',
      },
    },
    mission_report: {
      name: 'Rapport de mission |||| Rapports de mission',
      fields: {},
    },
    service_certificate: {
      name: 'Attestation de prestation',
    },
    preaffectation: {
      name: 'Pré-Affectation',
    },
    formation: {
      name: 'Formation',
    },
    account: {
      name: 'Compte |||| Comptes',
      card_title: {
        access: 'Accès',
        affectation: 'Affectations',
        billing: 'Facturation',
        billing_info: 'Informations de facturation',
        consumable: 'Consommables',
        contact: 'Contact',
        contract: 'Contrat',
        details: 'Détails',
        equipment: 'Equipements',
        glazing: 'Vitrerie',
        gocardless_payment: 'Prélèvements',
        infos: 'Infos',
        invoice: 'Factures',
        invoice_incubator: 'Pré-factures',
        leave: 'Absences',
        leave_affectation: "Affectations d'absence",
        ponctual_contract: 'Contrats ponctuels',
        product: 'Produits',
        recurring_contract: 'Contrat récurrent',
        recurring_contract_affectation: 'Affectations contrat récurrent',
        replacement: 'Remplacements',
        satisfaction: 'Satisfaction',
        site: 'Site',
        specification: 'Cahier des charges',
      },
      fields: {
        access: 'Accès',
        bill_with_parent: 'Facturation avec le compte parent ?',
        billing_address: 'Adresse de facturation',
        billing_city: 'Ville',
        billing_country: 'Pays',
        billing_data: 'Données de facturation',
        billing_email: 'Email de facturation',
        billing_postal_code: 'Code postal',
        billing_street: 'Rue',
        building_areas: 'Zones',
        created_date: 'Date de création',
        date_reprise_partielle: 'Date de reprise partielle',
        date_reprise_totale: 'Date de reprise totale',
        description: 'Description',
        drive_folder: 'Drive',
        duplicate_access: "Double d'accès",
        end_date: 'Date de fin',
        garbage_location: 'Emplacement du local poubelles',
        nombre_sous_traitant: 'Nombre de sous-traitant opérant sur le site',
        chef_equipe: "Chef d'équipe",
        gcl_customer_id: 'Id client GoCardLess',
        gcl_mandate_id: 'Id mandat GoCardLess',
        has_recurring_contract: 'Contrat récurrent actif',
        industry: 'Industrie',
        last_modified_date: 'Dernière modification',
        last_survey_date: 'Dernière satisfaction',
        last_survey_rating: 'Dernière note',
        leave: 'Absence',
        manager_email: 'Manager email',
        manager_fullname: 'Manager',
        manager_id: 'Manager',
        manager_mobile_phone: 'Manager mobile',
        manager_phone: 'Manager téléphone',
        name: 'Nom',
        number_of_employees: 'Effectif',
        operational_email: 'Opérationel email',
        operational_fullname: 'Opérationel',
        operational_id: 'Opérationel',
        operational_mobile_phone: 'Opérationel mobile',
        operational_phone: 'Opérationel téléphone',
        parent_id: 'Compte parent',
        payment_method: 'Methode de paiement',
        ponctual: 'Ponctuel',
        post_start_appreciation: 'Appréciation après démarrage',
        product: 'Produits',
        quality_controls_evolution: 'Evolution satisfaction',
        quality_controls_mean: 'Satisfaction normalisée',
        permanent: 'Permanent',
        recurring_contract_affectations_ok: 'Affectations ok ?',
        recurring_contract_amount: 'Montant contrat',
        recurring_contract_cleaning_amount_per_month: 'Montant nettoyage',
        recurring_contract_cleaning_duration_per_week:
          'Montant nettoyage / semaine',
        recurring_contract_consumables_amount_per_month: 'Montant consommables',
        recurring_contract_consumables_ok: 'Consommables ok ?',
        recurring_contract_consumables_quantity_per_month:
          'Quantité consommables',
        recurring_contract_cost: 'Coût',
        recurring_contract_margin: 'Marge',
        recurring_contract_percent_margin: 'Marge %',
        recurring_contract_permanent_affectation_duration_per_week:
          'Heures affectées / semaine',
        recurring_contract_start_date: 'Date de début',
        recurring_contract_end_date: 'Date de fin',
        replacement: 'Remplacement',
        salesman_email: 'Commercial email',
        salesman_fullname: 'Commercial',
        salesman_id: 'Commercial',
        salesman_mobile_phone: 'Commercial mobile',
        salesman_phone: 'Commercial téléphone',
        shipping_address: 'Adresse de livraison',
        shipping_city: 'Ville',
        shipping_postal_code: 'Code postal',
        shipping_street: 'Rue',
        sold_vs_done: 'Vendu / Affecté',
        specification: 'Cahier des charges',
        start_date: 'Date de début',
        storage_locker_location: "Emplacement du local d'entretien",
        surface: 'Surface',
        tags: 'Qualifications',
        total_amount: 'Montant total',
        transit_stop: 'Transports en commun',
        trigram: 'Trigram',
        type: 'Type',
        website: 'Site web',
      },
      edit: {
        title: '✏️ Editer',
        areasModel: 'Retournez à la ligne après chaque zone entrée',
        areaSample: 'Bâtiment',
      },
      error: {
        too_much_contracts:
          'Le compte a %{contract_number} contrats récurents actifs',
      },
      tab: {
        actifs: 'Actifs',
        actives: 'Actives',
        history: 'Historique',
      },
      tabs: {
        cleaning: 'Nettoyage',
        consumable: 'Consommables',
        glazing: 'Vitrerie',
        invoice: 'Factures',
        miscellanous: 'Divers',
        ponctual: 'Ponctuels',
      },
      values: {
        post_start_appreciation: {
          UNDERESTIMATED: 'Sous-estimé',
          WELL_ESTIMATED: 'Bien estimé',
          PREMIUM: 'Premium',
          null: 'Non définie',
        },
      },
    },
    dashboard: {
      name: 'Tableau de bord',
      fields: {
        informations: 'Vos informations',
        number_of_employees: 'Effectif',
        access: 'Accès',
        surface: 'Surface',
        billing_email: 'Contact de facturation',
        payment_method: 'Méthode de paiement',
      },
      responsibleCard: {
        contact: 'Pour toute question, contactez le manager de votre site',
        responsible: 'Personnes en charge',
      },
    },
    affectation: {
      name: 'Affectation |||| Affectations',
      card_title: {
        schedule: 'Emploi du temps',
        site: 'Site',
        info: 'Infos',
        other: 'Autre',
      },
      fields: {
        absence_type: "Type d'absence",
        account_name: 'Compte',
        active: 'Actif ?',
        catalog_item_id: 'Elément du catalogue',
        cleaner_fullname: 'Cleaner',
        cleaner_id: 'Cleaner',
        cleaner_ids: 'Cleaners',
        contract_id: 'Contrat',
        dates: 'Dates',
        duration_per_week: 'Hebdo',
        duration: 'Durée',
        end_date: 'Date de fin',
        end_time: 'Fin',
        fullname: 'Nom',
        leave_id: 'Absence associée',
        limit_start_date: 'Date limite de début',
        manager_fullname: 'Manager',
        manager_id: 'Manager',
        name: 'Nom',
        planning: 'Planning',
        prestation_type: 'Type de prestation',
        punctual_reason: "Motif de l'affectation ponctuelle",
        related_affectation_id: 'Affectation parente',
        replacement_table: 'Affectations de remplacement',
        schedule: 'Planning',
        search: 'Recherche',
        site_address: 'Site',
        start_date: 'Date de début',
        start_time: 'Début',
        tags: 'Qualifications',
        transit_stop: 'Transports en commun',
        type: 'Type',
      },
      cards: {
        titles: {
          absence_details: "Détails de l'absence",
          details: 'Détails',
          replacement_details: 'Détails du replacement',
          site: 'Site',
        },
      },
      filters: {
        active_contract: 'Contrat actif',
        date: 'Date (active le)',
      },
      forms: {
        contract_cleaner_info: 'Informations sur le contrat et le cleaner',
        cleaner: {
          missing_recurring_hours: 'Heures manquantes du cleaner',
          recurring_work_schedule_per_week: 'Heures effectuées par le cleaner',
        },
        contract: {
          cleaning_duration_per_week: 'Heures vendues',
          permanent_affectation_duration_per_week:
            'Heures hebdomadaires déjà affectées',
        },
        duration_per_week: 'Heures hebdomadaires pour cette affectation',
        required: 'Ce champ est requis',
        cleaners_validation: 'Au moins un cleaner doit être selectionné',
        time_required: 'Horaires requis',
        time_validation: 'Ne peut pas se terminer avant le début',
        minutes_validation: 'Les minutes doivent être un multiple de 5',
        planning_validation: 'Au moins un jour doit être sélectioné',
        type: {
          permanent: 'Permanent',
          replacement: 'Remplacement',
          ponctual: 'Ponctuel',
          absence: 'Absence',
          intercontract: 'Intercontrat',
          partiel: 'Maintien Partiel',
          saisonnier: 'Remplacement Saisonnier',
        },
        prestation_type: {
          Carpet_cleaning: 'Shampoing moquette',
          cleaning: 'Nettoyage',
          glazing: 'Vitrerie',
          handover: 'Passation',
          high_pressure_cleaning: 'Nettoyage haute pression',
          restoration: 'Remise en état',
          permanence: 'Permanence',
        },
      },
      edit: {
        title: {
          assign_affectation: 'Modifier le cleaner',
          end_affectation: 'Mettre une date de fin',
        },
      },
      create: {
        title: 'Créer une affectation',
      },
      actions: {
        absence: 'Absence',
        assign_affectation: 'Assigner',
        detail: 'Détails',
        duplicate: 'Dupliquer',
        end_affectation: 'Terminer',
        replacement: 'Remplacement',
        see_affectations: 'Voir les affectations',
      },
      notify: {
        auto_select_cleaners: 'Envoyer à tous les cleaners disponibles',
        cleaners:
          'Si vous souhaitez que les invitations soient envoyées à des cleaners spécifiques au lieu de tous, entrez leurs noms ci-dessous:',
        invite: 'Inviter',
        invite_cleaner: 'Inviter des Cleaners',
        send: 'Envoyer',
        submit_with_cleaners: 'Envoyer à %{nb_cleaners} cleaner(s)',
        close: 'Fermer',
      },
      tabs: {
        current: 'Affectation active |||| Affectations actives',
        current_short: 'Active |||| Actives',
        history: 'Historique des affectations',
        history_short: 'Historique',
      },
      value: {
        active: {
          true: 'Actif',
          false: 'Inactif',
        },
      },
    },
    cleaner: {
      name: 'Cleaner |||| Cleaners',
      card_title: {
        affectations: 'Affectations',
        check: 'Check',
        cleaner_contract: 'Avenants',
        civil: 'Civil',
        contract: 'Contrat',
        document: 'Documents',
        health_care: 'Santé',
        human_ressources: 'Ressources humaines',
        infos: 'Infos',
        interactions: 'Interactions',
        leave: 'Absences',
        letter: 'Courriers',
        occupational_medecine: 'Médecine du travail',
        other: 'Autre',
        worked_stats: 'Temps travaillé',
      },
      create: {
        contact: 'Enregistrer les informations',
      },
      edit: {
        contact: 'Modifier les informations',
        required: 'Champ obligatoire',
      },
      fields: {
        account_name: 'Compte',
        address: 'Adresse',
        birthdate: 'Date de naissance',
        birthplace: 'Lieu de naissance',
        calendly: 'Calendly',
        classification: 'Classification',
        cleaner_ids: 'Cleaners',
        consistency_error: 'Erreur de cohérence',
        contract_end_date: 'Fin de contrat',
        contract_end_reason: 'Raison de fin de contrat',
        contract_end_type: 'Type de fin de contrat',
        contract_start_date: 'Début de contrat',
        contract_type: 'Type de contrat',
        contract_subtype: 'Sous-type de contrat',
        duree_complement: 'Durée du complément',
        contract_worked_time: 'Temps travaillé contrat',
        day_worked_time: 'Temps travail de jour',
        density: 'Densité',
        drive_folder: 'Drive',
        email: 'E-mail',
        employee_status: 'Statut',
        firstname: 'Prénom',
        full_time: 'Temps plein',
        fullname: 'Nom',
        function: 'Fonction',
        has_missing_recurring_hours: 'Heures manquantes ?',
        health_insurance: 'Mutuelle',
        health_insurance_exemption: 'Dispense de mutuelle',
        health_insurance_exemption_end_date: 'Fin de dispense de mutuelle',
        health_insurance_exemption_file: 'Justificatif de dispense',
        health_insurance_exemption_reason: 'Raison de la dispense de mutuelle',
        hourly_rate: 'Taux horaire',
        intercontract_work_schedule_per_week: 'Total travail intercontrat',
        is_consistent: 'Cohérent',
        is_taken_over: 'Reprise annexe 7 ?',
        is_valid: 'Valide',
        last_modified_date: 'Dernière date de modification',
        last_terms_start_date: 'Dernier avenant',
        lastname: 'Nom',
        level: 'Echellon',
        mailing_additional: 'Complément',
        mailing_city: 'Ville',
        mailing_country: 'Pays',
        mailing_postal_code: 'Code postal',
        mailing_street: 'Rue',
        manager_fullname: 'Manager',
        manager_id: 'Manager',
        match_legal_weekly_work: '<35H',
        match_max_daily_work: 'H/jours',
        match_max_weekly_work: '<44H',
        match_min_daily_rest: 'Repos/jour',
        match_min_weekly_rest: 'Repos/sem',
        min_weekly_hours_due: 'Heures minimales par semaine',
        missing_recurring_hours: 'Heures manquantes',
        mobile_phone: 'Mobile',
        nationality: 'Nationalité',
        nb_worked_days: 'Jours travaillés',
        night_worked_time: 'Temps travail de nuit',
        occupational_medecine: 'Médecine du travail',
        occupational_medecine_date: 'Date de la visite médicale',
        occupational_medecine_file: 'Justificatif de la visite médicale',
        occupational_medecine_reason: 'Raison de la visite médicale',
        payfit_id: 'Id payfit',
        silae_id: 'Id Silae',
        cdd_etudiant: 'CDD étudiant',
        quartier_prioritaire_de_la_ville: 'Quartier prioritaire de la ville',
        phone: 'Téléphone',
        real_hours_computation: 'Calcul au réel dans Payfit',
        recurring_work_schedule_per_week: 'Total récurrent',
        search: 'Recherche',
        seniority_date: "Date d'ancienneté",
        site_address: 'Site',
        social_security_number: 'N° de sécurité sociale',
        residence_permit_number: 'N° de titre de séjour',
        residence_permit_expiry_date: "Date d'expiration du titre de séjour",
        residence_permit_nature: 'Nature du Titre de séjour',
        spvie_id: 'Id SPVIE',
        status: 'État',
        sunday_worked_time: 'Temps travail le dimanche',
        tags: 'Qualifications',
        total_worked_time: 'Total travaillé',
        transportation_help: 'Subvention transport',
        traveltime: 'Temps de trajet',
        trial_period_renewal: "Renouvellement de période d'essai",
        trial_period_renewal_signing: 'Signature du renouvellement de P.E',
        type: 'Type',
        vacancy_time: 'Temps vacant',
      },
      form: {
        required: 'Requis',
        consistency_level:
          'Echellon %{level} doit correspondre à la function %{function}',
        choose_cleaner: 'Veuillez choisir un Cleaner',
        empty_for_office: 'Doit être vide pour les employés du bureau',
        empty_for_team_leader: "Doit être vide pour les chefs d'équipes",
        min_hourly_rate:
          "Ne peut être < à %{hourly_rate} pour l'échellon %{level} - %{classification}",
        max_hourly_rate: 'Ne peut être > %{limit}',
        type: {
          former: 'Ancien salarié',
          current: 'Salarié actuel',
          applicant: 'Vivier salarial',
        },
      },
      function: {
        cleaner: 'Cleaner',
        hotel_staff: 'Hôtellerie',
        event_staff: 'Événementiel',
        team_leader: "Chef d'équipe",
        glazier: 'Vitrier',
        handyman: 'Polyvalent',
        manager: 'Manager',
        customer_service: 'Service client',
        office_employee: 'Employé de bureau',
      },
      cards: {
        actives: 'Actives',
        history: 'Historique',
        titles: {
          cleaner_information: 'Informations sur le cleaner',
        },
      },
      mission: {
        title: 'Avenant',
        object:
          'Le présent ordre de mission précise les chantiers et les ' +
          'horaires de travail du salarié sur chacun des chantiers. ' +
          'Il est une annexe au contrat de travail.',
        cleanerInfo: 'Coordonnées du cleaner',
        workingTime:
          'Temps de travail hebdomadaire récurrent : %{duration} heures',
        done: 'Fait à %{city}, le %{date}',
        cleanerSign: 'Le salarié',
        cleanySign: 'Alexandre Bellity - Président',
        legal:
          'IMMONET, Société par Action Simplifiées immatriculée au RCS ' +
          'Bobigny sous le numéro 539 593 319 00053, dont le siège social ' +
          "est situé au 6, rue de l'hippodrome, 93400 Saint-Ouen",
      },
      actions: {
        createLeave: 'Créer une absence',
        download: 'Télécharger',
        download_certificat: 'Attestation de travail',
        download_dismissalConvocation: '🔔 Convocation EPAL',
        download_endOfTrialPeriod: "Fin de période d'essai",
        download_trialPeriodRenewal: "Renouvellement de période d'essai",
        initDrive: 'Initialiser le dossier Drive',
        interaction: 'Interaction',
        lrar: '✅ Recommandé',
      },
      modal: {
        title: 'Téléchargement des heures des Cleaners pour un mois',
        download: 'Télécharger',
        download_csv: 'Télécharger les heures',
        close: 'Fermer',
        choose_period: 'Choisir un jour du mois à calculer',
        choose_date: 'Choisir la date',
        choose_time: "Choisir l'heure",
      },
      tabs: {
        affectations: 'Affectations',
        timeline: 'Timeline',
        miscellanous: 'Infos',
        documents: 'Documents',
      },
      timeline: {
        no_timeline: "Il n'y a rien a afficher pour le moment",
      },
    },
    cleaner_contract: {
      name: 'Avenant au contrat |||| Avenants au contrat',
      actions: {
        sign: 'Signer',
        cancelProcedure: 'Annuler',
      },
      aside: {
        title: 'Détails',
        no_value: 'Veuillez sélectionner un Cleaner',
      },
      forms: {
        required: 'Champ obligatoire',
      },
      fields: {
        cleaner_id: 'Cleaner',
        classification: 'Classification',
        start_date: 'Début',
        contract_type: 'Type de contrat',
        contract_subtype: 'Sous-type de contrat',
        duree_complement: 'Durée du complément',
        full_time: 'Temps plein',
        hourly_rate: 'Taux horaire',
        level: 'Echellon',
        contract_worked_time: 'Temps de travail',
        comment: 'Commentaire',
        detail: 'Détails contrat',
        procedure: 'Procedure Yousign',
        procedure_status: {
          active: 'Actif',
          draft: 'Démarré',
          expired: 'Expiré',
          finished: 'Terminé',
          refused: 'Refusé',
          unknown: 'Non lancé',
        },
      },
    },
    cleaner_document: {
      dialog: {
        title: 'Téléverser un document',
        button: '♻️ Téléverser',
      },
      create: '♻️ Téléverser',
      name: 'Document Cleaner |||| Documents Cleaners',
      fields: {
        cleaner_id: 'Cleaner',
        comment: 'Commentaire',
        status: 'Statut',
        subtype: 'Type de document',
        type: 'Type',
        upload_date: 'Date de téléversement',
        validation_date: 'Date de validation',
        validator_id: 'Validateur',
      },
      status: {
        APPROVED: 'Approuvé',
        PENDING: 'En cours',
        REJECTED: 'Refusé',
      },
      subtype: {
        acs_attestation: 'Attestation ACS',
        advance_payment: "Demande d'accompte",
        avenant: 'Avenant au contrat',
        babysitting_attestation: "Attestation de garde d'enfant(s)",
        contrat: 'Contrat de travail',
        birth_certificat: 'Acte de naissance',
        cmu_attestation: 'Attestation de CMU',
        image: "Attestation de droit à l'image",
        depot_dossier_prefecture: 'Preuve dépot dossier préfecture',
        accuse_due: 'Accusé DUE mutuelle',
        final_settlement: 'Reçu pour solde de tout compte',
        reconnaissance_travailleur_handicape:
          'Reconnaissance de la qualité de travailleur handicapé',
        family_book: 'Extrait du livret de famille',
        reconnaissance_AT_MP: 'Reconnaissance AT-MP',
        home_receipt: 'Justificatif de domicile',
        host_attestation: "Attestation sur l'honneur d'hébergement",
        host_id_card: "Pièce d'identité de l'hébergeur",
        id_card_back: 'CI verso',
        id_card_front: 'CI recto',
        insurance_attestation: 'Attestation de mutuelle',
        insurance_refusal: 'Dispense de mutuelle',
        insurance_subscription: "Bulletin d'inscription à la mutuelle",
        bulletin_repris: 'Bulletin de paie repris',
        invalidity_attestation: "Attestation d'invalidité",
        attestation_etudiante: 'Attestation/Carte étudiant',
        keys_hand_over: 'Remise de clé',
        maternity_leave_cerfa: 'Cerfa premier examen prénatal',
        maternity_leave_proof: 'Certificat de grossesse',
        death_certificat: 'Certificat de décès',
        work_autorisation: "Demande d'autorisation de travail employeur",
        maternity_leave_request: 'Demande de congés maternité / congé parental',
        pass: 'Carte navigo',
        passport: 'Passeport',
        receipt: 'Facture',
        resignation_letter: 'Lettre de demission',
        rib: 'RIB',
        sick_leave: 'Arrêt maladie',
        trial_end: "Fin de période d'essai",
        trial_renewal: "Renouvellement de période d'essai",
        visa_receipt: 'Récépissé de demande de titre de séjour',
        visa_recto: 'Titre de séjour recto',
        visa_verso: 'Titre de séjour verso',
        vitale_attestation: 'Attestation sécurité sociale',
        visa_authentification: 'Authentification Titre de Séjour Préfecture',
        autre_absence: "Autre justificatif d'absence",
        vitale_card: 'Carte vitale',
        waiver_letter: 'Desistement de site',
        work_injury: 'Accident de travail',
        occupational_medecine_attestation:
          'Attestation de visite à la médecine du travail',
        occupational_medecine_convocation:
          'Convocation de visite à la médecine du travail',
        avertissement: 'Avertissement',
        AR_demission: 'Accusé réception de démission',
        EPAL: 'Convocation EPAL',
        prefecture: 'Convocation préfecture',
        medical_visit: 'Convocation visite médicale',
        DPAE: "Déclaration préalable à l'embauche",
        MED: 'Mise en demeure',
        notice_of_dismissal: 'Notification de licenciement',
        TAKEN_OVER: "Transfert d'annexe 7",
        contract_transfer: 'Transfert de contrat',
        going_retirement: 'Départ en retraite',
      },
      type: {
        advance_payment: 'Accompte',
        bank: 'Banque',
        contract: 'Contrat',
        health_care: 'Santé',
        home: 'Domicile',
        identity: 'Identité',
        key_retrieval: 'Clé',
        leave: "Justificatif d'absence",
        occupational_medecine: 'Médecine du travail',
        lrar: 'Courrier Récommandé',
        transportation: 'Transports',
      },
    },
    cleaner_letter: {
      name: 'Courrier Cleaner |||| Courriers Cleaners',
      fields: {
        cleaner_id: 'Cleaner',
        created_date: 'Date de création',
        description: 'Description',
        distributed_date: 'Date de distribution',
        extrernal_id: 'ID Seeuletter',
        first_distributed_date: 'Première date de distribution',
        owner_id: 'Envoyé par',
        postage_type: "Type d'envoi",
        sent_date: "Date d'envoi",
        status: 'Status',
        subject: 'Sujet',
        tracking_number: 'Numéro de suivi',
        type: 'Type',
        updated_date: 'Date de mise à jour',
      },
      values: {
        status: {
          ACCEPTED:
            "Cette lettre a été acceptée par le système d'impression et d'expédition.",
          CANCELED: 'Cette lettre a été annulée.',
          CREATED: 'Cette lettre a été créée.',
          DELIVERY_PROOF: 'Cette lettre a reçu une preuve de livraison.',
          DISTRIBUTED: 'Cette lettre a été distribuée.',
          ERROR: "Cette lettre est dans un état d'erreur.",
          FILLING_PROOF: 'Cette lettre a reçu une preuve de dépôt.',
          IN_TRANSIT: 'Cette lettre est en transit.',
          RETURNED_TO_SENDER: "Cette lettre a été retournée à l'expéditeur.",
          SENT: 'Cette lettre a été envoyée.',
          WAITING_TO_BE_WITHDRAWN:
            "Cette lettre est en attente d'être retirée par le destinataire.",
          WRONG_ADDRESS:
            'Cette lettre a reçu un événement "N\'habite pas à l\'adresse indiqué" de La Poste.',
        },
      },
    },
    client: {
      name: 'Client |||| Clients',
      fields: {
        account_id: 'Compte',
        email: 'E-mail',
        firstname: 'Prénom',
        function: 'Fonction',
        lastname: 'Nom',
        mobile_phone: 'Mobile',
        name: 'Nom',
        phone: 'Téléphone',
      },
    },
    contract: {
      name: 'Contrat |||| Contrats',
      fields: {
        access: 'Accès',
        account_id: 'Compte',
        account_name: 'Compte',
        consumables_delivery: 'Consommables',
        salesman_phone: 'Commercial',
        salesman_fullname: 'Commercial',
        billing_email: 'Facturation',
        effective_date: 'Début',
        end_date: 'Fin',
        manager_id: 'Manager',
        manager_fullname: 'Manager',
        name: 'Nom',
        operational_id: 'Opérationel',
        ordernumber: 'Identifiant Contract Cleany',
        phase: 'Phase',
        commercial_id: 'Acheteur',
        search: 'Recherche',
        site_address: 'Adresse',
        site_type: 'Type de site',
        status: 'État',
        total_amount: 'Montant total',
        type: 'Type',
        importance: 'Importance',
        permanent_affectation_duration_per_week: 'Heures faites',
        cleaning_duration_per_week: 'Heures vendues',
      },
      importance: {
        standard: 'Standard',
        high: 'Prioritaire',
      },
      tabs: {
        detail: 'Détail',
        affectation: 'Cleaner',
        specification: 'Cahier des charges',
        cleaning: 'Nettoyage',
        glazing: 'Vitrerie',
      },
      edit: {
        title: 'Modifier les accès',
      },
      button: {
        notification: 'Notifier manager',
        invoice: 'Facture',
        pre_computed_invoice: 'Pré-facture',
      },
      notification: {
        mail_manager: {
          success: 'Mail envoyé au manager',
          fail:
            "Une erreur est survenue : pas d'affectation pour ce contrat ou problème interne de serveur",
        },
      },
    },
    contract_client: {
      name: 'Contrat |||| Contrats',
      title: 'Contrats',
      fields: {
        billing: 'Facturation',
        manager: 'Manager',
        operation: 'Opération',
        salesman: 'Commercial',
      },
      cards: {
        title_permanent: 'Contrat récurrent',
        title_ponctual: 'Contrat ponctuel',
        client: 'Contact client',
        cleany: 'Contact cleany',
        product: 'Détails produits',
      },
      actions: {
        leave: 'Absence',
        leaveForThisContract: 'Absence sur ce contrat',
      },
      specifications: 'Cahier des charges',
      see_specifications: 'Voir le cahier des charges',
    },
    genepi: {
      name: 'Cleaners disponibles',
      fields: {
        density: 'Densité',
        total_worked_time: 'Total hebdo',
        vacancy_time: 'Temps vacants',
        traveltime: 'Temps de trajet',
      },
    },
    gocardless_payment: {
      name: 'Prélèvement |||| Prélèvements',
      fields: {
        amount: 'Montant',
        charge_date: 'Date de prélèvement',
        description: 'Description',
        mandate_id: 'Id mandat',
        status: 'Status',
      },
    },
    invoice: {
      name: 'Facture |||| Factures',
      last_invoice: 'Dernière facture',
      see_all_invoices: 'Voir toutes les factures',
      detail_invoice: 'Détail de la facture',
      no_invoice: 'Aucune facture',
      table_fields: {
        products: 'Nom du produit',
        quantity: 'Quantité du produit',
        price: 'Prix du produit',
      },
      fields: {
        account_name: 'Nom',
        account_id: 'Compte',
        billing_address: 'Adresse de facturation',
        frequency: 'Fréquence',
        invoice_number: 'N°',
        due_date: 'Date',
        cleaning_amount: 'Nettoyage',
        glazzing_amount: 'Vitrerie',
        consumable_amount: 'Conso.',
        bin_amount: 'Poubelles',
        other_amount: 'Autre',
        amount: 'Total HT',
        total_tax: 'TVA',
        total_amount: 'Total TTC',
        status: 'Statut',
        search: 'Recherche',
      },
      values: {
        status: {
          OPEN: 'À payer',
          CLOSED: 'Payé',
          CANCELLED: 'Annulé',
          null: 'Non renseigné',
        },
      },
    },
    management: {
      name: 'Management',
      fields: {
        manager_fullname: 'Manager',
        manager_phone: 'Téléphone',
      },
    },
    ticket: {
      name: 'Ticket |||| Tickets',
      fields: {
        assignee_id: 'Propriétaire',
        created_at: 'Création',
        description: 'Description',
        organization: 'Organisation',
        organization_id: 'Compte',
        priority: 'Priorité',
        requester_id: 'Demandeur',
        status: 'Status',
        subject: 'Sujet',
        updated_at: 'Mise à Jour',
      },
    },
    cleaner_leave: {
      name: 'Absence |||| Absences',
      fields: {
        cleaner_id: 'Cleaner',
        contract_id: 'Contrat',
        end_date: 'Date de fin',
        processed: 'Traité',
        search: 'Recherche',
        start_date: 'Date de début',
      },
      tabs: {
        current: 'Absence active |||| Absences actives',
        current_short: 'Active |||| Actives',
        history: 'Historique des absences',
        history_short: 'Historique',
      },
    },
    leave: {
      name: 'Absence |||| Absences',
      simple_name: 'Absence',
      actions: {
        create_replacement_all: 'Tout remplacer',
        create_replacement_distribute: 'Distribuer',
      },
      aside: {
        title: 'Affectations',
      },
      fields: {
        cleaner_id: 'Cleaner',
        cleaner_fullname: 'Cleaner',
        comment: 'Commentaire',
        contract_id: 'Contrat',
        end_date: 'Date de fin',
        half_day: 'Demi-journée',
        half_start_time: "Heure de début de l'après-midi",
        half_end_time: 'Heure de fin du matin',
        leaves: 'Absences',
        manager: 'Manager',
        no_affectation: "Pas d'affectation",
        no_replacement: 'Pas de remplacement',
        processed: 'Traité',
        replacements: 'Remplacements',
        search: 'Recherche',
        start_date: 'Date de début',
      },
      forms: {
        required: 'Requis',
        days_validation: 'Au moins un jour doit être sélectioné',
        half_day_validation:
          "L'horaire choisi ne correspond pas au milieu de journée",
        all: 'Tous',
      },
      create: {
        title: 'Créer une absence',
      },
    },
    replacement: {
      name: 'Remplacement |||| Remplacements',
      simple_name: 'Remplacement',
      fields: {
        end_date: 'Date de fin',
        start_date: 'Date de début',
        cleaner_id: 'Cleaner',
        cleaner_ids: 'Cleaners',
        affectations_to_replace:
          'Affectations à remplacer pour cette absence. Pour remplacer toutes les affectations, laissez les cases déselectionnées',
        leave_dates: "Dates d'absence",
        replace: 'Remplacer',
      },
      create: {
        title: 'Nouveau remplacement',
        buttons: {
          details: 'Détails',
        },
        aside: {
          title: 'Détails',
        },
      },
    },
    scheduler: {
      name: 'Scheduler',
      granularity: {
        year: 'année',
        quarter: 'trimestre',
        month: 'mois',
        week: 'semaine',
        day: 'jour',
        hour: 'heure',
        minute: 'minute',
      },
      fields: {
        granularity: 'Granularité',
        period: 'Période',
        next_execution_date: 'Date de prochaine exécution',
      },
      buttons: {
        launch: 'Démarrer',
        active: 'Activer',
        disable: 'Desactiver',
      },
    },
    opportunity_detail: {
      name: 'Opportunité |||| Opportunités',
    },
    job_status: {
      fields: {
        name: 'Nom',
        start_datetime: 'Début',
        end_datetime: 'Fin',
        duration: "Temps d'exécution",
        status: 'Statut',
      },
    },
    logs: {
      details: {
        delivered: 'Commande livrée',
        requested: 'Répond à une demande',
      },
      name: 'Log |||| Logs',
      no_interaction_logs: 'Aucune interaction',
      fields: {
        actions: 'Actions correctives',
        account: {
          name: 'Nom du compte',
          address: 'Adresse',
        },
        affectation: {
          name: 'Site',
          address: 'Adresse',
        },
        answers: {
          positive: 'Points positifs',
          improvement: "Points d'amélioration",
        },
        cleaner: {
          name: 'Nom',
        },
        contact: {
          fullname: 'Nom',
          email: 'Email',
          phone: 'Téléphone',
        },
        owner: {
          name: 'Créé par',
        },
        comment: 'Commentaire',
        contact_type: 'Méthode de contact',
        date: 'Date',
        deadline: 'Date limite de livraison',
        delivered: 'Commande livrée',
        description: 'Description',
        details: 'Détails',
        distributed_date: 'Date de distribution',
        first_distributed_date: 'Date de première distribution',
        global_rating: 'Note',
        hide_preparatory_comment: 'Cacher le commentaire de préparation',
        tracking_link: 'Lien de suivi',
        logType: 'Type',
        number_rooms: 'Nombre de pièces',
        preparatory_comment: 'Commentaire de préparation',
        provider: 'Fournisseur',
        requested: 'Répond à une demande',
        salesforce_id: 'Id salesforce',
        scheduled_date: 'Date',
        sent_date: "Date d'envoi",
        show_preparatory_comment: 'Montrer le commentaire de préparation',
        status: 'Statut',
        subject: 'Sujet',
        tags: 'Catégories',
        type: 'Type',
      },
      actions: {
        agent_request: "Demande d'agent du vivier",
        bill_issue_report: 'Signaler un problème de facturation',
        consumable_delivery_request: 'Demande de livraison de consommables',
        create_interaction: 'Déclarer un échange client',
        create_quality: 'Créer un contrôle qualité',
        create_visit: 'Déclarer une visite',
        disciplinary_action: 'Demander une mesure disciplinaire',
        edit_interview: 'Entretien',
        extra_hours_report: 'Déclarer des heures supplémentaires',
        glazing_request: 'Demande de passage vitrerie',
        learning_request: 'Demander une formation',
        declare_affectation: 'Déclarer une affectation',
        devis: '📑 Demande de devis',
        interview: {
          NEW: "Programmer l'entretien",
          SCHEDULED: "Préparer l'entretien",
          PREPARED: "Réaliser l'entretien",
        },
        leave_report: 'Signaler une absence',
        payroll_issue: 'Signaler un problème de paie',
        product_delivery: 'Déclarer une livraison de produit',
        product_delivery_request: 'Demande de livraison de produits',
        quote_request: 'Demande de devis',
        handyman_mission: 'Déclarer un passage de polyvalent',
      },
      dialog: {
        open: 'Détails',
      },
      edit: {
        interview: {
          pointModel: 'Retournez à la ligne après chaque point entré.',
          pointSample: 'Point',
        },
      },
      log_type: {
        cleanerLeave: 'Absence',
        cleanerContractAmendment: 'Avenant au contrat de travail',
        cleanerContractEnd: 'Fin du contrat de travail',
        cleanerContractStart: 'Début du contrat de travail',
        cleanerInteraction: 'Interaction cleaner',
        cleanerLetter: 'Lettre recommandé',
        cleanerAffectation: 'Affectation',
        cleanerTrialPeriodRenewal: "Renouvellement de période d'essai",
        deliveryRequest: 'Demande de livraison',
        delivery: 'Livraison',
        interaction: 'Interaction',
        interview: 'Entretien',
        leave: 'Absence',
        quality: 'Qualité',
        quote: 'Demande de devis',
        visit: 'Visite',
      },
      modal: {
        quality: {
          body: 'Etes-vous sur de vouloir supprimer ce contrôle qualité ?',
          cancel: 'Annuler',
          close: 'Fermer',
          delete: 'Supprimer',
          show: 'Voir',
          title: 'Supprimer ce contrôle qualité',
        },
      },
      titles: {
        deliveryRequest: 'Demande de livraison |||| Demandes de livraison',
        delivery: 'Livraison |||| Livraisons',
        leave: 'Absence |||| Absences',
        quote: 'Demande de devis |||| Demandes de devis',
        interaction: 'Interaction |||| Interactions',
        quality: 'Contrôle qualité |||| Contrôles qualité',
        visit: 'Visite |||| Visites',
        interview: 'Entretien Cleaner |||| Entretiens Cleaner',
      },
      values: {
        logs_type: {
          deliveryRequest: 'Demande de livraison',
          delivery: 'Livraison',
          interaction: 'Interaction',
          interview: 'Entretien',
          leave: 'Absence',
          quality: 'Qualité',
          quote: 'Demande de devis',
          visit: 'Visite',
        },
        interview_status: {
          NEW: 'A programmer',
          SCHEDULED: 'A préparer',
          PREPARED: 'Prêt',
          DONE: 'Fait',
        },
      },
      form: {
        cancel: 'Annuler',
        contact_interaction: 'Contacté par',
      },
    },
    logsInterview: {
      form: {
        schedule_interview_error:
          "La date entrée ne correspond pas aux jours travaillés de l'affectation sélectionnée.",
      },
    },
    logsQuality: {
      name: 'Contrôle qualité |||| Contrôles qualités',
      no_quality_logs: 'Aucun contrôle qualité',
      no_room_checked: 'Encore aucune pièce contrôlée',
      rooms: {
        addTitle: 'Ajouter une pièce',
        editTitle: 'Editer la pièce',
        fields: {
          building: 'Zone',
          roomType: 'Type de pièce',
          roomName: 'Nom de la pièce',
          comment: 'Commentaires additionnels',
          rating: 'Note de la pièce',
          addCorrectiveAction: 'Ajouter une action corrective',
          removeCorrectiveAction: "Retirer l'action corrective",
          correctiveAction: 'Action corrective |||| Actions correctives',
        },
        add_room: 'Ajouter une pièce',
        cancel: 'Annuler',
        add: 'Ajouter',
        edit: 'Valider',
      },
      specification: 'Cahier des charges',
      warningTitle: 'Attention',
      warningContent:
        "Vous avez déjà un contrôle qualité en cours. Merci de le terminer ou de l'abandonner.",
    },
    account_leave: {
      fields: {
        cleaner_id: 'Cleaner',
        contract_id: 'Contrat',
        end_date: 'Date de fin',
        processed: 'Traité',
        start_date: 'Date de début',
      },
    },
    available_cleaners: {
      fields: {
        address: 'Adresse',
        contract_start_date: 'Début de contrat',
        density: 'Densité',
        fullname: 'Nom',
        manager_fullname: 'Manager',
        missing_recurring_hours: 'Heures manquantes',
        tags: 'Qualifications',
        total_worked_time: 'Total hebdo',
        traveltime: 'Temps de trajet',
        vacancy_time: 'Temps vacants',
      },
    },
    presence_detection: {
      name: 'Détection de présence',
      groups: {
        affectation: 'Affectation',
        device_movement: 'Mouvement de capteur',
        mission_report: 'Rapport de mission',
      },
      form: {
        submit: 'Voir la présence sur la semaine',
        validate: {
          no_account: 'Un compte doit être sélectionné',
        },
      },
      legend: {
        affectation_type: {
          title: "Type d'affectation",
          permanent: 'Permanent',
          replacement: 'Remplacement',
          ponctual: 'Ponctuel',
          absence: 'Absence',
          intercontract: 'Intercontrat',
        },
        confidence: {
          title: 'Confiance du signal capteur',
          subtitle:
            'Les plages de données des capteurs sont calculées à partir des données transmises. Ces données peuvent comporter des erreurs. La couleur permet de savoir le niveau de fiabilité de la plage de données',
          good: 'Sûr',
          bad: 'Incertain',
        },
      },
    },
    invoice_incubator: {
      name: 'Pré-Facture |||| Pré-factures',
      action: {
        create: 'Régularisation',
      },
      fields: {
        account_id: 'Compte',
        billing_id: 'Compte de facturation',
        description: 'Description',
        generated: 'Généré',
        invoice_number: 'Numéro de facture',
        month: 'Mois',
        price: 'Prix',
        product: 'Produit',
        type: 'Type',
      },
    },
    issue_report: {
      name: 'Rapport de problème |||| Rapports de problème',
      actions: {
        setToDone: 'Terminer',
      },
      fields: {
        affectation: {
          account: {
            name: 'Compte',
          },
        },
        comment: 'Commentaire',
        created_at: 'Création',
        issueType: {
          ELECTRICITY: 'Electrique',
          LEAK: 'Fuite',
          SPOT: 'Taches anormales',
          ELEVATOR: 'Ascenseur',
          CLIENT: 'Consigne client',
          OTHER: 'Autre',
        },
        manager_fullname: 'Manager',
        mobile_phone: 'Téléphone',
        owner: {
          id: 'Cleaner',
          name: 'Cleaner',
        },
        photo: 'Photo',
        reason: {
          badAccess: 'Mauvais accès',
          badAddress: 'Mauvaise adresse',
          other: 'Autre',
        },
        reason_cannot_enter: "Raison empêchant d'entrer",
        status: {
          DONE: 'Terminé',
          PENDING: 'En cours',
        },
        type: {
          prestation: 'Prestation',
          cannotEnter: "Impossible d'entrer",
          affectationError: "Erreur d'affectation",
        },
        updated_at: 'Mise à jour',
      },
      filters: {
        account: 'Compte',
        owner: 'Propriétaire',
        status: {
          DONE: 'Terminé',
          PENDING: 'En cours',
        },
        type: {
          prestation: 'Prestation',
          cannotEnter: "Impossible d'entrer",
          affectationError: "Erreur d'affectation",
        },
      },
    },
    provider: {
      name: 'Fournisseur |||| Fournisseurs',
      fields: {
        id: 'Id',
        last_action: 'Dernière livraison',
        next_action: 'Prochaine livraison',
        provider: 'Fournisseur',
        provider_ref_id: 'Réference fournisseur',
      },
    },
    provider_item: {
      name: 'Détail fournisseur |||| Détails fournisseur',
      fields: {
        last_action: 'Dernière livraison',
        next_action: 'Prochaine livraison',
        period: 'Fréquence livraison',
        provider_item_name: 'Item',
        provider_item_ref_id: 'Référence item',
        provider: 'Fournisseur',
        type: 'Type',
      },
      title: {
        equipment: 'Equipements',
        supply: 'Consommables',
      },
    },
    provider_invoice: {
      name: 'Facture fournisseur |||| Factures fournisseur',
      actions: {
        upload: 'Créer depuis PDF ou CSV',
      },
      dialog: {
        title: "Générer des données de facture à partir d'un document",
        upload_button: '♻️ Téléverser',
        ok_button: 'Ok',
      },
      fields: {
        amortization: "Temps d'amortissement (mois)",
        amount: 'Montant',
        date: 'Date',
        provider_account_id: 'Fournisseur',
        invoice_number: 'Numéro de la facture',
        contract_id: 'Contrat',
      },
      filters: {
        date: 'Date (après le)',
      },
      unit: {
        month: 'mois',
      },
    },
    action: {
      name: 'Action |||| Actions',
      fields: {
        account_id: 'Compte',
        assignee_id: 'Exécutant',
        category: 'Catégorie',
        comment: 'Commentaire',
        creator_id: 'Propriétaire',
        description: 'Description',
        done_date: "Date d'exécution",
        due_date: 'Echéance',
        status: 'Status',
        title: 'Sujet',
        type: 'Type',
      },
      forms: {
        category: {
          DELIVERY: 'Livraison',
          AFFECTATION_CHECK: "Horaires de l'affectation à vérifier",
          NO_SHOW_CHECK: 'Potentielle absence sur site',
          OTHER: 'Autre',
        },
        status: {
          KO: 'KO',
          OK: 'OK',
          PARTIAL: 'Partiel',
          PENDING: 'En cours',
        },
        type: {
          CHECK: 'Vérification',
          TO_DO: 'Tâche à faire',
        },
      },
      no_data: 'Aucune action',
      title: {
        action: 'Actions',
      },
    },
  },
  errors: {
    contract: {
      no_mission: 'Mission introuvable !',
    },
  },
  format: {
    date: {
      period: 'Du %{start_date} au %{end_date}',
      since: 'Depuis le %{start_date}',
      format: 'DD/MM/YYYY',
      day: 'jour',
      schedule: 'horaires',
      days: {
        monday: 'Lundi',
        tuesday: 'Mardi',
        wednesday: 'Mercredi',
        thursday: 'Jeudi',
        friday: 'Vendredi',
        saturday: 'Samedi',
        sunday: 'Dimanche',
      },
    },
  },
  login: {
    username: 'Identifiant',
    password: 'Mot de passe',
    sign_in: 'Connexion',
    cleaning:
      'Il est primordial de maintenir les espaces de travail propres pour le confort des collaborateurs et des clients.',
    glazing:
      'Nettoyage ponctuel ou récurrent de la vitrerie intérieure/extérieure accessible.',
    tasks:
      "Besoin d'aide pour réparer ou colmater ? Nos Helpers sont présents à tout moment.",
    supply: 'Tout ce dont vous avez besoin, au moment où vous en avez besoin.',
    snacks:
      "Commander des repas, des apéros ou organiser des évènements, c'est possible.",
    forgot_password_button: 'Mot de passe oublié ?',
    mail_forgotten_password: 'Votre adresse mail',
    confirmation: 'ENVOYER',
    emailSended: 'Mail envoyé',
    back: 'Retour',
    password_reset: 'Nouveau mot de passe',
    password_reset2: 'Confirmer',
    password_reset_success:
      'Votre mot de passe a été réinitialisé avec succès !',
    different_passwords: ' mots de passe différents',
    first_connection: 'Première connexion',
    form: {
      required: 'Champ obligatoire',
    },
  },
  notification: {
    legal_document: {
      success: 'Document créé',
      fail: 'Informations manquante : %{message}',
    },
    init_drive: {
      success: 'Drive du cleaner initialisé',
      fail: "Erreur durant l'initialisation du drive du cleaner",
    },
    invite_cleaner: {
      success: 'Invitation envoyée à %{invitationsSent} cleaner(s)',
      fail: "%{message} manquant(e) à propos de l'affectation",
    },
    invoice_download: {
      success: 'Facture téléchargée',
      fail: 'Echec du téléchargement de la facture',
    },
    issue_report: {
      setToDone: {
        success: 'Le problème a été marqué comme résolu',
        fail: "Erreur de l'édition du rapport de problème",
      },
    },
    create_replacement: {
      success: 'Remplacement créé',
      fail: 'Echec de la création du remplacement',
    },
    quality_logs: {
      success: 'Le récapitulatif du contrôle qualité a bien été envoyé',
      fail: "Echec dans l'envoi du récapitulatif du contrôle qualité",
      download_success:
        'Le récapitulatif du contrôle qualité a bien été téléchargé',
      download_fail:
        'Echec dans le téléchargement du récapitulatif du contrôle qualité',
    },
    save_affectation_bundle: {
      success: '%{numberAffectation} Affectation(s) créée(s) avec succès',
      fail: "Echec de la création de l'affectation",
    },
    sign_contract: {
      success: 'Procédure de signature électronique créée avec succès',
      fail: 'Echec de la création de la procédure de signature électronique',
    },
    upload_document: {
      success: 'Document téléversé',
      fail: 'Echec du téléversement',
    },
    upload_invoice: {
      fail: 'Echec du téléversement du fichier',
    },
  },
  sidebar: {
    buttons: {
      logout: 'Déconnexion',
    },
  },
  zendesk: {
    name: 'Tickets',
    no_data: 'Aucun ticket',
    dialog: {
      open: 'Détails',
    },
    error: {
      notImplemented: 'Pas Implémenté',
    },
    priorities: {
      low: 'Basse',
      normal: 'Normale',
      high: 'Haute',
      urgent: 'Urgente',
    },
    statuses: {
      closed: 'Fermé',
      hold: 'Suspendu',
      new: 'Nouveau',
      open: 'Ouvert',
      pending: 'En attente',
      solved: 'Résolu',
    },
  },
  components: {
    button: {
      download: {
        text: 'Télécharger',
      },
      leave: {
        createLeave: 'Créer une absence',
        createLeaveForThisContract: 'Créer une absence sur ce contrat',
      },
      timeline: {
        details: 'Détails',
      },
    },
    dialog: {
      close: 'Fermer',
      open: 'Ouvrir',
    },
    field: {
      absence_type: {
        UNAUTHORIZED_LEAVE_UNPAID: '✔️ Absence autorisée (non payée)',
        LEAVE_ABSENCE_PAID: '🤑 Absence autorisée (rémunérée)',
        UNAUTHORIZED_LEAVE: '❌ Absence injustifiée (non payée)',
        COMMUTE_INJURY: '🚇 Accident de trajet',
        WORK_INJURY: '⚠️ Accident de travail',
        TRAINING_LEAVE: '🎓 Congé Formation',
        PARENTAL_LEAVE: '🍼 Congé parental',
        PATERNITY_LEAVE: '👨‍🍼 Congé paternité',
        BIRTH_LEAVE: '👶 Congé naissance',
        FAMILY_LEAVE: '💒 Congé pour événement familial',
        UNPAYED_LEAVE: '🏝️ Congé sans solde',
        PAYED_LEAVE: '💸 Congés payés',
        SICK_CHILD: '🤒 Enfant malade',
        CLOSED_SITE: '🔒 Fermeture de site',
        ILLNESS: '🤧 Maladie ordinaire',
        WORK_ILLNESS: '🩹 Maladie professionnelle',
        MATERNITY_LEAVE: '🤰 Maternité',
        CONSERVATORY_LAID_OFF: '⌛ Mise à pied à titre conservatoire',
        DISCIPLINARY_LAID_OFF: '😠 Mise à pied à titre disciplinaire',
        SABBATICAL_LEAVE: '🤠 Congé sabbatique',
      },
      action_category: {
        DELIVERY: 'Livraison',
        AFFECTATION_CHECK: "Horaires de l'affectation à vérifier",
        NO_SHOW_CHECK: 'Potentielle absence sur site',
        OTHER: 'Autre',
      },
      action_status: {
        KO: 'Non fait',
        OK: 'Fait',
        PARTIAL: 'Partiel',
        PENDING: 'En cours',
        REJECTED: 'Rejeté',
      },
      action_type: {
        CHECK: 'Check',
        TO_DO: 'To do',
      },
      cleaner_contract_spec: {
        full_time: {
          true: 'Temps plein',
          false: 'Temps partiel',
        },
      },
      contract_end_reason: {
        INSUFFICIENT_HOURS: "⏰ Pas assez d'heures",
        WORKLOAD: '🤖 Surcharge de travail',
        MANAGEMENT: '📋 Management',
        TOO_LONG_TRAVEL_TIME: '🚈 Temps de trajet trop long',
        PHYSICAL_WORK: '💪 Travail physique',
        BETTER_OPPORTUNITY: '📈 Meilleure opportunité professionnelle',
        NO_REASON: '🤷‍♀️ Sans raison',
        ABUSIVE_BEHAVIOR: '😡 Comportement abusif',
        UNAUTHORIZED_LEAVES: '💤 Absences injustifiées',
        RECURRINGLY_LATE: '🕤 Retards répétés',
        UNRESPECTED_SPECIFICATIONS: '😵‍💫 Non respect du cahier des charges',
        MISSING_DOCUMENTS: '📂 Documents manquants',
        PROFESSIONAL_INSUFFICIENCY: '😨 Insuffisance professionnelle',
        MISSION_REFUSAL: '🤮 Refus de missions (Annexe 7)',
        ABANDONMENT_OF_POST: '🏃 Abandon de poste',
        SECURITY_RULES_BREAKING:
          "🦠 Manquement aux règles d'hygiène et de sécurité",
        FORGED_DOCUMENTS: '📝Documents falsifiés',
        IDENTITY_THEFT: "🕵️‍♂️ Usurpation d'identité",
        THEFT_OR_DEGRADATION: '👎 Vol ou dégradation',
        VIOLENCE_OR_INSULT: '💢 Violence ou injure',
        INSUBORDINATION: '🤬 Insubordination',
        INJURY_TO_EMPLOYER: "🚷 Préjudice à l'employeur",
        null: 'Aucune',
        undefined: 'Aucune',
      },
      contract_end_type: {
        RESIGNATION: 'Démission',
        TRIAL_PERIOD_END_1: 'Fin de PE - Employeur',
        TRIAL_PERIOD_END_2: 'Fin de PE - Salarié',
        rupture_amiable: "Rupture à l'amiable CDD",
        rupture_conventionnelle: 'Rupture Conventionnelle',
        SIMPLE_MISCONDUCT_DISMISSAL: 'Licenciement faute simple',
        SERIOUS_MISCONDUCT_DISMISSAL: 'Licenciement faute grave',
        LICENCIEMENT_NON_PROFESSIONNELLE:
          'Licenciement inaptitude non professionnelle',
        LICENCIEMENT_PROFESSIONNELLE: 'Licenciement inaptitude professionnelle',
        GRAVE_MISCONDUCT_DISMISSAL: 'Licenciement faute lourde',
        RETIREMENT: 'Retraite',
        FIXED_TERM_CONTRACT_END: 'Fin de CDD',
        TAKEN_OVER: 'Départ en annexe 7',
        null: 'Aucun',
        undefined: 'Aucun',
      },
      contact_type: {
        EMAIL: 'Email',
        PHONE: 'Téléphone',
        SMS: 'Sms',
        MEETING: 'Rendez-vous',
      },
      direct_debit_status: {
        CANCELLED: 'Annulé',
        CHARGED_BACK: 'Remboursé',
        CUSTOMER_APPROVAL_DENIED: 'Refusé',
        FAILED: 'Echoué',
        PAID_OUT: 'Payé',
        PENDING_CUSTOMER_APPROVAL: 'Att client',
        PENDING_SUBMISSION: 'Att envoi',
      },
      issue_reason: {
        badAccess: 'Mauvais accès',
        badAddress: 'Mauvaise adresse',
        other: 'Autre',
      },
      issue_type: {
        cannotEnter: 'Ne peut pas entrer sur site',
        prestation: 'Prestation',
      },
      prestation_type: {
        CLEANING: 'Nettoyage',
        GLAZING: 'Vitrerie',
        CARPET_CLEANING: 'Shampoing moquette',
        PERMANENCE: 'Permanence',
        null: 'Non spécifié',
        undefined: 'Non défini',
      },
      consistency_error: {
        CONFLICT: "Conflit d'horaire",
        VACANCY_TOO_SHORT: 'Vacance trop courte',
        null: 'Aucune erreur',
      },
      invoice_status: {
        CLOSED: 'Payée',
        CANCELLED: 'Annulée',
        OPEN: 'En cours',
        INCOMPLET: 'Incomplet',
      },
      procedureStatus: {
        active: '⠀Actif ⠀ ',
        draft: 'Démarré',
        expired: 'Expiré',
        finished: 'Terminé',
        refused: 'Refusé',
        unknown: 'Non lancé',
      },
      cleaner_document: {
        APPROVED: 'Approuvé',
        PENDING: 'En Attente',
        REJECTED: 'Refusé',
      },
      letter_status: {
        ACCEPTED:
          "Cette lettre a été acceptée par le système d'impression et d'expédition.",
        CANCELED: 'Cette lettre a été annulée.',
        CREATED: 'Cette lettre a été créée.',
        DELIVERY_PROOF: 'Cette lettre a reçu une preuve de livraison.',
        DISTRIBUTED: 'Cette lettre a été distribuée.',
        ERROR: "Cette lettre est dans un état d'erreur.",
        FILLING_PROOF: 'Cette lettre a reçu une preuve de dépôt.',
        IN_TRANSIT: 'Cette lettre est en transit.',
        RETURNED_TO_SENDER: "Cette lettre a été retournée à l'expéditeur.",
        SENT: 'Cette lettre a été envoyée.',
        WAITING_TO_BE_WITHDRAWN:
          "Cette lettre est en attente d'être retirée par le destinataire.",
        WRONG_ADDRESS:
          'Cette lettre a reçu un événement "N\'habite pas à l\'adresse indiqué" de La Poste.',
      },
      timeline_type: {
        cleanerLeave: 'Absences',
        cleanerAffectation: 'Affectations',
        cleanerContractStart: 'Début de contrat',
        cleanerTrialPeriodRenewal: "Période d'essai",
        cleanerContractEnd: 'Fin de contrat',
        cleanerContractAmendment: 'Avenants',
        cleanerLetter: 'Envoi de recommandé',
      },
      unit: {
        week: 'Semaines',
      },
      visit_tag: {
        START_UP: 'Démarrage',
        CLIENT_MEETING: 'Rencontre Client',
        QUALITY_CONTROL: 'Contrôle',
        NOTHING_TO_REPORT: 'RAS',
        PROBLEMS: 'Remontée de problèmes',
        PRODUCT_DELIVERY: 'Livraison de produit',
        CLEANER_MEETING: 'Entretien Cleaner',
        ACCESS_RECOVERY: 'Récupération des accès',
        GOOD_FEEDBACK: 'Bon Retour',
        REPLACEMENT: 'Remplacement',
        OTHER: 'Autre',
        FULL_CLEANING: 'Remise en état',
        SET_UP: 'Mise en place',
      },
    },
    cleaner_information: {
      title: 'Informations sur le cleaner',
      no_data:
        "Veuillez sélectionner un Cleaner pour avoir plus d'informations",
    },
    leave_information: {
      title: "Informations sur l'absence",
      button: "Aller au remplacement de l'absence",
    },
    form: {
      contract_input: {
        all_contract: 'Tous les contrats',
      },
      hint_space_checkbox:
        "Veuillez sélectionner les actions correctives pour l'espace sélectionné",
      image_input: {
        placeholder: 'Déposer un fichier ici',
      },
      file_input: {
        placeholder: 'Déposer un fichier ici',
      },
      required: 'Ce champ est requis',
      time_validation: 'Ne peut pas se terminer avant le début',
      replacement_outside_leave_scope:
        'Le remplacement doit être effectué durant une absence',
      save: 'Enregistrer',
      validators: {
        email_or_phone_required: 'Mail ou téléphone requis',
        url_malformed: 'La valeur entrée ne correspond pas à une URL',
      },
    },
    planning: {
      days: 'Jours',
      schedule: 'Horaires',
      variable_hours: 'Horaires variables en fonction des jours',
    },
    replacement_completion: {
      ratio: '%{current}/%{max} heures remplacées',
    },
  },
  payroll: {
    title: 'Détail de la paie',
    copy: 'Copier',
    copied: 'Copié',
    contract: {
      intro:
        'Votre avenant comprend un total de %{total} heures ce mois-ci. Le détail de celui-ci est le suivant:',
      current:
        '-   Votre avenant comprend %{contract_worked_time} heures par semaine depuis le %{start_date}, soit un total de %{total} heures.',
      old:
        '-   Votre avenant comprenait %{contract_worked_time} heures par semaine du %{start_date} au %{end_date}, soit un total de %{total} heures',
    },
    permanent: {
      intro:
        'Avec vos affectations permanentes vous avez réalisé %{total} heures. Le détail de celles-ci est le suivant:',
      single_day: 'le %{day}',
      multiple_day: 'à raison de %{hours} heures par jours le %{weekdays}',
      current:
        '-   Une affectation permanente de %{duration_per_week} heures par semaine, %{days}, depuis le %{start_date} chez "%{account_name}, %{site_address}", soit un total de %{total} heures.',
      old:
        '-   Une affectation permanente de %{duration_per_week} heures par semaine, %{days}, terminée le %{end_date} chez "%{account_name}, %{site_address}", soit un total de %{total} heures.',
    },
    punctual: {
      intro:
        'Avec vos affectations ponctuelles vous avez réalisé %{total} heures. Le détail de celles-ci est le suivant:',
      day:
        '-   Un %{type} chez "%{account_name}, %{site_address}" le %{start_date} d\'une durée de %{total} heures.',
      period:
        '-   Un %{type} chez "%{account_name}, %{site_address}" du %{start_date} au %{end_date} comprenant un total de %{total} heures.',
      ponctuel: 'ponctuel',
      remplacement: 'remplacement',
    },
    leave: {
      intro:
        'Vous avez été absent durant %{total} heures ce mois-ci. Le détail de ces absences est le suivant:',
      day:
        '-   Une absence chez "%{account_name}, %{site_address}" le %{start_date} d\'une durée de %{total} heures.',
      period:
        '-    Une absence chez "%{account_name}, %{site_address}" du %{start_date} au %{end_date}, soit un total de %{total} heures.',
    },
    conclusion: {
      sum:
        'En additionant toutes vos affectations vous avez réalisé un total de %{total} heures. ',
      sub:
        " En y deduisant vos %{hours} heures d'absence(s) vous avez réalisé un total de %{total} heures. ",
      more:
        'Ces heures (%{total}h) étant supérieures à celles de votre avenant (%{contract}h), vous avez donc été en heures supplémentaires pendant %{overtime} heures ce mois-ci.',
      equal:
        "Ces heures (%{total}h) étant égales à celles de votre avenant (%{contract}h), vous avez donc réalisé l'integralité de vos heures ce mois-ci.",
      less:
        'Ces heures (%{total}h) étant inferieures à celles de votre avenant (%{contract}h) vous avez donc été en inter-contrat pendant %{hours} heures ce mois-ci.',
      summary: ' Au total vous donc avez été payé pour %{total} heures.',
      summary_details:
        ' Au total vous donc avez été payé pour %{total} heures qui se décomposent ainsi:',
      special_hours: '-   Heures %{type} -> %{hours} heures.',
    },
    hours: {
      base_100: 'de base payées à 100%',
      base_110: 'complémentaires payées à 110%',
      base_125: 'supplémentaires payées à 125%',
      base_150: 'supplémentaires payées à 150%',
      night_sunday_120: 'de nuit/dimanche payées à 120%',
      night_sunday_200: 'de nuit/dimanche payées à 200%',
    },
  },
};
