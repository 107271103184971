import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const CleanerMenuActions = ({ children, className, label, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button className={className} variant="contained" onClick={handleClick}>
        <div
          style={{
            fontSize: '0.8125rem',
            fontWeight: 500,
            fontFamily: 'Roboto',
          }}
        >
          <ExitToAppIcon style={{ fontSize: 0 }} />📕 Annexe 7
        </div>
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.map(children, (child) => {
          if (child) {
            return React.cloneElement(child, {
              ...props,
            });
          }
          return null;
        })}
      </Menu>
    </div>
  );
};

CleanerMenuActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  label: PropTypes.string,
};

CleanerMenuActions.defaultProps = {
  children: {},
  className: undefined,
  label: '',
};

export default CleanerMenuActions;
