import React from 'react';
import PropTypes from 'prop-types';
import {
  NumberField,
  EmailField,
  SelectField,
  DateField,
  FunctionField,
  TextField,
  ReferenceField,
  useTranslate,
} from 'react-admin';
import { Grid, makeStyles } from '@material-ui/core';

import { GridCard, TimeField } from '../../../components';
import getTrialRenewalDate from './getTrialRenewalDate';
import ListEntretienPro from './ListEntretienPro';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
});

const MiscellanousTab = ({ cleanerId, match, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Grid container spacing={2}>
      <GridCard
        {...props}
        classes={classes}
        title={translate('resources.cleaner.card_title.check')}
        xl={12}
      >
        <SelectField
          source="is_valid"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        {/* not computed anymore
        <SelectField source="is_consistent" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <TextField source="consistency_error" />
        */}
        <SelectField
          source="match_max_daily_work"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_legal_weekly_work"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_max_weekly_work"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_min_daily_rest"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_min_weekly_rest"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
      </GridCard>

      <GridCard
        {...props}
        classes={classes}
        title={translate('resources.cleaner.card_title.civil')}
        lg={3}
        md={6}
      >
        <TextField xs={6} source="salutation" />
        <TextField xs={6} source="fullname" />
        <TextField xs={6} source="manager_fullname" />
        <EmailField xs={12} source="email" />
        <TextField xs={6} source="mobile_phone" />
        <TextField xs={6} source="phone" />
        <TextField xs={6} source="address" />
        <SelectField
          xs={6}
          source="quartier_prioritaire_de_la_ville"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <DateField xs={6} source="birthdate" />
        <TextField xs={6} source="birthplace" />
        <TextField xs={6} source="nationality" />
        <TextField xs={6} source="social_security_number" />
        <TextField xs={6} source="residence_permit_number" />
        <DateField xs={6} source="residence_permit_expiry_date" />
        <TextField xs={6} source="residence_permit_nature" />
        <TextField xs={6} source="silae_id" />
        <TextField xs={12} source="payfit_id" />
      </GridCard>
      {/* move to other tab
      <GridCard
        {...props}
        title={translate('resources.cleaner.card_title.document')}
        xs={12}
      >
        <ReferenceManyField
          reference="cleaner_document"
          target="cleaner_id"
          addLabel={false}
          xs={12}
        >
          <DocumentsDrawerList
            {...props}
            originRoute={`${match.url}/miscellanous`}
          />
        </ReferenceManyField>
      </GridCard>

      <GridCard
        {...props}
        title={translate('resources.cleaner.card_title.letter')}
        xs={12}
      >
        <ReferenceManyField
          reference="cleaner_letter"
          target="cleaner_id"
          addLabel={false}
          xs={12}
        >
          <LettersDrawerList
            {...props}
            originRoute={`${match.url}/miscellanous`}
          />
        </ReferenceManyField>
      </GridCard>
*/}

      <GridCard
        {...props}
        title={translate('resources.cleaner.card_title.health_care')}
        classes={classes}
        lg={3}
        md={6}
      >
        <TextField xs={6} source="social_security_number" />
        <TextField xs={6} source="health_insurance" />
        <SelectField
          xs={6}
          source="health_insurance_exemption"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField xs={6} source="health_insurance_exemption_reason" />
        <TextField xs={6} source="health_insurance_exemption_end_date" />
        <ReferenceField
          xs={6}
          reference="cleaner_document"
          link="show"
          source="health_insurance_exemption_file"
          allowEmpty
        >
          <TextField xs={6} source="type" />
        </ReferenceField>
        <TextField xs={12} source="spvie_id" />

        <TextField xs={6} source="occupational_medecine_date" />
        <TextField xs={6} source="occupational_medecine_reason" />
        <ReferenceField
          reference="cleaner_document"
          link="show"
          source="occupational_medecine_file"
          allowEmpty
        >
          <TextField xs={6} source="type" />
        </ReferenceField>
      </GridCard>

      {/* }
      <GridCard
        {...props}
        title={translate('resources.cleaner.card_title.occupational_medecine')}
        xs={3}
      >
        <TextField xs={6} source="occupational_medecine_date" />
        <TextField xs={6} source="occupational_medecine_reason" />
        <ReferenceField
          reference="cleaner_document"
          link="show"
          source="occupational_medecine_file"
          allowEmpty
        >
          <TextField xs={6} source="type" />
        </ReferenceField>
      </GridCard>
*/}

      <GridCard
        {...props}
        title={translate('resources.cleaner.card_title.contract')}
        classes={classes}
        lg={3}
        md={6}
      >
        <TextField xs={6} source="contract_type" />
        <SelectField
          xs={6}
          source="is_taken_over"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          xs={6}
          source="cdd_etudiant"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <DateField xs={6} source="contract_start_date" />
        <DateField xs={6} source="seniority_date" />
        <FunctionField
          xs={6}
          label="resources.cleaner.fields.trial_period_renewal"
          render={getTrialRenewalDate}
        />
        <TextField xs={6} source="trial_period_renewal_signing" />
        <TextField xs={6} source="employee_status" />
        <TextField xs={6} source="function" />
        <TextField xs={6} source="level" />
        <TextField xs={6} source="classification" />
        <NumberField xs={6} source="hourly_rate" />
        <TimeField xs={6} source="contract_worked_time" />
        <TextField xs={6} source="transportation_help" />
        <TextField xs={6} source="contract_end_date" />
        <TextField xs={6} source="contract_end_type" />
        <TextField xs={6} source="contract_end_reason" />
        {/* <DateField xs={6} source="last_terms_start_date" />
        <SelectField xs={6} source="real_hours_computation" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField xs={6} source="full_time" choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' }]} /> */}
      </GridCard>

      <GridCard
        {...props}
        classes={classes}
        title={translate('resources.cleaner.card_title.worked_stats')}
        lg={3}
        md={6}
      >
        <NumberField xs={6} source="nb_worked_days" />
        <TimeField xs={6} source="recurring_work_schedule_per_week" />
        <TimeField xs={6} source="day_worked_time" />
        <TimeField xs={6} source="night_worked_time" />
        <TimeField xs={6} source="sunday_worked_time" />
        {/* <TimeField source="traveltime" /> */}
        {/* <TimeField source="vacancy_time" /> */}
        {/* <TextField source="density" /> */}
        <TimeField xs={6} source="contract_worked_time" />
        <TimeField xs={6} source="total_worked_time" />
        <TimeField xs={6} source="intercontract_work_schedule_per_week" />
        <SelectField
          xs={6}
          source="has_missing_recurring_hours"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TimeField xs={6} source="missing_recurring_hours" />
      </GridCard>

      {/*
      <GridCard
        {...props}
        title={translate('resources.cleaner.card_title.other')}
        xs={12}
      >
        <TextField xs={6} source="spvie_id" />
        <TextField xs={6} source="payfit_id" />
      {/*  <TextField xs={6} source="calendly" />
        <TextField xs={6} source="tags" />
      </GridCard> */}

      {/* No long relevant as payroll is outside backoffice
      <PayrollExplanation cleanerId={cleanerId} /> */}
      <GridCard
        {...props}
        classes={classes}
        xs={12}
        title="Entretien Professionnel"
      >
        <ListEntretienPro xs={12} />
      </GridCard>
    </Grid>
  );
};

MiscellanousTab.propTypes = {
  match: PropTypes.object,
  cleanerId: PropTypes.string,
};

MiscellanousTab.defaultProps = {
  match: {},
  cleanerId: '',
};

export default MiscellanousTab;
