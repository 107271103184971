import React from 'react';
import PropTypes from 'prop-types';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';

import 'moment/locale/fr';
import moment from 'moment';

import IconMenuActions from './IconMenuActions';
import LaunchButton from './LaunchButton';
import ActiveButton from './ActiveButton';
import Period from './Period';
import TaskAvatar from './TaskAvatar';

const styles = createStyles({
  actions: {
    paddingTop: 0,
    justifyContent: 'flex-end',
    display: 'flex',
  },
  headerActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  more: {
    paddingTop: 0,
  },
  cardContent: {
    paddingBottom: 0,
  },
});

const ListCard = ({ classes, record, ...props }) => {
  return (
    <Card>
      <CardHeader
        avatar={<TaskAvatar record={record} />}
        action={
          <div className={classes.headerActions}>
            <IconMenuActions record={record} {...props} />
          </div>
        }
        title={record.label}
        subheader={`Prochaine execution ${moment(record.next_execution_date)
          .locale('fr')
          .fromNow()}`}
      />
      <CardContent className={classes.cardContent}>
        <Typography color="textSecondary">{record.description}</Typography>
        <Period record={record} />
      </CardContent>
      <CardActions className={classes.actions}>
        <LaunchButton record={record} variant="text" size="small" />
        <ActiveButton record={record} variant="text" size="small" />
      </CardActions>
    </Card>
  );
};

ListCard.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
};

ListCard.defaultProps = {
  classes: {},
  record: {},
};

export default withStyles(styles)(ListCard);
