import React, { useState } from 'react';
import PropTypes from 'prop-types';
import shortid from 'shortid';

import StartEndTimeInput from './StartEndTimeInput';

const StartEndTimeArrayInput = ({ onRemove, ...props }) => {
  const [fields, setFields] = useState([{ id: 0 }]);

  const addInput = () => {
    const newFields = [...fields];
    newFields.push({ id: shortid.generate() });
    setFields(newFields);
  };

  const removeInput = (id) => {
    const newFields = [...fields];
    newFields.splice(id, 1);
    setFields(newFields);
    onRemove(id);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {fields.map((field, index) => {
        return (
          <StartEndTimeInput
            {...props}
            fieldArray
            first={index < 1}
            key={field.id}
            index={index}
            onAdd={addInput}
            onRemove={removeInput}
          />
        );
      })}
    </div>
  );
};

StartEndTimeArrayInput.propTypes = {
  onRemove: PropTypes.func,
};

StartEndTimeArrayInput.defaultProps = {
  onRemove: () => {},
};

export default StartEndTimeArrayInput;
