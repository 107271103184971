import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { GridCard, TabbedLayout, Tab } from '../../../../../components';
import AffectationList from './AffectationList';
import AffectationListHistory from './AffectationListHistory';
import Actions from './Actions';
import { URLParamField } from '../../../../../components';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
  headerRoot: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: '0 !important',
  },
});

const AffectationCard = ({ active, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      classes={classes}
      title={translate(`resources.cleaner.card_title.affectations`)}
      action={<Actions {...props} />}
    >
      <URLParamField
        url="https://cleany-planning.bubbleapps.io/?cleaner_id={{id}}"
        source="id"
        label=""
        textAlign="center"
        text="📅 Agenda Simplifié 📅"
        emptyText="∅"
      />
      <TabbedLayout xs={12}>
        <Tab label="resources.cleaner.cards.actives">
          <AffectationList
            active
            sort={{ field: 'start_date', order: 'ASC' }}
          />
        </Tab>
        <Tab label="resources.cleaner.cards.history">
          <AffectationListHistory />
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

AffectationCard.propTypes = {
  active: PropTypes.bool,
};

AffectationCard.defaultProps = {
  active: false,
};

export default AffectationCard;
