import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import { Route } from 'react-router-dom';
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';

const EspaceClient = ({ className, record }) => {
  return (
    <Fragment>
      <Route
        render={() => {
          return (
            <Button
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  'https://dashboard-cleany.bubbleapps.io/espacecleanybackoffice?account_id=' +
                    `${record.id}` +
                    '&email=' +
                    `${record.operational_email}`,
                );
              }}
            >
              <div
                style={{ fontSize: 12, fontWeight: 600, fontFamily: 'Roboto' }}
              >
                <ImportantDevicesIcon style={{ fontSize: 12 }} /> Espace Client
              </div>
            </Button>
          );
        }}
      />
    </Fragment>
  );
};

EspaceClient.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

EspaceClient.defaultProps = {
  className: undefined,
  record: undefined,
};

export default EspaceClient;
