import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { SaveButton, Toolbar } from 'react-admin';
import { useFormState } from 'react-final-form';
import { createReplacement } from '../../../action';
import { buildBundle } from '../helper';

const FormToolbar = ({ basePath, contractsPlanning, leaveId, redirect }) => {
  const dispatch = useDispatch();
  const formState = useFormState();

  const submitReplacement = useCallback(
    (values) => {
      dispatch(
        createReplacement({
          record: buildBundle(values, contractsPlanning, leaveId),
          redirect,
          basePath,
        }),
      );
    },
    [dispatch, basePath, redirect, contractsPlanning, leaveId],
  );

  const submit = useCallback(() => {
    if (formState.valid) {
      submitReplacement(formState.values);
    }
  }, [formState.valid, formState.values, submitReplacement]);

  return (
    <Toolbar>
      <SaveButton handleSubmitWithRedirect={submit} />
    </Toolbar>
  );
};

FormToolbar.propTypes = {
  basePath: PropTypes.string,
  contractsPlanning: PropTypes.object,
  leaveId: PropTypes.string.isRequired,
  redirect: PropTypes.string,
};

FormToolbar.defaultProps = {
  basePath: '',
  contractsPlanning: {},
  redirect: '',
};

export default FormToolbar;
