import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const SubTypeContractSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        { id: 'accroissement', name: 'Accroissement' },
        {
          id: "CDD Accroissement temporaire d'activité",
          name: "CDD Accroissement temporaire d'activité",
        },
        { id: 'CDD_en_CDI', name: 'CDD en CDI' },
        { id: 'CDD Remplacement', name: 'CDD Remplacement' },
        { id: 'CDI', name: 'CDI' },
        { id: 'CDI classique', name: 'CDI classique' },
        { id: 'remplacement', name: 'Remplacement' },
      ]}
    />
  );
};

SubTypeContractSelectInput.propTypes = {
  validate: PropTypes.array,
  source: PropTypes.string.isRequired,
};

SubTypeContractSelectInput.defaultProps = {
  validate: [],
};

export default SubTypeContractSelectInput;
