export const CLEAR_DRIVE_DOCUMENT = 'CLEAR_DRIVE_DOCUMENT';
export const GET_DRIVE_DOCUMENT = 'SAGA/GET_DRIVE_DOCUMENT';
export const SET_DRIVE_DOCUMENT = 'SET_DRIVE_DOCUMENT';

export const clearDriveDocument = () => {
  return {
    type: CLEAR_DRIVE_DOCUMENT,
  };
};

export const getDriveDocument = (payload) => {
  return {
    type: GET_DRIVE_DOCUMENT,
    payload,
  };
};
