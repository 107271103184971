import React from 'react';
import PropTypes from 'prop-types';
import {
  Show as ShowRA,
  useTranslate,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
} from 'react-admin';
import { FormerCleanerTitleFromRecord } from '../../components';

import ShowActions from './detail/ShowActions';
// import TimelineTab from './detail/TimelineTab';
import DocumentsTab from './detail/DocumentsTab';
import AffectationTab from './detail/AffectationTab';
import MiscellanousTab from './detail/MiscellanousTab';
import PayTab from './detail/PayTab';

const Show = ({ location, match, ...props }) => {
  const translate = useTranslate();

  const suffix = location.pathname
    .split('/')
    .slice(4)
    .join('/');
  const miscellanousWithSuffix =
    suffix.indexOf('miscellanous') !== -1 ? suffix : '';

  const payWithSuffix = suffix.indexOf('pay') !== -1 ? suffix : '';

  const documentsWithSuffix = suffix.indexOf('documents') !== -1 ? suffix : '';

  return (
    <ShowRA
      {...props}
      component="div"
      actions={<ShowActions />}
      title={<FormerCleanerTitleFromRecord />}
    >
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="fullWidth" centered />}
      >
        {/* <Tab label={translate('resources.cleaner.tabs.timeline')}>
          <TimelineTab cleanerId={match.params.id} />
        </Tab> */}
        <Tab label={translate('resources.cleaner.tabs.affectations')}>
          <AffectationTab match={match} cleanerId={match.params.id} />
        </Tab>
        <Tab
          label={translate('resources.cleaner.tabs.documents')}
          path={documentsWithSuffix || 'documents'}
        >
          <DocumentsTab match={match} cleanerId={match.params.id} />
        </Tab>
        <Tab
          label={translate('resources.cleaner.tabs.miscellanous')}
          path={miscellanousWithSuffix || 'miscellanous'}
        >
          <MiscellanousTab match={match} cleanerId={match.params.id} />
        </Tab>
        <Tab label="Paie" path={payWithSuffix || 'pay'}>
          <PayTab match={match} cleanerId={match.params.id} />
        </Tab>
      </TabbedShowLayout>
    </ShowRA>
  );
};

Show.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
};

Show.defaultProps = {
  match: {},
};

export default Show;
