import React from 'react';
import PropTypes from 'prop-types';
import { Labeled, NumberField, useTranslate } from 'react-admin';
import {
  LinearProgress,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';

import ListProductView from '../PonctualCard/ListProduct';

const useStyles = makeStyles((theme) => {
  return {
    linear: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
  };
});

const ListProduct = ({ basePath, contract, loading, record, resource }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Grid container spacing={1}>
      <Grid item lg={10}>
        <Typography variant="h6">
          {translate('resources.account.fields.product')}
        </Typography>
        {loading ? (
          <LinearProgress className={classes.linear} />
        ) : (
          <ListProductView
            basePath={basePath}
            resource={resource}
            record={contract}
          />
        )}
      </Grid>
      <Grid item lg={2}>
        <Labeled
          label={translate(
            'resources.account.fields.recurring_contract_amount',
          )}
        >
          <NumberField
            record={record}
            source="recurring_contract_amount"
            options={{ style: 'currency', currency: 'EUR' }}
            variant="h6"
          />
        </Labeled>
      </Grid>
    </Grid>
  );
};

ListProduct.propTypes = {
  basePath: PropTypes.string,
  contract: PropTypes.object,
  loading: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
};

ListProduct.defaultProps = {
  basePath: '/',
  contract: {},
  loading: false,
  record: {},
  resource: undefined,
};

export default ListProduct;
