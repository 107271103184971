import { call, put, takeLatest } from 'redux-saga/effects';
import {
  showNotification,
  FETCH_START,
  FETCH_ERROR,
  FETCH_END,
  resolveRedirectTo,
} from 'react-admin';
import { push } from 'connected-react-router';
import { CREATE_REPLACEMENT } from '../action';
import fetchApi from './helpers/fetchApi';

export function* createReplacement({
  payload: { record, redirect, basePath },
}) {
  try {
    yield put({ type: FETCH_START });
    const res = yield call(fetchApi, {
      url: '/manageAffectation/createBundle',
      payload: record,
    });
    yield put({ type: FETCH_END });
    if (res.success) {
      yield put(
        showNotification('notification.create_replacement.success', 'info'),
      );
      const pathToRedirectTo = resolveRedirectTo(
        redirect,
        basePath,
        record.id,
        record,
      );
      yield put(push(pathToRedirectTo));
    } else {
      yield put(showNotification(res.message, 'warning'));
    }
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(
      showNotification('notification.create_replacement.fail', 'warning'),
    );
  }
}

export default function* createReplacementSaga() {
  yield takeLatest(CREATE_REPLACEMENT, createReplacement);
}
