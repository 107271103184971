import React, { Children } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { SimpleShowLayout } from 'react-admin';

const getChildrenGrid = (childrenProps, defaultChildrenGrid) => {
  return {
    xs: childrenProps.xs || defaultChildrenGrid.xs,
    sm: childrenProps.sm || childrenProps.xs || defaultChildrenGrid.sm,
    md:
      childrenProps.md ||
      childrenProps.sm ||
      childrenProps.xs ||
      defaultChildrenGrid.md,
    lg:
      childrenProps.lg ||
      childrenProps.md ||
      childrenProps.sm ||
      childrenProps.xs ||
      defaultChildrenGrid.lg,
    xl:
      childrenProps.xl ||
      childrenProps.lg ||
      childrenProps.md ||
      childrenProps.sm ||
      childrenProps.xs ||
      defaultChildrenGrid.xl,
  };
};

const GridSimpleShowLayout = ({
  children,
  title,
  subtitle,
  action,
  avatar,
  xs,
  sm,
  md,
  lg,
  xl,
  defaultChildrenGrid,
  classes,
  spacing,
  ...props
}) => {
  return (
    <Grid container spacing={spacing} className={classes.root}>
      {Children.map(children, (child) => {
        if (!child) {
          return null;
        }
        return (
          <Grid
            item
            {...getChildrenGrid(child.props, defaultChildrenGrid)}
            className={clsx(
              classes.item,
              child.props.containerclasses && child.props.containerclasses.item,
            )}
          >
            <SimpleShowLayout
              {...props}
              className={clsx(
                classes.content,
                child.props.containerclasses &&
                  child.props.containerclasses.content,
              )}
            >
              {child}
            </SimpleShowLayout>
          </Grid>
        );
      })}
    </Grid>
  );
};

GridSimpleShowLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.element,
  avatar: PropTypes.element,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  defaultChildrenGrid: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  classes: PropTypes.object,
  spacing: PropTypes.number,
};

GridSimpleShowLayout.defaultProps = {
  children: undefined,
  title: undefined,
  subtitle: undefined,
  action: null,
  avatar: null,
  xs: 12,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  defaultChildrenGrid: {
    xs: 12,
    sm: 6,
    md: 3,
    lg: 2,
    xl: undefined,
  },
  classes: {},
  spacing: 0,
};

export default GridSimpleShowLayout;
