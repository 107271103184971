import React from 'react';
import {
  Datagrid,
  EmailField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

export default (props) => {
  return (
    <List {...props} bulkActionButtons={false} perPage={25}>
      <Datagrid rowClick="edit">
        <TextField source="fullname" />
        <ReferenceField source="account_id" reference="account">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="function" />
        <EmailField source="email" />
        <TextField source="phone" />
        <TextField source="mobile_phone" />
      </Datagrid>
    </List>
  );
};
