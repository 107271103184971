import { CLEAR_DRIVE_DOCUMENT, SET_DRIVE_DOCUMENT } from '../action';

export default (previousState = null, { type, payload }) => {
  if (type === SET_DRIVE_DOCUMENT) {
    return payload;
  }
  if (type === CLEAR_DRIVE_DOCUMENT) {
    return null;
  }
  return previousState;
};
