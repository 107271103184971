import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  required,
} from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const ProviderForm = ({ onCancel, record, ...props }) => {
  return (
    <Fragment>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm {...sanitizeRestProps(props)} record={record}>
        <ReferenceInput
          filterToQuery={(searchText) => {
            return { name: searchText };
          }}
          source="account_id"
          reference="account"
          validate={[required()]}
        >
          <AutocompleteInput
            source="name"
            options={{
              suggestionsContainerProps: {
                /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                style: { zIndex: 2000 },
              },
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          filterToQuery={(searchText) => {
            return { name: searchText };
          }}
          source="provider"
          reference="account"
          validate={[required()]}
        >
          <AutocompleteInput
            source="name"
            options={{
              suggestionsContainerProps: {
                /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                style: { zIndex: 2000 },
              },
            }}
          />
        </ReferenceInput>
        <TextInput source="provider_ref_id" validate={[required()]} />
      </SimpleForm>
    </Fragment>
  );
};

ProviderForm.propTypes = {
  classes: PropTypes.object,
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

ProviderForm.defaultProps = {
  classes: {},
  onCancel: /* istanbul ignore next */ () => {},
  record: {},
};

export default ProviderForm;
