import React, { cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateInput, required, SimpleForm } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { FormSpy } from 'react-final-form';
import { CleanerAutocompleteInput } from '../../../components';

import ContractList from './ContractList';
import Toolbar from './Toolbar';
import { validateDates } from '../validation';
import { getLeaveDefaultCleanerIds } from '../helper';

export const useStyles = makeStyles({
  contractList: {
    marginTop: 30,
  },
  dateInput: {
    marginRight: 30,
  },
});

const Form = ({
  affectations,
  aside,
  cleanerIds,
  planning,
  leaveId,
  record,
  setSelectedCleaners,
  spyChangeCleanerIds,
  ...props
}) => {
  const classes = useStyles();
  const defaultCleanerIds = getLeaveDefaultCleanerIds(affectations, cleanerIds);

  return (
    <SimpleForm
      {...props}
      affectations={affectations}
      record={record}
      toolbar={<Toolbar contractsPlanning={planning} leaveId={leaveId} />}
      redirect={`/leave/${leaveId}/show`}
      initialValues={{ cleaner_ids: defaultCleanerIds }}
    >
      <Fragment>
        <DateInput
          affectations={affectations}
          source="start_date"
          className={classes.dateInput}
          validate={[required(), validateDates(affectations)]}
        />
        <DateInput
          source="end_date"
          validate={[required(), validateDates(affectations)]}
        />
      </Fragment>
      <CleanerAutocompleteInput
        multiple
        source="cleaner_ids"
        validate={required()}
        filter={{ employee_status: 'Salarié actuel' }}
      />
      <ContractList
        formClassName={classes.contractList}
        contractsPlanning={planning}
      />
      <FormSpy subscription={{ valid: true, values: true }}>
        {({ values }) => {
          return cloneElement(aside, {
            ...aside.props,
            selectedCleaners: values.cleaner_ids ? values.cleaner_ids : [],
          });
        }}
      </FormSpy>
    </SimpleForm>
  );
};

Form.propTypes = {
  aside: PropTypes.element,
  affectations: PropTypes.array,
  cleanerIds: PropTypes.array,
  planning: PropTypes.object,
  leaveId: PropTypes.string.isRequired,
  record: PropTypes.object,
  setSelectedCleaners: PropTypes.func,
  spyChangeCleanerIds: PropTypes.func,
};

Form.defaultProps = {
  aside: undefined,
  affectations: [],
  cleanerIds: [],
  planning: {},
  record: {},
  setSelectedCleaners: () => {},
  spyChangeCleanerIds: () => {},
};

export default Form;
