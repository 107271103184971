import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

const PhoneCallPField = ({ record, source, variant, text }) => {
  const param = get(record, source);
  const urlparam = `tel:${param}`;
  return (
    <Typography component="span" variant={variant}>
      <a href={urlparam} rel="noopener noreferrer" target="_blank">
        {text}
      </a>
    </Typography>
  );
};

PhoneCallPField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  variant: PropTypes.string,
  text: PropTypes.string,
};

PhoneCallPField.defaultProps = {
  record: {},
  variant: 'body3',
  text: '',
};

const PurePhoneCallPField = pure(PhoneCallPField);

PurePhoneCallPField.defaultProps = {
  addLabel: true,
};

export default PurePhoneCallPField;
