import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { EditButton } from 'react-admin';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useDispatch } from 'react-redux';
import { ActionsContainer, AccountMenuActions } from '../../../components';
import QualityButton from './QualityButton';
import VisitButton from './buttons/VisitButton';
import AccessButton from './buttons/AccessButton';
import RechercheCleanerButton from './buttons/RechercheCleanerButton';
import EspaceClient from './EspaceClient';
import UploadDocumentDialog from './UploadDocumentDialog';
import PreaffectationButton from './buttons/PreaffectationButton.js';
import { getLegalDocument } from '../../../action';
import GoToHandymanRequestTypeformButton from './buttons/GoToHandymanRequestTypeformButton';
import GoToAmendmentQuoteRequestTypeformButton from './buttons/GoToAmendmentQuoteRequestTypeformButton';
import GoToOneOffQuoteRequestTypeformButton from './buttons/GoToOneOffQuoteRequestTypeformButton';
import ContractMenu from './ContractMenu/ContractMenu.js'

const ActionsMenu = ({ className, record }) => {
  const dispatch = useDispatch();
  const getLegalDocumentClick = useCallback(
    (documentType) => {
      const payload = {
        id: record.id,
        fullname: record.name,
        documentType,
      };
      dispatch(getLegalDocument(payload));
    },
    [dispatch, record],
  );

  return (
    <AccountMenuActions className={className} label="Actions" record={record}>
      <PreaffectationButton />
      <GoToAmendmentQuoteRequestTypeformButton />
      <GoToOneOffQuoteRequestTypeformButton />
      <QualityButton />
      <VisitButton />
      <AccessButton />
      <MenuItem
        onClick={() => {
          getLegalDocumentClick('cahierDesCharge');
        }}
      >
        <ListItemText primary="📋 Cahier Des Charges" />
      </MenuItem>
      <GoToHandymanRequestTypeformButton />
    </AccountMenuActions>
  );
};

ActionsMenu.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

ActionsMenu.defaultProps = {
  className: undefined,
  record: {},
};

const ShowActions = (props) => {
  return (
    <ActionsContainer {...props}>
      <EditButton />
      <ContractMenu/>
      <ActionsMenu />
      <UploadDocumentDialog />
      <EspaceClient />
      <RechercheCleanerButton />
    </ActionsContainer>
  );
};

export default ShowActions;
