import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  TextField,
  Datagrid,
  ArrayField,
  NumberField,
  DateField,
} from 'react-admin';
import { GridSimpleShowLayout } from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
      width: '100%',
    },
    content: {
      padding: '0 !important',
      width: '100%',
    },
  };
});

export const transforme = (chiffre) => {
  switch (chiffre) {
    default:
      return 'ERROR';
    case -1:
      return <span title="Absence injustifiée (non payée)">❌</span>;
      break;
    case -2:
      return <span title="Maladie ordinaire">🤧</span>;
      break;
    case -3:
      return <span title="Congé pour événement familial">💒</span>;
      break;
    case -4:
      return <span title="Enfant malade">🤰</span>;
      break;
    case -5:
      return <span title="Enfant malade">🤒</span>;
      break;
    case -6:
      return <span title="Mise à pied à titre disciplinaire">😠</span>;
      break;
    case -7:
      return <span title="Mise à pied à titre conservatoire">⌛</span>;
      break;
    case -8:
      return <span title="Congé sans solde">🏝️</span>;
      break;
    case -9:
      return <span title="Congés payés">💸</span>;
      break;
    case -10:
      return <span title="Jour ferié">🗓️</span>;
      break;
    case -11:
      return <span title="Fermeture de site">🔒</span>;
      break;
    case -12:
      return <span title="Accident de travail">⚠️</span>;
      break;
  }
};

const ConditionalEmojiField1 = ({ record }) => {
  return record[1] < 0 ? transforme(record[1]) : record[1];
};

const ConditionalEmojiField2 = ({ record }) => {
  return record[2] < 0 ? transforme(record[2]) : record[2];
};

const ConditionalEmojiField3 = ({ record }) => {
  return record[3] < 0 ? transforme(record[3]) : record[3];
};

const ConditionalEmojiField4 = ({ record }) => {
  return record[4] < 0 ? transforme(record[4]) : record[4];
};

const ConditionalEmojiField5 = ({ record }) => {
  return record[5] < 0 ? transforme(record[5]) : record[5];
};

const ConditionalEmojiField6 = ({ record }) => {
  return record[6] < 0 ? transforme(record[6]) : record[6];
};

const ConditionalEmojiField7 = ({ record }) => {
  return record[7] < 0 ? transforme(record[7]) : record[7];
};

const ConditionalEmojiField8 = ({ record }) => {
  return record[8] < 0 ? transforme(record[8]) : record[8];
};

const ConditionalEmojiField9 = ({ record }) => {
  return record[9] < 0 ? transforme(record[9]) : record[9];
};

const ConditionalEmojiField10 = ({ record }) => {
  return record[10] < 0 ? transforme(record[10]) : record[10];
};

const ConditionalEmojiField11 = ({ record }) => {
  return record[11] < 0 ? transforme(record[11]) : record[11];
};

const ConditionalEmojiField12 = ({ record }) => {
  return record[12] < 0 ? transforme(record[12]) : record[12];
};

const ConditionalEmojiField13 = ({ record }) => {
  return record[13] < 0 ? transforme(record[13]) : record[13];
};

const ConditionalEmojiField14 = ({ record }) => {
  return record[14] < 0 ? transforme(record[14]) : record[14];
};

const ConditionalEmojiField15 = ({ record }) => {
  return record[15] < 0 ? transforme(record[15]) : record[15];
};

const ConditionalEmojiField16 = ({ record }) => {
  return record[16] < 0 ? transforme(record[16]) : record[16];
};

const ConditionalEmojiField17 = ({ record }) => {
  return record[17] < 0 ? transforme(record[17]) : record[17];
};

const ConditionalEmojiField18 = ({ record }) => {
  return record[18] < 0 ? transforme(record[18]) : record[18];
};

const ConditionalEmojiField19 = ({ record }) => {
  return record[19] < 0 ? transforme(record[19]) : record[19];
};

const ConditionalEmojiField20 = ({ record }) => {
  return record[20] < 0 ? transforme(record[20]) : record[20];
};

const ConditionalEmojiField21 = ({ record }) => {
  return record[21] < 0 ? transforme(record[21]) : record[21];
};

const ConditionalEmojiField22 = ({ record }) => {
  return record[22] < 0 ? transforme(record[22]) : record[22];
};

const ConditionalEmojiField23 = ({ record }) => {
  return record[23] < 0 ? transforme(record[23]) : record[23];
};

const ConditionalEmojiField24 = ({ record }) => {
  return record[24] < 0 ? transforme(record[24]) : record[24];
};

const ConditionalEmojiField25 = ({ record }) => {
  return record[25] < 0 ? transforme(record[25]) : record[25];
};

const ConditionalEmojiField26 = ({ record }) => {
  return record[26] < 0 ? transforme(record[26]) : record[26];
};

const ConditionalEmojiField27 = ({ record }) => {
  return record[27] < 0 ? transforme(record[27]) : record[27];
};

const ConditionalEmojiField28 = ({ record }) => {
  return record[28] < 0 ? transforme(record[28]) : record[28];
};

const ConditionalEmojiField29 = ({ record }) => {
  return record[29] < 0 ? transforme(record[29]) : record[29];
};

const ConditionalEmojiField30 = ({ record }) => {
  return record[30] < 0 ? transforme(record[30]) : record[30];
};

const ConditionalEmojiField31 = ({ record }) => {
  return record[31] < 0 ? transforme(record[31]) : record[31];
};

const ListDetail = ({ ...props }) => {
  const classes = useStyles();
  return (
    <GridSimpleShowLayout {...props} classes={classes}>
      <ArrayField
        source="weeks"
        emptyText="∅"
        label="Détail Semaine"
        style={{ marginBottom: '30px', marginTop: '30px' }}
      >
        <Datagrid>
          <TextField source="Semaine" emptyText="∅" />
          <DateField
            source="Début"
            options={{ month: 'numeric', day: 'numeric' }}
            emptyText="∅"
          />
          <DateField
            source="Fin"
            options={{ month: 'numeric', day: 'numeric' }}
            emptyText="∅"
          />
          <NumberField source="Contrat" emptyText="∅" />
          <NumberField source="Fait" emptyText="∅" />
          <NumberField source="En_Plus" emptyText="∅" />
          <NumberField source="Absence" emptyText="∅" />
          <NumberField source="Intercontrat" emptyText="∅" />
          <NumberField source="Chomé" emptyText="∅" />
          <NumberField source="Nuit" emptyText="∅" />
          <NumberField source="Dimanche" emptyText="∅" />
        </Datagrid>
      </ArrayField>
      <TextField source="rien" label=" " />
      <TextField source="rien" label=" " />

      <div>
        <strong>⠀</strong>
        <p>❌ : Absence injustifiée (non payée) </p>
        <p>🤑 : Absence autorisée (rémunérée)</p>
        <p>🤧 : Maladie ordinaire </p>
        <p>💒 : Congé pour événement familial </p>
        <p>🤒 : Enfant malade </p>
        <p>😠 : Mise à pied à titre disciplinaire </p>
        <p>⌛ : Mise à pied à titre conservatoire </p>
      </div>

      <div>
        <strong>Légende :</strong>
        <p>✔️ : Absence autorisée (non payée)</p>
        <p>🚇 : Accident de trajet </p>
        <p>🍼 : Congé parental </p>
        <p>👶 : Congé naissance </p>
        <p>🤰 : Maternité </p>
        <p>🏝 : Congé sans solde </p>
        <p>💸 : Congés payés </p>
      </div>

      <div>
        <strong>⠀</strong>
        <p>🩹 : Maladie professionnelle </p>
        <p>👨‍🍼 : Congé paternité </p>
        <p>🎓 : Congé Formation</p>
        <p>🗓️ : Jour Ferié </p>
        <p>🔒 : Fermeture de site </p>
        <p>⚠️ : Accident de travail </p>
      </div>

      <ArrayField
        source="clients"
        emptyText="∅"
        label="Détail client"
        style={{ marginBottom: '30px', marginTop: '30px' }}
      >
        <Datagrid>
          <TextField source="client" emptyText="∅" />
          <ConditionalEmojiField1 source="1" emptyText="∅" />
          <ConditionalEmojiField2 source="2" emptyText="∅" />
          <ConditionalEmojiField3 source="3" emptyText="∅" />
          <ConditionalEmojiField4 source="4" emptyText="∅" />
          <ConditionalEmojiField5 source="5" emptyText="∅" />
          <ConditionalEmojiField6 source="6" emptyText="∅" />
          <ConditionalEmojiField7 source="7" emptyText="∅" />
          <ConditionalEmojiField8 source="8" emptyText="∅" />
          <ConditionalEmojiField9 source="9" emptyText="∅" />
          <ConditionalEmojiField10 source="10" emptyText="∅" />
          <ConditionalEmojiField11 source="11" emptyText="∅" />
          <ConditionalEmojiField12 source="12" emptyText="∅" />
          <ConditionalEmojiField13 source="13" emptyText="∅" />
          <ConditionalEmojiField14 source="14" emptyText="∅" />
          <ConditionalEmojiField15 source="15" emptyText="∅" />
          <ConditionalEmojiField16 source="16" emptyText="∅" />
          <ConditionalEmojiField17 source="17" emptyText="∅" />
          <ConditionalEmojiField18 source="18" emptyText="∅" />
          <ConditionalEmojiField19 source="19" emptyText="∅" />
          <ConditionalEmojiField20 source="20" emptyText="∅" />
          <ConditionalEmojiField21 source="21" emptyText="∅" />
          <ConditionalEmojiField22 source="22" emptyText="∅" />
          <ConditionalEmojiField23 source="23" emptyText="∅" />
          <ConditionalEmojiField24 source="24" emptyText="∅" />
          <ConditionalEmojiField25 source="25" emptyText="∅" />
          <ConditionalEmojiField26 source="26" emptyText="∅" />
          <ConditionalEmojiField27 source="27" emptyText="∅" />
          <ConditionalEmojiField28 source="28" emptyText="∅" />
          <ConditionalEmojiField29 source="29" emptyText="∅" />
          <ConditionalEmojiField30 source="30" emptyText="∅" />
          <ConditionalEmojiField31 source="31" emptyText="∅" />
        </Datagrid>
      </ArrayField>
    </GridSimpleShowLayout>
  );
};

export default ListDetail;
