import React from 'react';
import PropTypes from 'prop-types';

const FormerCleanerTitleFromRecord = ({ record }) => {
  return (
    <span>
      {record.fullname}{' '}
      {record.employee_status === 'Ancien salarié'
        ? record.contract_end_date !== null
          ? `(${record.contract_end_date})`
          : '(N/A)'
        : ''}
    </span>
  );
};

FormerCleanerTitleFromRecord.propTypes = {
  record: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

FormerCleanerTitleFromRecord.defaultProps = {
  record: {},
  source: undefined,
};

export default FormerCleanerTitleFromRecord;
