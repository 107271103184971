import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin';
import { URLParamField } from '../../../components';

const DocumentDatagrid = ({ origin, ...props }) => {
  return (
    <Datagrid
      {...props}
      rowClick={(id) => {
        return `${origin}/${id}`;
      }}
    >
      <TextField source="id" />
      <ReferenceField source="account_id" reference="account" link="show">
        <TextField source="name" />
      </ReferenceField>
      <DateField source="upload_date" />
      <DateField source="document_date" />
      <ReferenceField
        allowEmpty
        source="televerseur_id"
        label="Téléverseur"
        reference="cleaner"
        link="show"
      >
        <TextField source="fullname" />
      </ReferenceField>
      <TextField source="comment" />
      <SelectField
        source="type"
        choices={[
          {
            id: 'contract',
            name: 'resources.client_document.type.contract',
          },
          {
            id: 'contract_end',
            name: 'resources.client_document.type.contract_end',
          },
          {
            id: 'provider',
            name: 'resources.client_document.type.provider',
          },
          {
            id: 'keys',
            name: "Attestation de remise d'accès",
          },
          {
            id: 'purchase_order',
            name: 'resources.client_document.subtype.purchase_order',
          },
        ]}
      />
      <SelectField
        source="subtype"
        choices={[
          {
            id: 'other',
            name: 'resources.client_document.subtype.other',
          },
          {
            id: 'endorsement',
            name: 'resources.client_document.subtype.endorsement',
          },
          {
            id: 'provider_contract',
            name: 'resources.client_document.subtype.provider_contract',
          },
          {
            id: 'ponctual_contract',
            name: 'resources.client_document.subtype.ponctual_contract',
          },
          {
            id: 'recurrent_contract',
            name: 'resources.client_document.subtype.recurrent_contract',
          },
          {
            id: 'complaint_letter',
            name: 'resources.client_document.subtype.complaint_letter',
          },
          {
            id: 'suspension_request',
            name: 'resources.client_document.subtype.suspension_request',
          },
          {
            id: 'resignation_letter',
            name: 'resources.client_document.subtype.resignation_letter',
          },
          {
            id: 'plan_prevention',
            name: 'resources.client_document.subtype.plan_prevention',
          },
          {
            id: 'keys',
            name: "Attestation de remise d'accès",
          },
          {
            id: 'purchase_order',
            name: 'resources.client_document.subtype.purchase_order',
          },
        ]}
      />
      <ReferenceField
        allowEmpty
        source="provider_id"
        reference="account"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <URLParamField
        url="https://drive.google.com/file/d/{{drive_id}}/view"
        source="drive_id"
        label="Télécharger"
        textAlign="center"
        text="📩"
        variant="body2"
        emptyText="∅"
      />
    </Datagrid>
  );
};

DocumentDatagrid.propTypes = {
  origin: PropTypes.string,
};

DocumentDatagrid.defaultProps = {
  origin: '',
};

export default DocumentDatagrid;
