import React from 'react';
import {
  Datagrid,
  ReferenceManyField,
  TextField,
  BooleanField,
} from 'react-admin';

import {
  TabbedLayout,
  Tab,
  GridCard,
  Pagination,
  URLParamField,
} from '../../../components';

const ProduitCard = (props) => {
  return (
    <GridCard {...props} title="Produits sur site" xs={12}>
      <TabbedLayout xs={12}>
        <Tab label="Présent sur site">
          <ReferenceManyField
            addLabel={false}
            reference="materiel_produit_site_vue"
            target="account_id"
            filter={{
              present: true,
            }}
            xs={12}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="nom_produit" />
              <BooleanField source="present" />
              <URLParamField
                url="https://drive.google.com/drive/folders/{{id_drive}}"
                source="id_drive"
                label="Information"
                textAlign="center"
                text="ℹ️"
                variant="body2"
                emptyText="∅"
              />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Absent du site">
          <ReferenceManyField
            addLabel={false}
            reference="materiel_produit_site_vue"
            target="account_id"
            filter={{
              present: false,
            }}
            xs={12}
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="nom_produit" />
              <BooleanField source="present" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

export default ProduitCard;
