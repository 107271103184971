import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { pure, compose } from 'recompose';
import { NumberField, translate as translateComponent } from 'react-admin';

const styles = createStyles({
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  unit: {
    marginLeft: '4px',
  },
});

export const enhanceStyles = withStyles(styles);

export const NumberWithUnitField = ({
  record,
  source,
  translate,
  translated,
  classes,
  unit,
}) => {
  return (
    <div className={classes.container}>
      <NumberField record={record} source={source} />
      <Typography component="span" variant="body2" className={classes.unit}>
        {translated
          ? translate(`components.field.unit.${unit}`).toLowerCase()
          : unit}
      </Typography>
    </div>
  );
};

NumberWithUnitField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  translate: PropTypes.func,
  translated: PropTypes.bool,
  classes: PropTypes.object,
  unit: PropTypes.string.isRequired,
};

NumberWithUnitField.defaultProps = {
  record: {},
  source: '',
  translate: /* istanbul ignore next */ () => {},
  classes: {},
  translated: false,
};

const enhance = compose(enhanceStyles, translateComponent, pure);

const EnhanceNumberWithUnitField = enhance(NumberWithUnitField);

EnhanceNumberWithUnitField.defaultProps = {
  addLabel: true,
};

export default EnhanceNumberWithUnitField;
