import { computeMinRate } from '../../../helpers';

export default (value, values) => {
  const limit = 20;
  if (value > limit) {
    return {
      message: 'resources.cleaner.form.max_hourly_rate',
      args: { limit },
    };
  }

  if (!values.level) {
    return undefined;
  }

  const minRate = computeMinRate(values.level, values.classification);

  return value < minRate
    ? {
        message: 'resources.cleaner.form.min_hourly_rate',
        args: {
          hourly_rate: minRate,
          level: values.level,
          classification: values.classification ? values.classification : '',
        },
      }
    : undefined;
};
