import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { TextField, useReference } from 'react-admin';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorIcon from '@material-ui/icons/Error';

const ProductField = ({ record, ...props }) => {
  const { loaded, error, referenceRecord } = useReference({
    reference: 'item',
    id: get(record, 'product'),
  });

  if (!loaded) {
    return <LinearProgress />;
  }
  if (error) {
    return (
      <ErrorIcon
        aria-errormessage={error.message ? error.message : error}
        color="error"
        fontSize="small"
      />
    );
  }

  if (referenceRecord) {
    return <TextField {...props} source="name" record={referenceRecord} />;
  }

  return (
    <TextField {...props} source="product" record={referenceRecord || record} />
  );
};

ProductField.propTypes = {
  record: PropTypes.object,
};

ProductField.defaultProps = {
  record: {},
};

export default ProductField;
