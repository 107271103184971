import React from 'react';
import { SelectField, DateField, ReferenceField, TextField } from 'react-admin';
import { ShowGrid, GridCard, URLParamField } from '../../../components';
import ShowActions from './ShowActions';

export default (props) => {
  return (
    <ShowGrid {...props} actions={<ShowActions />}>
      <GridCard {...props} xs={12}>
        <ReferenceField source="cleaner_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <DateField source="date" />
        <TextField source="intitule" />
        <TextField source="description" />
        <SelectField
          source="certifiante"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="interne"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField source="commentaire" />
        <ReferenceField source="validateur_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <URLParamField
          url="{{lien_detail}}"
          source="lien_detail"
          label="Détail"
          text="ℹ️"
        />
        <DateField
          source="last_modification_date"
          label="Dernière Modification"
        />
      </GridCard>
    </ShowGrid>
  );
};
