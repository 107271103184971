import React from 'react';
import { Edit } from 'react-admin';
import AffectationForm from './detail/AffectationForm';
import EditActions from './detail/EditActions';

export default (props) => {
  return (
    <Edit {...props} redirect="show" actions={<EditActions />}>
      <AffectationForm />
    </Edit>
  );
};
