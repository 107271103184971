import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, translate as translateComponent } from 'react-admin';

const typeAffectation = (type) => {
  if (type === 'ponctuel') {
    return 'Ponctuel';
  }
  if (type === 'remplacement') {
    return 'Remplacement';
  }
  if (type === 'permanent') {
    return 'Permanent';
  }
  if (type === 'absence') {
    return 'Absence';
  }
  return 'Permanent';
};

const CreateAffectation = ({ className, record, variant }) => {
  console.log('REC 2', record);
  return (
    <Button
      className={className}
      component={Link}
      to={{
        pathname: '/affectation/create',
        search: `?source={"start_date":"${record.start_date}","end_date":"${
          record.end_date
        }","cleaner":["${
          record.cleaner_id
        }"],"catalog_item_id":"01t0Y000001C4C7QAK","type":"${typeAffectation(
          record.type,
        )}"}`,
      }}
      label="Création Affectation"
      variant={variant}
    />
  );
};

CreateAffectation.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

CreateAffectation.defaultProps = {
  className: undefined,
  record: {},
  variant: 'contained',
};

export default translateComponent(CreateAffectation);
