import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin';

export const optionRenderer = (choice, translate) => {
  if (!choice) {
    return '';
  }
  if (!choice.account_name) {
    return translate('components.form.contract_input.all_contract');
  }

  return `${choice.account_name} - ${choice.site_address} - ${choice.type} - ${choice.effective_date}`;
};

const ContractAutocompleteInput = ({
  affectationIds,
  emptyMessage,
  fullWidth,
  helperText,
  resource,
  validate,
  ...props
}) => {
  const translate = useTranslate();

  const filter =
    affectationIds && affectationIds.length ? { 'in(id)': affectationIds } : {};

  return (
    <ReferenceInput
      {...props}
      filterToQuery={
        /* istanbul ignore next */
        (searchText) => {
          return { account_name: searchText };
        }
      }
      perPage={100}
      source="contract_id"
      sort={{
        field: 'type, start_date',
        order: 'DESC, DESC',
      }}
      reference="contract"
      resource={resource}
      validate={validate}
      allowEmpty
      emptyText="components.form.contract_input.all_contract"
      filter={filter}
    >
      <AutocompleteInput
        optionText={(choice) => {
          return optionRenderer(choice, translate);
        }}
        options={{
          suggestionsContainerProps: {
            /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
            style: { zIndex: 2000 },
          },
          placeholder: emptyMessage,
          fullWidth,
        }}
        helperText={helperText}
      />
    </ReferenceInput>
  );
};

ContractAutocompleteInput.propTypes = {
  affectationIds: PropTypes.array,
  emptyMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.bool,
  resource: PropTypes.string,
  validate: PropTypes.func,
};

ContractAutocompleteInput.defaultProps = {
  affectationIds: [],
  emptyMessage: '',
  fullWidth: true,
  helperText: undefined,
  resource: '',
  validate: /* istanbul ignore next */ () => {},
};

export default ContractAutocompleteInput;
