import React from 'react';
import PropTypes from 'prop-types';
import {
  List,
  NumberField,
  EmailField,
  SelectField,
  DateField,
  TextField,
  FunctionField,
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TimeField } from '../../components';
import Filter from './detail/Filter';
import InteractionListButton from './detail/InteractionListButton';

const CleanersList = ({ basePath, ...props }) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      bulkActionButtons={false}
      perPage={25}
      basePath={basePath}
    >
      <CustomizableDatagrid
        defaultColumns={['employee_status', 'salutation', 'fullname', 'phone']}
        rowClick="show"
      >
        <InteractionListButton />
        {/* {{{ default values */}
        <TextField source="employee_status" />
        <TextField source="salutation" />
        <FunctionField
          label="Name"
          render={(record) => {
            return record.employee_status === 'Ancien salarié'
              ? `${record.fullname} (${
                  record.contract_end_date !== null
                    ? record.contract_end_date
                    : 'N/A'
                })`
              : `${record.fullname}`;
          }}
        />
        <TextField source="silae_id" />
        <TextField source="phone" />
        <TimeField source="recurring_work_schedule_per_week" />
        <TimeField source="missing_recurring_hours" />
        {/* }}} */}
        {/* {{{ general */}
        <EmailField source="email" />
        <TextField source="mobile_phone" />
        <TextField source="address" />
        <DateField source="birthdate" />
        <TextField source="birthplace" />
        <TextField source="nationality" />
        <TextField source="social_security_number" />
        {/* }}} */}
        {/* {{{ contract */}
        <SelectField
          source="is_taken_over"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField source="function" />
        <TextField source="contract_type" />
        <TextField source="trial_period_renewal_signing" />
        <DateField source="contract_start_date" />
        <TextField source="contract_end_date" />
        <DateField source="last_terms_start_date" />
        <TextField source="contract_end_type" />
        <TextField source="contract_end_reason" />
        <DateField source="seniority_date" />
        <SelectField
          source="health_insurance_exemption"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField source="health_insurance" />
        {/* }}} */}
        {/* {{{ other */}
        <TextField source="manager_fullname" />
        <TextField source="drive_folder" />
        <TextField source="calendly" />
        <TextField source="tags" />
        {/* }}} */}
        {/* {{{ payroll */}
        <TextField source="level" />
        <TextField source="classification" />
        <SelectField
          source="full_time"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <NumberField source="hourly_rate" />
        <TimeField source="contract_worked_time" />
        <SelectField
          source="real_hours_computation"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField source="transportation_help" />
        {/* }}} */}
        {/* {{{ worked time */}
        <NumberField source="nb_worked_days" />
        <TimeField source="total_worked_time" />
        <TimeField source="day_worked_time" />
        <TimeField source="night_worked_time" />
        <TimeField source="sunday_worked_time" />
        <TimeField source="traveltime" />
        <TimeField source="vacancy_time" />
        <TextField source="density" />
        <NumberField source="min_weekly_hours_due" />
        <TimeField source="intercontract_work_schedule_per_week" />
        <SelectField
          source="has_missing_recurring_hours"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        {/* }}} */}
        {/* {{{  check */}
        <SelectField
          source="is_valid"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="is_consistent"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField source="consistency_error" />

        <SelectField
          source="match_max_daily_work"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_legal_weekly_work"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_max_weekly_work"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_min_daily_rest"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="match_min_weekly_rest"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        {/* }}} */}
        {/* {{{  mailing */}
        <TextField source="mailing_street" />
        <DateField source="mailing_postal_code" />
        <TextField source="mailing_city" />
        <TextField source="mailing_country" />
        {/* }}} */}
        <DateField source="last_modified_date" />
      </CustomizableDatagrid>
    </List>
  );
};

CleanersList.propTypes = {
  basePath: PropTypes.string,
};

CleanersList.defaultProps = {
  basePath: '',
};

export default CleanersList;
