const nationalities = [
  'Afghanistan',
  'Afrique du Sud',
  'Åland Islands',
  'Albanie',
  'Algérie',
  'Allemagne',
  'Andorre',
  'Angola',
  'Anguilla',
  'Antarctique',
  'Antigua et Barbuda',
  'Arabie saoudite',
  'Argentine',
  'Arménie',
  'Aruba',
  'Australie',
  'Autriche',
  'Azerbaïdjan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbade',
  'Belarus',
  'Belgique',
  'Belize',
  'Bénin',
  'Bermuda',
  'Bhutan',
  'Bolivie',
  'Bonaire, Saint-Eustache et Saba',
  'Bosnie-Herzégovine',
  'Botswana',
  'Brésil',
  'Brunéi Darussalam',
  'Bulgarie',
  'Burkina Faso',
  'Burundi',
  'Cambodge',
  'Cameroun',
  'Canada',
  'Cap-Vert',
  'Chili',
  'Chine',
  'Chypre',
  'Colombie',
  'Comores',
  'Congo, République démocratique du Congo',
  'Congo',
  'Corée, République de Corée',
  'Corée, République populaire démocratique de Corée',
  'Costa Rica',
  'Côte dIvoire',
  'Croatie',
  'Cuba',
  'Curaçao',
  'Danemark',
  'Djibouti',
  'Dominique',
  'Égypte',
  'El Salvador',
  'Émirats arabes unis',
  'Équateur',
  'Érythrée',
  'Espagne',
  'Estonie',
  'États-Unis',
  'Éthiopie',
  'Fédération de Russie',
  'Fidji',
  'Finlande',
  'France',
  'Gabon',
  'Gambie',
  'Géorgie du Sud et îles Sandwich du Sud',
  'Géorgie',
  'Ghana',
  'Gibraltar',
  'Grèce',
  'Grenade',
  'Groenland',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernesey',
  'Guinée équatoriale',
  'Guinée-Bissau',
  'Guinée',
  'Guyane française',
  'Guyane',
  'Haïti',
  'Honduras',
  'Hong Kong',
  'Hongrie',
  'Île Bouvet',
  'Île Christmas',
  'Île de Man',
  'Île Heard et îles McDonald',
  'Île Norfolk',
  'Îles Caïmans',
  'Îles Cocos (Keeling)',
  'Îles Cook',
  'Îles Falkland (Malvinas)',
  'Îles Féroé',
  'Îles Mariannes du Nord',
  'Îles Marshall',
  'Îles mineures éloignées des États-Unis',
  'Îles Salomon',
  'Îles Turques et Caïques',
  'Îles Vierges américaines.',
  'Îles Vierges britanniques',
  'Inde',
  'Indonésie',
  'Irak',
  'Iran, République islamique d Iran',
  'Irlande',
  'Islande',
  'Israël',
  'Italie',
  'Jamaïque',
  'Japon',
  'Jersey',
  'Jordanie',
  'Kazakhstan',
  'Kenya',
  'Kirghizistan',
  'Kiribati',
  'Kosovo',
  'Koweït',
  'Lesotho',
  'Lettonie',
  'Liban',
  'Liberia',
  'Libye',
  'Liechtenstein',
  'Lituanie',
  'Luxembourg',
  'Macao',
  'Macédoine, ancienne République de Yougoslavie',
  'Madagascar',
  'Malaisie',
  'Malawi',
  'Maldives',
  'Mali',
  'Malte',
  'Maroc',
  'Martinique',
  'Maurice',
  'Mauritanie',
  'Mayotte',
  'Mexique',
  'Micronésie, États fédérés de Micronésie',
  'Moldavie, République de Moldavie',
  'Monaco',
  'Mongolie',
  'Monténégro',
  'Montserrat',
  'Mozambique',
  'Myanmar',
  'Namibie',
  'Nauru',
  'Népal',
  'Nicaragua',
  'Niger',
  'Nigéria',
  'Niue',
  'Norvège',
  'Nouvelle-Calédonie',
  'Nouvelle-Zélande',
  'Oman',
  'Ouganda',
  'Ouzbékistan',
  'Pakistan',
  'Palau',
  'Panama',
  'Papouasie-Nouvelle-Guinée',
  'Paraguay',
  'Pays-Bas',
  'Pérou',
  'Philippines',
  'Pitcairn',
  'Pologne',
  'Polynésie française',
  'Porto Rico',
  'Portugal',
  'Qatar',
  'République arabe syrienne',
  'République centrafricaine',
  'République démocratique populaire lao',
  'République dominicaine',
  'République tchèque',
  'Réunion',
  'Roumanie',
  'Royaume-Uni',
  'Rwanda',
  'Sahara occidental',
  'Saint Barthélemy',
  'Saint-Kitts-et-Nevis',
  'Saint-Marin',
  'Saint-Martin (partie française)',
  'Saint-Pierre-et-Miquelon',
  'Saint-Siège (État de la Cité du Vatican)',
  'Saint-Vincent-et-les Grenadines',
  'Sainte-Hélène, Ascension et Tristan da Cunha',
  'Sainte-Lucie',
  'Samoa',
  'Sao Tomé-et-Principe',
  'Sénégal',
  'Serbie',
  'Seychelles',
  'Sierra Leone',
  'Singapour',
  'Sint Maarten (partie néerlandaise)',
  'Slovaquie',
  'Slovénie',
  'Somalie',
  'Soudan du Sud',
  'Soudan',
  'Sri Lanka',
  'Suède',
  'Suisse',
  'Suriname',
  'Svalbard et Jan Mayen',
  'Swaziland',
  'Tadjikistan',
  'Taïwan, province de Chine',
  'Tanzanie, République-Unie de Tanzanie',
  'Tchad',
  'Terres australes françaises',
  'Territoire britannique de l`océan Indien',
  'Territoire palestinien occupé',
  'Thaïlande',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinité-et-Tobago',
  'Tunisie',
  'Turkménistan',
  'Turquie',
  'Tuvalu',
  'Ukraine',
  'Uruguay',
  'Vanuatu',
  'Venezuela, République bolivarienne',
  'Viet Nam',
  'Wallis et Futuna',
  'Yémen',
  'Zambie',
  'Zimbabwe',
];
/*
[
  'Afghanistan',
  'Åland Islands',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'British Indian Ocean Territory',
  'British Virgin Islands',
  'Brunei Darussalam',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Cayman Islands',
  'Central African',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos (Keeling) Islands',
  'Colombia',
  'Comoros',
  'Cook Islands',
  'Costa Rica',
  "Côte d'Ivoire",
  'Croatia',
  'Cuba',
  'Cyprus',
  'Czech Republic',
  "Democratic People's Republic of Korea",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'French Southern Territories',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Heard Island and McDonald Islands',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iraq',
  'Ireland',
  'Islamic Republic of Iran',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kuwait',
  'Kyrgyzstan',
  "Lao People's Democratic Republic",
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libyan Arab Jamahiriya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Monaco',
  'Mongolia',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Northern Mariana Islands',
  'Norway',
  'Occupied Palestinian Territory',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Republic of Korea',
  'Republic of Moldova',
  'Republic of the Congo',
  'Réunion',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Saint Helena',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Saint-Pierre and Miquelon',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia and Montenegro',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia and the South Sandwich Islands',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard and Jan Mayen',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syrian Arab Republic',
  'Taiwan',
  'Tajikistan',
  'Thailand',
  'The Democratic Republic Of The Congo',
  'The Former Yugoslav Republic of Macedonia',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks and Caicos Islands',
  'Tuvalu',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United Republic Of Tanzania',
  'United States',
  'United States Minor Outlying Islands',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City State',
  'Venezuela',
  'Vietnam',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
]; */

export default nationalities.map((nationality) => {
  return { id: nationality, name: nationality };
});
