import { takeLatest, put, call } from 'redux-saga/effects';
import { FETCH_START, FETCH_ERROR, FETCH_END } from 'react-admin';
import { GET_CLEANER_TIMELINE, setCleanerTimeline } from '../action';
import fetchApi from './helpers/fetchApi';

export function* getCleanerTimeline({ payload }) {
  try {
    yield put({ type: FETCH_START });
    const cleanerTimeline = yield call(fetchApi, {
      url: '/cleaner/getTimeline',
      payload: { cleanerId: payload.cleanerId, filters: payload.filters },
    });
    yield put(setCleanerTimeline(cleanerTimeline));
    yield put({ type: FETCH_END });
  } catch (error) {
    yield put({ type: FETCH_ERROR });
  }
}

export default function* watch() {
  yield takeLatest(GET_CLEANER_TIMELINE, getCleanerTimeline);
}
