import React from 'react';
import { Create } from 'react-admin';
import AffectationForm from './detail/AffectationForm';

export default (props) => {
  return (
    <Create {...props} redirect="show">
      <AffectationForm create />
    </Create>
  );
};
