import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  List,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin';
import { URLParamField } from '../../components';
import Details from './detail/Details';
import Filter from './detail/Filter';

const ConditionalDownloadResumeField = ({ record, ...props }) => {
  return record.logtype !== 'visit' && record.url_control_report ? (
    <URLParamField
      source="url_control_report"
      url={record.url_control_report}
      label="Compte-rendu"
      textAlign="center"
      variant="body2"
      text="📩"
    />
  ) : (
    '❌'
  );
};

export default (props,record) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'created_at', order: 'DESC' }}
      filters={<Filter />}
    >
      <Datagrid expand={<Details />}>
        <DateField source="date" textAlign="center" />
        <ReferenceField
          source="account_id"
          reference="account"
          textAlign="center"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField
          source="manager_id"
          reference="cleaner"
          textAlign="center"
          link="show"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <SelectField
          source="logtype"
          label="Type"
          textAlign="center"
          choices={[
            { id: 'quality', name: '✔️ Contrôle Qualité' },
            { id: 'visit', name: '🤝 Visite' },
            { id: 'interaction', name: '👥 Interaction' },
            { id: 'Questionnaire', name: '❓ Questionnaire' },
          ]}
        />
        <TextField source="comment" label="Commentaire" emptyText="∅" />
        <NumberField source="global_rating" emptyText="∅" textAlign="center" />
        <ConditionalDownloadResumeField source="logtype" label="Compte-rendu" />
      </Datagrid>
    </List>
  );
};
