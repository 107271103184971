import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceManyField,
  ReferenceField,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Pagination } from '../../../../components';
import Details from './Details';

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
    width: '100%',
  },
});

const ListPay = (props) => {
  const classes = useStyles();
  return (
    <ReferenceManyField
      {...props}
      classes={classes}
      reference="timecard_month"
      target="cleaner_id"
      sort={{ field: 'month', order: 'DESC' }}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid expand={<Details />}>
        <DateField
          source="month"
          label="Mois"
          textAlign="center"
          options={{ year: 'numeric', month: 'long' }}
        />
        <ReferenceField
          source="cleaner_id"
          reference="cleaner"
          textAlign="center"
          label="Taux"
        >
          <NumberField source="hourly_rate" label="Taux" />
        </ReferenceField>
        <ReferenceField
          source="cleaner_id"
          reference="cleaner"
          textAlign="center"
          label="Contrat"
        >
          <NumberField source="contract_worked_time" label="contrat" />
        </ReferenceField>
        <NumberField
          source="complementary_10"
          textAlign="center"
          label="Complémentaire 110%"
        />
        <NumberField
          source="complementary_25"
          textAlign="center"
          label="Complémentaire 125%"
        />
        <NumberField
          source="additional_25"
          textAlign="center"
          label="Supplémentaire 125%"
        />
        <NumberField
          source="additional_50"
          textAlign="center"
          label="Supplémentaire 150%"
        />
        <NumberField
          source="recurrent_night"
          textAlign="center"
          label="Nuit Récurrente"
        />
        <NumberField
          source="recurrent_sunday"
          textAlign="center"
          label="Dimanche Récurrent"
        />
        <NumberField
          source="ponctual_night"
          textAlign="center"
          label="Nuit Ponctuelle"
        />
        <NumberField
          source="ponctual_sunday"
          textAlign="center"
          label="Dimanche Ponctuel"
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ListPay;
