import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { GridCard, TabbedLayout, Tab } from '../../../../components';

import ListAffectation from '../AffectationCard/ListAffectation';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: '0 !important',
  },
});

const AffectationCard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...sanitizeRestProps(props)}
      classes={classes}
      title={translate('resources.account.card_title.glazing')}
      xs={12}
    >
      <TabbedLayout xs={12}>
        <Tab label={translate('resources.account.tab.actives')}>
          <ListAffectation
            active
            filter={{
              catalog_item_id: '01t0Y000001C4D1QAK',
            }}
            perPage={5}
            sort={{ field: 'start_date', order: 'ASC' }}
          />
        </Tab>
        <Tab label={translate('resources.account.tab.history')}>
          <ListAffectation
            active={false}
            filter={{
              catalog_item_id: '01t0Y000001C4D1QAK',
            }}
          />
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

export default AffectationCard;
