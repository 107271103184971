import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button, translate as translateComponent } from 'react-admin';

const ReplacementButton = ({
  className,
  leaveId,
  record,
  translate,
  variant,
}) => {
  return (
    <Button
      className={className}
      component={Link}
      to={{
        pathname: '/replacement/create',
        search: leaveId
          ? `?leave_id=${leaveId}&contract_id=${record.contract_id}`
          : `?leave_id=${record.id}`,
      }}
      label={translate(
        `resources.leave.actions.${
          leaveId ? 'create_replacement_distribute' : 'create_replacement_all'
        }`,
      )}
      variant={variant}
    />
  );
};

ReplacementButton.propTypes = {
  className: PropTypes.string,
  leaveId: PropTypes.number,
  record: PropTypes.object,
  translate: PropTypes.func,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

ReplacementButton.defaultProps = {
  className: undefined,
  leaveId: undefined,
  record: {},
  translate: () => {},
  variant: 'contained',
};

export default translateComponent(ReplacementButton);
