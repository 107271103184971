import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput, translate as translateComponent } from 'react-admin';

export const SortedSelectInput = ({
  addLabel,
  choices,
  source,
  translate,
  validate,
  ...props
}) => {
  const sortedChoices = choices
    .map((choice) => {
      return { id: choice.id, name: translate(choice.name) };
    })
    .sort((first, second) => {
      return first.name.localeCompare(second.name);
    });

  return (
    <SelectInput
      {...props}
      addLabel={addLabel}
      source={source}
      validate={validate}
      choices={sortedChoices}
      translateChoice={false}
    />
  );
};
SortedSelectInput.propTypes = {
  addLabel: PropTypes.bool,
  choices: PropTypes.array,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func,
  validate: PropTypes.array,
};

SortedSelectInput.defaultProps = {
  addLabel: false,
  choices: [],
  translate: () => {},
  validate: [],
};

export default translateComponent(SortedSelectInput);
