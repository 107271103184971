import React, { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { Show as ShowRA } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import TitleFromRecord from './TitleFromRecord';

const ShowLayout = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      {Children.map(children, (child) => {
        return cloneElement(child, props);
      })}
    </Grid>
  );
};

ShowLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

const Show = ({ children, titleSource, ...props }) => {
  return (
    <ShowRA
      {...props}
      component="div"
      title={<TitleFromRecord source={titleSource} />}
    >
      <ShowLayout>{children}</ShowLayout>
    </ShowRA>
  );
};

Show.propTypes = {
  children: PropTypes.node.isRequired,
  titleSource: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

Show.defaultProps = {
  titleSource: undefined,
};

export default Show;
