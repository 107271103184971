import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import moment from 'moment';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate as translateComponent } from 'react-admin';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';

import CardHeaderAvatar from '../CardHeaderAvatar';
import Point from './Point';

const styles = createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    marginLeft: 16,
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
});

const getInitialsFromName = (name) => {
  if (typeof name !== 'string' || !name) {
    return '';
  }
  return name
    .split(' ')
    .map((namePart) => {
      return namePart[0];
    })
    .join('');
};

const TimelineCard = ({ classes, event, onDetailsClick, translate }) => {
  const subject =
    event.subject ||
    translate(`resources.logs.log_type.${event.logType}`) ||
    '';

  return (
    <Grid item xs={12} className={classes.root}>
      <Point />
      <Card className={classes.card}>
        <CardHeaderAvatar
          avatar={
            event.owner ? (
              <Avatar>{getInitialsFromName(event.owner.name)}</Avatar>
            ) : null
          }
          title={`${subject} - ${moment(event.date).format('DD/MM/YY')}`}
          subheader={event.description}
        />
        <CardActions>
          <Button
            size="small"
            color="primary"
            onClick={() => {
              onDetailsClick(event);
            }}
          >
            {translate('components.button.timeline.details')}
          </Button>
        </CardActions>
      </Card>
    </Grid>
  );
};

TimelineCard.propTypes = {
  event: PropTypes.object,
  classes: PropTypes.object,
  onDetailsClick: PropTypes.func,
  translate: PropTypes.func,
};

TimelineCard.defaultProps = {
  event: {},
  classes: {},
  onDetailsClick: () => {},
  translate: () => {},
};

const enhance = compose(translateComponent, withStyles(styles));

export default enhance(TimelineCard);
