import React from 'react';
import PropTypes from 'prop-types';
import { Datagrid, DateField, TextField, useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ScheduleField } from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    title: {
      paddingBottom: theme.spacing(1),
    },
    noData: {
      paddingLeft: theme.spacing(2),
    },
  };
});

const ListAffectation = ({
  classes: classesOverride,
  data,
  ids,
  title,
  ...props
}) => {
  const classes = useStyles({ classes: classesOverride });
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      {title && (
        <Typography className={classes.title} variant="h6">
          {title}
        </Typography>
      )}
      {ids && ids.length ? (
        <Datagrid
          {...props}
          currentSort={{}}
          setSort={() => {}}
          ids={ids}
          data={data}
          basePath="/affectation"
          rowClick="show"
        >
          <TextField source="cleaner_fullname" />
          <DateField source="start_date" />
          <DateField source="end_date" />
          <ScheduleField
            label="Planning"
            source="days"
            refStartTime="startTime"
            refEndTime="endTime"
          />
        </Datagrid>
      ) : (
        <Typography className={classes.noData} variant="body2">
          {translate('ra.navigation.no_results')}
        </Typography>
      )}
    </div>
  );
};

ListAffectation.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  ids: PropTypes.array,
  title: PropTypes.string,
};

ListAffectation.defaultProps = {
  classes: {},
  data: {},
  ids: [],
  title: undefined,
};

export default ListAffectation;
