export const largeStyles = {
  bgCover: {
    background: 'linear-gradient(90deg, #a1c4fd 0%,#c2e9fb 100% )',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    maxWidth: '65%',
    height: '500px',
    width: '100%',
    backgroundColor: 'white',
    display: 'flex',
    position: 'relative',
    boxShadow: '-6px 15px 106px 6px rgba(0,0,0,0.46)',
    paddingBottom: '5%',
  },
  filter: {
    background: 'linear-gradient(135deg,  #003d5a  0%, #002c4c 100%)',
    opacity: '0.5',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 2,
  },
  leftLabel: {
    zIndex: 3,
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: 25,
    wordWrap: 'break-word',
    margin: '10%',
    marginTop: '40%',
    position: 'absolute',
  },
  resetLabel: {
    marginTop: '20%',
    fontSize: 23,
    fontFamily: 'Roboto',
    color: '#123876',
  },
  leftContainer: {
    flex: 1,
    zIndex: 1,
  },
  rightContainer: {
    flex: 1,
    zIndex: 1,
    marginLeft: '50%',
    width: '50%',
    backgroundColor: '#FFFFFF',
    textAlign: 'center',
    paddingTop: '20px',
  },
  rightContainerForm: {
    margin: '15%',
    width: '70%',
    height: '80%',
  },
  errorStyle: {
    bottom: '-30%',
    float: 'left',
  },
  textField: {
    paddingTop: '10%',
    fontSize: 20,
    position: 'relative',
  },
  hintStyle: {
    fontSize: 20,
    color: '#909FB2',
  },
  underlineStyle: {
    bottom: '-5%',
  },
  button: {
    marginTop: '25px',
    marginBottom: '30px',
  },
  buttonDisabled: {
    top: 40,
    backgroundColor: 'grey',
    color: '#FFFFFF',
    width: '25%%',
    fontSize: '34x',
  },
  backStyle: {
    textDecoration: 'none',
    backgroundColor: 'none',
    color: '#123876',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '10%',
    right: '-10%',
    fontFamily: 'Roboto',
  },
  resetButton: {
    textDecoration: 'none',
    backgroundColor: 'none',
    color: '#123876',
    cursor: 'pointer',
    bottom: '-30px',
    fontFamily: 'Roboto',
  },
  formStyle: {
    position: 'relative',
    margin: '5%',
    width: '95%',
    height: '100%',
    marginTop: '5%',
    padding: '5px 0px',
  },
  linkContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    fontSize: '14px',
  },
};

export const mediumStyles = {
  bgCover: {
    background: 'linear-gradient(90deg, #a1c4fd 0%,#c2e9fb 100% )',
    width: '100vw',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  container: {
    textAlign: 'center',
    maxWidth: '100%',
    width: '100%',
    height: '500px',
    display: 'flex',
    position: 'relative',
    boxShadow: '-6px 15px 106px 6px rgba(0,0,0,0.46)',
  },
  filter: {
    background: 'linear-gradient(135deg,  #003d5a  0%,#002c4c 100%)',
    opacity: '0.5',
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 2,
  },
  leftLabel: {
    zIndex: 3,
    color: 'white',
    fontFamily: 'Roboto',
    fontSize: 24,
    wordWrap: 'break-word',
    margin: '10%',
    marginTop: '40%',
    position: 'absolute',
  },
  resetLabel: {
    marginTop: '20%',
    fontSize: 22,
    fontFamily: 'Roboto',
    color: '#123876',
  },
  rightContainer: {
    flex: 1,
    zIndex: 1,
    display: 'flex',
    alignitems: 'center',
    marginLeft: '50%',
    width: '50%',
    height: '100%',
    backgroundColor: '#FFFFFF',
  },
  rightContainerForm: {
    margin: '15%',
    width: '70%',
  },
  errorStyle: {
    bottom: '-20%',
    float: 'left',
  },
  textField: {
    paddingTop: '12%',
    fontSize: 25,
  },
  hintStyle: {
    fontSize: 18,
    color: '#909FB2',
  },
  button: {
    marginTop: '25px',
    marginBottom: '15px',
  },
  buttonDisabled: {
    top: 40,
    backgroundColor: 'grey',
    color: '#FFFFFF',
    width: '25%%',
    fontSize: '34x',
  },
  formStyle: {
    position: 'relative',
    margin: '6%',
    width: '90%',
    height: '98%',
    marginTop: '30px',
  },
  backStyle: {
    textDecoration: 'none',
    backgroundColor: 'none',
    color: '#123876',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '7%',
    right: '-10%',
    fontFamily: 'Roboto',
  },
  resetButton: {
    textDecoration: 'none',
    backgroundColor: 'none',
    color: '#123876',
    cursor: 'pointer',
    bottom: '-30px',
    fontFamily: 'Roboto',
    padding: '5px 0px',
  },
  linkContainer: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-between',
    fontSize: '13px',
  },
};

export const smallStyles = {
  container: {
    textAlign: 'center',
    maxWidth: '90%',
    boxShadow: '-6px 15px 106px 6px rgba(0,0,0,0.46)',
    zIndex: 10,
    backgroundColor: '#FFFFFF',
  },
  containerForm: {
    margin: '15% 10% 15% 10%',
    width: '80%',
  },
  textField: {
    paddingTop: '12%',
    fontSize: 18,
  },
  buttonDisabled: {
    top: 40,
    backgroundColor: 'grey',
    color: '#FFFFFF',
    width: '25%%',
    fontSize: '34x',
  },
  backStyle: {
    textDecoration: 'none',
    backgroundColor: 'none',
    color: '#123876',
    cursor: 'pointer',
    position: 'absolute',
    bottom: '-30px',
    right: '-15%',
    fontFamily: 'Roboto',
  },
  formStyle: {
    position: 'relative',
    margin: '7%',
    marginTop: '40px',
    width: '85%',
    height: '90%',
  },
  resetLabel: {
    marginTop: '20%',
    fontSize: 22,
    fontFamily: 'Roboto',
    color: '#123876',
  },
  button: {
    marginTop: '25px',
    marginBottom: '15px',
  },
  resetButton: {
    textDecoration: 'none',
    backgroundColor: 'none',
    color: '#123876',
    cursor: 'pointer',
    bottom: '-30px',
    fontFamily: 'Roboto',
    padding: '5px 0px',
  },
  linkContainer: {
    display: 'flex',
    flexFlow: 'column wrap',
    justifyContent: 'space-between',
    fontSize: '13px',
  },
};
