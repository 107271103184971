import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import StartEndTimeInput from '../../../components/input/StartEndTimeInput';
import DaysInput from '../../../components/input/DaysInput';

const useStyles = makeStyles({
  errorText: {
    marginTop: 0,
  },
});

const PlanningDaysInput = ({
  addLabel,
  className: classNameProp,
  defaultDays,
  defaultTime,
  label,
  labelError,
  id,
  setParentInputValue,
  source,
  ...props
}) => {
  const [time, setTime] = useState(defaultTime);
  const classes = useStyles();
  const translate = useTranslate();
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
  } = useField(source);

  const helperText = touched && error && error.day ? error.day : '';
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  const handleChangeTime = (val) => {
    const newValue = Object.keys(value).reduce((acc, item) => {
      if (value[item] && value[item].length) {
        return { ...acc, [item]: [val] };
      }
      return acc;
    }, {});

    setTime(val);
    setParentInputValue(newValue);
    onChange(newValue);
  };

  const handleChange = (day) => {
    return (checked) => {
      const newValue = Object.assign({}, value);

      newValue[day] = checked
        ? [
            {
              start_time: time.start_time,
              end_time: time.end_time,
            },
          ]
        : [];

      setParentInputValue(newValue);
      onChange(newValue);
    };
  };

  const fieldError = error && error.time && Object.values(error.time)[0];
  return (
    <Fragment>
      <StartEndTimeInput
        label={translate('components.planning.schedule')}
        defaultValue={defaultTime}
        meta={{ touched, error: fieldError }}
        onChange={handleChangeTime}
      />
      <FormControl margin="normal">
        {addLabel && (
          <InputLabel shrink htmlFor="days" error={!!helperText}>
            {label}
          </InputLabel>
        )}
        <Input
          disableUnderline
          inputComponent={DaysInput}
          inputProps={{
            ...props,
            ...restInput,
            className: classNameProp,
            defaultDays,
            handleChange,
          }}
        />
      </FormControl>
      {helperText && (
        <FormHelperText id={helperTextId} error className={classes.errorText}>
          {helperText}
        </FormHelperText>
      )}
    </Fragment>
  );
};

PlanningDaysInput.propTypes = {
  addLabel: PropTypes.bool,
  className: PropTypes.string,
  defaultDays: PropTypes.object,
  defaultTime: PropTypes.object,
  id: PropTypes.string,
  input: PropTypes.object,
  label: PropTypes.string,
  labelError: PropTypes.bool,
  meta: PropTypes.object,
  setParentInputValue: PropTypes.func,
  source: PropTypes.string.isRequired,
};

PlanningDaysInput.defaultProps = {
  addLabel: true,
  className: undefined,
  defaultDays: {},
  defaultTime: {},
  id: undefined,
  input: {},
  label: undefined,
  labelError: false,
  meta: {},
  setParentInputValue: () => {},
};

export default PlanningDaysInput;
