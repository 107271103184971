import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Button, translate as translateComponent } from 'react-admin';
import { updateSchedulerJob as updateSchedulerJobAction } from '../../../action';

const ActiveButton = ({
  translate,
  updateSchedulerJob,
  record,
  variant,
  size,
}) => {
  const [active, setActive] = useState(record.active);

  return (
    <Button
      label={translate(
        active
          ? 'resources.scheduler.buttons.disable'
          : 'resources.scheduler.buttons.active',
      )}
      variant={variant}
      size={size}
      onClick={() => {
        updateSchedulerJob(!active);
        setActive(!active);
      }}
    />
  );
};

ActiveButton.propTypes = {
  translate: PropTypes.func,
  updateSchedulerJob: PropTypes.func,
  record: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.string,
};

ActiveButton.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
  updateSchedulerJob: /* istanbul ignore next */ () => {},
  record: {},
  variant: 'contained',
  size: 'medium',
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateSchedulerJob: (active) => {
      dispatch(
        updateSchedulerJobAction(ownProps.record.id, {
          active,
        }),
      );
    },
  };
};

const enhance = compose(translateComponent, connect(null, mapDispatchToProps));

export default enhance(ActiveButton);
