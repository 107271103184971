import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => {
  return {
    expand: {
      color: theme.palette.grey[600],
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  };
});

const ExpandIcon = ({ className, open }) => {
  const classes = useStyles();

  return (
    <ExpandMoreIcon
      className={clsx(
        classes.expand,
        {
          [classes.expandOpen]: open,
        },
        className,
      )}
    />
  );
};

ExpandIcon.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
};

ExpandIcon.defaultProps = {
  className: undefined,
};

export default ExpandIcon;
