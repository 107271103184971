import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { ReferenceManyField, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { GridCard } from '../../../components';
import DocumentsDrawerList from './DocumentDrawerList';
import LettersDrawerList from './LetterDrawerList';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
});

const DocumentsTab = ({ cleanerId, match, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Fragment>
      <GridCard
        {...props}
        classes={classes}
        title={translate('resources.cleaner.card_title.document')}
        xs={12}
      >
        <ReferenceManyField
          reference="cleaner_document"
          target="cleaner_id"
          addLabel={false}
          xs={12}
        >
          <DocumentsDrawerList
            {...props}
            originRoute={`${match.url}/documents`}
          />
        </ReferenceManyField>
      </GridCard>

      <GridCard
        {...props}
        classes={classes}
        title={translate('resources.cleaner.card_title.letter')}
        xs={12}
      >
        <ReferenceManyField
          reference="cleaner_letter"
          target="cleaner_id"
          addLabel={false}
          xs={12}
        >
          <LettersDrawerList
            {...props}
            originRoute={`${match.url}/documents`}
          />
        </ReferenceManyField>
      </GridCard>
    </Fragment>
  );
};

DocumentsTab.propTypes = {
  match: PropTypes.object,
  cleanerId: PropTypes.string,
};

DocumentsTab.defaultProps = {
  match: {},
  cleanerId: '',
};

export default DocumentsTab;
