import React from 'react';
import PropTypes from 'prop-types';
import {
  DateField,
  NumberField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { Pagination } from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      padding: theme.spacing(1),
    },
    item: {
      padding: theme.spacing(2),
    },
    secondText: {
      color: theme.palette.grey[600],
    },
    separator: {
      color: theme.palette.grey[600],
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  };
});

const ListProduct = ({ ids, data, variant }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.root}>
      {ids.map((id) => {
        return (
          <Grid key={id} item xs={12} sm={6} md={4} lg={4}>
            <TextField record={data[id]} source="name" variant={variant} />
            <div>
              <NumberField
                className={classes.secondText}
                record={data[id]}
                source="total_price"
                options={{ style: 'currency', currency: 'EUR' }}
                variant={variant}
              />
              <span className={classes.separator}>-</span>
              <DateField
                className={classes.secondText}
                record={data[id]}
                source="start_date"
              />
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

ListProduct.propTypes = {
  ids: PropTypes.array,
  data: PropTypes.object,
  variant: PropTypes.string,
};

ListProduct.defaultProps = {
  ids: [],
  data: {},
  variant: 'body2',
};

const ListProductController = ({ className, perPage, title, ...props }) => {
  return (
    <div className={className}>
      {title && <Typography variant="h6">{title}</Typography>}
      <ReferenceManyField
        {...props}
        reference="product"
        target="contract_id"
        addLabel={false}
        sort={{ field: 'name, start_date', order: 'ASC, ASC' }}
        xs={12}
        perPage={perPage}
        pagination={<Pagination />}
      >
        <ListProduct />
      </ReferenceManyField>
    </div>
  );
};

ListProductController.propTypes = {
  className: PropTypes.string,
  perPage: PropTypes.number,
  title: PropTypes.string,
};

ListProductController.defaultProps = {
  className: undefined,
  perPage: 25,
  title: undefined,
};

export default ListProductController;
