import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { useDispatch } from 'react-redux';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { CleanerMenuTelecharger } from '../../../../components';
import RenouvellementPE from './renouvellementPE';
import ReleveHeure from './releveHeure';
import { getLegalDocument } from '../../../../action';

const DownloadMenu = ({ className, record }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const getLegalDocumentClick = useCallback(
    (documentType) => {
      const payload = {
        id: record.id,
        fullname: record.fullname,
        documentType,
      };
      dispatch(getLegalDocument(payload));
    },
    [dispatch, record],
  );

  return (
    <CleanerMenuTelecharger
      className={className}
      label={translate('resources.cleaner.actions.download')}
      record={record}
    >
      <MenuItem
        onClick={() => {
          getLegalDocumentClick('certificat');
        }}
      >
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText
          primary={`${translate(
            'resources.cleaner.actions.download_certificat',
          )}`}
        />
      </MenuItem>
      <MenuItem
        onClick={() => {
          getLegalDocumentClick('authentificationRequest');
        }}
      >
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText
          primary={"Formulaire d'authentification du titre de séjour"}
        />
      </MenuItem>
      <MenuItem
        onClick={() => {
          getLegalDocumentClick('planning');
        }}
      >
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText primary="Planning" />
      </MenuItem>
      <MenuItem
        onClick={() => {
          getLegalDocumentClick('endOfTrialPeriod');
        }}
      >
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <ListItemText
          primary={`${translate(
            'resources.cleaner.actions.download_endOfTrialPeriod',
          )}`}
        />
      </MenuItem>
      <MenuItem record={record}>
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <RenouvellementPE record={record} />
      </MenuItem>
      <MenuItem record={record}>
        <ListItemIcon>
          <CloudDownload />
        </ListItemIcon>
        <ReleveHeure record={record} />
      </MenuItem>
    </CleanerMenuTelecharger>
  );
};

DownloadMenu.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

DownloadMenu.defaultProps = {
  className: undefined,
  record: {},
};

export default DownloadMenu;
