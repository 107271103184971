import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  TextField,
  DateField,
  SelectField,
  ReferenceField,
} from 'react-admin';
import { GridCard } from '../../../components';
import { URLParamField } from '../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const ListDetail = ({ ...props }) => {
  const classes = useStyles();

  return (
    <GridCard {...props} classes={classes}>
      <SelectField source="check_out" label="Le Cleaner a fini la mission?"  choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      <SelectField source="signature" label="La prestation a été signée?" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      <SelectField source="mail_envoye" label="Un mail a-t-il été envoyé?" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      <DateField source="created_at" label="Début de la mission" showTime />
      <DateField source="update_at" label="Fin de la misison" showTime />
      <ReferenceField
        source="affectation"
        reference="affectation"
        label="Cleaner"
        textAlign="center"
        link="show"
      >
        <TextField source="cleaner_fullname" name="Cleaner" />
      </ReferenceField>
      <ReferenceField
        source="affectation"
        reference="affectation"
        label="Cleaner"
        textAlign="center"
        link="show"
      >
        <TextField source="account_name" label="Compte" />
      </ReferenceField>
      <ReferenceField
        source="affectation"
        reference="affectation"
        label="Début affectation"
        textAlign="center"
        link="show"
      >
        <TextField source="start_time" label="Début affectation" />
      </ReferenceField>
      <ReferenceField
        source="affectation"
        reference="affectation"
        label="Fin affectation"
        textAlign="center"
        link="show"
      >
        <TextField source="end_time" label="Fin affectation" />
      </ReferenceField>
      <URLParamField
        url="{{id_signature}}"
        source="id_signature"
        label="Compte-rendu"
        textAlign="center"
        text="📩"
        variant="body2"
        emptyText="∅"
      />
    </GridCard>
  );
};

export default ListDetail;
