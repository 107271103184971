import React from 'react';
import PropTypes from 'prop-types';
import querystring from 'query-string';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTranslate } from 'react-admin';

import { getTable } from './googleFormTable';

export const openGoogleForm = (record, type) => {
  const googleFormTable = getTable();
  const { params, tools, url } = googleFormTable[type];
  const extracted = Object.keys(params).reduce((obj, key) => {
    return {
      ...obj,
      [params[key]]: tools && tools[key] ? tools[key](record) : record[key],
    };
  }, {});
  const query = querystring.stringify(extracted);
  const win = window.open(`${url}?${query}`, '_blank');
  win.focus();
};

export const GoogleFormMenuItem = React.forwardRef(({ record, type }, ref) => {
  const translate = useTranslate();

  return (
    <MenuItem
      onClick={
        /* istanbul ignore next */ () => {
          openGoogleForm(record, type);
        }
      }
      ref={ref}
    >
      <ListItemText primary={translate(`resources.logs.actions.${type}`)} />
    </MenuItem>
  );
});

GoogleFormMenuItem.propTypes = {
  type: PropTypes.string,
  record: PropTypes.object,
};

GoogleFormMenuItem.defaultProps = {
  type: '',
  record: {},
};

export default GoogleFormMenuItem;
