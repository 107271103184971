import React from 'react';
import { EditButton } from 'react-admin';
import { ActionsContainer } from '../../../components';
import LaunchButton from './LaunchButton';

const ShowActions = (props) => {
  return (
    <ActionsContainer {...props}>
      <EditButton />
      <LaunchButton />
    </ActionsContainer>
  );
};

export default ShowActions;
