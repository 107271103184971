export default list => {
  const days = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
  ];

  return list.length === 0 ? '-' : list.map(index => days[index]).join(' ');
};
