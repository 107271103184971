import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  translate as translateComponent,
  BulkDeleteButton,
} from 'react-admin';
import moment from 'moment';
import { DrawerCreateEdit } from '../../containers';
import { NumberWithUnitField, ProductField } from '../../components';
import Edit from './Edit';
import Create from './Create';
import Filter from './detail/Filter';


const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);



const InvoiceIncubatorList = ({ translate, ...props }) => {
  return (
    <DrawerCreateEdit
      {...props}
      routePath="/invoice_incubator"
      renderCreate={Create}
      renderEdit={Edit}
    >
      <List
        {...props}
        bulkActionButtons={<PostBulkActionButtons />}
        hasCreate
        filters={<Filter />}
        perPage={25}
        filterDefaultValues={{
          month: moment()
            .subtract(7, 'days')
            .format('YYYY-MM'),
          generated: false,
        }}
      >
        <Datagrid
          rowClick={(id, basePath, record) => {
            if (record.invoice_number) {
              return null;
            }
            return 'edit';
          }}
        >
          <DateField source="month" />
          <ReferenceField link="show" source="account_id" reference="account">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField link="show" source="billing_id" reference="account">
            <TextField source="name" />
          </ReferenceField>
          <ProductField
            label={translate('resources.invoice_incubator.fields.product')}
          />
          <TextField source="description" />
          <NumberWithUnitField source="price" unit="€ (HT)" />
          <TextField source="invoice_number" />
        </Datagrid>
      </List>
    </DrawerCreateEdit>
  );
};

InvoiceIncubatorList.propTypes = {
  translate: PropTypes.func,
};

InvoiceIncubatorList.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(InvoiceIncubatorList);
