import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';

const TabbedShowLayout = ({
  basePath,
  children,
  classes,
  record,
  resource,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs value={value} onChange={handleChange}>
        {Children.map(children, (tab) => {
          return cloneElement(tab, {
            context: 'header',
          });
        })}
      </Tabs>
      <div className={classes.content}>
        {Children.map(children, (tab, index) => {
          return value === index
            ? cloneElement(tab, {
                context: 'content',
                resource,
                record,
                basePath,
              })
            : null;
        })}
      </div>
    </div>
  );
};

TabbedShowLayout.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  classes: PropTypes.object,
  record: PropTypes.object,
  resource: PropTypes.string,
};

TabbedShowLayout.defaultProps = {
  basePath: '/',
  children: null,
  classes: {},
  record: {},
  resource: '',
};

export default TabbedShowLayout;
