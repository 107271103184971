import React from 'react';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin';
import { Pagination, URLParamField } from '../../../../components';

import Details from './Details';

const ListServiceCertificate = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference="service_certificate"
      target="account_id"
      addLabel={false}
      sort={{ field: 'update_at', order: 'DESC' }}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid expand={<Details />}>
        <DateField
          source="update_at"
          label="Date Signature"
          textAlign="center"
        />
        <ReferenceField
          source="cleaner_id"
          reference="cleaner"
          textAlign="center"
          link="show"
        >
          <TextField source="fullname" label="Agent" />
        </ReferenceField>
        <SelectField
          source="type"
          label="Type"
          textAlign="center"
          choices={[
            { id: 'Vitrerie', name: '🟦 Vitrerie' },
            { id: 'Factotum', name: '🛠️ Factotum' },
          ]}
        />
        <SelectField source="signature" label="Le client a signé ?" emptyText="∅" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <URLParamField
          url="{{id_signature}}"
          source="id_signature"
          label="Attestation de prestation"
          textAlign="center"
          text="📩"
          variant="body2"
          emptyText="∅"
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ListServiceCertificate;
