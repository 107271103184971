import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-admin';
import Menu from '@material-ui/core/Menu';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

const AccountMenuActions = ({ children, className, label, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        className={className}
        variant="contained"
        onClick={handleClick}
        label={label}
      >
        <LibraryBooksIcon />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.map(children, (child) => {
          if (child) {
            return React.cloneElement(child, {
              ...props,
            });
          }
          return null;
        })}
      </Menu>
    </div>
  );
};

AccountMenuActions.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  label: PropTypes.string,
};

AccountMenuActions.defaultProps = {
  children: {},
  className: undefined,
  label: '',
};

export default AccountMenuActions;
