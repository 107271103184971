import React from 'react';
import { translate as translateComponent } from 'react-admin';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import get from 'lodash.get';

const DatePeriodField = ({ record, translate, variant }) => {
  const startDate = get(record, 'start_date');
  const endDate = get(record, 'end_date');
  if (!endDate) {
    return (
      <Typography variant={variant}>
        {translate('format.date.since', {
          start_date: moment(startDate).format(translate('format.date.format')),
        })}
      </Typography>
    );
  }
  return (
    <Typography variant={variant}>
      {translate('format.date.period', {
        start_date: moment(startDate).format(translate('format.date.format')),
        end_date: moment(endDate).format(translate('format.date.format')),
      })}
    </Typography>
  );
};

DatePeriodField.propTypes = {
  record: PropTypes.object,
  translate: PropTypes.func,
  variant: PropTypes.string,
};

DatePeriodField.defaultProps = {
  record: {},
  translate: /* istanbul ignore next */ () => {},
  variant: 'body1',
};

export default translateComponent(DatePeriodField);
