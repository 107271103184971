import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin';
import { DrawerCreateEdit } from '../../containers';
import { URLParamField } from '../../components';
import Details from './detail/Details';
import Filter from './detail/Filter';

const ServiceCertificateList = (props) => {
  return (
    <DrawerCreateEdit {...props} routePath="/service_certificate">
      <List
        {...props}
        hasCreate
        filters={<Filter />}
        sort={{ field: 'start_date', order: 'DESC' }}
        bulkActionButtons={false}
        perPage={25}
      >
        <Datagrid expand={<Details />}>
          <DateField
            source="start_date"
            label="Date de passage"
            textAlign="center"
          />
          <ReferenceField
            source="contract_id"
            reference="contract"
            label="Compte"
            link="show"
          >
            <TextField source="account_name" />
          </ReferenceField>

          <ReferenceField
            source="affectation"
            reference="affectation"
            label="Cleaner"
            textAlign="center"
            link="show"
          >
            <TextField source="cleaner_fullname" name="Cleaner" />
          </ReferenceField>
          <SelectField source="signature" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <URLParamField
            url="{{id_signature}}"
            source="id_signature"
            label="Compte-rendu"
            textAlign="center"
            text="📩"
            variant="body2"
            emptyText="∅"
          />
        </Datagrid>
      </List>
    </DrawerCreateEdit>
  );
};

ServiceCertificateList.propTypes = {
  basePath: PropTypes.string,
};

ServiceCertificateList.defaultProps = {
  basePath: '',
};

export default ServiceCertificateList;
