import React from 'react';
import PropTypes from 'prop-types';
import PermanentIcon from '@material-ui/icons/AllInclusive';
import ReplacementIcon from '@material-ui/icons/SwapVert';
import PunctualIcon from '@material-ui/icons/LooksOne';
import LeaveIcon from '@material-ui/icons/Block';
import IntercontractIcon from '@material-ui/icons/Assignment';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';
// import FlipIcon from '@material-ui/icons/Flip';

import IconedTextField from './IconedTextField';

const ContractTypeIcon = ({ record, ...props }) => {
  if (!record || !record.type) {
    return null;
  }

  switch (record.type) {
    case 'Permanent':
    case 'Récurrent':
      return <PermanentIcon {...props} />;
    case 'Remplacement':
      return <ReplacementIcon {...props} />;
    case 'Ponctuel':
      return <PunctualIcon {...props} />;
    case 'Absence':
      return <LeaveIcon style={{ color: '#FF0000' }} {...props} />;
    case 'Intercontrat':
      return <IntercontractIcon style={{ color: '#ffc100' }} {...props} />;
    case 'Remplacement Saisonnier':
      return <BeachAccessIcon style={{ color: '#ffc100' }} {...props} />;
    /* case 'Maintien Partiel':
      return <FlipIcon style={{ color: '#ffc100' }} {...props} />; */
    default:
      return null;
  }
};

ContractTypeIcon.propTypes = {
  record: PropTypes.object,
};

ContractTypeIcon.defaultProps = {
  record: {},
};

const AffectationTypeField = ({ record, source, ...props }) => {
  return (
    <IconedTextField
      {...props}
      record={record}
      icon={<ContractTypeIcon record={record} />}
      source="type"
    />
  );
};

AffectationTypeField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
};

AffectationTypeField.defaultProps = {
  record: {},
  source: 'type',
};

export default AffectationTypeField;
