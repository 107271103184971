import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, makeStyles, Grid, Card } from '@material-ui/core';
import { Datagrid, TextField, useTranslate } from 'react-admin';
import { arrayToIdsData } from '../../../../helpers';
import {
  StartToEndDatesField,
  ScheduleField,
  TwoLinesField,
} from '../../../../components';

import Header from './Header';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    containerReplacement: {
      paddingBottom: theme.spacing(2),
    },
    sectionTitle: {
      fontWeight: 500,
    },
    default: {
      padding: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  };
});

const redirectAffectation = (id) => {
  return `/affectation/${id}/show`;
};

export const AccountItem = ({ planning, record, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const accountName = planning.affectations[0].account_name;
  const opportunityName = planning.affectations[0].opportunity_name;
  const manager = planning.affectations[0].manager_fullname;

  const affectations = arrayToIdsData(planning.affectations);
  const replacements = arrayToIdsData(planning.replacements);

  return (
    <Grid item xs={6}>
      <Card>
        <Header
          title={accountName}
          leaveId={record.id}
          subtitle={opportunityName}
          totalDuration={planning.totalDuration}
          replacementDuration={planning.replacementDuration}
          manager={manager}
        />
        <div className={classes.container}>
          <Typography className={classes.sectionTitle}>
            {translate('resources.leave.fields.leaves')}
          </Typography>
          {affectations.ids && affectations.ids.length ? (
            <Datagrid
              {...props}
              ids={affectations.ids}
              data={affectations.data}
              currentSort={{}}
              rowClick={redirectAffectation}
            >
              <StartToEndDatesField source="dates" />
              <ScheduleField
                label="Planning"
                source="days"
                refStartTime="startTime"
                refEndTime="endTime"
              />
            </Datagrid>
          ) : (
            <Typography>
              {translate('resources.leave.fields.no_affectation')}
            </Typography>
          )}
        </div>
        <div className={clsx(classes.container, classes.containerReplacement)}>
          <Typography className={classes.sectionTitle}>
            {translate('resources.leave.fields.replacements')}
          </Typography>
          {replacements.ids && replacements.ids.length ? (
            <Datagrid
              {...props}
              ids={replacements.ids}
              data={replacements.data}
              currentSort={{}}
              rowClick={redirectAffectation}
            >
              <TwoLinesField
                source="days"
                primary={<StartToEndDatesField source="dates" />}
                secondary={
                  <TextField variant="body2" source="cleaner_fullname" />
                }
              />
              <ScheduleField
                label="Planning"
                source="days"
                refStartTime="startTime"
                refEndTime="endTime"
              />
            </Datagrid>
          ) : (
            <Typography className={classes.default}>
              {translate('resources.leave.fields.no_replacement')}
            </Typography>
          )}
        </div>
      </Card>
    </Grid>
  );
};

AccountItem.propTypes = {
  planning: PropTypes.object,
  record: PropTypes.object,
};

AccountItem.defaultProps = {
  planning: {},
  record: {},
};

export default AccountItem;
