import React from 'react';
import PropTypes from 'prop-types';
import { BooleanInput, Filter, TextInput } from 'react-admin';
import { CleanerAutocompleteInput } from '../../../components';

const FormationFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <CleanerAutocompleteInput source="cleaner_id" alwaysOn />
      <TextInput source="intitule" />
      <BooleanInput
        alwaysOn
        label="Certifiante/Diplomante"
        source="certifiante"
      />
      <BooleanInput alwaysOn label="Interne" source="interne" />
    </Filter>
  );
};

FormationFilter.propTypes = {
  translate: PropTypes.func,
};

FormationFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default FormationFilter;
