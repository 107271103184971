import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const status = {
  CANCELLED: 'cancelled',
  CHARGED_BACK: 'charged_back',
  CUSTOMER_APPROVAL_DENIED: 'customer_approval_denied',
  FAILED: 'failed',
  PAID_OUT: 'paid_out',
  PENDING_CUSTOMER_APPROVAL: 'pending_customer_approval',
  PENDING_SUBMISSION: 'pending_submission',
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 90,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      fontSize: 12,
    },
    cancelled: {
      backgroundColor: theme.palette.status.cancelled,
    },
    charged_back: {
      backgroundColor: theme.palette.status.success,
    },
    customer_approval_denied: {
      backgroundColor: theme.palette.status.error,
    },
    failed: {
      backgroundColor: theme.palette.status.error,
    },
    paid_out: {
      backgroundColor: theme.palette.status.success,
    },
    pending_customer_approval: {
      backgroundColor: theme.palette.status.pending,
    },
    pending_submission: {
      backgroundColor: theme.palette.status.pending,
    },
  };
});

const InvoiceStatusChipField = ({ record, source }) => {
  const value = get(record, source).toUpperCase();

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.root, classes[status[value]])}
      color="primary"
      label={translate(
        `components.field.direct_debit_status.${value}`,
      ).toUpperCase()}
      size="small"
    />
  );
};

InvoiceStatusChipField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

InvoiceStatusChipField.defaultProps = {
  record: {},
};

export default InvoiceStatusChipField;
