import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { Button, translate as translateComponent } from 'react-admin';
import { getLegalDocument } from '../../../../action';

const ReleveHeure = ({ className, getLegalDocumentClick }) => {
  const [dateMois, setPE] = useState('01/03/2022');
  const [open, setOpen] = useState(false);
  const invertOpenStatus = () => {
    setOpen(!open);
  };
  const OnChangePE = (newPE) => {
    setPE(newPE.target.value);
  };

  const getTemplate = (date) => {
    const split = date.split('/');
    return new Date(split[2], split[1], 0).getDate();
  };

  return (
    <Fragment>
      <MenuItem
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
      >
        <ListItemText primary="Relevé d'heure" />
      </MenuItem>
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle color="primary" id="alert-dialog-title">
          Relevé heure du Cleaner
        </DialogTitle>

        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '10px', width: '40%' }}
            label="Premier jour du mois"
            defaultValue={dateMois}
            onChange={OnChangePE}
            InputLabelProps={{
              shrink: true,
            }}
          />
          ⚠️ Attention a bien mettre le premier jour du mois qui vous interesse
        </DialogContent>
        <DialogActions>
          <Button
            label="resources.cleaner.actions.download"
            variant="contained"
            onClick={() => {
              if (getTemplate(dateMois) === 28) {
                getLegalDocumentClick('releveHeure28', {
                  dateMois: `${dateMois}`,
                });
              }
              if (getTemplate(dateMois) === 29) {
                getLegalDocumentClick('releveHeure29', {
                  dateMois: `${dateMois}`,
                });
              }
              if (getTemplate(dateMois) === 30) {
                getLegalDocumentClick('releveHeure30', {
                  dateMois: `${dateMois}`,
                });
              }
              if (getTemplate(dateMois) === 31) {
                getLegalDocumentClick('releveHeure31', {
                  dateMois: `${dateMois}`,
                });
              }
              invertOpenStatus();
            }}
          >
            <CloudDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

ReleveHeure.propTypes = {
  className: PropTypes.string,
  getLegalDocumentClick: PropTypes.func.isRequired,
};

ReleveHeure.defaultProps = {
  className: undefined,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLegalDocumentClick: (documentType, others) => {
      const payload = {
        id: ownProps.record.id,
        fullname: ownProps.record.fullname,
        documentType,
        ...others,
      };
      dispatch(getLegalDocument(payload));
    },
  };
};

const enhance = compose(translateComponent, connect(null, mapDispatchToProps));

export default enhance(ReleveHeure);
