import affectationsFromLeave from './affectationsFromLeave';
import cleanerTimeline from './cleanerTimeline';
import driveDocument from './getDriveDocument';
import leaveSiteCleaners from './leaveSiteCleaners';
import payrollExplanation from './payrollExplanationReducer';
import providerInvoices from './providerInvoices';

export default {
  affectationsFromLeave,
  cleanerTimeline,
  driveDocument,
  leaveSiteCleaners,
  payrollExplanation,
  providerInvoices,
};
