import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import { arrayToIdsData } from '../../../../helpers';

import ListAffectation from './ListAffectation';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      marginTop: theme.spacing(2),
    },
  };
});

const ListLeaveDetails = ({ planning, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const affectation = arrayToIdsData(planning.affectations);
  const replacement = arrayToIdsData(planning.replacements);

  return (
    <div>
      <ListAffectation
        {...props}
        ids={affectation.ids}
        data={affectation.data}
        title={translate('resources.account.card_title.leave_affectation')}
      />
      <ListAffectation
        {...props}
        classes={{ root: classes.list }}
        ids={replacement.ids}
        data={replacement.data}
        title={translate('resources.account.card_title.replacement')}
      />
    </div>
  );
};

ListLeaveDetails.propTypes = {
  planning: PropTypes.object,
  title: PropTypes.string,
};

ListLeaveDetails.defaultProps = {
  planning: {},
  title: undefined,
};

export default ListLeaveDetails;
