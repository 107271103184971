import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles, createStyles } from '@material-ui/core/styles';

import { ReplacementCompletionField } from '../../../../components';

const styles = (theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 8,
      marginBottom: 4,
    },
    titleContainer: {
      background: 'color',
      width: '60%',
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    completion: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 10,
    },
  });
};

const ContractHeader = ({
  classes,
  title,
  totalDuration,
  replacementDuration,
  subtitle,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="subtitle1" className={classes.subtitle}>
          {subtitle}
        </Typography>
      </div>
      <div className={classes.completion}>
        {!!totalDuration && (
          <ReplacementCompletionField
            current={replacementDuration}
            max={totalDuration}
          />
        )}
      </div>
    </div>
  );
};

ContractHeader.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  totalDuration: PropTypes.number,
  replacementDuration: PropTypes.number,
  subtitle: PropTypes.string,
};

ContractHeader.defaultProps = {
  classes: {},
  title: undefined,
  totalDuration: 0,
  replacementDuration: 0,
  subtitle: undefined,
};

export default withStyles(styles)(ContractHeader);
