import React from 'react';
import {
  SelectField,
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';
import Filter from './detail/Filter';
import { ProcedureReferenceField } from '../../components';

export default (props) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField source="id" />
        <ReferenceField link="show" source="cleaner_id" reference="cleaner">
          <TextField source="fullname" />
        </ReferenceField>
        <DateField source="start_date" />
        <TextField source="level" />
        <TextField source="classification" />
        <TextField source="contract_type" />
        <TextField source="contract_subtype" />
        <SelectField
          source="full_time"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <NumberField source="hourly_rate" />
        <TextField source="contract_worked_time" />
        <TextField source="duree_complement" />
        <TextField source="comment" />
        <ProcedureReferenceField
          {...props}
          reference="procedure-field"
          label="resources.cleaner_contract.fields.procedure"
        />
      </Datagrid>
    </List>
  );
};
