import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  translate as translateComponent,
} from 'react-admin';
import { ContractAutocompleteInput } from '../../../components';

const DocumentFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <ContractAutocompleteInput source="contract_id" alwaysOn allowEmpty />
    </Filter>
  );
};

DocumentFilter.propTypes = {
  translate: PropTypes.func,
};

DocumentFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(DocumentFilter);
