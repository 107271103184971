import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import compose from 'recompose/compose';
import { withStyles, createStyles } from '@material-ui/core/styles';
import { translate as translateComponent } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import ChipBooleanInput from './ChipBooleanInput';

import StartEndTimeArrayInput from './StartEndTimeArrayInput';

const styles = createStyles({
  input: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    minHeight: 40,
  },
  chip: {
    marginTop: 16,
    marginRight: 20,
    height: 48,
  },
  errorText: {
    textAlign: 'right',
  },
});

const TimedDayInput = ({
  classes,
  className: classNameProp,
  defaultDay,
  defaultTime,
  meta: { error, submitFailed },
  onChange,
  source,
  translate,
  value,
  ...props
}) => {
  const [daySelected, setSelected] = useState(defaultDay);

  const handleCheck = (checked) => {
    setSelected(checked);

    if (!checked) {
      return onChange();
    }
    return onChange(defaultTime);
  };

  const id = source ? `${source}-time` : 'time';
  const helperText = submitFailed && error ? error : '';
  const helperTextId = helperText && id ? `${id}-helper-text` : undefined;

  return (
    <FormControl margin="normal" key={source}>
      <div className={classes.input}>
        <ChipBooleanInput
          {...props}
          className={clsx(classes.chip, classNameProp)}
          defaultValue={defaultDay}
          label={translate(`format.date.days.${source}`).substring(0, 2)}
          source={source}
          onChange={handleCheck}
        />
        {daySelected && (
          <StartEndTimeArrayInput
            {...props}
            defaultValue={defaultTime}
            source={source}
            meta={{ submitFailed, error }}
            hideError
            onChange={onChange}
          />
        )}
      </div>
      {helperText && (
        <FormHelperText id={helperTextId} className={classes.errorText} error>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

TimedDayInput.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  defaultDay: PropTypes.bool,
  defaultTime: PropTypes.object,
  meta: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  translate: PropTypes.func,
  source: PropTypes.string.isRequired,
  value: PropTypes.object,
};

TimedDayInput.defaultProps = {
  classes: {},
  className: undefined,
  defaultDay: false,
  defaultTime: {},
  meta: {},
  translate: () => {},
  value: {},
};

const enhance = compose(translateComponent, withStyles(styles));

export default enhance(TimedDayInput);
