/* {{{ illustration */
import drive from '../images/illustration/drive.png';
import fountain from '../images/illustration/fountain.png';
import gocardless from '../images/illustration/gocardless.jpeg';
import payfit from '../images/illustration/payfit.png';
import pennylane from '../images/illustration/pennylane.png';
import quickbooks from '../images/illustration/quickbooks.png';
import seeuletter from '../images/illustration/seeuletter.jpg';
import spvie from '../images/illustration/spvie.jpg';
import yousign from '../images/illustration/yousign.jpeg';
// import absentism from '../images/illustration/absentism.png';
// import calendargoogle from '../images/illustration/calendar-google.png';
// import glazingnotify from '../images/illustration/glazingnotify.jpg';

export default {
  drive,
  fountain,
  gocardless,
  payfit,
  pennylane,
  quickbooks,
  seeuletter,
  spvie,
  yousign,
  //  absentism,
  //  calendargoogle,
  //  glazingnotify,
};
/* }}} */
