import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectField,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { URLParamField } from '../../components';
import Filter from './detail/Filter';

const FormationList = ({ basePath, ...props }) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      bulkActionButtons={false}
      perPage={25}
      basePath={basePath}
    >
      <CustomizableDatagrid
        defaultColumns={[
          'cleaner_id',
          'date',
          'intitule',
          'certifiante',
          'interne',
          'description',
          'validateur_id',
          'lien_detail',
        ]}
        rowClick="show"
      >
        <TextField source="id" />
        <ReferenceField source="cleaner_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <DateField source="date" />
        <TextField source="intitule" label="Intitulé" />
        <TextField source="description" />
        <SelectField
          source="certifiante"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <SelectField
          source="interne"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <ReferenceField source="validateur_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <URLParamField
          url="{{lien_detail}}"
          source="lien_detail"
          label="Détail"
          text="ℹ️"
        />
      </CustomizableDatagrid>
    </List>
  );
};

FormationList.propTypes = {
  basePath: PropTypes.string,
};

FormationList.defaultProps = {
  basePath: '',
};

export default FormationList;
