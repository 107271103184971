import React from 'react';
import PropTypes from 'prop-types';
import { DeleteButton, ShowButton } from 'react-admin';
import { ActionsContainer } from '../../../components';
import { isAffectationGenerated } from '../helper';

/* XXX: A wrapper is necessary, see ShowActions for details */
const EditActionsWrapper = (props) => {
  return <EditActions {...props} />;
};

const EditActions = ({ data, ...props }) => {
  if (!data) {
    return null;
  }
  return (
    <ActionsContainer {...props} data={data}>
      <ShowButton />
      <DeleteButton disabled={isAffectationGenerated(data)} />
    </ActionsContainer>
  );
};

EditActions.propTypes = {
  data: PropTypes.object,
};

EditActions.defaultProps = {
  data: undefined,
};

export default EditActionsWrapper;
