import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { GridCard } from '../../../../components';

import ListPay from './ListPay';
import ListRetard from './ListRetard';

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
    width: '100%',
  },
  root: {
    width: '100%',
  },
});

const PayCard = (props) => {
  const classes = useStyles();

  return (
    <GridCard {...props} classes={classes} xs={12} title="Détail Paie">
      <ListPay xs={12} />
      <ListRetard xs={12} />
    </GridCard>
  );
};

export default PayCard;
