import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'react-admin';
import { timeValidation } from '../../helpers/date';

const TimeInput = ({ source, resource, validate, ...props }) => {
  const validation = typeof validate === 'function' ? [validate] : validate;

  return (
    <TextInput
      {...props}
      options={{
        inputProps: {
          step: 300,
        },
        InputLabelProps: {
          shrink: true,
        },
      }}
      resource={resource}
      source={source}
      type="time"
      validate={[timeValidation, ...validation]}
    />
  );
};

TimeInput.propTypes = {
  source: PropTypes.string,
  resource: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

TimeInput.defaultProps = {
  source: '',
  resource: '',
  validate: [],
};

export default TimeInput;
