import React from 'react';
import { Edit } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import Form from './detail/Form';
import Aside from './detail/Aside';

const useStyles = makeStyles({
  left: {
    width: `calc(100% - 350px)`,
  },
});

const CleanerContractEdit = (props) => {
  const classes = useStyles();

  return (
    <Edit {...props} className={classes.left}>
      <Form aside={<Aside />} />
    </Edit>
  );
};

export default CleanerContractEdit;
