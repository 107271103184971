import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import {
  SimpleForm,
  ReferenceInput,
  AutocompleteInput,
  DateInput,
  SelectInput,
  TextInput,
  NumberInput,
  BooleanInput,
  FormDataConsumer,
  required,
} from 'react-admin';
import { FormSpy } from 'react-final-form';
import { useLocation, useHistory } from 'react-router-dom';
import { cleanerLevels, parseDate } from '../../../helpers';
import {
  checkClassification,
  checkMinHourlyRate,
  isInterval,
} from '../validation';

const levelChoices = Object.keys(cleanerLevels).map((level) => {
  return {
    id: level,
    name: level,
  };
});

export const getDefaultValues = (location) => {
  if (!location) {
    return {};
  }

  const values = queryString.parse(location.search.slice(1));
  values.full_time = values.full_time === 'true';

  return values;
};

const checkContractCDD = (contractType) => {
  if (contractType === 'CDD') {
    return true;
  }
  return false;
};

const checkContractSubtype = (contractSubtype) => {
  if (contractSubtype === 'complement_heure') {
    return true;
  }
  return false;
};

const checkContractCDI = (contractType) => {
  if (contractType === 'CDI') {
    return true;
  }
  return false;
};

const Form = ({ aside, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const initialValues = getDefaultValues(location);

  return (
    <SimpleForm
      {...props}
      initialValues={initialValues}
      redirect={() => {
        history.goBack();
      }}
    >
      <ReferenceInput
        filterToQuery={
          /* istanbul ignore next */
          (searchText) => {
            return { fullname: searchText };
          }
        }
        sort={{ field: 'fullname', order: 'ASC' }}
        perPage={10}
        source="cleaner_id"
        reference="cleaner"
        validate={[required()]}
      >
        <AutocompleteInput optionText="fullname" />
      </ReferenceInput>
      <DateInput
        source="start_date"
        validate={[required()]}
        parse={parseDate}
      />
      <SelectInput
        source="level"
        defaultValue="ASP"
        choices={levelChoices}
        validate={[required()]}
      />
      <SelectInput
        source="classification"
        emptyValue={null}
        allowEmpty
        defaultValue="A"
        choices={[
          {
            id: 'A',
            name: 'A',
          },
          {
            id: 'B',
            name: 'B',
          },
        ]}
        validate={[checkClassification]}
      />
      <NumberInput
        source="hourly_rate"
        defaultValue={12.13}
        step={0.01}
        validate={[required(), checkMinHourlyRate]}
      />
      <SelectInput
        source="contract_type"
        choices={[
          { id: 'CDI', name: 'CDI' },
          { id: 'CDD', name: 'CDD' },
        ]}
        defaultValue="CDI"
        validate={[required()]}
      />
      <FormDataConsumer>
        {({ formData }) => {
          return (
            checkContractCDD(formData.contract_type) && (
              <SelectInput
                source="contract_subtype"
                label="Sous-type de contrat"
                choices={[
                  { id: 'remplacement', name: 'CDD Remplacement' },
                  {
                    id: 'accroissement',
                    name: "CDD Accroissement temporaire d'activité",
                  },
                ]}
                validate={[required()]}
                defaultValue="accroissement"
              />
            )
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            checkContractCDD(formData.contract_type) && (
              <DateInput
                source="end_date"
                validate={[required()]}
                parse={parseDate}
              />
            )
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            checkContractCDI(formData.contract_type) && (
              <SelectInput
                source="contract_subtype"
                label="Sous-type de contrat"
                choices={[
                  { id: 'CDD_en_CDI', name: 'CDD en CDI' },
                  { id: 'CDI', name: 'CDI classique' },
                  { id: 'complement_heure', name: "Complément d'heure" },
                ]}
                validate={[required()]}
                defaultValue="CDI"
              />
            )
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            checkContractSubtype(formData.contract_subtype) && (
              <DateInput
                source="end_date"
                validate={[required()]}
                parse={parseDate}
              />
            )
          );
        }}
      </FormDataConsumer>
      <FormDataConsumer>
        {({ formData }) => {
          return (
            checkContractSubtype(formData.contract_subtype) && (
              <TextInput
                source="duree_complement"
                validate={[required(), isInterval]}
              />
            )
          );
        }}
      </FormDataConsumer>
      <BooleanInput
        label="Temps plein"
        source="full_time"
        defaultValue={false}
      />
      <TextInput
        source="contract_worked_time"
        validate={[required(), isInterval]}
      />
      <SelectInput
        source="comment"
        label="Raison si moins 16h"
        choices={[
          {
            id: 'Contraintes Personnelles',
            name: 'Contraintes Personnelles',
          },
          { id: 'Plusieurs Activités', name: 'Plusieurs Activités' },
          {
            id: 'Poursuivre ses études',
            name: 'Poursuivre ses études',
          },
        ]}
      />
      <TextInput multiline source="comment" resettable />
      <FormSpy subscription={{ valid: true, values: true }}>
        {({ values }) => {
          const cleanerId = values.cleaner_id ? values.cleaner_id : null;

          return React.cloneElement(aside, { cleanerId });
        }}
      </FormSpy>
    </SimpleForm>
  );
};

Form.propTypes = {
  aside: PropTypes.element.isRequired,
};

export default Form;
