import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { Pagination } from '../../../../components';

import Details from './Details';

const ListOpportunityDetail = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference="opportunity_detail"
      target="account_id"
      addLabel={false}
      sort={{ field: 'date', order: 'DESC' }}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid expand={<Details />}>
        <DateField source="date" textAlign="center" />
        <TextField source="recap" label="Récapitulatif" emptyText="∅" />
        <TextField
          source="stagename"
          label="Etat de l'opportunité"
          emptyText="∅"
        />
        <NumberField source="amount" emptyText="∅" textAlign="center" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ListOpportunityDetail;
