import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = (theme) => {
  return createStyles({
    root: {
      backgroundColor: theme.palette.background.default,
    },
    point: {
      marginTop: 8,
      marginBottom: 8,
      height: 9,
      width: 9,
      backgroundColor: theme.palette.tertiary.main,
      borderRadius: '50%',
    },
  });
};

const Point = ({ classes }) => {
  return (
    <div className={classes.root}>
      <div className={classes.point}></div>
    </div>
  );
};

Point.propTypes = {
  classes: PropTypes.object,
};

Point.defaultProps = {
  classes: {},
};

export default withStyles(styles)(Point);
