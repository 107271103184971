import React from 'react';
import PropTypes from 'prop-types';

import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CancelIcon from '@material-ui/icons/Cancel';

import IconedTextField from './IconedTextField';

const StatusTypeIcon = ({ record, ...props }) => {
  if (!record || !record.status_covid) {
    return null;
  }

  switch (record.status_covid) {
    case 'Passif':
      return <CancelIcon style={{ color: '#FF0000' }} {...props} />;
    case 'Actif':
      return <VerifiedUserIcon style={{ color: '#008000' }} {...props} />;
    default:
      return null;
  }
};

StatusTypeIcon.propTypes = {
  record: PropTypes.object,
};

StatusTypeIcon.defaultProps = {
  record: {},
};

const AffectationStatusField = ({ record, source, ...props }) => {
  return (
    <IconedTextField
      {...props}
      record={record}
      icon={<StatusTypeIcon record={record} />}
    />
  );
};

AffectationStatusField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string,
  label: PropTypes.string,
};

AffectationStatusField.defaultProps = {
  record: {},
  source: 'type',
  label: 'Statut Covid',
};

export default AffectationStatusField;
