import React from 'react';
import { Grid } from '@material-ui/core';

import InvoiceCard from './InvoiceCard';
import InvoiceIncubatorCard from './InvoiceIncubatorCard';
import InvoiceResumeCard from './InvoiceResumeCard';
import OpportunityDetailCard from './OpportunityDetailCard';

const TabInvoice = (props) => {
  return (
    <Grid container spacing={2}>
      <InvoiceResumeCard {...props} />
      <InvoiceCard {...props} />
      <InvoiceIncubatorCard {...props} />
      <OpportunityDetailCard {...props} />
    </Grid>
  );
};

export default TabInvoice;
