import React from 'react';
import PropTypes from 'prop-types';
import {
  BooleanField,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import {
  GridSimpleShowLayout,
  ReplacementCompletionField,
  TranslatedField,
  TwoLinesField,
} from '../../../../components';

const useStyles = makeStyles({
  item: {
    alignSelf: 'center',
  },
  content: {
    padding: '0 !important',
  },
});

const ListLeaveItem = ({ planning, ...props }) => {
  const classes = useStyles();

  const { totalDuration, replacementDuration } = planning;

  return (
    <GridSimpleShowLayout
      {...props}
      classes={{ item: classes.item, content: classes.content }}
      spacing={2}
    >
      <TwoLinesField
        sm={4}
        primary={
          <ReferenceField
            {...props}
            source="cleaner_id"
            reference="cleaner"
            link="show"
            addLabel={false}
            variant="body1"
          >
            <TextField source="fullname" />
          </ReferenceField>
        }
        secondary={
          <TranslatedField
            translationPath="components.field.absence_type"
            source="absence_type"
          />
        }
      />
      <DateField source="start_date" />
      <DateField source="end_date" />
      <BooleanField sm={1} source="processed" />
      {!!totalDuration && (
        <ReplacementCompletionField
          sm={3}
          current={replacementDuration}
          max={totalDuration}
        />
      )}
    </GridSimpleShowLayout>
  );
};

ListLeaveItem.propTypes = {
  planning: PropTypes.object,
};

ListLeaveItem.defaultProps = {
  planning: {},
};

export default ListLeaveItem;
