import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectField,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TranslatedField } from '../../components';
import Filter from './detail/Filter';
import ListActions from './detail/ListActions';

const LeaveList = ({ basePath, ...props }) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      filterDefaultValues={{ processed: false }}
      bulkActionButtons={false}
      perPage={25}
      actions={<ListActions basePath={basePath} />}
      basePath={basePath}
    >
      <CustomizableDatagrid
        defaultColumns={[
          'cleaner_id',
          'contract_id',
          'start_date',
          'end_date',
          'absence_type',
          'processed',
        ]}
        rowClick="show"
      >
        <TextField source="id" />
        <ReferenceField source="cleaner_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField
          allowEmpty
          source="contract_id"
          reference="contract"
          link={false}
        >
          <TextField source="account_name" />
        </ReferenceField>
        <DateField source="start_date" />
        <DateField source="end_date" />
        <TranslatedField
          translationPath="components.field.absence_type"
          source="absence_type"
        />
        <TextField source="comment" />
        <SelectField source="processed" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      </CustomizableDatagrid>
    </List>
  );
};

LeaveList.propTypes = {
  basePath: PropTypes.string,
};

LeaveList.defaultProps = {
  basePath: '',
};

export default LeaveList;
