import React from 'react';
import PropTypes from 'prop-types';
import { CleanerMenuTakenOver } from '../../../../components';
import TakenOver from './TakenOver';
import RenouvellementPE from './renouvellementPE';
import TakenOverCleanerLetter from './TakenOverCleanerLetter';

const ActionsMenu = ({ className, record }) => {
  return (
    <CleanerMenuTakenOver
      className={className}
      label="Annexe 7"
      record={record}
    >
      <TakenOver />
      <TakenOverCleanerLetter />
      <RenouvellementPE />
    </CleanerMenuTakenOver>
  );
};

ActionsMenu.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

ActionsMenu.defaultProps = {
  className: undefined,
  record: {},
};

export default ActionsMenu;
