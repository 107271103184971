import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectField,
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
} from 'react-admin';
import { TranslatedField } from '../../../../../components';

const LeaveList = ({ active, filter, sort, ...props }) => {
  return (
    <ReferenceManyField
      {...props}
      reference="cleaner_leave"
      target="cleaner_id"
      addLabel={false}
      filter={{
        ...filter,
        active,
      }}
      sort={sort}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid
        {...props}
        rowClick={(id) => {
          return `/leave/${id}/show`;
        }}
      >
        <TranslatedField
          translationPath="components.field.absence_type"
          source="absence_type"
        />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <SelectField source="processed" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      </Datagrid>
    </ReferenceManyField>
  );
};

LeaveList.propTypes = {
  active: PropTypes.bool,
  filter: PropTypes.object,
  sort: PropTypes.object,
};

LeaveList.defaultProps = {
  active: false,
  filter: {},
  sort: { field: 'start_date', order: 'DESC' },
};

export default LeaveList;
