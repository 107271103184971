import React from 'react';
import { Datagrid, DateField, List, TextField, ReferenceField, useTranslate } from 'react-admin';
import { LetterStatusField, URLParamField } from '../../components';
import Expand from './detail/Expand';
import Filter from './detail/Filter';

export default (props) => {

const translate = useTranslate();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'created_date', order: 'DESC' }}
      filters={<Filter />}
    >
      <Datagrid expand={<Expand />}>
        <ReferenceField source="cleaner_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <TextField source="type" />
        <TextField source="description" />
        <DateField source="sent_date" />
        <DateField source="first_distributed_date" />
        <LetterStatusField source="status" />
        <URLParamField
          url="https://www.seeuletter.com/app/dashboard/letters/{{external_id}}"
          source="external_id"
          label={translate('resources.logs.fields.tracking_link')}
          text="Suivi Seeuletter"
          variant="body2"
        />
        <URLParamField
          url="https://www.laposte.fr/outils/suivre-vos-envois?code={{tracking_number}}"
          source="tracking_number"
          label={translate('resources.logs.fields.tracking_link')}
          text="Suivi La Poste"
          variant="body2"
        />
        <ReferenceField
          allowEmpty
          source="owner_id"
          reference="cleaner"
          link="show"
        >
          <TextField source="fullname" />
        </ReferenceField>
      </Datagrid>
    </List>
  );
};
