import React, { cloneElement, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Datagrid, DateField, ReferenceField, TextField } from 'react-admin';
import { CircularProgress, Typography, makeStyles } from '@material-ui/core';
import { Card, CardHeader } from '@material-ui/core';

const useStyles = makeStyles({
  circularProgress: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 40,
    marginBottom: 40,
  },
});

const UploadDialogContent = ({
  accountsInvoices,
  errors,
  loading,
  form,
  onClose,
}) => {
  const classes = useStyles();

  useEffect(() => {
    return () => {
      onClose();
    };
  }, [onClose]);

  if (loading) {
    return (
      <div className={classes.circularProgress}>
        <CircularProgress />
      </div>
    );
  }
  if (errors.length) {
    return errors.map((error, index) => {
      /* eslint-disable-next-line react/no-array-index-key */
      return <Typography key={index}>{error}</Typography>;
    });
  }
  if (!accountsInvoices || !Object.keys(accountsInvoices).length) {
    return cloneElement(form);
  }
  return (
    <Card>
      <CardHeader
        title={`${
          Object.keys(accountsInvoices).length
        } factures ont été importées : `}
      />
      <Datagrid
        data={accountsInvoices}
        ids={Object.keys(accountsInvoices)}
        currentSort={{ field: 'id', order: 'DESC' }}
      >
        <ReferenceField
          allowEmpty
          basePath="provider_invoice"
          link={false}
          source="contract_id"
          reference="contract"
        >
          <TextField source="account_name" />
        </ReferenceField>
        <TextField source="external_id" />
        <TextField source="amount" />
        <TextField source="invoice_number" />
        <DateField source="date" />
      </Datagrid>
    </Card>
  );
};

UploadDialogContent.propTypes = {
  accountsInvoices: PropTypes.object,
  errors: PropTypes.array,
  loading: PropTypes.number,
  form: PropTypes.element,
  onClose: PropTypes.func,
};

UploadDialogContent.defaultProps = {
  accountsInvoices: {},
  errors: [],
  loading: 0,
  form: null,
  onClose: () => {},
};

export default UploadDialogContent;
