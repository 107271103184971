import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectField,
  DateField,
  ReferenceField,
  TextField,
  translate as translateComponent,
} from 'react-admin';

import {
  GridCard,
  ShowGrid,
  TimeField,
  TranslatedField,
} from '../../components';

import ShowActions from './detail/ShowActions';

const Show = ({ translate, ...props }) => {
  return (
    <ShowGrid
      {...props}
      actions={<ShowActions />}
      titleSource={['account_name', 'cleaner_fullname']}
    >
      <GridCard
        title={translate('resources.affectation.card_title.info')}
        xs={12}
      >
        <ReferenceField source="cleaner_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField
          source="account_id"
          reference="account"
          link="show"
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <DateField source="start_date" />
        <DateField source="end_date" />
      </GridCard>
      <GridCard
        title={translate('resources.affectation.card_title.schedule')}
        xs={12}
      >
        <TimeField source="start_time" />
        <TimeField source="end_time" />
        <TimeField source="duration" />
        <SelectField source="monday" label="Lundi"  choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="tuesday" label="Mardi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="wednesday" label="Mercredi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="thursday" label="Jeudi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="friday" label="Vendredi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="saturday" label="Samedi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="sunday" label="Dimanche" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <TimeField source="duration_per_week" />
      </GridCard>
      <GridCard title={translate('resources.affectation.card_title.site')}>
        <TextField source="site_address" />
        <TextField source="transit_stop" />
        <TextField source="manager_fullname" />
        <TextField source="operational_fullname" />
      </GridCard>
      <GridCard title={translate('resources.affectation.card_title.other')}>
        <TranslatedField
          translationPath="components.field.prestation_type"
          source="prestation_type"
        />
        <ReferenceField
          source="catalog_item_id"
          reference="item"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="punctual_reason" />
        <TranslatedField
          translationPath="components.field.absence_type"
          source="absence_type"
        />
        <TextField source="status" />
        <SelectField source="active" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      </GridCard>
    </ShowGrid>
  );
};

Show.propTypes = {
  translate: PropTypes.func,
};

Show.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(Show);
