import moment from 'moment';
import { SET_PAYROLL_EXPLANATION, CLEAR_PAYROLL_EXPLANATION } from '../action';

const initialState = {
  date: moment().subtract(1, 'month'),
};

export default function(state = initialState, action = {}) {
  switch (action.type) {
    case SET_PAYROLL_EXPLANATION:
      return {
        ...action.explanation,
        date: action.date,
      };
    case CLEAR_PAYROLL_EXPLANATION:
      return {
        date: moment().subtract(1, 'month'),
      };
    default:
      return state;
  }
}
