import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import clsx from 'clsx';
import { withStyles, createStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => {
  return createStyles({
    root: {
      borderRadius: '50%',
      flexGrow: 0,
      width: 'auto',
      marginRight: 10,
    },
    checkbox: {
      width: 35,
      height: 35,
      border: `2px solid ${theme.palette.secondary.main}`,
      backgroundSize: '200% 100%',
      backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main} 50%, white 50%)`,
      backgroundPosition: '100% 0%',
      transition: 'background-position 0.5s',
      borderRadius: '50%',
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'visible',
      '&$checked': {
        backgroundPosition: '0% 0%',
      },
    },
    checked: {},
    text: {
      color: theme.palette.secondary.main,
      '&$checked': {
        color: theme.palette.white,
      },
    },
    /* XXX: hide basic html input */
    input: {
      cursor: 'inherit',
      position: 'absolute',
      opacity: 0,
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
      margin: 0,
      padding: 0,
    },
  });
};

const ChipBooleanInput = ({
  className: classNameProp,
  classes,
  defaultValue,
  disabled,
  onChange,
  label,
  record,
  source,
}) => {
  const [checked, setChecked] = useState(get(record, source) || defaultValue);

  const handleInputChange = (event) => {
    setChecked(event.target.checked);
    onChange(event.target.checked);
  };

  return (
    <ButtonBase
      className={clsx(classes.root, classNameProp)}
      disabled={disabled}
    >
      <div
        className={clsx(classes.checkbox, {
          [classes.checked]: checked,
          [classes.disabled]: disabled,
        })}
      >
        <Typography
          className={clsx(classes.text, {
            [classes.checked]: checked,
          })}
          component="span"
          variant="body1"
        >
          {label}
        </Typography>
        <input
          type="checkbox"
          className={classes.input}
          checked={checked}
          disabled={disabled}
          onChange={handleInputChange}
        />
      </div>
    </ButtonBase>
  );
};

ChipBooleanInput.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  defaultValue: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onChange: PropTypes.func,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

ChipBooleanInput.defaultProps = {
  className: undefined,
  classes: {},
  defaultValue: false,
  disabled: false,
  label: undefined,
  onChange: () => {},
  record: {},
};

export default withStyles(styles)(ChipBooleanInput);
