import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

const PayrollListField = ({ intro, list }) => {
  const [open, setOpen] = React.useState(false);
  const listEnd = React.useRef(null);

  const handleClick = () => {
    setOpen(!open);
    listEnd.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  if (!list.length) {
    return null;
  }

  const renderedList = list.map((text) => {
    return (
      <ListItem
        key={text}
        style={{ paddingTop: 0, paddingBottom: 0, marginLeft: 20 }}
      >
        <ListItemText primary={text} />
      </ListItem>
    );
  });

  return (
    <Fragment>
      <ListItem button onClick={handleClick}>
        <ListItemText primary={intro} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List disablePadding>{renderedList}</List>
      </Collapse>
      <div ref={listEnd} />
    </Fragment>
  );
};

PayrollListField.propTypes = {
  intro: PropTypes.string,
  list: PropTypes.array,
};

PayrollListField.defaultProps = {
  intro: '',
  list: [],
};

export default PayrollListField;
