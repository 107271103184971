import React from 'react';
import PropTypes from 'prop-types';
import { Form, Field } from 'react-final-form';
import {
  useTranslate,
  TextInput,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { uploadClientDocument } from '../../../action';
import {
  SubtypeClientSelectInput,
  RenderImageInput,
  UploadDialog,
  ConditionalInput,
} from '../../../components';
import { getUser } from '../../../user';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['subtype', 'data'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

const UploadForm = ({ additionalActionOnSubmit, record }) => {
  const dispatch = useDispatch();
  const onSubmit = (values) => {
    dispatch(uploadClientDocument({ ...values, accountId: record.id }));
    additionalActionOnSubmit();
  };

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form }) => {
        return (
          <form id="documentUploadForm" onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <Field name="subtype" component={SubtypeClientSelectInput} />
              <ConditionalInput when="subtype" is="provider_contract">
                <ReferenceInput
                  filterToQuery={(searchText) => {
                    return { name: searchText };
                  }}
                  source="provider_id"
                  reference="account"
                  filter={{ type: 'Partenaire' }}
                  sort={{ field: 'name', order: 'ASC' }}
                  label="Fournisseur"
                >
                  <AutocompleteInput
                    source="name"
                    name="provider_id"
                    options={{
                      suggestionsContainerProps: {
                        /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                        style: { zIndex: 2000 },
                      },
                    }}
                  />
                </ReferenceInput>
              </ConditionalInput>
              <TextInput label="Commentaire" name="comment" />
              <DateInput
                label="Date du document"
                name="document_date"
                initialValue={moment().format('YYYY-MM-DD')}
              />
              <TextInput
                disabled
                label="Téléverseur"
                name="televerseur_id"
                source="televerseur_id"
                initialValue={getUser().id}
              />
              <Field name="data" source="data" component={RenderImageInput} />
            </div>
          </form>
        );
      }}
    />
  );
};

UploadForm.propTypes = {
  additionalActionOnSubmit: PropTypes.func,
  record: PropTypes.object,
};

UploadForm.defaultProps = {
  additionalActionOnSubmit: () => {},
  record: {},
};

const submitForm = () => {
  document
    .getElementById('documentUploadForm')
    .dispatchEvent(new Event('submit', { cancelable: true }));
};

const UploadDocumentDialog = ({ className, record }) => {
  const translate = useTranslate();

  return (
    <UploadDialog
      className={className}
      record={record}
      form={<UploadForm />}
      submitForm={submitForm}
      openButtonText={translate('resources.client_document.create')}
      title={translate('resources.client_document.dialog.title')}
      uploadButtonText={translate('resources.client_document.dialog.button')}
    />
  );
};

UploadDocumentDialog.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

UploadDocumentDialog.defaultProps = {
  className: undefined,
  record: {},
};

export default UploadDocumentDialog;
