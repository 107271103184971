import React from 'react';
import PropTypes from 'prop-types';
import {
  ImageInput,
  ImageField,
  translate as translateComponent,
} from 'react-admin';

const renderImageInput = ({ source, translate }) => {
  return (
    <ImageInput
      label=""
      source={source}
      accept="image/*,application/pdf"
      placeholder={
        <p>{translate('components.form.image_input.placeholder')}</p>
      }
    >
      <ImageField source={source} />
    </ImageInput>
  );
};

renderImageInput.propTypes = {
  source: PropTypes.string,
  translate: PropTypes.func,
};

renderImageInput.defaultProps = {
  source: '',
  translate: /* istanbul ingore next */ () => {},
};

export default translateComponent(renderImageInput);
