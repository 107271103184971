import React from 'react';
import PropTypes from 'prop-types';
import { translate as translateComponent } from 'react-admin';
import ChipBooleanInput from './ChipBooleanInput';
import { days } from '../../helpers';

const DaysInput = ({
  className: classNameProp,
  defaultDays,
  handleChange,
  translate,
  ...props
}) => {
  return days.map((day) => {
    return (
      <ChipBooleanInput
        {...props}
        className={classNameProp}
        key={day}
        label={translate(`format.date.days.${day}`).substring(0, 2)}
        defaultValue={defaultDays[day]}
        source={day}
        onChange={handleChange(day)}
      />
    );
  });
};

DaysInput.propTypes = {
  className: PropTypes.string,
  defaultDays: PropTypes.object,
  handleChange: PropTypes.func,
  translate: PropTypes.func,
};

DaysInput.defaultProps = {
  className: undefined,
  defaultDays: {},
  handleChange: () => {},
  translate: () => {},
};

export default translateComponent(DaysInput);
