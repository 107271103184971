import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { connect } from 'react-redux';

import Magnifier from 'react-magnifier';
import { Document, Page } from 'react-pdf';

import { LoadingImageField } from '../../components';

import { clearDriveDocument, getDriveDocument } from '../../action';

const DriveImageField = ({
  clearDocument,
  getDocument,
  image,
  record,
  source,
}) => {
  const documentId = get(record, source);

  useEffect(() => {
    getDocument(documentId);
    return () => {
      clearDocument();
    };
  }, [/* clearDocument */ documentId /* getDocument */]);

  if (image && image && image.file.charAt(0) === 'J') {
    return (
      <Document width={400} file={`data:base64,${image.file}`}>
        <Page pageNumber={1} />
      </Document>
    );
  }

  return image && image.file ? (
    <Magnifier
      src={`data:image/jpeg;base64,${image.file}`}
      width={400}
      mgWidth={300}
      mgHeight={300}
      zoomFactor={1}
    />
  ) : (
    <LoadingImageField />
  );
};

DriveImageField.propTypes = {
  clearDocument: PropTypes.func,
  getDocument: PropTypes.func,
  image: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

DriveImageField.defaultProps = {
  clearDocument: () => {},
  getDocument: () => {},
  image: undefined,
  record: {},
};

const mapStateToProps = (state) => {
  return {
    image: state.driveDocument,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    clearDocument: () => {
      dispatch(clearDriveDocument());
    },
    getDocument: () => {
      dispatch(getDriveDocument({ documentId: ownProps.record.drive_id }));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DriveImageField);
