import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  SelectInput,
  translate as translateComponent,
} from 'react-admin';
import { AccountAutocompleteInput } from '../../../components';

const DocumentFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <AccountAutocompleteInput source="account_id" alwaysOn allowEmpty />
      <SelectInput
        alwaysOn
        allowEmpty
        emptyText="ra.action.clear_input_value"
        source="type"
        choices={[
          {
            id: 'contract',
            name: 'resources.client_document.type.contract',
          },
          {
            id: 'contract_end',
            name: 'resources.client_document.type.contract_end',
          },
          {
            id: 'provider',
            name: 'resources.client_document.type.provider',
          },
          {
            id: 'keys',
            name: "Attestation de remise d'accès",
          },
          {
            id: 'purchase_order',
            name: 'resources.client_document.subtype.purchase_order',
          },
        ]}
      />
      <SelectInput
        source="subtype"
        alwaysOn
        allowEmpty
        emptyText="ra.action.clear_input_value"
        choices={[
          {
            id: 'other',
            name: 'resources.client_document.subtype.other',
          },
          {
            id: 'endorsement',
            name: 'resources.client_document.subtype.endorsement',
          },
          {
            id: 'provider_contract',
            name: 'resources.client_document.subtype.provider_contract',
          },
          {
            id: 'ponctual_contract',
            name: 'resources.client_document.subtype.ponctual_contract',
          },
          {
            id: 'recurrent_contract',
            name: 'resources.client_document.subtype.recurrent_contract',
          },
          {
            id: 'complaint_letter',
            name: 'resources.client_document.subtype.complaint_letter',
          },
          {
            id: 'suspension_request',
            name: 'resources.client_document.subtype.suspension_request',
          },
          {
            id: 'resignation_letter',
            name: 'resources.client_document.subtype.resignation_letter',
          },
          {
            id: 'plan_prevention',
            name: 'resources.client_document.subtype.plan_prevention',
          },
          {
            id: 'keys',
            name: "Attestation de remise d'accès",
          },
          {
            id: 'purchase_order',
            name: 'resources.client_document.subtype.purchase_order',
          },
        ]}
      />
    </Filter>
  );
};

DocumentFilter.propTypes = {
  translate: PropTypes.func,
};

DocumentFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(DocumentFilter);
