import React from 'react';
import PropTypes from 'prop-types';
import {
  DateField,
  NumberField,
  TextField,
  ReferenceManyField,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import {
  GridSimpleShowLayout,
  Pagination,
  NestedList,
  CreateButtonPonctuel,
} from '../../../../components';

import SpecificationDialog from '../SpecificationDialog';
import PonctualDetails from './PonctualDetails';

const useStyles = makeStyles({
  item: {
    alignSelf: 'center',
  },
  content: {
    padding: '0 !important',
  },
});

const PonctualListController = ({ active, sort, ...props }) => {
  const classes = useStyles();
  return (
    <ReferenceManyField
      {...props}
      reference="contract"
      target="account_id"
      addLabel={false}
      filter={{ type: 'Ponctuel', active }}
      sort={sort}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <NestedList
        action={<SpecificationDialog icon />}
        collapse={<PonctualDetails />}
      >
        <GridSimpleShowLayout
          {...props}
          classes={{ item: classes.item, content: classes.content }}
        >
          <TextField
            addLabel={false}
            source="opportunity_name"
            xs={4}
            variant="body1"
          />
          <DateField source="effective_date" />
          <DateField source="end_date" />
          <NumberField
            source="total_amount"
            options={{ style: 'currency', currency: 'EUR' }}
            variant="h6"
          />
          <CreateButtonPonctuel
            {...props}
            pathname="/affectation/create"
            initialValues={{
              catalog_item_id: '01t0Y000001C4C7QAK',
            }}
          />
        </GridSimpleShowLayout>
      </NestedList>
    </ReferenceManyField>
  );
};

PonctualListController.propTypes = {
  active: PropTypes.bool,
  sort: PropTypes.object,
};

PonctualListController.defaultProps = {
  active: true,
  sort: { field: 'start_date', order: 'ASC' },
};

export default PonctualListController;
