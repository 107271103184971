import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Edit, TextInput, SimpleForm, SelectInput } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { URLParamField } from '../../components';
import { Toolbar } from '../../components';
import { DriveImageField } from '../../containers';

import { getUser } from '../../user';
import StatusSelectInput from './detail/StatusSelectInput';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

export const DocumentForm = ({ onCancel, record, ...props }) => {
  const defaultValues = {
    validation_date: moment().format('YYYY-MM-DD'),
    validator_id: getUser().id,
  };

  return (
    <Fragment>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm
        {...sanitizeRestProps(props)}
        toolbar={<Toolbar isDeletable={false} />}
        record={{ ...record, ...defaultValues }}
      >
        <DriveImageField source="drive_id" />
        <StatusSelectInput source="status" />
        <SelectInput
          source="type"
          choices={[
            { id: 'advance_payment', name: '💸 Accompte' },
            { id: 'bank', name: '🏦 Banque' },
            { id: 'contract', name: '📋 Contrat' },
            { id: 'health_care', name: '💊 Santé' },
            { id: 'occupational_medecine', name: '🧑‍⚕️ Médecine du travail' },
            { id: 'home', name: '🏠 Domicile' },
            { id: 'identity', name: '🆔 Identité' },
            { id: 'key_retrieval', name: '🔑 Clé' },
            { id: 'leave', name: "👻 Justificatif d'absence" },
            { id: 'picture', name: '🖼️ Photo' },
            { id: 'lrar', name: '🔅 Recommandé' },
          ]}
        />
        <TextInput source="comment" />
        <URLParamField
          url="https://drive.google.com/uc?export=download&id={{drive_id}}"
          source="drive_id"
          label="Télécharger"
          textAlign="center"
          text="📩"
          variant="body2"
          emptyText="∅"
        />
      </SimpleForm>
    </Fragment>
  );
};

DocumentForm.propTypes = {
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

DocumentForm.defaultProps = {
  onCancel: /* istanbul ignore next */ () => {},
  record: {},
};

export default (props) => {
  return (
    <Edit {...props}>
      <DocumentForm {...props} />
    </Edit>
  );
};
