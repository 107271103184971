import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { NumberInput, required } from 'react-admin';
import { RenderFileInput } from '../../../../components';
import { uploadProviderInvoice } from '../../../../action';
import ProviderAutocompleteInput from '../ProviderAutocompleteInput';

const validate = (values) => {
  const errors = {};
  const requiredFields = ['providerAccountId', 'data'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });
  return errors;
};

const UploadForm = () => {
  const dispatch = useDispatch();
  const onSubmit = useCallback(
    (values) => {
      dispatch(uploadProviderInvoice(values));
    },
    [dispatch],
  );

  return (
    <Form
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => {
        return (
          <form id="invoiceProviderUpload" onSubmit={handleSubmit}>
            <ProviderAutocompleteInput
              source="providerAccountId"
              validate={required()}
            />
            <NumberInput source="amortization" step={1} />
            <Field
              name="data"
              source="data"
              isMultiple
              component={RenderFileInput}
            />
          </form>
        );
      }}
    />
  );
};

export default UploadForm;
