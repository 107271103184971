import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const LeaveSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        {
          id: 'UNAUTHORIZED_LEAVE_UNPAID',
          name: 'components.field.absence_type.UNAUTHORIZED_LEAVE_UNPAID',
        },
        {
          id: 'LEAVE_ABSENCE_PAID',
          name: 'components.field.absence_type.LEAVE_ABSENCE_PAID',
        },
        {
          id: 'UNAUTHORIZED_LEAVE',
          name: 'components.field.absence_type.UNAUTHORIZED_LEAVE',
        },
        {
          id: 'COMMUTE_INJURY',
          name: 'components.field.absence_type.COMMUTE_INJURY',
        },
        {
          id: 'WORK_INJURY',
          name: 'components.field.absence_type.WORK_INJURY',
        },
        {
          id: 'TRAINING_LEAVE',
          name: 'components.field.absence_type.TRAINING_LEAVE',
        },
        {
          id: 'PARENTAL_LEAVE',
          name: 'components.field.absence_type.PARENTAL_LEAVE',
        },
        {
          id: 'PATERNITY_LEAVE',
          name: 'components.field.absence_type.PATERNITY_LEAVE',
        },
        {
          id: 'BIRTH_LEAVE',
          name: 'components.field.absence_type.BIRTH_LEAVE',
        },
        {
          id: 'FAMILY_LEAVE',
          name: 'components.field.absence_type.FAMILY_LEAVE',
        },
        {
          id: 'UNPAYED_LEAVE',
          name: 'components.field.absence_type.UNPAYED_LEAVE',
        },

        {
          id: 'PAYED_LEAVE',
          name: 'components.field.absence_type.PAYED_LEAVE',
        },
        {
          id: 'SICK_CHILD',
          name: 'components.field.absence_type.SICK_CHILD',
        },
        {
          id: 'CLOSED_SITE',
          name: 'components.field.absence_type.CLOSED_SITE',
        },
        {
          id: 'ILLNESS',
          name: 'components.field.absence_type.ILLNESS',
        },
        {
          id: 'WORK_ILLNESS',
          name: 'components.field.absence_type.WORK_ILLNESS',
        },
        {
          id: 'MATERNITY_LEAVE',
          name: 'components.field.absence_type.MATERNITY_LEAVE',
        },
        {
          id: 'CONSERVATORY_LAID_OFF',
          name: 'components.field.absence_type.CONSERVATORY_LAID_OFF',
        },
        {
          id: 'DISCIPLINARY_LAID_OFF',
          name: 'components.field.absence_type.DISCIPLINARY_LAID_OFF',
        },
        {
          id: 'SABBATICAL_LEAVE',
          name: 'components.field.absence_type.SABBATICAL_LEAVE',
        },
      ]}
    />
  );
};
LeaveSelectInput.propTypes = {
  source: PropTypes.string,
  validate: PropTypes.array,
};

LeaveSelectInput.defaultProps = {
  source: 'absence_type',
  validate: [],
};

export default LeaveSelectInput;
