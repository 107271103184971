import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  image: {
    width: 300,
  },
});

const Base64ImageField = ({ classes, record, source }) => {
  const image = get(record, source);

  return image ? (
    <img
      className={classes.image}
      alt={source}
      src={`data:image/jpeg;base64,${image}`}
    />
  ) : null;
};

Base64ImageField.propTypes = {
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  classes: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

Base64ImageField.defaultProps = {
  addLabel: true,
  classes: {},
  record: {},
};

export default withStyles(styles)(Base64ImageField);
