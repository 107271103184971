import React from 'react';
import { Admin, Layout, Resource, resolveBrowserLocale } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import dataProvider from './dataProvider';

import { AccountList, AccountShow, AccountEdit } from './resources/Account';
// import ActionList from './resources/Action/List';
import {
  AffectationCreate,
  AffectationEdit,
  AffectationList,
  AffectationShow,
} from './resources/Affectation';
import {
  CleanerCreate,
  CleanerEdit,
  CleanerList,
  CleanerShow,
} from './resources/Cleaner';
import ClientList from './resources/Client/List';
import DocumentList from './resources/Document/List';
import DocumentClientList from './resources/DocumentClient/List';
import LetterList from './resources/Letter/List';
import FollowupList from './resources/ClientFollowup/List';
import OpportunityDetailList from './resources/OpportunityDetail/List';
import InvoiceList from './resources/Invoice/List';
import {
  LeaveCreate,
  LeaveEdit,
  LeaveList,
  LeaveShow,
} from './resources/Leave';
import {
  FormationCreate,
  FormationEdit,
  FormationList,
  FormationShow,
} from './resources/Formation';
import ProviderList from './resources/Provider/List';
import ProviderItemList from './resources/ProviderItem/List';
import ProviderInvoiceList from './resources/ProviderInvoice/List';
import {
  CleanerContractCreate,
  CleanerContractEdit,
  CleanerContractList,
} from './resources/CleanerContract';
import { ReplacementCreate } from './resources/Replacement';
import ServiceCertificateList from './resources/serviceCertificate/List';
import {
  PreaffectationList,
  PreaffectationShow,
} from './resources/preaffectation';
import SchedulerList from './resources/Scheduler/List';
import SchedulerShow from './resources/Scheduler/Show';
import SchedulerEdit from './resources/Scheduler/Edit';

// import DeviceList from './resources/Device/List';
// import DeviceSetupList from './resources/DeviceSetup/List';

import InvoiceIncubatorList from './resources/InvoiceIncubator/List';

import reducers from './reducer';
import sagas from './saga';
import theme from './theme';

import MyLoginPage from './login';
import authProvider from './authProvider';
import { Menu } from './containers';
import messages from './i18n';

const CustomLayout = (props) => {
  return <Layout {...props} menu={Menu} />;
};

const i18nProvider = polyglotI18nProvider((locale) => {
  return messages[locale];
}, resolveBrowserLocale());

const App = () => {
  return (
    <Admin
      customReducers={reducers}
      customSagas={sagas}
      layout={CustomLayout}
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={MyLoginPage}
      i18nProvider={i18nProvider}
      theme={theme}
    >
      <Resource
        name="account"
        list={AccountList}
        show={AccountShow}
        edit={AccountEdit}
      />
      <Resource
        name="affectation"
        create={AffectationCreate}
        edit={AffectationEdit}
        list={AffectationList}
        show={AffectationShow}
      />
      <Resource
        name="cleaner"
        create={CleanerCreate}
        edit={CleanerEdit}
        list={CleanerList}
        show={CleanerShow}
      />
      <Resource
        name="cleaner_contract"
        create={CleanerContractCreate}
        edit={CleanerContractEdit}
        list={CleanerContractList}
      />
      <Resource name="cleaner_document" list={DocumentList} />
      <Resource name="client_document" list={DocumentClientList} />
      <Resource name="service_certificate" list={ServiceCertificateList} />
      <Resource
        name="preaffectation"
        list={PreaffectationList}
        show={PreaffectationShow}
      />
      <Resource name="cleaner_letter" list={LetterList} />
      <Resource name="client" list={ClientList} />
      <Resource name="invoice" list={InvoiceList} />
      <Resource
        name="leave"
        edit={LeaveEdit}
        create={LeaveCreate}
        list={LeaveList}
        show={LeaveShow}
      />
      <Resource
        name="formation"
        edit={FormationEdit}
        create={FormationCreate}
        list={FormationList}
        show={FormationShow}
      />
      <Resource name="invoice_incubator" list={InvoiceIncubatorList} />
      <Resource name="opportunity_detail" list={OpportunityDetailList} />
      <Resource name="provider" list={ProviderList} />
      <Resource name="provider_item" list={ProviderItemList} />
      <Resource name="provider_invoice" list={ProviderInvoiceList} />
      <Resource name="replacement" create={ReplacementCreate} />
      <Resource name="contract" />
      <Resource name="tag" />
      <Resource name="leave_info" />
      <Resource
        name="scheduler"
        list={SchedulerList}
        show={SchedulerShow}
        edit={SchedulerEdit}
      />
      <Resource name="account_leave" />
      <Resource name="account_space" />
      <Resource name="cleaner_leave" />
      <Resource name="gocardless_payment" />
      <Resource name="item" />
      <Resource name="client_followup" list={FollowupList} />
      <Resource name="job_status" />
      <Resource name="timecard_month" />
      <Resource name="timecard_cleaner_client" />
      <Resource name="materiel_produit_site_vue" />
      <Resource name="retard" />
      <Resource name="entretien_professionnel" />
      <Resource name="prestation" />
      <Resource name="product" />
      <Resource name="yousign_procedure" />
    </Admin>
  );
};

export default App;
