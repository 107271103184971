import moment from 'moment';

export default (record) => {
  if (!record.contract_start_date) {
    return undefined;
  }
  return moment(record.contract_start_date)
    .add(1, 'month')
    .locale('fr')
    .format('DD/MM/YYYY');
};
