/* eslint-disable-next-line import/prefer-default-export */
export const arrayToIdsData = (data) => {
  return data.reduce(
    (acc, val) => {
      acc.ids.push(val.id);
      acc.data[val.id] = val;

      return acc;
    },
    { ids: [], data: {} },
  );
};
