import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getUser } from '../../../../user';

const GoToHandymanRequestTypeformButton = ({ className, record }) => {
  const user = getUser();
  const baseUrl = process.env.REACT_APP_HANDYMAN_REQUEST_TYPEFORM_URL;
  const accountFragment = `account_id=${record.id}&account_name=${record.name}&account_address=${record.shipping_address}`;
  const requesterFragment = `requester_id=${user.id}&requester_first_name=${user.firstname}&requester_last_name=${user.lastname}&requester_email=${user.email}`;
  return (
    <Fragment>
      <Route
        render={() => {
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${baseUrl}#${accountFragment}&${requesterFragment}`,
                );
              }}
            >
              <ListItemText primary="🛠️ Demande de polyvalent" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

GoToHandymanRequestTypeformButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

GoToHandymanRequestTypeformButton.defaultProps = {
  className: undefined,
  record: undefined,
};

export default GoToHandymanRequestTypeformButton;
