import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  translate as translateComponent,
} from 'react-admin';
import { AccountAutocompleteInput } from '../../../components';

const ProviderFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <AccountAutocompleteInput
        source="account_id"
        alwaysOn
        filter={{ type: 'Client' }}
      />
      <AccountAutocompleteInput
        source="provider"
        alwaysOn
        filter={{ type: 'Partenaire' }}
      />
    </Filter>
  );
};

ProviderFilter.propTypes = {
  translate: PropTypes.func,
};

ProviderFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(ProviderFilter);
