import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';
import Collapse from '@material-ui/core/Collapse';
import ListItem from '@material-ui/core/ListItem';
import { createStyles, withStyles } from '@material-ui/core/styles';

import ContractReplacementField from './ContractReplacementField';
import ContractReplacementItemHeader from './ContractReplacementItemHeader';

const styles = createStyles({
  item: {
    paddingLeft: 20,
    paddingTop: 20,
    paddingRight: 20,
  },
});

const ContractReplacementItem = ({
  record,
  basePath,
  totalDuration,
  replacementDuration,
  classes,
  ...props
}) => {
  const [open, setOpen] = useState(true);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <ListItem
        button
        onClick={handleClick}
        className={classes.item}
        disableGutters
      >
        <ContractReplacementItemHeader
          totalDuration={totalDuration}
          replacementDuration={replacementDuration}
          record={record}
          open={open}
        />
      </ListItem>
      <Collapse in={open} timeout="auto">
        <ContractReplacementField
          {...props}
          basePath={basePath}
          record={record}
        />
      </Collapse>
      <Divider />
    </Fragment>
  );
};

ContractReplacementItem.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  totalDuration: PropTypes.number,
  replacementDuration: PropTypes.number,
  classes: PropTypes.object,
};

ContractReplacementItem.defaultProps = {
  basePath: '',
  totalDuration: 0,
  replacementDuration: 0,
  record: {},
  classes: {},
};

export default withStyles(styles)(ContractReplacementItem);
