import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Drawer from '@material-ui/core/Drawer';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import { Route, withRouter } from 'react-router';

const sanitizeRestProps = ({
  hasList,
  hasEdit,
  hasShow,
  loaded,
  currentSort,
  setSort,
  staticContext,
  ...rest
}) => {
  return rest;
};

const DrawerCreateEdit = ({
  push,
  routePath,
  children,
  editPath,
  location,
  renderEdit,
  renderCreate,
  ...props
}) => {
  const handleClose = () => {
    push(`${routePath}`);
  };

  return (
    <Route path={`${routePath}${editPath}/:id`}>
      {({ match }) => {
        const isMatchEdit = !!(
          match &&
          match.params &&
          match.params.id !== 'create'
        );
        const isMatchCreate = !!(
          match &&
          match.params &&
          match.params.id === 'create'
        );
        return (
          <Fragment>
            {children}
            <Drawer
              variant="temporary"
              open={isMatchEdit || isMatchCreate}
              anchor="right"
              onClose={handleClose}
            >
              {/* To avoid any errors if the route does not match, we don't render at all the component in this case */}
              {isMatchEdit
                ? renderEdit({
                    ...sanitizeRestProps(props),
                    id: match.params.id,
                    onCancel: handleClose,
                  })
                : null}
              {isMatchCreate
                ? renderCreate({
                    ...sanitizeRestProps(props),
                    onCancel: handleClose,
                    location,
                  })
                : null}
            </Drawer>
          </Fragment>
        );
      }}
    </Route>
  );
};

DrawerCreateEdit.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  editPath: PropTypes.string,
  location: PropTypes.object,
  push: PropTypes.func,
  renderEdit: PropTypes.func,
  renderCreate: PropTypes.func,
  routePath: PropTypes.string,
};

DrawerCreateEdit.defaultProps = {
  children: [],
  editPath: '',
  location: {},
  push: /* istanbul ignore next */ () => {},
  renderEdit: /* istanbul ignore next */ () => {},
  renderCreate: /* istanbul ignore next */ () => {},
  routePath: '',
};

export default connect(undefined, { push: routerPush })(
  withRouter(DrawerCreateEdit),
);
