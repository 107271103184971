import React from 'react';
import { Filter, SelectInput } from 'react-admin';
import {
  AccountAutocompleteInput,
  CleanerAutocompleteInput,
} from '../../../components';

const CleanrLetterFilter = (props) => {
  return (
    <Filter {...props}>
      <AccountAutocompleteInput alwaysOn />
      <CleanerAutocompleteInput source="manager_id" alwaysOn />
      <SelectInput
        source="logtype"
        label="Type"
        choices={[
          { id: 'visit', name: '🤝 Visite' },
          { id: 'quality', name: '✔️  Contrôle Qualité' },
          { id: 'interaction', name: '👥 Interaction' },
          { id: 'Questionnaire', name: '❓ Questionnaire' },
        ]}
        alwaysOn
      />
    </Filter>
  );
};

export default CleanrLetterFilter;
