import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';

const ConditionalInput = ({ when, is, children, ...props }) => {
  return (
    <Field name={when} subscription={{ value: true }}>
      {({ input: { value } }) => {
        return value === is ? React.cloneElement(children, props) : null;
      }}
    </Field>
  );
};

ConditionalInput.propTypes = {
  children: PropTypes.node.isRequired,
  is: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    .isRequired,
  when: PropTypes.string.isRequired,
};

export default ConditionalInput;
