import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import {
  NumberField,
  TextField,
  ImageField,
  SelectField,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { getUser } from '../../../../user';

import { GridCard, URLParamField } from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    gridCard: {
      marginTop: '16px',
    },
    importantField: {
      color: theme.palette.error.main,
      fontWeight: 500,
    },
  };
});

const ResumeCard = ({ record, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const user = getUser();
  const isMissingHours = get(record, 'has_missing_recurring_hours');
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="center"
      justify="center"
    >
      <GridCard
        {...props}
        record={record}
        direction="row"
        classes={{ root: classes.gridCard }}
        lg={2}
        md={0}
        xs={12}
      >
        <div>⠀ </div>
        <ImageField label="⠀" source="photo_cleaner_url" />
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://qc-test-ol.bubbleapps.io/version-test/photo_cleaner?cleaner_id=${record.id}&user_id=${user.id}`}
        >
          <button type="button">Ajouter</button>
        </a>
      </GridCard>
      <GridCard
        {...props}
        record={record}
        classes={{ root: classes.gridCard }}
        title={translate(`resources.cleaner.card_title.infos`)}
        lg={10}
        md={6}
      >
        <SelectField
          source="is_valid"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <TextField source="employee_status" />
        <NumberField source="contract_worked_time" />
        <NumberField source="recurring_work_schedule_per_week" />
        <NumberField
          className={isMissingHours ? classes.importantField : null}
          source="missing_recurring_hours"
        />
        <TextField source="contract_type" />
        <SelectField
          source="droit_image"
          choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' },
          ]}
        />
        <URLParamField
          url={'https://drive.google.com/drive/folders/'.concat(
            '',
            record.drive_folder_id,
          )}
          source="drive_folder"
          label="Drive"
          text="📁"
          variant="body2"
          emptyText="∅"
        />
        <URLParamField
          url={'tel:'.concat('', record.phone)}
          source="phone"
          label="Appel"
          text="☎️"
          variant="body2"
          emptyText="∅"
        />
      </GridCard>
    </Grid>
  );
};

ResumeCard.propTypes = {
  record: PropTypes.object,
};

ResumeCard.defaultProps = {
  record: {},
};

export default ResumeCard;
