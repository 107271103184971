import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const CatalogItemAutocompleteInput = ({
  helperText,
  reference,
  source,
  validate,
  family,
  ...props
}) => {
  return (
    <ReferenceInput
      {...props}
      filterToQuery={(searchText) => {
        return {
          name: searchText,
          ...(family ? { family } : {}),
        };
      }}
      source={source}
      reference={reference}
      validate={validate}
      allowEmpty
      emptyText="ra.action.clear_input_value"
    >
      <AutocompleteInput
        optionText={record => `${record?.family || ''} - ${record?.name || ''}`}
        source="name"
        options={{
          suggestionsContainerProps: {
            /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
            style: { zIndex: 2000 },
          },
        }}
        helperText={helperText}
      />
    </ReferenceInput>
  );
};

CatalogItemAutocompleteInput.propTypes = {
  helperText: PropTypes.bool,
  reference: PropTypes.string,
  source: PropTypes.string,
  family: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

CatalogItemAutocompleteInput.defaultProps = {
  helperText: undefined,
  reference: 'item',
  source: 'catalog_item_id',
  family: undefined,
  validate: [],
};

export default CatalogItemAutocompleteInput;
