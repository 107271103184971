import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
} from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { getUser } from '../../../user';
import { parseDate } from '../../../helpers';
import {
  ConditionalInput,
  SubtypeClientSelectInput,
} from '../../../components';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const defaultValues = {
  upload_date: moment().format('YYYY-MM-DD'),
  type: 'contract',
};

const DocumentClientForm = ({ onCancel, record, ...props }) => {
  const user = getUser();
  return (
    <Fragment>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm
        {...sanitizeRestProps(props)}
        record={{ ...record, ...defaultValues }}
      >
        <ReferenceInput
          filterToQuery={(searchText) => {
            return { name: searchText };
          }}
          source="account_id"
          reference="account"
          validate={[required()]}
        >
          <AutocompleteInput
            source="name"
            options={{
              suggestionsContainerProps: {
                /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                style: { zIndex: 2000 },
              },
            }}
          />
        </ReferenceInput>
        <DateInput
          source="document_date"
          parse={parseDate}
          initialValue={moment().format('YYYY-MM-DD')}
        />
        <TextInput
          source="drive_id"
          label="drive id du document à ajouter"
          validate={[required()]}
        />
        <SelectInput
          source="type"
          choices={[
            { id: 'contract_end', name: 'Fin de contrat' },
            { id: 'provider', name: 'Fournisseur' },
            { id: 'contract', name: 'Contrat' },
          ]}
          defaultValue="contract"
          validate={[required()]}
        />
        <SubtypeClientSelectInput validate={[required()]} />
        <ConditionalInput when="subtype" is="provider_contract">
          <ReferenceInput
            filterToQuery={(searchText) => {
              return { name: searchText };
            }}
            source="provider_id"
            reference="account"
            filter={{ type: 'Partenaire' }}
            sort={{ field: 'name', order: 'ASC' }}
            label="Fournisseur"
          >
            <AutocompleteInput
              source="name"
              options={{
                suggestionsContainerProps: {
                  /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                  style: { zIndex: 2000 },
                },
              }}
            />
          </ReferenceInput>
        </ConditionalInput>
        <TextInput source="comment" label="Commentaire" />
        <TextInput
          source="televerseur_id"
          label="id du téléverseur"
          defaultValue={user.id}
          disabled
        />
      </SimpleForm>
    </Fragment>
  );
};

DocumentClientForm.propTypes = {
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

DocumentClientForm.defaultProps = {
  onCancel: /* istanbul ignore next */ () => {},
  record: {},
};

export default DocumentClientForm;
