import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Form, FormSpy } from 'react-final-form';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'baseline',
    },
    input: {
      marginLeft: theme.spacing(1),
    },
  };
});

const Filter = ({ children, initialValues, onChange }) => {
  const classes = useStyles();

  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => {}}
      render={() => {
        return (
          <div className={classes.root}>
            <FormSpy
              subscription={{ pristine: true, values: true }}
              onChange={({ pristine, values }) => {
                if (pristine) {
                  return;
                }
                onChange(values);
              }}
            />
            {Children.map(children, (child) => {
              return cloneElement(child, { className: classes.input });
            })}
          </div>
        );
      }}
    />
  );
};

Filter.propTypes = {
  children: PropTypes.node,
  initialValues: PropTypes.object,
  onChange: PropTypes.func,
};

Filter.defaultProps = {
  children: null,
  initialValues: null,
  onChange: () => {},
};

export default Filter;
