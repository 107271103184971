import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import PayrollListField from './PayrollList';
import PayrollConclusionField from './PayrollConclusion';

const styles = {
  root: {
    whiteSpace: 'pre-wrap',
    paddingTop: 0,
  },
};

const PayrollExplanationField = ({ payrollExplanation }) => {
  if (!Object.keys(payrollExplanation).length) {
    return null;
  }
  return (
    <List style={styles.root} component="div">
      <PayrollListField
        intro={payrollExplanation.contract.intro}
        list={payrollExplanation.contract.details}
      />
      <PayrollListField
        intro={payrollExplanation.permanent.intro}
        list={payrollExplanation.permanent.details}
      />
      <PayrollListField
        intro={payrollExplanation.punctual.intro}
        list={payrollExplanation.punctual.details}
      />
      <PayrollListField
        intro={payrollExplanation.leave.intro}
        list={payrollExplanation.leave.details}
      />
      <PayrollConclusionField
        summary={payrollExplanation.conclusion.summary}
        hoursList={payrollExplanation.conclusion.hoursList}
      />
    </List>
  );
};

PayrollExplanationField.propTypes = {
  payrollExplanation: PropTypes.object,
};

PayrollExplanationField.defaultProps = {
  payrollExplanation: {},
};

export default PayrollExplanationField;
