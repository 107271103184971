import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

/*
 * TODO: use AutocompleteInput but there was a bug when typing and seleting
 * a value the selectionned value wasn't displayed
 * use that styles to make appear the suggestion container:
 * const styles = createStyles({
    root: {
      width: '100%',
    },
    suggestionsContainerOpen: {
      zIndex: 100000000000000, // take value from theme
      minWidth: 200,
    },
  });
 * 
 */
export const SpaceTypeSelectInput = ({
  classes,
  source,
  validate,
  ...props
}) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      classes={classes}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        { id: 'Bureaux', name: 'Bureaux' },
        { id: 'Salles de réunion', name: 'Salles de réunion' },
        { id: 'Circulation', name: 'Circulation' },
        { id: 'Cuisine', name: 'Cuisine' },
        { id: 'Sanitaires', name: 'Sanitaires' },
        { id: 'Vitrerie', name: 'Vitrerie' },
        { id: 'Accueil', name: 'Accueil' },
        { id: 'Terrasse', name: 'Terrasse' },
        { id: 'Hall', name: 'Hall' },
        { id: 'Escaliers', name: 'Escaliers' },
        { id: 'Paliers', name: 'Paliers' },
        { id: 'Douches', name: 'Douches' },
        { id: 'Caves', name: 'Caves' },
        { id: 'Cours', name: 'Cours' },
        { id: 'Vestiaires', name: 'Vestiaires' },
        { id: "Salles d'attente", name: "Salles d'attente" },
        { id: 'Ateliers', name: 'Ateliers' },
        { id: 'Bar', name: 'Bar' },
        { id: 'Boutique', name: 'Boutique' },
        { id: 'Entrepöt', name: 'Entrepöt' },
        { id: 'Laboratoires', name: 'Laboratoires' },
        { id: 'Parkings', name: 'Parkings' },
        { id: 'Salles', name: 'Salles' },
        { id: 'Salles de détente', name: 'Salles de détente' },
        { id: 'Salles de pratique', name: 'Salles de pratique' },
        { id: 'Salles de restauration', name: 'Salles de restauration' },
        { id: 'Salles de sport', name: 'Salles de sport' },
        { id: 'Ascenseur', name: 'Ascenseur' },
        { id: 'Showroom', name: 'Showroom' },
        { id: 'Appartement', name: 'Appartement' },
      ]}
    />
  );
};

SpaceTypeSelectInput.propTypes = {
  classes: PropTypes.object,
  source: PropTypes.string.isRequired,
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
};

SpaceTypeSelectInput.defaultProps = {
  classes: {},
  validate: [],
};

export default SpaceTypeSelectInput;
