import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';

export const getError = async (response) => {
  const error = await response.json();

  return typeof error === 'string'
    ? error
    : error.message || 'aor.notification.http_error';
};

export const sendSignature = async (contract) => {
  const response = await fetch('/cleanerContract/sign', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify({ contract }),
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(await getError(response));
  }

  return response.json();
};

export const cancelSignature = async (procedureId) => {
  const response = await fetch('/cleanerContract/cancelProcedure', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify({ procedureId }),
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(await getError(response));
  }

  return response.json();
};

export function* signContract({ payload: { contract, cancel } }) {
  try {
    yield put({ type: FETCH_START });
    if (cancel) {
      yield call(cancelSignature, contract.procedure_id);
    } else {
      yield call(sendSignature, contract);
    }
    yield put({ type: FETCH_END });
    yield put(showNotification('notification.sign_contract.success', 'info'));
  } catch (error) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification(error.message, 'warning'));
  }
}

export default function* watch() {
  yield takeLatest('SAGA/SIGN_CONTRACT', signContract);
}
