import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const CleanerTypeSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        {
          id: 'Salarié actuel',
          name: 'resources.cleaner.form.type.current',
        },
        {
          id: 'Ancien salarié',
          name: 'resources.cleaner.form.type.former',
        },
        {
          id: 'Vivier salarial',
          name: 'resources.cleaner.form.type.applicant',
        },
      ]}
    />
  );
};

CleanerTypeSelectInput.propTypes = {
  validate: PropTypes.array,
  source: PropTypes.string.isRequired,
};

CleanerTypeSelectInput.defaultProps = {
  validate: [],
};

export default CleanerTypeSelectInput;
