import React from 'react';
import {
  SelectField,
  DateField,
  ReferenceField,
  TextField,
} from 'react-admin';
import { GridCard, TranslatedField } from '../../../components';

export const LeaveDetails = (props) => {
  return (
    <GridCard {...props} xs={12}>
      <ReferenceField source="cleaner_id" reference="cleaner" link="show">
        <TextField source="fullname" />
      </ReferenceField>
      <ReferenceField
        source="contract_id"
        reference="contract"
        allowEmpty
        link={false}
      >
        <TextField source="account_name" />
      </ReferenceField>
      <TranslatedField
        translationPath="components.field.absence_type"
        source="absence_type"
      />
      <DateField source="start_date" />
      <DateField source="end_date" />
      <SelectField source="processed" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
      <TextField source="comment" />
    </GridCard>
  );
};

export default LeaveDetails;
