import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';
import { UPLOAD_CLIENT_DOCUMENT } from '../action';

export const getTypeFromSubType = (subtype) => {
  switch (subtype) {
    case 'ponctual_contract':
    case 'recurrent_contract':
    case 'endorsement':
    case 'plan_prevention':
    case 'purchase_order':
      return 'contract';

    case 'provider_contract':
      return 'provider';

    case 'resignation_letter':
    case 'complaint_letter':
    case 'suspension_request':
      return 'contract_end';

    case 'other':
      return 'other';

    default:
      return '';
  }
};

const readFileAsync = /* istanbul ignore next */ (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const sendClientDocument = async (payload) => {
  const contentBuffer = await readFileAsync(payload.data.rawFile);
  const params = {
    files: [
      {
        type: getTypeFromSubType(payload.subtype),
        subtype: payload.subtype,
        comment: payload.comment,
        document_date: payload.document_date,
        televerseur_id: payload.televerseur_id,
        provider_id: payload.provider_id,
        data: contentBuffer,
      },
    ],
    accountId: payload.accountId,
  };
  const response = await fetch('/clientDocument/upload', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(params),
  });
  if (
    response.status < 200 ||
    response.status >= 300 ||
    response.status === 206
  ) {
    throw new Error(response.statusText);
  }
};

export function* uploadClientDocument({ payload }) {
  try {
    yield put({ type: FETCH_START });
    yield call(sendClientDocument, payload);
    yield put({ type: FETCH_END });
    yield put(showNotification('notification.upload_document.success', 'info'));
    // yield put(push(`/cleaner/${payload.cleanerId}`));
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification('notification.upload_document.fail', 'warning'));
  }
}

export default function* watch() {
  yield takeLatest(UPLOAD_CLIENT_DOCUMENT, uploadClientDocument);
}
