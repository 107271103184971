export const CLEAR_LEAVE_SITE_CLEANERS = 'CLEAR_LEAVE_SITE_CLEANERS';
export const GET_LEAVE_SITE_CLEANERS = 'SAGA/GET_LEAVE_SITE_CLEANERS';
export const SET_LEAVE_SITE_CLEANERS = 'SET_LEAVE_SITE_CLEANERS';

export const clearSiteCleaners = () => {
  return {
    type: CLEAR_LEAVE_SITE_CLEANERS,
  };
};

export const getLeaveSiteCleaners = (contractId) => {
  return {
    type: GET_LEAVE_SITE_CLEANERS,
    payload: { contractId },
  };
};
