import React from 'react';
import EditIcon from '@material-ui/icons/Edit';

import {
  DatagridBody,
  DateField,
  List,
  RecordContextProvider,
  ReferenceField,
  SelectField,
  DatagridHeader,
  TextField,
  Datagrid,
  useRecordContext,
  DatagridHeaderCell,
} from 'react-admin';
import { TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { DrawerCreateEdit } from '../../containers';
import { Pagination, TimeField } from '../../components';
import Filter from './detail/Filter';
import Edit from './Edit';
import computeMaxAdditionalHours from './functions/computeMaxAdditionalHours';
import computeRequestedDuration from './functions/computeRequestedDuration';
import convertWeeklyWorkedTimeToNumberOfHours from './functions/convertWeeklyWorkedTimeToNumberOfHours';
import filterOnlyValidDays from './functions/filterOnlyValidDays';
import daysOfWeekToFrenchNames from './functions/daysOfWeekToFrenchNames';

const RequestedWeeklyHoursColumn = ({ preaffectationRecord, record }) => {
  return (
    <span>
      {computeMaxAdditionalHours(record) <
        computeRequestedDuration(preaffectationRecord) &&
        preaffectationRecord.type != 'absence' &&
        '⚠️ '}
      {preaffectationRecord.type == 'absence' && '- '}
      {computeRequestedDuration(preaffectationRecord)}
    </span>
  );
};

const ContractWeeklyWorkedHoursColumn = ({ record }) => {
  return <span>{convertWeeklyWorkedTimeToNumberOfHours(record)}</span>;
};

const MaxWeeklyAdditionalHoursColumn = ({ record }) => {
  return <span>{computeMaxAdditionalHours(record)}</span>;
};

const CustomDatagridRow = ({
  record,
  id,
  onToggleItem,
  children,
  selected,
  selectable,
}) => {
  return (
    <RecordContextProvider value={record}>
      <TableRow>
        <TableCell>
          <Link to={`/preaffectation/${id}`}>
            <EditIcon />
          </Link>
        </TableCell>
        <TableCell>
          <SelectField
            source="type"
            label="Raison"
            choices={[
              { id: 'absence', name: '❌ Absence' },
              { id: 'ponctuel', name: '1️⃣ Ponctuel' },
              { id: 'remplacement', name: '🔄 Remplacement' },
              { id: 'permanent', name: '♾️ Permanent' },
              { id: 'cloture', name: '🏁 Clôture' },
            ]}
          />
        </TableCell>
        <TableCell>
          <ReferenceField
            source="cleaner_id"
            reference="cleaner"
            label="Vol. demandé (h/sem)"
            link="show"
          >
            <RequestedWeeklyHoursColumn
              label="Vol. demandé (h/sem)"
              preaffectationRecord={record}
            />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <ReferenceField
            source="cleaner_id"
            reference="cleaner"
            label="Vol. additionnel max (h/sem)"
            link="show"
          >
            <MaxWeeklyAdditionalHoursColumn />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <ReferenceField
            source="cleaner_id"
            reference="cleaner"
            label="Vol. contrat (h/sem)"
            link="show"
          >
            <ContractWeeklyWorkedHoursColumn />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <ReferenceField
            source="cleaner_id"
            reference="cleaner"
            label="Cleaner"
            link="show"
          >
            <TextField source="fullname" />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <ReferenceField
            source="account_id"
            reference="account"
            label="Compte"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <DateField
            source="start_date"
            label="Début affectation"
            textAlign="center"
          />
        </TableCell>
        <TableCell>
          <DateField
            source="end_date"
            label="Fin affectation"
            textAlign="center"
          />
        </TableCell>
        <TableCell>
          <TimeField source="start_time" record={record} />
        </TableCell>
        <TableCell>
          <TimeField source="end_time" record={record} />{' '}
        </TableCell>
        <TableCell>
          <DaysOfTheWeekCell record={record} />
        </TableCell>
        <TableCell>
          <TextField source="commentaire" />
        </TableCell>
        <TableCell>
          <ReferenceField
            source="absent_id"
            reference="cleaner"
            label="Absent"
            link="show"
          >
            <TextField source="fullname" />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <ReferenceField
            source="manager_id"
            reference="cleaner"
            label="Manager"
            link="show"
          >
            <TextField source="fullname" />
          </ReferenceField>
        </TableCell>
        <TableCell>
          <DateField
            source="date_creation"
            label="Date création"
            textAlign="center"
          />
        </TableCell>
        <TableCell>
          <TextField source="absence_type" label="Type d'absence" />
        </TableCell>
        <TableCell>
          <TextField source="prestation_type" label="Type de prestation" />
        </TableCell>
        <TableCell>
          <TextField source="commentaire_validateur" />
        </TableCell>
      </TableRow>
    </RecordContextProvider>
  );
};

const DaysOfTheWeekCell = ({ record }) => {
  const indicesDaysOfWeek = [
    record.monday,
    record.tuesday,
    record.wednesday,
    record.thursday,
    record.friday,
    record.saturday,
    record.sunday,
  ]
    .map((dayOfWeekSelected, index) => {
      return dayOfWeekSelected ? index : -1;
    })
    .filter(index => {
      return index > -1;
    });

  const filterDaysBasedOnRecordDates = filterOnlyValidDays(
    indicesDaysOfWeek,
    record,
  );

  const allDays = daysOfWeekToFrenchNames(filterDaysBasedOnRecordDates);

  return <div>{allDays}</div>;
};

const CustomDatagridBody = props => {
  return <DatagridBody {...props} row={<CustomDatagridRow />} />;
};

const CustomDatagrid = props => {
  return (
    <Datagrid
      {...props}
      header={<CustomDatagridHeader />}
      body={<CustomDatagridBody />}
    ></Datagrid>
  );
};

const CustomDatagridHeaderCell = ({ key, label }) => {
  return (
    <DatagridHeaderCell key={key}>
      <span>{label}</span>
    </DatagridHeaderCell>
  );
};

const CustomDatagridHeader = props => {
  const headerLabels = [
    'Edit',
    'Raison',
    'Vol. demandé (h/sem)',
    'Vol. additionnel max (h/sem)',
    'Vol. contrat (h/sem)',
    'Cleaner',
    'Compte',
    'Début affectation',
    'Fin affectation',
    'Start time',
    'End time',
    'Journée Semaine 📆 ',
    'Commentaire Manager',
    'Absent',
    'Manager',
    'Date de création',
    "Type d'absence",
    'Type de prestation',
    'Commentaire RH',
  ];

  return (
    <DatagridHeader {...props}>
      {headerLabels.map((label, index) => {
        return <CustomDatagridHeaderCell key={index} label={label} />;
      })}
    </DatagridHeader>
  );
};

const PreaffectationList = ({
  record,
  hasEdit,
  hasList,
  hasShow,
  syncWithLocation,
  ...props
}) => {
  return (
    <DrawerCreateEdit {...props} routePath="/preaffectation" renderEdit={Edit}>
      <List
        {...props}
        hasCreate
        sort={{ field: 'date_creation', order: 'DESC' }}
        bulkActionButtons={false}
        filters={<Filter />}
        pagination={<Pagination />}
      >
        <CustomDatagrid {...props} />
      </List>
    </DrawerCreateEdit>
  );
};

PreaffectationList.propTypes = {
  record: PropTypes.object,
};

PreaffectationList.defaultProps = {
  record: {},
};

export default PreaffectationList;
