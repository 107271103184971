import React from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { createStyles, withStyles } from '@material-ui/core/styles';
import { translate as translateComponent } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import PlanningLineDaysField from './PlanningLineDaysField';
import { removeSeconds } from '../../../../helpers';

import { buildDays } from '../../helper';

const styles = createStyles({
  root: {
    display: 'flex',
    margin: '5px 10px',
  },
  days: {
    minWidth: 400,
  },
});

const PlanningLine = ({ classes, days, endTime, startTime, translate }) => {
  const formattedDays = buildDays(days, translate);

  return (
    <div className={classes.root}>
      <PlanningLineDaysField text={formattedDays} />
      <Typography component="span" variant="body1">
        {`${removeSeconds(startTime)} - ${removeSeconds(endTime)}`}
      </Typography>
    </div>
  );
};

PlanningLine.propTypes = {
  classes: PropTypes.object,
  days: PropTypes.array,
  endTime: PropTypes.string,
  startTime: PropTypes.string,
  translate: PropTypes.func,
};

PlanningLine.defaultProps = {
  classes: {},
  days: [],
  endTime: undefined,
  startTime: undefined,
  translate: () => {},
};

const enhance = compose(translateComponent, withStyles(styles));

export default enhance(PlanningLine);
