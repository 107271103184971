import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { push as routerPush } from 'connected-react-router';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';

const IconMenuActions = ({ className, record, basePath, push }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <Fragment>
      <IconButton className={className} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem
          onClick={() => {
            push(`${basePath}/${record.id}`);
          }}
        >
          Edit
        </MenuItem>
        <MenuItem
          onClick={() => {
            push(`${basePath}/${record.id}/show`);
          }}
        >
          Detail
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

IconMenuActions.propTypes = {
  className: PropTypes.string,
  basePath: PropTypes.string,
  record: PropTypes.object,
  push: PropTypes.func,
};

IconMenuActions.defaultProps = {
  className: undefined,
  basePath: '',
  record: {},
  push: /* istanbul ignore next */ () => {},
};

export default connect(null, { push: routerPush })(IconMenuActions);
