import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, SaveButton, DeleteButton } from 'react-admin';
import { withStyles } from '@material-ui/core';

const toolbarStyles = {
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const FormToolbar = ({ isDeletable, redirect, ...props }) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      {isDeletable && <DeleteButton redirect={redirect} />}
    </Toolbar>
  );
};

FormToolbar.propTypes = {
  isDeletable: PropTypes.bool,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
};

FormToolbar.defaultProps = {
  isDeletable: false,
  redirect: undefined,
};

export default withStyles(toolbarStyles)(FormToolbar);
