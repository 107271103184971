import React from 'react';
import get from 'lodash/get';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/Warning';
import TimeField from './TimeField';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    separator: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    icon: {
      paddingLeft: theme.spacing(1),
    },
    iconSucces: {
      color: theme.palette.success.main,
    },
    iconError: {
      color: theme.palette.error.main,
    },
  };
});

const SoldVsDoneField = ({ record, variant }) => {
  const classes = useStyles();

  const isAffectationOk = get(record, 'recurring_contract_affectations_ok');

  return (
    <div className={classes.root}>
      <TimeField
        record={record}
        source="recurring_contract_cleaning_duration_per_week"
        variant={variant}
      />
      <Typography className={classes.separator} variant={variant}>
        /
      </Typography>
      <TimeField
        record={record}
        source="recurring_contract_permanent_affectation_duration_per_week"
        variant={variant}
      />
      {isAffectationOk ? (
        <CheckIcon className={clsx(classes.icon, classes.iconSucces)} />
      ) : (
        <WarningIcon className={clsx(classes.icon, classes.iconError)} />
      )}
    </div>
  );
};

SoldVsDoneField.propTypes = {
  addLabel: PropTypes.bool, // eslint-disable-line react/no-unused-prop-types
  record: PropTypes.object,
  variant: PropTypes.string,
};

SoldVsDoneField.defaultProps = {
  addLabel: true,
  record: {},
  variant: 'body2',
};

export default SoldVsDoneField;
