import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, makeStyles } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useTranslate } from 'react-admin';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 85,
      marginLeft: theme.spacing(1),
    },
  };
});

export const ProcedureButton = ({ contract, status }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translate = useTranslate();

  const onProcedureAction = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch({
        type: 'SAGA/SIGN_CONTRACT',
        payload: {
          contract,
          cancel: status === 'active' || status === 'draft',
        },
      });
    },
    [dispatch, contract, status],
  );

  const signAction = status !== 'active' && status !== 'draft';

  return (
    <Button
      className={classes.root}
      color="primary"
      onClick={(event) => {
        onProcedureAction(event);
      }}
    >
      {translate(
        `resources.cleaner_contract.actions.${
          signAction ? 'sign' : 'cancelProcedure'
        }`,
      )}
    </Button>
  );
};

ProcedureButton.propTypes = {
  contract: PropTypes.object,
  status: PropTypes.string,
};

ProcedureButton.defaultProps = {
  contract: {},
  status: '',
};

export default ProcedureButton;
