import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import { Map, TileLayer, Marker } from 'react-leaflet';

export const MapField = ({ record, sourceLatitude, sourceLongitude, zoom }) => {
  const latitude = get(record, sourceLatitude);
  const longitude = get(record, sourceLongitude);
  if (!longitude || !latitude) {
    return null;
  }

  const position = [latitude, longitude];
  return (
    <Map center={position} zoom={zoom}>
      <TileLayer
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} />
    </Map>
  );
};

MapField.propTypes = {
  record: PropTypes.object,
  sourceLatitude: PropTypes.string.isRequired,
  sourceLongitude: PropTypes.string.isRequired,
  zoom: PropTypes.number,
};

MapField.defaultProps = {
  zoom: 13,
  record: {},
};

export default MapField;
