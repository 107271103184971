import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  TextField,
  Datagrid,
  DateField,
  ArrayField,
  NumberField,
  SelectField,
  ReferenceField,
} from 'react-admin';
import { GridCard } from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const ListDetail = ({ ...props }) => {
  const classes = useStyles();

  return (
    <GridCard {...props} classes={classes}>
      <TextField source="id" label="Identifiant" />
      <ReferenceField
        source="affectation"
        reference="affectation"
        textAlign="center"
        link="show"
      >
        <TextField source="id" label="Id Affectation" />
      </ReferenceField>
      <DateField source="created_at" label="Créé le" />
      <SelectField source="check_out" choices={[
        { id: true, name: '✅' },
        { id: false, name: '❌' }]} />
        <SelectField source="signature" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="mail_envoye" choices={[
            { id: true, name: '✅' },
            { id: false, name: '❌' }]} />

      <ArrayField source="details" emptyText="∅" label="Détail Factotum">
        <Datagrid>
          <TextField source="prestationType" emptyText="∅" label={false} />
          <NumberField source="comment" emptyText="∅" label={false} />
        </Datagrid>
      </ArrayField>
    </GridCard>
  );
};

export default ListDetail;
