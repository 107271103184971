import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslate } from 'react-admin';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import Switch from '@material-ui/core/Switch';
import PlanningDaysInput from './PlanningDaysInput';
import PlanningDaysWithTimeInput from './PlanningDaysWithTimeInput';

const useStyles = makeStyles({
  title: {
    marginTop: 16,
    marginBottom: 8,
  },
});

export const buildDefaultDays = (values) => {
  if (!values) {
    return {};
  }

  return Object.keys(values).reduce((acc, key) => {
    const newValue = { ...acc };
    newValue[key] = !!values[key] && !!values[key].length;

    return newValue;
  }, {});
};

export const buildDefaultTime = (values) => {
  if (!values) {
    return {
      start_time: null,
      end_time: null,
    };
  }

  const firstValue = Object.values(values).filter((value) => {
    return (
      !!value && value.length && (value[0].start_time || value[0].end_time)
    );
  })[0];

  if (firstValue) {
    return firstValue[0];
  }
  return {
    start_time: null,
    end_time: null,
  };
};

const PlanningInput = ({ label, resource, source, ...props }) => {
  const [defaultValues, setDefaultValues] = useState({ time: {}, days: {} });
  const [inputValue, setInputValue] = useState({});
  const [specificTime, setSpecificTime] = useState(false);
  const classes = useStyles();
  const translate = useTranslate();

  const initializeDefaultValues = () => {
    const value = {
      time: buildDefaultTime(inputValue),
      days: buildDefaultDays(inputValue),
    };
    return setDefaultValues(value);
  };

  const handleCheck = (event) => {
    setSpecificTime(event.target.checked);
    initializeDefaultValues();
  };

  return (
    <FormControl fullWidth margin="normal">
      <Typography className={classes.title} component="span" variant="h5">
        {translate(`resources.${resource}.fields.${source}`)}
      </Typography>
      {specificTime ? (
        <PlanningDaysWithTimeInput
          {...props}
          source={source}
          defaultDays={defaultValues.days}
          defaultTime={defaultValues.time}
          setParentInputValue={setInputValue}
        />
      ) : (
        <PlanningDaysInput
          {...props}
          source={source}
          label={translate('components.planning.days')}
          defaultDays={defaultValues.days}
          defaultTime={defaultValues.time}
          setParentInputValue={setInputValue}
        />
      )}
      <div>
        <FormControlLabel
          control={<Switch checked={specificTime} onChange={handleCheck} />}
          label={translate('components.planning.variable_hours')}
        />
      </div>
    </FormControl>
  );
};

PlanningInput.propTypes = {
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
};

PlanningInput.defaultProps = {
  label: undefined,
  resource: undefined,
};

export default PlanningInput;
