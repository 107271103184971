export const SET_AFFECTATIONS_LIST = 'SET_AFFECTATIONS_LIST';
export const GET_AFFECTATIONS_FROM_LEAVE = 'SAGA/GET_AFFECTATIONS_FROM_LEAVE';
export const CLEAR_AFFECTATIONS_LIST = 'CLEAR_AFFECTATIONS_LIST';

export const getAffectationsFromLeave = (leaveId) => {
  return {
    type: GET_AFFECTATIONS_FROM_LEAVE,
    payload: { leaveId },
  };
};

export const clearAffectations = () => {
  return {
    type: CLEAR_AFFECTATIONS_LIST,
  };
};
