import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCreateEdit } from '../../../../containers';
import Edit from '../../../InvoiceIncubator/Edit';
import Create from '../../../InvoiceIncubator/Create';

import Datagrid from './Datagrid';

const EDIT_PATH = '/edit_invoice_incubator';

const buildDefaultRecord = (record, filter) => {
  return {
    account_id: record.id,
    billing_id: record.id,
    month: filter.month,
  };
};

const DrawerList = ({
  basePath,
  filter,
  originRoute,
  record: record_,
  resource,
  ...props
}) => {
  const record = buildDefaultRecord(record_, filter);

  return (
    <DrawerCreateEdit
      basePath={basePath}
      resource={resource}
      record={record}
      routePath={originRoute}
      editPath={EDIT_PATH}
      renderCreate={Create}
      renderEdit={Edit}
      redirect={() => {
        return originRoute;
      }}
    >
      <Datagrid
        {...props}
        basePath={basePath}
        resource={resource}
        origin={`${originRoute}${EDIT_PATH}`}
      />
    </DrawerCreateEdit>
  );
};

DrawerList.propTypes = {
  basePath: PropTypes.string,
  filter: PropTypes.object,
  originRoute: PropTypes.string.isRequired,
  record: PropTypes.object,
  resource: PropTypes.string,
};

DrawerList.defaultProps = {
  basePath: '/',
  filter: {},
  record: {},
  resource: undefined,
};

export default DrawerList;
