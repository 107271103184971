import React, { cloneElement, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useReferenceManyFieldController } from 'react-admin';
import { LinearProgress, Grid, makeStyles, Divider } from '@material-ui/core';
import { buildPlanning } from '../../../Replacement/helper';

const useStyles = makeStyles((theme) => {
  return {
    loading: {
      marginTop: theme.spacing(4),
    },
  };
});
const PlanningController = ({
  accountId,
  basePath,
  children,
  ids,
  data: originData,
  resource,
}) => {
  const classes = useStyles();
  const { loading, data } = useReferenceManyFieldController({
    basePath,
    resource,
    record: { id: ids },
    source: 'id',
    target: 'in(leave_id)',
    reference: 'affectation',
    filter: { account_id: accountId },
    sort: {
      field: 'start_time,account_name',
      order: 'ASC,ASC',
    },
    page: 1,
    perPage: 100,
  });
  if (loading) {
    return (
      <Grid className={classes.loading} item xs={12}>
        <LinearProgress />
      </Grid>
    );
  }
  let planning = [];
  if (data != null) {
    planning = buildPlanning(Object.values(data), { isLeaveIdKey: true });
  }

  return ids.map((id) => {
    return (
      <Fragment key={id}>
        {cloneElement(children, {
          key: id,
          basePath,
          record: originData[id],
          resource,
          planning: planning[id],
        })}
        <Divider />
      </Fragment>
    );
  });
};
PlanningController.propTypes = {
  accountId: PropTypes.string.isRequired,
  basePath: PropTypes.string,
  children: PropTypes.element,
  ids: PropTypes.array.isRequired,
  data: PropTypes.object.isRequired,
  resource: PropTypes.string,
};
PlanningController.defaultProps = {
  basePath: '/',
  children: null,
  resource: undefined,
};
const Plop = ({ ids, data, ...props }) => {
  if (!ids || !ids.length) {
    return null;
  }

  const accountId = data[Object.keys(data).map(Number)[0]].account_id;
  return (
    <PlanningController
      {...props}
      accountId={accountId}
      ids={Object.keys(data)
        .map(Number)
        .reverse()}
      data={data}
    />
  );
};
Plop.propTypes = {
  data: PropTypes.object,
  ids: PropTypes.array,
};
Plop.defaultProps = {
  data: {},
  ids: undefined,
};
export default Plop;
