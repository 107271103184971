import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceManyField,
  TextField,
  useTranslate,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';
import {
  GridCard,
  CleanerContractSpecificationField,
  ProcedureLinkField,
  ProcedureReferenceField,
} from '../../../../../components';
import Actions from './Actions';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
});

const CleanerContractCard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      classes={classes}
      title={translate('resources.cleaner.card_title.cleaner_contract')}
      xs={12}
      action={<Actions {...props} />}
    >
      <ReferenceManyField
        {...props}
        reference="cleaner_contract"
        target="cleaner_id"
        addLabel={false}
        xs={12}
        perPage={10}
        sort={{ field: 'start_date', order: 'DESC' }}
        pagination={<Pagination />}
      >
        <Datagrid {...props} rowClick="show">
          <DateField source="start_date" />
          <DateField source="end_date" label="Fin" />
          <CleanerContractSpecificationField source="detail" />
          <NumberField source="hourly_rate" />
          <TextField source="contract_worked_time" />
          <TextField source="comment" />
          <ProcedureLinkField
            {...props}
            label={translate('resources.cleaner_contract.fields.procedure')}
          />
          <ProcedureReferenceField
            {...props}
            label={translate('resources.cleaner_contract.fields.procedure')}
          />
        </Datagrid>
      </ReferenceManyField>
    </GridCard>
  );
};

export default CleanerContractCard;
