import React from 'react';
import PropTypes from 'prop-types';
import 'moment/locale/fr';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format';
import { makeStyles, Typography } from '@material-ui/core';

momentDurationFormat(moment);

const getGranularity = (granularity) => {
  const granularities = {
    year: 'Tous les ans',
    quarter: 'Tous les trimestres',
    month: 'Tous les mois',
    week: 'Toutes les semaines',
    isoWeek: 'Toutes les semaines',
    day: 'Tous les jours',
    hour: 'Toutes les heures',
    minute: 'Toutes les minutes',
  };

  return granularities[granularity];
};

const useStyles = makeStyles({
  root: {
    paddingTop: 24,
  },
});

const Period = ({ record }) => {
  const classes = useStyles();

  if (!record) {
    return null;
  }

  switch (record.type) {
    case 'PERIOD':
      return (
        <Typography className={classes.root} color="textSecondary">
          Toutes les{' '}
          {moment
            .duration(record.period)
            .format(
              'y [ans], w [semaines], d [jours], h [heures], m [minutes], s [secondes]',
              {
                largest: 3,
                trim: 'both',
              },
            )}
        </Typography>
      );

    case 'PATTERN':
      return (
        <Typography className={classes.root} color="textSecondary">
          {getGranularity(record.granularity)} à{' '}
          {moment
            .duration(record.offset)
            .format('w [semaines], d [jours], hh:mm:ss', {
              trim: 'large',
              stopTrim: 'h',
            })}
        </Typography>
      );

    case 'ON_DEMAND':
      return (
        <Typography className={classes.root} color="textSecondary">
          Lancement à la demande
        </Typography>
      );

    default:
      return null;
  }
};

Period.propTypes = {
  record: PropTypes.object,
};

Period.defaultProps = {
  record: {},
};

export default Period;
