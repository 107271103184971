import React from 'react';
import { translate as translateComponent } from 'react-admin';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { days } from '../../helpers';

const WeekdaysWorkedField = ({ record, translate }) => {
  const weekdaysWorked = days
    .filter((day) => {
      return record[day];
    })
    .map((day) => {
      return translate(`format.date.days.${day}`);
    });
  return <Typography variant="caption">{weekdaysWorked.join(' ')}</Typography>;
};

WeekdaysWorkedField.propTypes = {
  record: PropTypes.object,
  translate: PropTypes.func,
};

WeekdaysWorkedField.defaultProps = {
  record: {},
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(WeekdaysWorkedField);
