import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  TextInput,
  BooleanInput,
  DateInput,
  translate as translateComponent,
} from 'react-admin';
import {
  CleanerTypeSelectInput,
  EchellonSelectInput,
  ContractTypeSelectInput,
} from '../../../components';

const FilterCleaner = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="search" alwaysOn />
      <CleanerTypeSelectInput
        source="employee_status"
        alwaysOn
        initialValue="Salarié actuel"
      />
      <BooleanInput source="has_missing_recurring_hours" />
      <TextInput source="email" />
      <TextInput source="silae_id" />
      <TextInput source="nationality" />
      <TextInput source="manager_fullname" />
      <EchellonSelectInput source="level" />
      <ContractTypeSelectInput source="contract_type" />
      <DateInput
        label="Date de contract"
        source="between(contract_start_date, contract_end_date)"
      />
    </Filter>
  );
};

FilterCleaner.propTypes = {
  translate: PropTypes.func,
};

FilterCleaner.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(FilterCleaner);
