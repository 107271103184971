import { CLEAR_AFFECTATIONS_LIST, SET_AFFECTATIONS_LIST } from '../action';

const affectationsListFromLeave = (state = [], action) => {
  switch (action.type) {
    case SET_AFFECTATIONS_LIST:
      return action.affectations;
    case CLEAR_AFFECTATIONS_LIST:
      return [];
    default:
      return state;
  }
};

export default affectationsListFromLeave;
