import React from 'react';
import { SelectField, TextField, EmailField, useTranslate } from 'react-admin';
import { GridCard } from '../../../components';

const InvoiceResumeCard = (props) => {
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      title={translate('resources.account.card_title.billing_info')}
      xs={12}
    >
    <SelectField source="bill_with_parent" choices={[
      { id: true, name: '✅' },
      { id: false, name: '❌' }]} />
      <TextField source="payment_method" />
      <EmailField source="billing_email" />
      <TextField source="billing_address" />
      <TextField source="gcl_customer_id" />
      <TextField source="gcl_mandate_id" />
    </GridCard>
  );
};

export default InvoiceResumeCard;
