import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: ({ level }) => {
      return {
        paddingLeft: level ? theme.spacing(2) : 0,
      };
    },
    default: {},
    primary: {
      color: theme.palette.primary.main,
    },
    light: {
      color: theme.palette.grey[600],
    },
  };
});

const NestedSimpleListItem = ({
  children,
  defaultOpen,
  dense,
  icon,
  level,
  text,
  variant,
}) => {
  const classes = useStyles({ level });
  const isNested = !!children;
  const [open, setOpen] = React.useState(defaultOpen);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div className={classes.root}>
      <ListItem button={isNested} dense={dense} onClick={handleClick}>
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        <ListItemText primary={text} className={classes[variant]} />
        {isNested && (open ? <ExpandLess /> : <ExpandMore />)}
      </ListItem>
      {isNested && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {React.Children.map(children, (field) => {
            return React.cloneElement(field, { level: level + 1 });
          })}
        </Collapse>
      )}
    </div>
  );
};

NestedSimpleListItem.propTypes = {
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
  dense: PropTypes.bool,
  icon: PropTypes.element,
  level: PropTypes.number,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'primary', 'light']),
};

NestedSimpleListItem.defaultProps = {
  children: null,
  defaultOpen: false,
  dense: false,
  icon: null,
  level: 0,
  text: '',
  variant: 'default',
};

export default NestedSimpleListItem;
