import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { DateField, SimpleList } from 'react-admin';
import {
  AffectationTypeField,
  TimePeriodField,
  WeekdaysWorkedField,
} from '../field';

const AffectationList = ({ data, ...props }) => {
  return (
    <SimpleList
      {...props}
      data={data}
      link="show"
      primaryText={(record) => {
        return (
          <Fragment>
            <AffectationTypeField
              record={record}
              source="account_name"
              variant="subtitle1"
            />
            <div>
              <DateField record={record} source="start_date" />
              <span style={{ padding: 4 }}>-</span>
              <DateField record={record} source="end_date" />
            </div>
            <TimePeriodField record={record} variant="body2" />
          </Fragment>
        );
      }}
      secondaryText={(record) => {
        return <WeekdaysWorkedField record={record} />;
      }}
    />
  );
};

AffectationList.propTypes = {
  data: PropTypes.object,
};

AffectationList.defaultProps = {
  data: {},
};

export default AffectationList;
