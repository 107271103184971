import React from 'react';
import PropTypes from 'prop-types';
import { Button, translate as translateComponent } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { withStyles, createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  container: {
    marginBottom: 20,
  },
  button: {
    paddingLeft: 40,
    paddingRight: 40,
    borderRadius: 30,
  },
});

const Header = withStyles(styles)(({ onClick, classes, translate }) => {
  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      className={classes.container}
    >
      <Typography variant="h5">
        {translate('resources.replacement.create.title')}
      </Typography>
      <Button
        label={translate('resources.replacement.create.buttons.details')}
        variant="outlined"
        className={classes.button}
        onClick={onClick}
      />
    </Grid>
  );
});

Header.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  translate: PropTypes.func,
};

Header.defaultProps = {
  classes: {},
  onClick: /* istanbul ignore next */ () => {},
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(Header);
