import moment from 'moment';

const computeExtremeDates = (affectations) => {
  const extrema = affectations.reduce((acc, affectation) => {
    const startAsMoment = moment(affectation.start_date);
    const endAsMoment = moment(affectation.end_date);

    const minmax = {
      startDate: acc.startDate
        ? moment.min(acc.startDate, startAsMoment)
        : startAsMoment,
      endDate: acc.endDate ? moment.max(acc.endDate, endAsMoment) : endAsMoment,
    };
    return minmax;
  }, {});
  return extrema;
};

export default (affectations_) => {
  return (value, values) => {
    const affectations = affectations_.filter((affectation) => {
      return affectation.type === 'Absence';
    });

    if (!value || !values) {
      return undefined;
    }

    const extrema = computeExtremeDates(affectations);
    const res =
      moment(value).isBefore(extrema.startDate) ||
      moment(value).isAfter(extrema.endDate);
    if (res) {
      return 'components.form.replacement_outside_leave_scope';
    }

    return undefined;
  };
};
