import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/core/styles';

import { DateField, NumberField, TextField } from 'react-admin';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    item: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      paddingLeft: 20,
      paddingTop: 20,
      paddingRight: 20,
    },
    subItem: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
    },
    title: {
      marginBottom: theme.spacing(1),
    },
    highlight: {
      fontWeight: 500,
    },
    comment: {
      marginTop: 4,
      color: theme.palette.grey[500],
    },
  };
});

const ContractListItem = ({ record }) => {
  const classes = useStyles();

  return (
    <Fragment>
      <ListItem disableGutters className={classes.item}>
        <div className={clsx(classes.subItem, classes.title)}>
          <DateField record={record} source="start_date" variant="body1" />
          <TextField
            className={classes.highlight}
            record={record}
            source="contract_worked_time"
            variant="body1"
          />
        </div>
        <div className={classes.subItem}>
          <Typography variant="body2">{`${record.contract_type} - ${record.level} - ${record.classification}`}</Typography>
          <NumberField record={record} source="hourly_rate" variant="body1" />
        </div>
        <TextField
          className={classes.comment}
          record={record}
          source="comment"
          variant="body1"
        />
      </ListItem>
      <Divider />
    </Fragment>
  );
};

ContractListItem.propTypes = {
  record: PropTypes.object,
};

ContractListItem.defaultProps = {
  record: {},
};

export default ContractListItem;
