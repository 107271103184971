import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import clsx from 'clsx';
import { Chip, makeStyles } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const status = {
  CLOSED: 'closed',
  CANCELLED: 'cancelled',
  OPEN: 'open',
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      minWidth: 90,
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      marginTop: theme.spacing(0.5),
      marginBottom: theme.spacing(0.5),
      fontSize: 12,
    },
    closed: {
      backgroundColor: theme.palette.status.success,
    },
    cancelled: {
      backgroundColor: theme.palette.status.cancelled,
    },
    open: {
      backgroundColor: theme.palette.status.fail,
    },
  };
});

const InvoiceStatusChipField = ({ record, source }) => {
  const value = get(record, source);

  const translate = useTranslate();
  const classes = useStyles();

  return (
    <Chip
      className={clsx(classes.root, classes[status[value]])}
      color="primary"
      label={translate(
        `components.field.invoice_status.${value}`,
      ).toUpperCase()}
      size="small"
    />
  );
};

InvoiceStatusChipField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

InvoiceStatusChipField.defaultProps = {
  record: {},
};

export default InvoiceStatusChipField;
