import React from 'react';
import PropTypes from 'prop-types';
import {
  ReferenceArrayInput,
  resolveBrowserLocale,
  SelectArrayInput,
} from 'react-admin';

const locale = resolveBrowserLocale();

export const search = (searchText) => {
  return { [locale]: searchText };
};

const TagsSelectInput = ({ onChange, source, ...props }) => {
  return (
    <ReferenceArrayInput
      {...props}
      source={source}
      reference="tag"
      filterToQuery={search}
    >
      <SelectArrayInput optionText={locale} onChange={onChange} />
    </ReferenceArrayInput>
  );
};

TagsSelectInput.propTypes = {
  onChange: PropTypes.func,
  source: PropTypes.string.isRequired,
};

TagsSelectInput.defaultProps = {
  onChange: /* istanbul ignore next */ () => {},
};

export default TagsSelectInput;
