import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const ContractTypeSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        {
          id: 'CDD',
          name: 'CDD',
        },
        {
          id: 'CDI',
          name: 'CDI',
        },
      ]}
    />
  );
};

ContractTypeSelectInput.propTypes = {
  validate: PropTypes.array,
  source: PropTypes.string.isRequired,
};

ContractTypeSelectInput.defaultProps = {
  validate: [],
};

export default ContractTypeSelectInput;
