import { SET_CLEANER_TIMELINE, CLEAR_CLEANER_TIMELINE } from '../action';

export default (state = {}, action = {}) => {
  switch (action.type) {
    case SET_CLEANER_TIMELINE:
      return action.payload.timeline;
    case CLEAR_CLEANER_TIMELINE:
      return {};
    default:
      return state;
  }
};
