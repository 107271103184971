const providerInvoices = (state = {}, action) => {
  switch (action.type) {
    case 'FETCH_ACCOUNTS_INVOICES':
      return { ...state, loading: 1 };
    case 'FETCH_ERROR_ACCOUNTS_INVOICES':
      return { ...state, loading: 0 };
    case 'SET_ACCOUNTS_INVOICES':
      return { ...action.payload, loading: 0 };
    case 'SET_INVOICE_UPLOAD_ERRORS':
      return { ...action.payload, loading: 0 };
    case 'CLEAR_ACCOUNTS_INVOICES':
      return {};
    default:
      return state;
  }
};

export default providerInvoices;
