import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { GridCard } from '../../../../components';

import ListFollowup from './ListFollowup';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
});

const FollowupCard = (props) => {
  const classes = useStyles();

  return (
    <GridCard
    {...sanitizeRestProps(props)}
    classes={classes}
    xs={12}
    title={'Suivi Client'}
    >
    <ListFollowup xs={12} />
    </GridCard>
  );
};

export default FollowupCard;
