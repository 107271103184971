import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  ReferenceManyField,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin';
import { Pagination, URLParamField } from '../../../../components';

import Details from './Details';

const ConditionalDownloadResumeField = ({ record, ...props }) => {
  return record.logtype !== 'visit' && record.url_control_report ? (
    <URLParamField
      source="url_control_report"
      url={record.url_control_report}
      label="Compte-rendu"
      textAlign="center"
      variant="body2"
      text="📩"
    />
  ) : (
    '❌'
  );
};

const ListFollowup = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference="client_followup"
      target="account_id"
      addLabel={false}
      sort={{ field: 'date', order: 'DESC' }}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid expand={<Details />}>
        <DateField source="date" textAlign="center" />
        <ReferenceField
          source="manager_id"
          reference="cleaner"
          textAlign="center"
          link="show"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <SelectField
          source="logtype"
          label="Type"
          textAlign="center"
          choices={[
            { id: 'quality', name: '✔️ Contrôle Qualité' },
            { id: 'visit', name: '🤝 Visite' },
            { id: 'interaction', name: '👥 Interaction' },
            { id: 'Questionnaire', name: '❓ Questionnaire' },
          ]}
        />
        <TextField source="comment" label="Commentaire" emptyText="∅" />
        <NumberField source="global_rating" emptyText="∅" textAlign="center" />
        <ConditionalDownloadResumeField source="logtype" label="Compte-rendu" />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ListFollowup;
