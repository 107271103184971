import React from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteInput,
  NullableBooleanInput,
  Filter,
  TextInput,
  ReferenceInput,
  translate as translateComponent,
} from 'react-admin';
import { CatalogItemAutocompleteInput } from '../../../components';

const InvoiceIncubatorFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="month" />
      <NullableBooleanInput source="generated" />
      <ReferenceInput
        filterToQuery={(searchText) => {
          return { search: searchText };
        }}
        source="account_id"
        reference="account"
        alwaysOn
      >
        <AutocompleteInput source="name" helperText={false} />
      </ReferenceInput>
      <ReferenceInput
        filterToQuery={(searchText) => {
          return { search: searchText };
        }}
        source="billing_id"
        reference="account"
      >
        <AutocompleteInput source="name" helperText={false} />
      </ReferenceInput>
      <TextInput source="description" />
      <CatalogItemAutocompleteInput source="product" helperText={false} />
    </Filter>
  );
};

InvoiceIncubatorFilter.propTypes = {
  translate: PropTypes.func,
};

InvoiceIncubatorFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(InvoiceIncubatorFilter);
