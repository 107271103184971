import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Button } from 'react-admin';

const UploadDialogAffectattention = ({
  className,
  form,
  openButtonText,
  record,
}) => {
  const [open, setOpen] = useState(false);
  const invertOpenStatus = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <Button
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
        label={openButtonText}
      />
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          {React.cloneElement(form, {
            additionalActionOnSubmit: invertOpenStatus,
            record,
          })}
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </Fragment>
  );
};

UploadDialogAffectattention.propTypes = {
  className: PropTypes.string,
  form: PropTypes.element,
  openButtonText: PropTypes.string,
  record: PropTypes.object,
};

UploadDialogAffectattention.defaultProps = {
  className: undefined,
  form: null,
  openButtonText: '',
  record: {},
};

export default UploadDialogAffectattention;
