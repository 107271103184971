import React from 'react';
import { Create } from 'react-admin';
import Form from './detail/Form';

const CleanerCreate = (props) => {
  return (
    <Create {...props}>
      <Form />
    </Create>
  );
};

export default CleanerCreate;
