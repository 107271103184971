import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid as RaDatagrid,
  ReferenceField,
  SelectField,
  TextField,
} from 'react-admin';
import { NumberWithUnitField, ProductField } from '../../../../components';

const Datagrid = ({ origin, ...props }) => {
  return (
    <RaDatagrid
      {...props}
      rowClick={(id, basePath, record) => {
        if (record.generated) {
          return null;
        }
        return `${origin}/${id}`;
      }}
    >
      <TextField source="month" />
      <ReferenceField link="show" source="billing_id" reference="account">
        <TextField source="name" />
      </ReferenceField>
      <ProductField source="product" />
      <TextField source="description" />
      <SelectField
        source="generated"
        choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' },
        ]}
      />
      <NumberWithUnitField source="price" unit="€ (HT)" />
      <TextField source="invoice_number" />
    </RaDatagrid>
  );
};

Datagrid.propTypes = {
  origin: PropTypes.string.isRequired,
};

export default Datagrid;
