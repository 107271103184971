import querystring from 'query-string';

const typeformTable = {
  declare_affectation: {
    url: 'https://cleany.typeform.com/to/ej20Li',
    params: {
      name: 'account_name',
      manager_email: 'manager_email',
    },
  },
  cleaner_interaction: {
    url: 'https://cleany.typeform.com/to/WFu0dU',
    params: {
      id: 'cleaner_id',
      fullname: 'cleaner_name',
      user_id: 'user_id',
      user_name: 'user_name',
    },
  },
  cleaner_lrar: {
    url: 'https://cleany.typeform.com/to/v0saGI',
    params: {
      id: 'id',
      fullname: 'name',
      owner_id: 'owner_id',
      owner_name: 'owner_name',
      mailing_street: 'street',
      mailing_additional: 'additional',
      mailing_postal_code: 'postalcode',
      mailing_city: 'city',
    },
  },
};

export default (record, type) => {
  const { params, url } = typeformTable[type];
  const extracted = Object.keys(params).reduce((obj, key) => {
    if (record[key] == null) {
      return {
        ...obj,
        [params[key]]: '',
      };
    }

    return {
      ...obj,
      [params[key]]: record[key],
    };
  }, {});
  const query = querystring.stringify(extracted);
  const win = window.open(`${url}?${query}`, '_blank');
  win.focus();
};
