import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  ReferenceField,
  TextField,
  SelectField,
} from 'react-admin';
import {
  CleanerDocumentStatusChipField,
  URLParamField,
} from '../../../components';

const DocumentDatagrid = ({ origin, ...props }) => {
  return (
    <Datagrid
      {...props}
      rowClick={(id) => {
        return `${origin}/${id}`;
      }}
    >
      <TextField source="id" />
      <ReferenceField source="cleaner_id" reference="cleaner" link="show">
        <TextField source="fullname" />
      </ReferenceField>
      <DateField source="upload_date" />
      <DateField source="validation_date" />
      <ReferenceField
        allowEmpty
        source="validator_id"
        reference="cleaner"
        link="show"
      >
        <TextField source="fullname" />
      </ReferenceField>
      <TextField source="comment" />
      <CleanerDocumentStatusChipField source="status" />
      <SelectField
        source="type"
        choices={[
          { id: 'advance_payment', name: '💸 Accompte' },
          { id: 'bank', name: '🏦 Banque' },
          { id: 'contract', name: '📋 Contrat' },
          { id: 'health_care', name: '💊 Santé' },
          { id: 'occupational_medecine', name: '🧑‍⚕️ Médecine du travail' },
          { id: 'home', name: '🏠 Domicile' },
          { id: 'identity', name: '🆔 Identité' },
          { id: 'key_retrieval', name: '🔑 Clé' },
          { id: 'leave', name: "👻 Justificatif d'absence" },
          { id: 'picture', name: '🖼️ Photo' },
          { id: 'lrar', name: '🔅 Recommandé' },
        ]}
      />
      <SelectField
        source="subtype"
        choices={[
          { id: 'acs_attestation', name: 'Attestation ACS' },
          { id: 'advance_payment', name: "Demande d'accompte" },
          { id: 'AR_demission', name: 'Accusé réception de démission' },
          { id: 'Avenant', name: 'Avenant au contrat' },
          { id: 'avertissement', name: 'avertissement' },
          { id: 'babysitting_attestation', name: "Attestation de garde d'enfant(s)" },
          { id: 'birth_certificat', name: 'Acte de naissance' },
          { id: 'accuse_due', name: 'Accusé DUE mutuelle' },
          { id: 'cmu_attestation', name: 'Attestation de CMU' },
          { id: 'image', name: "Attestation de droit à l'image" },
          { id: 'contrat', name: 'Contrat de travail' },
          { id: 'contract_transfer', name: 'Transfert de contrat' },
          { id: 'going_retirement', name: 'Départ en retraite' },
          { id: 'Courrier Administratif', name: 'Courrier Administratif' },
          { id: 'Décharge', name: 'Décharge -16h' },
          { id: 'Exigences-Cleany.pdf', name: 'Exigences-Cleany' },
          { id: 'EPAL', name: 'Convocation EPAL' },
          { id: 'prefecture', name: 'Convocation préfecture' },
          { id: 'depot_dossier_prefecture', name: 'Preuve dépot dossier préfecture' },
          { id: 'final_settlement', name: 'Reçu pour solde de tout compte' },
          { id: 'reconnaissance_travailleur_handicape', name: 'Reconnaissance de la qualité de travailleur handicapé' },
          { id: 'reconnaissance_AT_MP', name: 'Reconnaissance AT-MP' },
          { id: 'family_book', name: 'Extrait du livret de famille' },
          { id: 'home_receipt', name: 'Justificatif de domicile' },
          { id: 'host_attestation', name: "Attestation sur l'honneur d'hébergement" },
          { id: 'host_id_card', name: "Pièce d'identité de l'hébergeur" },
          { id: 'id_card_back', name: 'CI verso' },
          { id: 'id_card_front', name: 'CI recto' },
          { id: 'insurance_attestation', name: 'Attestation de mutuelle' },
          { id: 'insurance_refusal', name: 'Dispense de mutuelle' },
          { id: 'bulletin_repris', name: 'Bulletin de paie repris' },
          { id: 'insurance_subscription', name: "Bulletin d'inscription à la mutuelle" },
          { id: 'invalidity_attestation', name: "Attestation d'invalidité" },
          { id: 'attestation_etudiante', name: 'Attestation/Carte étudiant' },
          { id: 'keys_hand_over', name: 'Remise de clé' },
          { id: 'maternity_leave_cerfa', name: 'Cerfa premier examen prénatal' },
          { id: 'death_certificat', name: 'death_certificat' },
          { id: 'maternity_leave_proof', name: 'Certificat de grossesse' },
          { id: 'work_autorisation', name: "Demande d'autorisation de travail employeur" },
          { id: 'maternity_leave_request', name: 'Demande de congés maternité / congé parental' },
          { id: 'medical_visit', name: 'Convocation visite médicale' },
          { id: 'DPAE', name: "Déclaration préalable à l'embauche" },
          { id: 'MED', name: 'Mise en demeure' },
          { id: 'notice_of_dismissal', name: 'Notification de licenciement' },
          { id: 'occupational_medecine_attestation', name: 'Attestation de visite à la médecine du travail' },
          { id: 'occupational_medecine_convocation', name: 'Convocation de visite à la médecine du travail' },
          { id: 'pass', name: 'Carte navigo' },
          { id: 'passport', name: 'Passeport' },
          { id: 'receipt', name: 'Facture' },
          { id: 'Règlement Intérieur.pdf', name: 'Règlement Intérieur.pdf' },
          { id: 'resignation_letter', name: 'Lettre de demission' },
          { id: 'rib', name: 'RIB' },
          { id: 'selfie', name: 'Selfie' },
          { id: 'sick_leave', name: 'Arrêt maladie' },
          { id: 'TAKEN_OVER', name: "Transfert d'annexe 7" },
          { id: 'trial_end', name: "Fin de période d'essai" },
          { id: 'trial_renewal', name: "Renouvellement de période d'essai" },
          { id: 'visa_receipt', name: 'Récépissé de demande de titre de séjour' },
          { id: 'visa_recto', name: 'Titre de séjour recto' },
          { id: 'visa_verso', name: 'Titre de séjour verso' },
          { id: 'vitale_attestation', name: 'Attestation sécurité sociale' },
          { id: 'visa_authentification', name: 'Authentification Titre de Séjour Préfecture' },
          { id: 'autre_absence', name: "Autre justificatif d'absence" },
          { id: 'vitale_card', name: 'Carte vitale' },
          { id: 'waiver_letter', name: 'Desistement de site' },
          { id: 'work_injury', name: 'Accident de travail' },
        ]}
      />
      <URLParamField
        url="https://drive.google.com/uc?export=download&id={{drive_id}}"
        source="drive_id"
        label="Télécharger"
        textAlign="center"
        text="📩"
        variant="body2"
        emptyText="∅"
      />
    </Datagrid>
  );
};

DocumentDatagrid.propTypes = {
  origin: PropTypes.string,
};

DocumentDatagrid.defaultProps = {
  origin: '',
};

export default DocumentDatagrid;
