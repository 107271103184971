import React from 'react';
import PropTypes from 'prop-types';

import Avatar from '@material-ui/core/Avatar';

import Explore from '@material-ui/icons/Explore';
import FlightTakeoff from '@material-ui/icons/FlightTakeoff';
import Notifications from '@material-ui/icons/Notifications';
import People from '@material-ui/icons/People';
import Sms from '@material-ui/icons/Sms';

import { images } from '../../../theme';

const icons = {
  Explore: <Explore />,
  FlightTakeoff: <FlightTakeoff />,
  Notifications: <Notifications />,
  People: <People />,
  Sms: <Sms />,
};

const TaskAvatar = ({ record }) => {
  if (record && record.avatar) {
    const [type, name] = record.avatar.split(':');

    if (type === 'icon' && icons[name]) {
      return <Avatar>{icons[name]}</Avatar>;
    }
    if (type === 'image' && images[name]) {
      return <Avatar src={images[name]} />;
    }
  }

  return (
    <Avatar>
      {record.id.charAt(0).toUpperCase()}
      {record.id.charAt(1)}
    </Avatar>
  );
};

TaskAvatar.propTypes = {
  record: PropTypes.object,
};

TaskAvatar.defaultProps = {
  record: {},
};

export default TaskAvatar;
