import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';

import Details from './Details';


const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(2),
    },
  };
});

const Expand = ({ details, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      {cloneElement(details, {
        ...props,
        title: translate('resources.cleaner.card_title.letter'),
      })}
    </div>
  );
};

Expand.propTypes = {
  details: PropTypes.element,
};

Expand.defaultProps = {
  details: <Details />,
};

export default Expand;
