import React from 'react';
import PropTypes from 'prop-types';
import { SortedSelectInput } from '../../../components';

export const ContractEndReasonInput = ({ source, validate, ...props }) => {
  return (
    <SortedSelectInput
      {...props}
      source={source}
      allowEmpty
      emptyText="ra.action.clear_input_value"
      validate={validate}
      choices={[
        {
          id: 'INSUFFICIENT_HOURS',
          name: 'components.field.contract_end_reason.INSUFFICIENT_HOURS',
        },
        {
          id: 'WORKLOAD',
          name: 'components.field.contract_end_reason.WORKLOAD',
        },
        {
          id: 'MANAGEMENT',
          name: 'components.field.contract_end_reason.MANAGEMENT',
        },
        {
          id: 'TOO_LONG_TRAVEL_TIME',
          name: 'components.field.contract_end_reason.TOO_LONG_TRAVEL_TIME',
        },
        {
          id: 'PHYSICAL_WORK',
          name: 'components.field.contract_end_reason.PHYSICAL_WORK',
        },
        {
          id: 'BETTER_OPPORTUNITY',
          name: 'components.field.contract_end_reason.BETTER_OPPORTUNITY',
        },
        {
          id: 'NO_REASON',
          name: 'components.field.contract_end_reason.NO_REASON',
        },
        {
          id: 'ABUSIVE_BEHAVIOR',
          name: 'components.field.contract_end_reason.ABUSIVE_BEHAVIOR',
        },
        {
          id: 'UNAUTHORIZED_LEAVES',
          name: 'components.field.contract_end_reason.UNAUTHORIZED_LEAVES',
        },
        {
          id: 'RECURRINGLY_LATE',
          name: 'components.field.contract_end_reason.RECURRINGLY_LATE',
        },
        {
          id: 'UNRESPECTED_SPECIFICATIONS',
          name:
            'components.field.contract_end_reason.UNRESPECTED_SPECIFICATIONS',
        },
        {
          id: 'MISSING_DOCUMENTS',
          name: 'components.field.contract_end_reason.MISSING_DOCUMENTS',
        },
        {
          id: 'PROFESSIONAL_INSUFFICIENCY',
          name:
            'components.field.contract_end_reason.PROFESSIONAL_INSUFFICIENCY',
        },
        {
          id: 'MISSION_REFUSAL',
          name: 'components.field.contract_end_reason.MISSION_REFUSAL',
        },
        {
          id: 'ABANDONMENT_OF_POST',
          name: 'components.field.contract_end_reason.ABANDONMENT_OF_POST',
        },
        {
          id: 'SECURITY_RULES_BREAKING',
          name: 'components.field.contract_end_reason.SECURITY_RULES_BREAKING',
        },
        {
          id: 'FORGED_DOCUMENTS',
          name: 'components.field.contract_end_reason.FORGED_DOCUMENTS',
        },
        {
          id: 'IDENTITY_THEFT',
          name: 'components.field.contract_end_reason.IDENTITY_THEFT',
        },
        {
          id: 'THEFT_OR_DEGRADATION',
          name: 'components.field.contract_end_reason.THEFT_OR_DEGRADATION',
        },
        {
          id: 'VIOLENCE_OR_INSULT',
          name: 'components.field.contract_end_reason.VIOLENCE_OR_INSULT',
        },
        {
          id: 'INSUBORDINATION',
          name: 'components.field.contract_end_reason.INSUBORDINATION',
        },
        {
          id: 'INJURY_TO_EMPLOYER',
          name: 'components.field.contract_end_reason.INJURY_TO_EMPLOYER',
        },
      ]}
    />
  );
};
ContractEndReasonInput.propTypes = {
  validate: PropTypes.array,
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

ContractEndReasonInput.defaultProps = {
  validate: [],
  addLabel: false,
};

export default ContractEndReasonInput;
