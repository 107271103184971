import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-final-form';
import { Button, DateInput } from 'react-admin';
import { useFormState } from 'react-final-form';
import { UploadDialogAffectattention } from '../../../../components';

import { PlanningInput } from '../../../../containers';

export const getMonday = (planning) => {
  if (planning.monday && planning.monday[0] && planning.monday[0].start_time) {
    return '1';
  }
  return '0';
};

export const getTuesday = (planning) => {
  if (
    planning.tuesday &&
    planning.tuesday[0] &&
    planning.tuesday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getWednesday = (planning) => {
  if (
    planning.wednesday &&
    planning.wednesday[0] &&
    planning.wednesday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getThursday = (planning) => {
  if (
    planning.thursday &&
    planning.thursday[0] &&
    planning.thursday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getFriday = (planning) => {
  if (planning.friday && planning.friday[0] && planning.friday[0].start_time) {
    return '1';
  }
  return '0';
};

export const getSaturday = (planning) => {
  if (
    planning.saturday &&
    planning.saturday[0] &&
    planning.saturday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getSunday = (planning) => {
  if (planning.sunday && planning.sunday[0] && planning.sunday[0].start_time) {
    return '1';
  }
  return '0';
};

export const getStartTime = (planning) => {
  if (planning.monday) {
    return planning.monday[0].start_time;
  }
  if (planning.tuesday) {
    return planning.tuesday[0].start_time;
  }
  if (planning.wednesday) {
    return planning.wednesday[0].start_time;
  }
  if (planning.thursday) {
    return planning.thursday[0].start_time;
  }
  if (planning.friday) {
    return planning.friday[0].start_time;
  }
  if (planning.saturday) {
    return planning.saturday[0].start_time;
  }
  if (planning.sunday) {
    return planning.sunday[0].start_time;
  }
  return '00:00:00';
};

export const getEndTime = (planning) => {
  if (planning.monday) {
    return planning.monday[0].end_time;
  }
  if (planning.tuesday) {
    return planning.tuesday[0].end_time;
  }
  if (planning.wednesday) {
    return planning.wednesday[0].end_time;
  }
  if (planning.thursday) {
    return planning.thursday[0].end_time;
  }
  if (planning.friday) {
    return planning.friday[0].end_time;
  }
  if (planning.saturday) {
    return planning.saturday[0].end_time;
  }
  if (planning.sunday) {
    return planning.sunday[0].end_time;
  }
  return '00:00:00';
};

export const getEnd = (values) => {
  if (!values.end_date) {
    return null;
  }

  return values.end_date;
};

export const getStart = (values) => {
  if (!values.start_date) {
    return '2017-01-01';
  }

  return values.start_date;
};

const AffectattentionForm = ({ record }) => {
  const CheacknerInput = () => {
    const { values } = useFormState();
    return (
      <Button
        variant="contained"
        label="🔎 Affectattention 🔎"
        onClick={() => {
          window.open(
            `https://stats.cleany.fr/question/1053?cleaner=${
              record.id
            }&debut=${getStartTime(values.planning)}&Vendredi=${getFriday(
              values.planning,
            )}&Dimanche=${getSunday(values.planning)}&Jeudi=${getThursday(
              values.planning,
            )}&Mercredi=${getWednesday(values.planning)}&Mardi=${getTuesday(
              values.planning,
            )}&Samedi=${getSaturday(values.planning)}&Lundi=${getMonday(
              values.planning,
            )}&Fin=${getEndTime(values.planning)}&date_de_debut=${getStart(
              values,
            )}&date_de_fin=${getEnd(values)}`,
            '_blank',
          );
        }}
        style={{
          marginLeft: 10,
        }}
      />
    );
  };

  return (
    <Form
      onSubmit="jean"
      render={(form) => {
        return (
          <form id="documentUploadForm">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexDirection: 'column',
              }}
            >
              <DateInput source="start_date" label="Date de début" />
              <DateInput source="end_date" label="Date de fin" />
              <PlanningInput source="planning" />
              <CheacknerInput />
            </div>
          </form>
        );
      }}
    />
  );
};

AffectattentionForm.propTypes = {
  record: PropTypes.object,
};

AffectattentionForm.defaultProps = {
  record: {},
};

const AffectattentionDialog = ({ className, record }) => {
  return (
    <UploadDialogAffectattention
      className={className}
      record={record}
      form={<AffectattentionForm />}
      openButtonText="Affect-attention"
    />
  );
};

AffectattentionDialog.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

AffectattentionDialog.defaultProps = {
  className: undefined,
  record: {},
};

export default AffectattentionDialog;
