import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, makeStyles } from '@material-ui/core';
import ShowIcon from '@material-ui/icons/RemoveRedEye';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  };
});

const ListLeaveAction = ({ record }) => {
  const classes = useStyles();
  const history = useHistory();

  const path = record && record.id ? `/leave/${record.id}/show` : null;

  return (
    <div className={classes.root}>
      <IconButton
        color="primary"
        disabled={!path}
        onClick={() => {
          history.push(path);
        }}
      >
        <ShowIcon />
      </IconButton>
    </div>
  );
};

ListLeaveAction.propTypes = {
  record: PropTypes.object,
};

ListLeaveAction.defaultProps = {
  record: {},
};

export default ListLeaveAction;
