import React from 'react';
import PropTypes from 'prop-types';
import { CreateButton } from '../../../../../components';

const Actions = ({ record }) => {
  return (
    <CreateButton
      pathname="/affectation/create"
      initialValues={{
        cleaner_ids: [record.id],
        catalog_item_id: '01t0Y000001C4C7QAK',
      }}
    />
  );
};

Actions.propTypes = {
  record: PropTypes.object,
};

Actions.defaultProps = {
  record: {},
};

export default Actions;
