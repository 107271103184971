import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
    },
    primary: {},
    secondary: {
      color: theme.palette.grey[600],
    },
  };
});

const TwoLinesField = ({
  classes: classesOverride,
  record,
  primary,
  secondary,
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      {React.cloneElement(primary, {
        className: classes.primary,
        record,
      })}
      {React.cloneElement(secondary, {
        className: classes.secondary,
        record,
      })}
    </div>
  );
};

TwoLinesField.propTypes = {
  classes: PropTypes.object,
  record: PropTypes.object,
  primary: PropTypes.element,
  secondary: PropTypes.element,
};

TwoLinesField.defaultProps = {
  classes: {},
  record: {},
  primary: null,
  secondary: null,
};

export default TwoLinesField;
