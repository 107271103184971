import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloudUpload from '@material-ui/icons/CloudUpload';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { Button, useTranslate } from 'react-admin';
import UploadDialogContent from './UploadDialogContent';

const UploadDialog = ({
  accountsInvoices,
  className,
  errors,
  form,
  loading,
  onClose,
  submitForm,
}) => {
  const [open, setOpen] = useState(false);
  const translate = useTranslate();

  const invertOpenStatus = () => {
    setOpen(!open);
  };

  const uploadView =
    !errors.length &&
    (!accountsInvoices || !Object.keys(accountsInvoices).length);

  return (
    <Fragment>
      <Button
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
        label={translate('resources.provider_invoice.actions.upload')}
      />
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle color="primary" id="alert-dialog-title">
          {translate('resources.provider_invoice.dialog.title')}
        </DialogTitle>
        <DialogContent>
          <UploadDialogContent
            accountsInvoices={accountsInvoices}
            loading={loading}
            errors={errors}
            form={form}
            onClose={onClose}
          />
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            label={
              uploadView
                ? translate('resources.provider_invoice.dialog.upload_button')
                : translate('resources.provider_invoice.dialog.ok_button')
            }
            variant="contained"
            onClick={uploadView ? submitForm : invertOpenStatus}
          >
            {uploadView ? <CloudUpload /> : <CheckCircle />}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

UploadDialog.propTypes = {
  accountsInvoices: PropTypes.object,
  className: PropTypes.string,
  errors: PropTypes.array,
  loading: PropTypes.number,
  onClose: PropTypes.func,
  submitForm: PropTypes.func,
  form: PropTypes.element,
};

UploadDialog.defaultProps = {
  accountsInvoices: {},
  className: undefined,
  errors: [],
  loading: 0,
  onClose: /* istanbul ignore next */ () => {},
  submitForm: /* istanbul ignore next */ () => {},
  form: null,
};

export default UploadDialog;
