import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';
import { INVITE_CLEANER } from '../action';
import fetchApi from './helpers/fetchApi';

export function* sendInvitationHandler({ payload }) {
  try {
    yield put({ type: FETCH_START });
    const res = yield call(fetchApi, {
      url: '/notification/invite/affectation',
      payload,
    });
    yield put({ type: FETCH_END });
    if (res.success === true) {
      yield put(
        showNotification('notification.invite_cleaner.success', 'info', {
          messageArgs: {
            invitationsSent: res.invitationsSent,
          },
        }),
      );
    } else {
      yield put(
        showNotification('notification.invite_cleaner.fail', 'warning', {
          messageArgs: {
            message: res.missingParameters[0],
          },
        }),
      );
    }
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification(err.message, 'warning'));
  }
}

export default function* watch() {
  yield takeLatest(INVITE_CLEANER, sendInvitationHandler);
}
