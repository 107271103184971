import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { TopToolbar } from 'react-admin';

const useStyles = makeStyles({
  button: {
    marginLeft: 15,
  },
});

/*
 * XXX: we set back record: data in order to use directly action button from aor
 * without setting props. Because react-admin gives data to actions by default
 */
const ActionsContainer = ({ basePath, children, data, resource }) => {
  const classes = useStyles();

  return (
    <TopToolbar>
      {data &&
        Children.map(children, (child) => {
          return cloneElement(child, {
            basePath,
            className: classes.button,
            record: data,
            resource,
          });
        })}
    </TopToolbar>
  );
};

ActionsContainer.propTypes = {
  basePath: PropTypes.string.isRequired,
  children: PropTypes.node,
  data: PropTypes.object,
  resource: PropTypes.string.isRequired,
};

ActionsContainer.defaultProps = {
  children: null,
  data: undefined,
};

export default ActionsContainer;
