import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  clearAffectations,
  clearSiteCleaners,
  getAffectationsFromLeave,
  getLeaveSiteCleaners,
} from '../../../action';

const ReplacementController = ({
  basePath,
  children,
  clearData,
  contractId,
  fetchRelatedData,
  leaveId,
  leaveSiteCleaners,
  record,
  relatedAffectations,
  resource,
}) => {
  useEffect(() => {
    fetchRelatedData({ contractId, leaveId });
    return () => {
      clearData();
    };
  }, [contractId, leaveId, fetchRelatedData, clearData]);

  return React.cloneElement(children, {
    basePath,
    affectations: relatedAffectations,
    cleanerIds: leaveSiteCleaners,
    contractId,
    leaveId,
    record,
    resource,
  });
};

ReplacementController.propTypes = {
  clearData: PropTypes.func,
  fetchRelatedData: PropTypes.func,
  location: PropTypes.object,
  relatedAffectations: PropTypes.array,
};

ReplacementController.defaultProps = {
  clearData: () => {},
  fetchRelatedData: () => {},
  location: { search: {} },
  relatedAffectations: [],
};

export const mapStateToProps = (state) => {
  return {
    leaveSiteCleaners: state.leaveSiteCleaners,
    relatedAffectations: state.affectationsFromLeave,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    clearData: () => {
      dispatch(clearAffectations());
      dispatch(clearSiteCleaners());
    },
    fetchRelatedData: ({ contractId, leaveId }) => {
      dispatch(getAffectationsFromLeave(leaveId));
      if (contractId) {
        dispatch(getLeaveSiteCleaners(contractId));
      }
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReplacementController);
