import React from 'react';
import { ShowGrid } from '../../../components';

import ShowActions from './ShowActions';
import LeaveDetails from './LeaveDetails';
import AccountList from './AccountList';

export default (props) => {
  return (
    <ShowGrid {...props} actions={<ShowActions />}>
      <LeaveDetails />
      <AccountList />
    </ShowGrid>
  );
};
