export const setUser = (data) => {
  return localStorage.setItem('user', JSON.stringify(data));
};

export const removeUser = () => {
  return localStorage.removeItem('user');
};

export const getUser = () => {
  const userString = localStorage.getItem('user');

  if (userString && userString !== 'undefined') {
    const user = JSON.parse(userString);
    return user;
  }
  return null;
};

export default {
  getUser,
  setUser,
  removeUser,
};
