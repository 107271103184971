import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';
import { UPLOAD_CLEANER_DOCUMENT } from '../action';

export const getTypeFromSubType = (subtype) => {
  switch (subtype) {
    case 'pass':
    case 'receipt':
      return 'transportation';

    case 'passport':
    case 'id_card_front':
    case 'id_card_back':
    case 'visa_recto':
    case 'visa_verso':
    case 'visa_receipt':
    case 'visa_authentification':
    case 'prefecture':
    case 'depot_dossier_prefecture':
    case 'vitale_card':
    case 'vitale_attestation':
    case 'reconnaissance_travailleur_handicape':
    case 'attestation_etudiante':
      return 'identity';

    case 'insurance_attestation':
    case 'insurance_subscription':
    case 'insurance_refusal':
    case 'invalidity_attestation':
    case 'reconnaissance_AT_MP':
      return 'health_care';

    case 'cmu_attestation':
    case 'acs_attestation':
    case 'accuse_due':
      return 'health_insurance';

    case 'occupational_medecine_convocation':
    case 'occupational_medecine_attestation':
    case 'medical_visit':
      return 'occupational_medecine';

    case 'rib':
      return 'bank';

    case 'home_receipt':
    case 'host_attestation':
    case 'host_id_card':
    case 'host_home_receipt':
      return 'home';

    case 'sick_leave':
    case 'work_injury':
    case 'maternity_leave_request':
    case 'maternity_leave_proof':
    case 'maternity_leave_cerfa':
    case 'birth_certificat':
    case 'family_book':
    case 'death_certificat':
    case 'babysitting_attestation':
    case 'autre_absence':
      return 'leave';

    case 'advance_payment':
      return 'advance_payment';

    case 'EPAL':
    case 'MED':
    case 'Mise en demeure':
    case 'avertissement':
    case 'notice_of_dismissal':
      return 'disciplinary';

    case 'trial_renewal':
    case 'trial_end':
    case 'resignation_letter':
    case 'waiver_letter':
    case 'final_settlement':
    case 'contract_transfer':
    case 'TAKEN_OVER':
    case 'AR_demission':
    case 'DPAE':
    case 'contrat':
    case 'work_autorisation':
    case 'Décharge':
    case 'Avenant':
    case 'bulletin_repris':
    case 'image':
      return 'contract';

    case 'keys_hand_over':
      return 'key_retrieval';

    case 'selfie':
      return 'picture';

    default:
      return '';
  }
};

const readFileAsync = /* istanbul ignore next */ (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const sendCleanerDocument = async (payload) => {
  const contentBuffer = await readFileAsync(payload.data.rawFile);
  const params = {
    files: [
      {
        type: getTypeFromSubType(payload.subtype),
        subtype: payload.subtype,
        data: contentBuffer,
      },
    ],
    cleanerId: payload.cleanerId,
  };
  const response = await fetch('/cleanerDocument/upload', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(params),
  });

  if (
    response.status < 200 ||
    response.status >= 300 ||
    response.status === 206
  ) {
    throw new Error(response.statusText);
  }
};

export function* uploadCleanerDocument({ payload }) {
  try {
    yield put({ type: FETCH_START });
    yield call(sendCleanerDocument, payload);
    yield put({ type: FETCH_END });
    yield put(showNotification('notification.upload_document.success', 'info'));
    // yield put(push(`/cleaner/${payload.cleanerId}`));
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification('notification.upload_document.fail', 'warning'));
  }
}

export default function* watch() {
  yield takeLatest(UPLOAD_CLEANER_DOCUMENT, uploadCleanerDocument);
}
