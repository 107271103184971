import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceManyField } from 'react-admin';
import { Typography } from '@material-ui/core';
import { NestedListItem, Pagination } from '../../../../components';

import ListLeaveDetails from './ListLeaveDetails';
import ListLeaveAction from './ListLeaveAction';
import ListLeaveItem from './ListLeaveItem';
import PlanningController from './PlanningController';

const ListLeave = ({ active, sort, perPage, title, ...props }) => {
  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <ReferenceManyField
        {...props}
        reference="account_leave"
        target="account_id"
        addLabel={false}
        filter={{ active }}
        sort={sort}
        xs={12}
        perPage={perPage}
        pagination={<Pagination />}
      >
        <PlanningController>
          <NestedListItem
            action={<ListLeaveAction />}
            collapse={<ListLeaveDetails />}
            dense
          >
            <ListLeaveItem />
          </NestedListItem>
        </PlanningController>
      </ReferenceManyField>
    </>
  );
};

ListLeave.propTypes = {
  active: PropTypes.bool,
  perPage: PropTypes.number,
  sort: PropTypes.object,
  title: PropTypes.string,
};

ListLeave.defaultProps = {
  active: false,
  perPage: 10,
  sort: { field: 'start_date', order: 'DESC' },
  title: undefined,
};

export default ListLeave;
