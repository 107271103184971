import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import get from 'lodash.get';
import { removeSeconds } from '../../helpers';

const TimePeriodField = ({ record, variant }) => {
  const startTime = get(record, 'start_time');
  const endTime = get(record, 'end_time');
  return (
    <Typography variant={variant}>
      {`${removeSeconds(startTime)} - ${removeSeconds(endTime)}`}
    </Typography>
  );
};

TimePeriodField.propTypes = {
  record: PropTypes.object,
  variant: PropTypes.string,
};

TimePeriodField.defaultProps = {
  record: {},
  variant: 'body1',
};

export default TimePeriodField;
