import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import {
  GridSimpleShowLayout,
  NumberWithUnitField,
} from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const ListDetail = ({ title, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <GridSimpleShowLayout {...props} classes={classes}>
        <NumberWithUnitField unit="€" source="cleaning_amount" />
        <NumberWithUnitField unit="€" source="glazzing_amount" />
        <NumberWithUnitField unit="€" source="consumable_amount" />
        <NumberWithUnitField unit="€" source="bin_amount" />
        <NumberWithUnitField unit="€" source="other_amount" />
      </GridSimpleShowLayout>
    </>
  );
};

ListDetail.propTypes = {
  title: PropTypes.string,
};

ListDetail.defaultProps = {
  title: undefined,
};

export default ListDetail;
