export const getCleanerLink = (data) => {
  const prefix = 'https://backoffice.cleany.fr/#/cleaner/';
  if (!data.id) {
    return null;
  }
  return prefix + data.id;
};

export const getInfoOperational = (data) => {
  return `${data.operational_fullname} - ${data.operational_email} - ${data.operational_phone}`;
};

export const getAccountLink = (data) => {
  const prefix = 'https://backoffice.cleany.fr/#/account/';
  if (!data.id) {
    return null;
  }
  return prefix + data.id;
};

const cleanerParams = {
  fullname: 'entry.1558582620',
  contract_start_date: 'entry.1412342071',
  manager_fullname: 'entry.2116052852',
  recurring_work_schedule_per_week: 'entry.156008719',
  link: 'entry.293799846',
};

const accountParams = {
  shipping_address: 'entry.1412342071',
  name: 'entry.1558582620',
  shipping_postal_code: 'entry.156008719',
  operational: 'entry.1054046589',
  id: 'entry.1954605100',
  manager_fullname: 'entry.2116052852',
  link: 'entry.293799846',
};

export const googleFormTable = {
  disciplinary_action: {
    url:
      'https://docs.google.com/forms/d/e/1FAIpQLSdyiqYxcDt1eqTnIP1L-GY6vm66M3T7uiPxXSzgHso1W9o1xg/viewform',
    tools: {
      link: getCleanerLink,
    },
    params: cleanerParams,
  },
  devis: {
    url:
      'https://docs.google.com/forms/d/e/1FAIpQLSfUFn6nBX_j4nxhRWcWj9GcNNA6QkiB7d3J0YkreQo-XC0jGQ/viewform',
    tools: {
      link: getAccountLink,
      operational: getInfoOperational,
    },
    params: accountParams,
  },
  learning_request: {
    url:
      'https://docs.google.com/forms/d/e/1FAIpQLSdnXX94ReOFKwp0aZihK1tbMrRQlh9jdS1X-OsriIuXmibmYQ/viewform',
    tools: {
      link: getCleanerLink,
    },
    params: cleanerParams,
  },
  payroll_issue: {
    url:
      'https://docs.google.com/forms/d/e/1FAIpQLSe9vzNsAV88d9WbXWUgmYjTP7hV1BkxTGcTluBQjR6POP2TYg/viewform',
    tools: {
      link: getCleanerLink,
    },
    params: cleanerParams,
  },
  extra_hours_report: {
    url:
      'https://docs.google.com/forms/d/e/1FAIpQLScNjGVETAwlO3BwtBgUGRiBkkmpTnQ95q-5Y3V7AmdWtK49qg/viewform',
    tools: {
      link: getCleanerLink,
    },
    params: cleanerParams,
  },
};

export const getTable = () => {
  return googleFormTable;
};
