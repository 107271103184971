import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import ContractHeader from './ContractHeader';
import Planning from './Planning';
import { planningValidation } from '../../../../helpers/date';
import { getWorkedTimeAndDays } from '../../helper';
import { SwitchedInput } from '../../../../components';
import PlanningDaysWithTimeInput from '../../../../containers/input/PlanningInput/PlanningDaysWithTimeInput';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
});

const ContractBlock = ({ planning, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!planning || !planning.affectations || !planning.affectations.length) {
    return null;
  }
  const { workedDays, workedTime } = getWorkedTimeAndDays(
    planning.affectations,
  );
  const accountName = planning.affectations[0].account_name;
  const opportunityName = planning.affectations[0].opportunity_name;

  return (
    <div className={classes.root}>
      <ContractHeader
        title={accountName}
        subtitle={opportunityName}
        totalDuration={planning.totalDuration}
        replacementDuration={planning.replacementDuration}
      />
      <Planning planning={planning.affectations} />
      <SwitchedInput
        label={translate('resources.replacement.fields.replace')}
        defaultDays={workedDays}
        defaultTime={workedTime}
        differentTime
        inputDefaultValue={{}}
        validate={planningValidation}
        source={`${props.contract}.planning`}
      >
        <PlanningDaysWithTimeInput />
      </SwitchedInput>
    </div>
  );
};

ContractBlock.propTypes = {
  contract: PropTypes.string.isRequired,
  planning: PropTypes.object,
  source: PropTypes.string,
};

ContractBlock.defaultProps = {
  planning: {},
  source: undefined,
};

export default ContractBlock;
