import React from 'react';
import PropTypes from 'prop-types';
import { CreateButton } from '../../../../../components';

const Actions = ({ record }) => {
  return (
    <CreateButton
      pathname="/leave/create"
      initialValues={{ cleaner_id: record.id }}
    />
  );
};

Actions.propTypes = {
  record: PropTypes.object,
};

Actions.defaultProps = {
  record: {},
};

export default Actions;
