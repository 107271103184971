import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      width: 'auto',
    },
    ratioText: {
      textAlign: 'right',
      marginTop: 2,
    },
    root: {
      height: 5,
      backgroundColor: theme.palette.white,
      border: `1px solid ${theme.palette.secondary.main}`,
      borderRadius: 5,
    },
    bar: {
      borderRadius: 5,
    },
    barColorPrimary: {
      backgroundColor: theme.palette.error.main,
    },
    emptyRoot: {
      borderColor: theme.palette.error.main,
    },
  };
});

const ReplacementCompletionField = ({ current, max }) => {
  const classes = useStyles();
  const translate = useTranslate();

  const isEmpty = !current;
  const value = (current / max) * 100;
  const isError = value > 100;

  return (
    <div className={classes.container}>
      <LinearProgress
        classes={{
          root: clsx(classes.root, (isEmpty || isError) && classes.emptyRoot),
          bar: classes.bar,
          barColorPrimary: classes.barColorPrimary,
        }}
        variant="determinate"
        value={isError ? 100 : value}
        color={isError ? 'primary' : 'secondary'}
      />
      <Typography
        className={classes.ratioText}
        component="span"
        variant="body2"
      >
        {translate('components.replacement_completion.ratio', {
          current: Math.round(current * 100) / 100,
          max: Math.round(max * 100) / 100,
        })}
      </Typography>
    </div>
  );
};

ReplacementCompletionField.propTypes = {
  current: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
};

export default ReplacementCompletionField;
