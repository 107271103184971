const fetchApi = async ({ url, method = 'POST', payload }, callback) => {
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method,
    body: payload ? JSON.stringify(payload) : undefined,
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }

  if (callback) {
    return callback(response);
  }

  return response.json();
};

export default fetchApi;
