import React from 'react';
import PropTypes from 'prop-types';
import {
  DateField,
  TextField,
  NumberField,
  ReferenceField,
  useReferenceManyFieldController,
} from 'react-admin';

import {
  GridCard,
  SoldVsDoneField,
  NumberWithUnitField,
  URLParamField,
} from '../../../../components';
import SpecificationDialog from '../SpecificationDialog';

import ListProduct from './ListProduct';
import WarningContract from './WarningContract';

const ContractResumeCard = ({ basePath, record, resource }) => {
  const { data, ids, loading } = useReferenceManyFieldController({
    basePath,
    resource,
    record,
    source: 'id',
    target: 'account_id',
    reference: 'contract',
    filter: { type: 'Récurrent', active: true },
    page: 1,
    perPage: 10,
  });

  const recurringContract = ids && ids.length ? data[ids[0]] : {};
  const warningContract = ids && ids.length && ids.length > 1;
  let titre = '';
  if (!record.has_recurring_contract) {
    titre = 'Contrat Récurrent - 🏁 Fini';
  } else {
    titre = 'Contrat Récurrent - ✅ Ouvert';
  }
  return (
    <GridCard
      basePath={basePath}
      resource={resource}
      record={record}
      title={titre}
      action={
        <SpecificationDialog
          basePath={basePath}
          resource={resource}
          record={recurringContract}
        />
      }
    >
      <TextField source="manager_fullname" />
      <URLParamField
        url="https://www.google.fr/maps/place/{{shipping_address}}"
        source="shipping_address"
        label="Adresse de livraison"
        text={record.shipping_address}
        variant="body2"
        emptyText="∅"
        sm={4}
      />
      <TextField source="access" />
      <NumberField source="cle_quantity" label="Nombre de clé" />
      <NumberField source="badge_quantity" label="Nombre de Badge" />
      <NumberField source="number_of_employees" />
      <ReferenceField link="show" source="chef_equipe" reference="cleaner">
        <TextField source="fullname" />
      </ReferenceField>
      <NumberWithUnitField source="surface" unit="m²" />
      <DateField source="recurring_contract_start_date" />
      <DateField source="recurring_contract_end_date" />
      <TextField source="operational_fullname" />
      <TextField source="operational_mobile_phone" />
      <TextField source="operational_email" />
      <URLParamField
        url="https://drive.google.com/drive/folders/{{drive_folder}}"
        source="drive_folder"
        label="Fichier Drive"
        text="📁"
        variant="body2"
        emptyText="∅"
      />
      <SoldVsDoneField source="sold_vs_done" variant="h6" />
      <TextField multiline htmlFor="component-outlined" source="comment" label="Commentaire" resettable />


      <ListProduct xs={12} loading={loading} contract={recurringContract} />
      {warningContract && <WarningContract xs={12} contracts={ids} />}
    </GridCard>
  );
};

ContractResumeCard.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string.isRequired,
};

ContractResumeCard.defaultProps = {
  basePath: '/',
  record: {},
};

export default ContractResumeCard;
