import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import { GridCard, TabbedLayout, Tab } from '../../../../../components';
import LeaveList from './LeaveList';
import Actions from './Actions';

const useStyles = makeStyles({
  root: {
    marginTop: 16,
  },
  headerRoot: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: '0 !important',
  },
});

const AffectationGrid = ({ active, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      classes={classes}
      title={translate(`resources.cleaner.card_title.leave`)}
      action={<Actions {...props} />}
    >
      <TabbedLayout xs={12}>
        <Tab label="resources.cleaner.cards.actives">
          <LeaveList active sort={{ field: 'start_date', order: 'ASC' }} />
        </Tab>
        <Tab label="resources.cleaner.cards.history">
          <LeaveList />
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

AffectationGrid.propTypes = {
  active: PropTypes.bool,
};

AffectationGrid.defaultProps = {
  active: false,
};

export default AffectationGrid;
