import React from 'react';
import PropTypes from 'prop-types';
import { CloneButton, DeleteButton, EditButton } from 'react-admin';
import { ActionsContainer } from '../../../components';
import InviteButton from './InviteButton';
import { isAffectationGenerated } from '../helper';

/* XXX: A wrapper is necessary because of two things:
 *       - In ShowGrid, children props has priority over parent props
 *       - In ShowActions, in case of refresh, the first time, data is undefined
 *       => Default data is then used as value for data and cannot be replaced by parent props
 *       => The wrapper doesn't have default props so it can take the one given by the record in ShowGrid
 */
const ShowActionsWrapper = (props) => {
  return <ShowActions {...props} />;
};

const ShowActions = ({ data, ...props }) => {
  if (!data) {
    return null;
  }
  return (
    <ActionsContainer {...props} data={data}>
      <EditButton disabled={isAffectationGenerated(data)} />
      <CloneButton />
      <InviteButton />
      <DeleteButton disabled={isAffectationGenerated(data)} />
    </ActionsContainer>
  );
};

ShowActions.propTypes = {
  data: PropTypes.object,
};

ShowActions.defaultProps = {
  data: undefined,
};

export default ShowActionsWrapper;
