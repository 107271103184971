import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Edit } from 'react-admin';

import {
  BooleanInput,
  SelectInput,
  SimpleForm,
  TextInput,
  NumberInput,
  translate as translateComponent,
} from 'react-admin';

import Toolbar from '../../components/form/FormToolbar';
import CleanerAutocompleteInput from '../../components/input/CleanerAutocompleteInput';

const styles = {
  areasModel: {
    marginTop: '15px',
    fontSize: '13px',
  },
};

export const EditAccount = ({ translate, ...props }) => {
  return (
    <Edit
      {...props}
      title="resources.account.edit.title"
      actions={null}
      redirect="show"
    >
      <SimpleForm toolbar={<Toolbar isDeletable={false} />} redirect="show">
        <TextInput fullWidth multiline source="access" />
        <NumberInput fullWidth source="cle_quantity" label="Nombre de clé" />
        <NumberInput
          fullWidth
          source="badge_quantity"
          label="Nombre de badge"
        />
        <TextInput fullWidth multiline source="garbage_location" />
        <TextInput fullWidth multiline source="storage_locker_location" />
        <TextInput
          fullWidth
          multiline
          source="comment"
          label="Commentaire"
          placeholder="Signer votre commentaire"
          resettable
        />
        <BooleanInput source="duplicate_access" />
        <TextInput fullWidth multiline source="transit_stop" />
        <CleanerAutocompleteInput fullWidth source="chef_equipe" />
        <NumberInput
          fullWidth
          source="nombre_sous_traitant"
          label="Nombre de sous-traitant intervenant sur le site"
        />
        <Fragment>
          <div style={styles.areasModel}>
            {`${translate('resources.account.edit.areasModel')}`}
            <ul style={{ listStyleType: 'none' }}>
              <li>{`${translate('resources.account.edit.areaSample')} A`}</li>
              <li>{`${translate('resources.account.edit.areaSample')} B`}</li>
              <li>{`${translate('resources.account.edit.areaSample')} C`}</li>
            </ul>
          </div>
        </Fragment>
        <TextInput source="building_areas" />
        <SelectInput
          source="post_start_appreciation"
          choices={[
            {
              id: 'UNDERESTIMATED',
              name:
                'resources.account.values.post_start_appreciation.UNDERESTIMATED',
            },
            {
              id: 'WELL_ESTIMATED',
              name:
                'resources.account.values.post_start_appreciation.WELL_ESTIMATED',
            },
            {
              id: 'PREMIUM',
              name: 'resources.account.values.post_start_appreciation.PREMIUM',
            },
          ]}
        />
      </SimpleForm>
    </Edit>
  );
};

EditAccount.propTypes = {
  translate: PropTypes.func,
};

EditAccount.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(EditAccount);
