import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { TextField, DateField } from 'react-admin';
import { GridSimpleShowLayout } from '../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const Details = ({ title, ...props }) => {
  const classes = useStyles();

  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <GridSimpleShowLayout {...props} classes={classes} spacing={1}>
        <DateField source="created_date" sm={5} />
        <DateField source="distributed_date" sm={5} />
        <TextField source="postage_type" sm={5} />
        <TextField source="subject" sm={5} />
        <DateField source="updated_date" sm={5} />
      </GridSimpleShowLayout>
    </>
  );
};

Details.propTypes = {
  title: PropTypes.string,
};

Details.defaultProps = {
  title: undefined,
};

export default Details;
