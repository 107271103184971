import React from 'react';
import { SimpleForm, required, BooleanInput, DateTimeInput } from 'react-admin';

const Form = (props) => {
  return (
    <SimpleForm {...props} redirect="list">
      <DateTimeInput source="next_execution_date" validate={[required()]} />
      <BooleanInput source="active" />
    </SimpleForm>
  );
};

export default Form;
