import PropTypes from 'prop-types';
import React from 'react';
import { Responsive, useTranslate, Notification } from 'react-admin';
import { ThemeProvider } from '@material-ui/core';
import CleanyLogo from './images/cleany-logo-blue.png';
import CleanyCleaning from './images/cleany-cleaning.jpg';
import CleanyGlazing from './images/cleany-glazing.jpg';
import CleanyTask from './images/cleany-tasks.jpg';
import CleanySupply from './images/cleany-supply.jpg';
import CleanySnacksAndDrinks from './images/cleany-snacks-and-drinks.jpg';

import { largeStyles, mediumStyles, smallStyles } from './loginStyle';

const advertisings = [
  {
    image: CleanyCleaning,
    quote: 'cleaning',
  },
  {
    image: CleanyGlazing,
    quote: 'glazing',
  },
  {
    image: CleanyTask,
    quote: 'tasks',
  },
  {
    image: CleanySupply,
    quote: 'supply',
  },
  {
    image: CleanySnacksAndDrinks,
    quote: 'snacks',
  },
];

const advertising =
  advertisings[Math.floor(Math.random() * advertisings.length)];

const styles = {
  leftContainer: {
    alignItems: 'center',
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    width: '50%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url('${advertising.image}')`,
  },
  bgCover: {
    background:
      'linear-gradient(135deg, rgb(60, 163, 219) 30%, rgb(51, 138, 186) 40%)',
    backgroundSize: 'cover',
    backgroundImage: `url('${advertising.image}')`,
    width: '100%',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },
  logo: {
    width: '50%',
    marginBottom: '-4px',
  },
};

const SplashScreen = ({ children, theme }) => {
  const translate = useTranslate();

  return (
    <ThemeProvider theme={theme}>
      <Responsive
        large={
          <div className="bgCover" style={largeStyles.bgCover}>
            <div className="container" style={largeStyles.container}>
              <div className="leftContainer" style={styles.leftContainer}>
                <p style={largeStyles.leftLabel}>
                  {translate(`login.${advertising.quote}`)}
                </p>
                <div className="filter" style={largeStyles.filter} />
              </div>
              <div
                className="rightContainer"
                style={largeStyles.rightContainer}
              >
                <div
                  className="rightContainerForm"
                  style={largeStyles.rightContainerForm}
                >
                  <img style={styles.logo} src={CleanyLogo} alt="Cleany" />
                  {children}
                </div>
              </div>
            </div>
            <Notification />
          </div>
        }
        medium={
          <div className="bgCover" style={mediumStyles.bgCover}>
            <div className="container" style={mediumStyles.container}>
              <div className="leftContainer" style={styles.leftContainer}>
                <p style={mediumStyles.leftLabel}>
                  {translate(`login.${advertising.quote}`)}
                </p>
                <div className="filter" style={mediumStyles.filter} />
              </div>
              <div
                className="rightContainer"
                style={mediumStyles.rightContainer}
              >
                <div
                  className="rightContainerForm"
                  style={mediumStyles.rightContainerForm}
                >
                  <img style={styles.logo} src={CleanyLogo} alt="Cleany" />
                  {children}
                </div>
              </div>
            </div>
            <Notification />
          </div>
        }
        small={
          <div className="bgCover" style={styles.bgCover}>
            <div className="container" style={smallStyles.container}>
              <div style={smallStyles.containerForm}>
                <img style={styles.logo} src={CleanyLogo} alt="Cleany" />
                {children}
              </div>
            </div>
            <Notification />
          </div>
        }
      />
    </ThemeProvider>
  );
};

SplashScreen.propTypes = {
  children: PropTypes.object,
  theme: PropTypes.object,
};

SplashScreen.defaultProps = {
  children: null,
  theme: null,
};

export default SplashScreen;
