import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as RaPagination } from 'react-admin';

const Pagination = ({ forceDisplay, perPage, total, ...props }) => {
  const isOnePage = total <= perPage;

  if (total > 0 && isOnePage && !forceDisplay) {
    return null;
  }

  return (
    <RaPagination
      {...props}
      perPage={perPage}
      total={total}
      rowsPerPageOptions={[5, 10, 25, 50]}
    />
  );
};

Pagination.propTypes = {
  forceDisplay: PropTypes.bool,
  perPage: PropTypes.number,
  total: PropTypes.number,
};

Pagination.defaultProps = {
  forceDisplay: false,
  perPage: undefined,
  total: undefined,
};

export default Pagination;
