import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import qs from 'qs';

import { withRouter } from 'react-router-dom';
import {
  translate as translateComponent,
  MenuItemLink,
  getResources,
} from 'react-admin';

import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import BusinessIcon from '@material-ui/icons/Business';
import CreateIcon from '@material-ui/icons/Create';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import FreeBreakfastIcon from '@material-ui/icons/FreeBreakfast';
import LabelIcon from '@material-ui/icons/Label';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import MailIcon from '@material-ui/icons/Mail';
import SchoolIcon from '@material-ui/icons/School';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import RepeatIcon from '@material-ui/icons/Repeat';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import GradeIcon from '@material-ui/icons/Grade';
import LowPriorityIcon from '@material-ui/icons/LowPriority';

import { SubMenu } from '../../components';

export const getUsedResource = (menu) => {
  return menu
    .map((item) => {
      if (item.subMenu) {
        return getUsedResource(item.subMenu);
      }
      return item.resource;
    })
    .reduce((acc, item) => {
      if (item instanceof Array) {
        return acc.concat(item);
      }
      acc.push(item);
      return acc;
    }, [])
    .filter((resource) => {
      return !!resource;
    });
};

export const removeUnavailableResources = (menu, resources) => {
  return menu
    .map((item) => {
      if (!item.subMenu) {
        return item;
      }

      return {
        ...item,
        subMenu: removeUnavailableResources(item.subMenu, resources),
      };
    })
    .filter((item) => {
      return (
        (!item.subMenu && !item.resource) ||
        (item.subMenu && item.subMenu.length) ||
        (item.resource &&
          !!resources.find((res) => {
            return res.name === item.resource && res.hasList;
          }))
      );
    });
};

export const addAdditionalResources = (menu, resources) => {
  const usedResources = getUsedResource(menu);

  const additionalResources = resources
    .filter((resource) => {
      return resource.hasList && !usedResources.includes(resource.name);
    })
    .map((resource, index) => {
      const res = {
        key: index,
        resource: resource.name,
      };
      if (resource.icon) {
        res.icon = resource.icon;
      }
      return res;
    });

  if (!additionalResources.length) {
    return menu;
  }

  return menu.concat([
    {
      key: menu.length,
      label: 'menu.default.other',
      subMenu: additionalResources,
    },
  ]);
};

export const getMenuDescription = (resources) => {
  const defaultMenu = [
    {
      key: 0,
      icon: BusinessIcon,
      label: 'resources.account.name',
      subMenu: [
        {
          key: 0,
          icon: AssignmentIndIcon,
          resource: 'account',
          params: {
            page: 1,
            perPage: 25,
            sort: 'name',
            order: 'ASC',
          },
        },
        {
          key: 1,
          label: 'resources.client_followup.name',
          icon: GradeIcon,
          resource: 'client_followup',
        },
        {
          key: 2,
          label: 'resources.client_document.name',
          resource: 'client_document',
          icon: AttachFileIcon,
        },
        {
          key: 3,
          label: 'resources.service_certificate.name',
          resource: 'service_certificate',
          icon: AttachFileIcon,
        },
      ],
    },
    {
      key: 2,
      label: 'resources.cleaner.name',
      icon: PeopleIcon,
      subMenu: [
        {
          key: 0,
          resource: 'cleaner',
          label: 'menu.cleaner.all',
          icon: PeopleIcon,
          params: {
            page: 1,
            perPage: 25,
            sort: 'fullname',
            order: 'ASC',
          },
        },
        {
          key: 1,
          resource: 'cleaner',
          label: 'menu.cleaner.new',
          icon: PersonAddIcon,
          params: {
            filter: JSON.stringify({
              employee_status: 'Vivier salarial',
            }),
            page: 1,
            perPage: 25,
            sort: 'fullname',
            order: 'ASC',
          },
        },
        {
          key: 2,
          resource: 'cleaner',
          label: 'menu.cleaner.intercontract',
          icon: FreeBreakfastIcon,
          params: {
            filter: JSON.stringify({
              has_missing_recurring_hours: true,
            }),
            page: 1,
            perPage: 25,
            sort: 'fullname',
            order: 'ASC',
          },
        },
        {
          key: 3,
          resource: 'cleaner_contract',
          icon: CreateIcon,
        },
        {
          key: 4,
          resource: 'cleaner_document',
          icon: AttachFileIcon,
        },
        {
          key: 5,
          resource: 'cleaner_letter',
          icon: MailIcon,
        },
        {
          key: 6,
          resource: 'formation',
          icon: SchoolIcon,
        },
      ],
    },
    {
      key: 1,
      icon: AssignmentIcon,
      label: 'resources.affectation.name',
      subMenu: [
        {
          key: 0,
          icon: AssignmentIndIcon,
          resource: 'affectation',
          params: {
            page: 1,
            perPage: 25,
            sort: 'start_date',
            order: 'DESC',
          },
        },
        {
          key: 1,
          label: 'menu.affectation.activeReplacement',
          icon: RepeatIcon,
          resource: 'affectation',
          params: {
            filter: JSON.stringify({
              type: 'Remplacement',
              active: true,
            }),
            page: 1,
            perPage: 25,
            sort: 'start_date',
            order: 'ASC',
          },
        },
        {
          key: 2,
          icon: FlightTakeoffIcon,
          resource: 'leave',
          params: {
            filter: JSON.stringify({
              processed: false,
            }),
            page: 1,
            perPage: 25,
            sort: 'start_date',
            order: 'ASC',
          },
        },
        {
          key: 3,
          label: 'resources.preaffectation.name',
          resource: 'preaffectation',
          icon: LowPriorityIcon,
        },
      ],
    },
    {
      key: 3,
      label: 'resources.provider.name',
      icon: LocalShippingIcon,
      subMenu: [
        {
          key: 0,
          resource: 'provider',
          icon: LocalShippingIcon,
        },
        {
          key: 1,
          resource: 'provider_item',
          icon: ShoppingCartIcon,
        },
        {
          key: 2,
          resource: 'provider_invoice',
          icon: EuroSymbolIcon,
        },
      ],
    },
    {
      key: 4,
      label: 'resources.invoice.name',
      icon: EuroSymbolIcon,
      subMenu: [
        {
          key: 0,
          resource: 'invoice',
          icon: EuroSymbolIcon,
        },
        {
          key: 1,
          resource: 'invoice_incubator',
          icon: EuroSymbolIcon,
        },
        {
          key: 2,
          resource: 'provider_invoice',
          icon: EuroSymbolIcon,
        },
      ],
    },
  ];

  return addAdditionalResources(
    removeUnavailableResources(defaultMenu, resources),
    resources,
  );
};

export const getLink = (menuNode = {}) => {
  if (menuNode.link) {
    return menuNode.link;
  }
  if (menuNode.params) {
    return `/${menuNode.resource}?${qs.stringify(menuNode.params)}`;
  }
  if (menuNode.resource) {
    return `/${menuNode.resource}`;
  }
  return undefined;
};

export const getLabel = (menuNode = {}) => {
  if (menuNode.label) {
    return menuNode.label;
  }
  if (menuNode.resource) {
    return `resources.${menuNode.resource}.name`;
  }
  return menuNode.subMenu ? 'menu.default.menu' : 'menu.default.item';
};

export const getIcon = (menuNode = {}) => {
  return menuNode.icon ? <menuNode.icon /> : <LabelIcon />;
};

const MenuNode = ({ treeNode, onMenuClick, sidebarOpen, translate }) => {
  const [state, setState] = useState({});
  const handleToggle = /* istanbul ignore next */ (nodeMenuKey) => {
    setState({ ...state, [nodeMenuKey]: !state[nodeMenuKey] });
  };

  const OpenMenu = (key, etat) => {
    if ((key === 0 || key === 2) && (etat[key] === false || !etat[key])) {
      return true;
    }
    if ((key === 0 || key === 2) && (etat[key] === true || !etat[key])) {
      return false;
    }

    return !!etat[key];
  };

  return (
    <Fragment>
      {treeNode.map((item) => {
        if (!item.subMenu) {
          return (
            <>
              <MenuItemLink
                key={item.key}
                to={getLink(item)}
                primaryText={translate(getLabel(item), {
                  smart_count: 2,
                })}
                onClick={onMenuClick}
                leftIcon={getIcon(item)}
              />
            </>
          );
        }
        return (
          <SubMenu
            key={item.key}
            handleToggle={
              /* istanbul ignore next */ () => {
                handleToggle(item.key);
              }
            }
            isOpen={OpenMenu(item.key, state)}
            sidebarIsOpen={sidebarOpen}
            name={translate(getLabel(item), {
              smart_count: 2,
            })}
            icon={getIcon(item)}
          >
            <MenuNode treeNode={item.subMenu} translate={translate} />
            {/* <a
              className="MuiButtonBase-root MuiListItem-root MuiMenuItem-root RaMenuItemLink-root-185 MuiMenuItem-gutters MuiListItem-dense MuiMenuItem-dense MuiListItem-gutters MuiListItem-button"
              tabIndex="-1"
              role="menuitem"
              aria-disabled="false"
              href="#/affectation_manager/create"
              title="Courriers Cleaners"
            >
              <div className="MuiListItemIcon-root RaMenuItemLink-icon-187">
                <svg
                  className="MuiSvgIcon-root"
                  focusable="false"
                  viewBox="0 0 24 24"
                  role="img"
                >
                  <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm0 4c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H6v-1.4c0-2 4-3.1 6-3.1s6 1.1 6 3.1V19z"></path>
                  <title>Affectations Manager</title>
                </svg>
              </div>
              Affectations Manager
              <span className="MuiTouchRipple-root"></span>
            </a> */}
          </SubMenu>
        );
      })}
    </Fragment>
  );
};

MenuNode.propTypes = {
  onMenuClick: PropTypes.func,
  sidebarOpen: PropTypes.bool,
  translate: PropTypes.func,
  treeNode: PropTypes.arrayOf(PropTypes.object),
};

MenuNode.defaultProps = {
  onMenuClick: /* istanbul ignore next */ () => {},
  sidebarOpen: true,
  translate: /* istanbul ignore next */ () => {},
  treeNode: [],
};

const Menu = ({ onMenuClick, resources, translate, sidebarOpen }) => {
  return (
    <>
      <MenuNode
        treeNode={getMenuDescription(resources)}
        onMenuClick={onMenuClick}
        sidebarOpen={sidebarOpen}
        translate={translate}
      />
    </>
  );
};

Menu.propTypes = {
  onMenuClick: PropTypes.func,
  resources: PropTypes.array,
  sidebarOpen: PropTypes.bool,
  translate: PropTypes.func,
};

Menu.defaultProps = {
  onMenuClick: /* istanbul ignore next */ () => {},
  resources: [],
  sidebarOpen: true,
  translate: /* istanbul ignore next */ () => {},
};

const mapStateToProps = (state) => {
  return {
    resources: getResources(state),
    sidebarOpen: state.admin.ui.sidebarOpen,
    theme: state.theme,
  };
};

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {}),
  translateComponent,
);

export default enhance(Menu);
