import React from 'react';
import {
  Datagrid,
  DateField,
  NumberField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';

export default (props) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: 'date', order: 'DESC' }}
    >
      <Datagrid>
        <DateField source="date" textAlign="center" />
        <ReferenceField
          source="account_id"
          reference="account"
          textAlign="center"
          link="show"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="recap" label="Récapitulatif" emptyText="∅" />
        <TextField source="stagename" label="Stage Name" emptyText="∅" />
        <NumberField source="amount" emptyText="∅" textAlign="center" />
      </Datagrid>
    </List>
  );
};
