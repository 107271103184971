import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'react-final-form';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    minWidth: 256,
    paddingTop: 14,
    paddingBottom: 6,
    paddingLeft: 12,
  },
  input: {},
});

const SwitchedInput = ({
  children,
  classes: classesOverride,
  inputDefaultValue,
  label,
  record,
  source,
  ...props
}) => {
  const classes = useStyles({ classes: classesOverride });
  const [checked, setChecked] = useState(!!record[source]);
  const {
    input: { onChange },
  } = useField(source);

  const handleChange = (event) => {
    if (!event.target.checked) {
      onChange(null);
    } else if (!record[source]) {
      onChange(inputDefaultValue);
    }
    setChecked(event.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        control={<Switch checked={checked} onChange={handleChange} />}
        label={label}
        className={classes.root}
      />
      {checked &&
        Children.map(children, (child) => {
          return cloneElement(child, {
            ...props,
            className: classes.input,
            source,
            onChange: (event) => {
              onChange(event.target.value);
            },
          });
        })}
    </div>
  );
};

SwitchedInput.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  inputDefaultValue: PropTypes.any,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

SwitchedInput.defaultProps = {
  classes: {},
  inputDefaultValue: null,
  label: '',
  record: {},
};

export default SwitchedInput;
