import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_END, FETCH_ERROR, FETCH_START } from 'react-admin';
import fetchApi from './helpers/fetchApi';

import { SET_LEAVE_SITE_CLEANERS, GET_LEAVE_SITE_CLEANERS } from '../action';

export function* getLeaveSiteCleaners({ payload }) {
  try {
    yield put({ type: FETCH_START });
    const affectations = yield call(fetchApi, {
      url: `/api/affectation?contract_id=eq.${payload.contractId}&type=eq.Permanent&active=eq.true&order=id.desc`,
      method: 'GET',
    });
    yield put({ type: FETCH_END });
    yield put({ type: SET_LEAVE_SITE_CLEANERS, affectations });
  } catch (err) {
    yield put({ type: FETCH_ERROR });
  }
}

export default function* watch() {
  yield takeLatest(GET_LEAVE_SITE_CLEANERS, getLeaveSiteCleaners);
}
