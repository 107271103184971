import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core';

import ListAffectation from './ListAffectation';
import ListProduct from './ListProduct';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      marginTop: theme.spacing(2),
    },
  };
});

const PonctualDetails = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <>
      <ListAffectation
        {...props}
        title={translate('resources.account.card_title.affectation')}
      />
      <ListProduct
        {...props}
        title={translate('resources.account.card_title.product')}
        className={classes.list}
      />
    </>
  );
};

export default PonctualDetails;
