import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { GridCard } from '../../../../components';

import ListServiceCertificate from './ListServiceCertificate';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
});

const ServiceCertificateCard = (props) => {
  const classes = useStyles();

  return (
    <GridCard
      {...sanitizeRestProps(props)}
      classes={classes}
      xs={12}
      title="Attestation Ponctuel"
    >
      <ListServiceCertificate xs={12} />
    </GridCard>
  );
};

export default ServiceCertificateCard;
