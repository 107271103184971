import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { translate as translateComponent } from 'react-admin';
import { getUser } from '../../../../user';

const skillsMatrix = {
  Cleaner: [
    'Qualité de la prestation',
    'Relationnel client',
    'Savoir-être (ponctualité, assiduité, relations entre collègues...)',
   'Proactivité dans la communication avec le Manager'
  ],
  Handyman: [
    'Qualité de la prestation',
    'Relationnel client',
    'Savoir-être (ponctualité, assiduité, relations entre collègues...)',
   'Proactivité dans la communication avec le Manager'
  ],
  'Team Leader': [
    'Qualité de la prestation',
    'Relationnel client',
    'Savoir-être (ponctualité, assiduité, relations entre collègues...)',
   'Proactivité dans la communication avec le Manager'
  ],
};

const skillsQueryString = (record) => {
  return skillsMatrix[record.function].reduce((acc, competence, index) => {
    return `${acc}&competence${index + 1}=${encodeURIComponent(competence)}`;
  }, '&');
};

const EntretienEvaluationButton = ({ className, record, translate }) => {
  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          const formUrl = 'https://forms.cleany.fr/entretien-evaluation?';
          const skillMatrixPopulated = Array.from(
            Object.keys(skillsMatrix),
          ).includes(record.function);
          const translatedPosition = translate(
            `resources.cleaner.function.${record.function
              .toLowerCase()
              .replace(/\s/, '_')}`,
          );

          return (
            <MenuItem
              className={className}
              variant="contained"
              disabled={!skillMatrixPopulated}
              onClick={() => {
                return skillMatrixPopulated
                  ? window.open(
                      `${formUrl}fullname=${record.fullname}` +
                        `&function=${translatedPosition}` +
                        `&seniorityDate=${new Date(
                          record.seniority_date,
                        ).toLocaleDateString('fr-FR')}` +
                        `&managerFullname=${user.fullname}` +
                        `&interviewDate=${new Date().toLocaleDateString(
                          'fr-FR',
                        )}${skillsQueryString(record)}`+
                        `&cleanerEmail=${record.email}` +
                        `&managerEmail=${record.manager_email}`,
                    )
                  : alert(
                      `Désolé, la matrice de compétences pour le poste ` +
                        `"${translatedPosition}" n'est pas encore intégrée.` +
                        `Veuillez vous rapprocher des RH pour` +
                        `conduire cet entretien au format papier.`,
                    );
              }}
            >
              <ListItemText
                primary={`Faire un entretien d'évaluation (${translatedPosition})`}
              />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

EntretienEvaluationButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  translate: PropTypes.func,
};

EntretienEvaluationButton.defaultProps = {
  className: undefined,
  record: undefined,
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(EntretienEvaluationButton);
