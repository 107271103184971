import React from 'react';
import {
  Datagrid,
  DateField,
  TextField,
  ReferenceManyField,
  ReferenceField,
  SelectField,
} from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Pagination, URLParamField } from '../../../components';

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
    width: '100%',
  },
});

const ListEntretienPro = (props) => {
  const classes = useStyles();
  return (
    <ReferenceManyField
      {...props}
      classes={classes}
      reference="entretien_professionnel"
      target="cleaner_id"
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      <Datagrid>
        <DateField source="date_entretien" label="Date" textAlign="center" />
        <ReferenceField
          source="manager_id"
          reference="cleaner"
          textAlign="center"
          label="Réaliser par"
        >
          <TextField source="fullname" label="Réalisé par" />
        </ReferenceField>
        <SelectField
          source="nature_entretien"
          choices={[
            { id: 'periodique', name: 'Entretien Périodique (tous les 2 ans)' },
            { id: 'absence', name: 'Entretien après une longue absence' },
          ]}
        />
        <URLParamField
          source="document"
          url="https://drive.google.com/file/d/{{document}}"
          label="Résumé Drive"
          text="📁"
          variant="body2"
          emptyText="∅"
        />
      </Datagrid>
    </ReferenceManyField>
  );
};

export default ListEntretienPro;
