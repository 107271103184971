import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';
import {
  FETCH_ACCOUNTS_INVOICES,
  FETCH_ERROR_ACCOUNTS_INVOICES,
  UPLOAD_PROVIDER_INVOICE,
  SET_ACCOUNTS_INVOICES,
  SET_INVOICE_UPLOAD_ERRORS,
} from '../action';

const readFileAsync = /* istanbul ignore next */ (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  });
};

export const sendProviderInvoice = async (payload) => {
  const files = await Promise.all(
    payload.data.map(async (file) => {
      return { data: await readFileAsync(file.rawFile) };
    }),
  );

  const params = {
    files,
    providerAccountId: payload.providerAccountId,
    amortization: payload.amortization,
  };
  const response = await fetch('/providerInvoice/upload', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'POST',
    body: JSON.stringify(params),
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }

  const result = await response.json();

  if (response.status === 206) {
    return { errors: result };
  }

  return { accountsInvoices: result };
};

export function* uploadProviderInvoice({ payload }) {
  try {
    yield put({ type: FETCH_START });
    yield put({ type: FETCH_ACCOUNTS_INVOICES });
    const result = yield call(sendProviderInvoice, payload);
    yield put({ type: FETCH_END });
    if (result.accountsInvoices) {
      const accountsInvoices = result.accountsInvoices.length
        ? result.accountsInvoices.flat().reduce((acc, contractInvoice) => {
            return {
              ...acc,
              [contractInvoice.contract_id +
              contractInvoice.invoice_number +
              contractInvoice.external_id]: {
                ...contractInvoice,
                id:
                  contractInvoice.contract_id +
                  contractInvoice.invoice_number +
                  contractInvoice.external_id,
              },
            };
          }, {})
        : {};
      yield put({
        type: SET_ACCOUNTS_INVOICES,
        payload: { accountsInvoices },
      });
    }
    if (result.errors) {
      yield put({
        type: SET_INVOICE_UPLOAD_ERRORS,
        payload: { uploadErrors: result.errors },
      });
    }
  } catch (err) {
    yield put({ type: FETCH_ERROR_ACCOUNTS_INVOICES });
    yield put({ type: FETCH_ERROR });
    yield put(showNotification('notification.upload_invoice.fail', 'warning'));
  }
}

export default function* watch() {
  yield takeLatest(UPLOAD_PROVIDER_INVOICE, uploadProviderInvoice);
}
