import React from 'react';
import {
  SelectInput,
  Filter,
} from 'react-admin';
import { AccountAutocompleteInput } from '../../../components';

const InvoiceFilter = ({ ...props }) => {
  return (
    <Filter {...props}>
      <AccountAutocompleteInput alwaysOn allowEmpty />
      <SelectInput
        alwaysOn
        allowEmpty
        emptyText="ra.action.clear_input_value"
        source="status"
        choices={[
          {
            id: 'CANCELLED',
            name: 'ANNULÉ',
          },
          {
            id: 'CLOSED',
            name: 'PAYÉE',
          },
          {
            id: 'OPEN',
            name: 'EN COURS',
          },
          {
            id: 'INCOMPLET',
            name: 'INCOMPLET',
          },
        ]}
      />
    </Filter>
  );
};

export default InvoiceFilter;
