export default (value) => {
  if (!value) {
    return undefined;
  }

  const hour = parseInt(value.split(':')[0], 10);
  if (hour < 10 || hour > 17) {
    return 'resources.leave.forms.half_day_validation';
  }
  return undefined;
};
