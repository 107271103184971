export default {
  menu: {
    action: {
      my_actions: 'My pending actions',
    },
    affectation: {
      activeReplacement: 'Remplacemnt',
    },
    cleaner: {
      all: 'All',
      new: 'Applicant',
      docs: 'Documents',
      intercontract: 'Intercontract',
    },
    default: {
      other: 'Others',
      item: 'Item',
      menu: 'Menu',
    },
  },
  resources: {
    availability: {
      name: 'Availability |||| Availabilities',
      fields: {},
    },
    client_followup: {
      name: 'Customer Followup',
      fields: {},
    },
    opportunity: {
      name: 'Opportunities',
      fields: {},
    },
    undefined: {
      fields: {
        planning: 'Planning',
      },
    },
    client_document: {
      dialog: {
        title: 'Upload a document',
        button: '♻️ Upload',
      },
      create: '♻️ Upload',
      name: 'Customer Documents',
      fields: {
        cleaner_id: 'Cleaner',
        comment: 'Comment',
        status: 'Status',
        subtype: 'Type of document',
        type: 'Type',
        upload_date: 'Upload date',
        provider: 'Provider',
      },
      subtype: {
        complaint_letter: 'Complaint letter',
        other: 'Others correspondences',
        ponctual_contract: 'Ponctual contract',
        recurrent_contract: 'Recurrent contract',
        plan_prevention: 'Prevention plan',
        endorsement: 'Contract endorsement',
        provider_contract: 'Provider contract',
        resignation_letter: 'Resignation letter',
        suspension_request: 'Suspension service request',
        purchase_order: 'Purchase order',
      },
      type: {
        contract_end: 'End of contract',
        provider: 'Provider',
        contract: 'Contract',
        purchase_order: 'Purchase order',
      },
    },
    mission_report: {
      name: 'Mission report |||| Mission reports',
      fields: {},
    },
    service_certificate: {
      name: 'Service Certificate',
    },
    preaffectation: {
      name: 'Pre-affectations ',
    },
    formation: {
      name: 'Formation',
    },
    account: {
      name: 'Account |||| Accounts',
      card_title: {
        access: 'Access',
        affectation: 'Affectations',
        billing: 'Invoicing',
        billing_info: 'Invoicing information',
        consumable: 'Consumables',
        contact: 'Contact',
        contract: 'Contract',
        details: 'Details',
        equipment: 'Equipments',
        glazing: 'Glazing',
        gocardless_payment: ' Withdrawals',
        infos: 'Infos',
        invoice: 'Invoice',
        invoice_incubator: 'Pre-invoice',
        leave: 'Absence',
        leave_affectation: 'Absence affectation',
        ponctual_contract: 'Contracts ponctuels',
        product: 'Product',
        recurring_contract: 'Recurrent contract',
        recurring_contract_affectation: 'Recurrent contract affectation',
        replacement: 'Remplacements',
        satisfaction: 'Satisfaction',
        site: 'Site',
        specification: 'Specification',
      },
      fields: {
        access: 'Access',
        bill_with_parent: 'Billing with parent account ?',
        billing_address: 'Billing Address',
        billing_city: 'City',
        billing_country: 'Country',
        billing_data: 'Billing data',
        billing_email: 'Billing email',
        billing_postal_code: 'Postal code',
        billing_street: 'Street',
        building_areas: 'Building area',
        created_date: 'Creation date',
        date_reprise_partielle: 'Date of partial resumption',
        date_reprise_totale: 'Date of total resumption',
        description: 'Description',
        drive_folder: 'Drive',
        duplicate_access: 'Duplicate of access',
        end_date: 'End date',
        garbage_location: 'Garbage local location',
        nombre_sous_traitant: 'Number of subcontractor effective on the site ',
        chef_equipe: 'Team leader',
        gcl_customer_id: 'Id customer GoCardLess',
        gcl_mandate_id: 'Id mandate GoCardLess',
        has_recurring_contract: 'Recurring contrat active',
        industry: 'Industry',
        last_modified_date: 'Last change',
        last_survey_date: 'Last Survey',
        last_survey_rating: 'Last Survey Rating',
        leave: 'Absence',
        manager_email: 'Manager email',
        manager_fullname: 'Manager',
        manager_id: 'Manager',
        manager_mobile_phone: 'Manager mobile',
        manager_phone: 'Manager phone',
        name: 'Name',
        number_of_employees: 'Numbers of employees',
        operational_email: 'Operational email',
        operational_fullname: 'Operational',
        operational_id: 'Operational',
        operational_mobile_phone: 'Operational mobile',
        operational_phone: 'Operational phone',
        parent_id: 'Parent account',
        payment_method: 'Payment method',
        ponctual: 'Ponctual',
        post_start_appreciation: 'Post start appreciation',
        product: 'Products',
        quality_controls_evolution: 'Satisfaction evolution',
        quality_controls_mean: 'Satisfaction standard',
        permanent: 'Permanent',
        recurring_contract_affectations_ok: 'Affectations ok ?',
        recurring_contract_amount: 'Contract amount',
        recurring_contract_cleaning_amount_per_month: 'Amount cleaning / month',
        recurring_contract_cleaning_duration_per_week: 'Amount cleaning / week',
        recurring_contract_consumables_amount_per_month: 'Amount consumables',
        recurring_contract_consumables_ok: 'Consumables ok ?',
        recurring_contract_consumables_quantity_per_month:
          'Quantity consumables',
        recurring_contract_cost: 'Cost',
        recurring_contract_margin: 'Margin',
        recurring_contract_percent_margin: 'Margin %',
        recurring_contract_permanent_affectation_duration_per_week:
          'Hours affected / week',
        recurring_contract_start_date: 'Start date',
        recurring_contract_end_date: 'End date',
        replacement: 'Remplacement',
        salesman_email: 'Salesman email',
        salesman_fullname: 'Salesman',
        salesman_id: 'Salesman',
        salesman_mobile_phone: 'Salesman mobile',
        salesman_phone: 'Salesman phone',
        shipping_address: 'Shipping address',
        shipping_city: 'City',
        shipping_postal_code: 'Postal code',
        shipping_street: 'Street',
        sold_vs_done: 'Sold / Affected',
        specification: 'Specification',
        start_date: 'Start date',
        storage_locker_location: 'Location maintenance local',
        surface: 'Surface',
        tags: 'Qualifications',
        total_amount: 'Total amount',
        transit_stop: 'Public transports',
        trigram: 'Trigram',
        type: 'Type',
        website: 'Website',
      },
      edit: {
        title: '✏️ Edit',
        areasModel: 'Return to the line at each field added',
        areaSample: 'Building',
      },
      error: {
        too_much_contracts:
          'The account in %{contract_number} Recurrent contracts actives',
      },
      tab: {
        actifs: 'Actives',
        actives: 'Actives',
        history: 'History',
      },
      tabs: {
        cleaning: 'Cleaning',
        consumable: 'Consumable',
        glazing: 'Glazing',
        invoice: 'Invoice',
        miscellanous: 'Miscellanous',
        ponctual: 'Ponctuals',
      },
      values: {
        post_start_appreciation: {
          UNDERESTIMATED: 'Underestimated',
          WELL_ESTIMATED: 'Well estimated',
          PREMIUM: 'Premium',
          null: 'Undefined',
        },
      },
    },
    dashboard: {
      name: 'Dashboard',
      fields: {
        informations: 'Your informations',
        number_of_employees: 'Numbers of employees',
        access: 'Access',
        surface: 'Surface',
        billing_email: 'Billing contact',
        payment_method: 'Payment method',
      },
      responsibleCard: {
        contact: 'For all questions, contact your site manager',
        responsible: 'People in charge',
      },
    },
    affectation: {
      name: 'Affectation |||| Affectations',
      card_title: {
        schedule: 'Schedule',
        site: 'Site',
        info: 'Infos',
        other: 'Other',
      },
      fields: {
        absence_type: 'Absence type',
        account_name: 'Account',
        active: 'Active ?',
        catalog_item_id: 'Catalog item',
        cleaner_fullname: 'Cleaner',
        cleaner_id: 'Cleaner',
        cleaner_ids: 'Cleaners',
        contract_id: 'Contract',
        dates: 'Dates',
        duration_per_week: 'Hebdo',
        duration: 'Duration',
        end_date: 'End date',
        end_time: 'End',
        fullname: 'Name',
        leave_id: 'Absence related',
        limit_start_date: 'Limit starting date',
        manager_fullname: 'Manager',
        manager_id: 'Manager',
        name: 'Nom',
        planning: 'Planning',
        prestation_type: 'Service type',
        punctual_reason: 'Reason for ponctual affectation',
        related_affectation_id: 'Related affectation',
        replacement_table: 'Replacement affectations',
        schedule: 'Schedule',
        search: 'Search',
        site_address: 'Site',
        start_date: 'Start date',
        start_time: 'Start time',
        tags: 'Qualifications',
        transit_stop: 'Public transports',
        type: 'Type',
      },
      cards: {
        titles: {
          absence_details: 'Absence details',
          details: 'Details',
          replacement_details: 'Replacement details',
          site: 'Site',
        },
      },
      filters: {
        active_contract: 'Active contract',
        date: 'Date (active it)',
      },
      forms: {
        contract_cleaner_info:
          'Informations about the contract and the cleaner',
        cleaner: {
          missing_recurring_hours: 'Missing hours of the cleaner',
          recurring_work_schedule_per_week: 'Hours done by the cleaner',
        },
        contract: {
          cleaning_duration_per_week: 'Hours sold',
          permanent_affectation_duration_per_week: ' Weekly hours already done',
        },
        duration_per_week: 'Weekly hours for this affectation',
        required: 'This field is required',
        cleaners_validation: 'At least one cleaner have to be selected',
        time_required: 'Timetable required',
        time_validation: "Can't end before starting",
        minutes_validation: 'The minutes have to be multiple of 5',
        planning_validation: 'At the least one day have to be selected',
        type: {
          permanent: 'Permanent',
          replacement: 'Remplacement',
          ponctual: 'Ponctual',
          absence: 'Absence',
          intercontract: 'InterContract',
          partiel: 'Partial maintenance',
          saisonnier: 'Seasonal remplacement',
        },
        prestation_type: {
          Carpet_cleaning: 'Shampooing carpet',
          cleaning: 'Cleaning',
          glazing: 'Glazing',
          handover: 'Handover',
          high_pressure_cleaning: 'High pressure cleaning',
          restoration: 'Restoration',
          permanence: 'Permanence',
        },
      },
      edit: {
        title: {
          assign_affectation: 'Edit cleaner',
          end_affectation: 'Set end date',
        },
      },
      create: {
        title: 'Create an affectation',
      },
      actions: {
        absence: 'Absent',
        assign_affectation: 'Assign',
        detail: 'Details',
        duplicate: 'Duplicate',
        end_affectation: 'End',
        replacement: 'Replacement',
        see_affectations: 'See affectations',
      },
      notify: {
        auto_select_cleaners: 'Send to all available cleaners',
        cleaners:
          'If you wish for the invitations to be sent to specific instead of all available, enter their names here:',
        invite: 'Invite',
        invite_cleaner: 'Invite Cleaners',
        send: 'Send',
        submit_with_cleaners: 'Send to %{nb_cleaners} cleaners',
        close: 'Close',
      },
      tabs: {
        current: 'Active affectation |||| Active affectations',
        current_short: 'Active',
        history: 'Affectations history',
        history_short: 'History',
      },
      value: {
        active: {
          true: 'Active',
          false: 'Inactive',
        },
      },
    },
    cleaner: {
      name: 'Cleaner |||| Cleaners',
      card_title: {
        affectations: 'Affectations',
        check: 'Check',
        cleaner_contract: 'Contract amendment',
        civil: 'Civil',
        contract: 'Contract',
        document: 'Documents',
        health_care: 'Health care',
        human_ressources: 'Human Ressources',
        infos: 'Infos',
        interactions: 'Interactions',
        leave: 'Leaves',
        letter: 'Letters',
        occupational_medecine: 'Médecine du travail',
        other: 'Other',
        worked_stats: 'Worked time',
      },
      create: {
        contact: 'Information register',
      },
      edit: {
        contact: 'Information update',
        required: 'Required',
      },
      fields: {
        account_name: 'Account',
        address: 'Address',
        birthdate: 'Birthdate',
        birthplace: 'Birthplace',
        calendly: 'Calendly',
        classification: 'Classification',
        cleaner_ids: 'Cleaners',
        consistency_error: 'Consistency error',
        contract_end_date: 'Contract end',
        contract_end_reason: 'Contract end reason',
        contract_end_type: 'Contract end type',
        contract_start_date: 'Contract start',
        contract_type: 'Contract type',
        contract_subtype: 'Contract subtype',
        duree_complement: 'Durée du complément',
        contract_worked_time: 'Contract worked time',
        day_worked_time: 'Day worked time',
        density: 'Density',
        drive_folder: 'Drive',
        email: 'E-mail',
        employee_status: 'Status',
        firstname: 'Firstname',
        full_time: 'Full time',
        fullname: 'Name',
        function: 'Function',
        has_missing_recurring_hours: 'Has missing hours',
        health_insurance_exemption: 'Health insurance exemption',
        health_insurance_exemption_end_date:
          'Health insurance exemption_end_date',
        health_insurance_exemption_file: 'Health insurance exemption_file',
        health_insurance_exemption_reason: 'Health insurance exemption reason',
        health_insurance: 'Health insurance',
        hourly_rate: 'Hourly rate',
        intercontract_work_schedule_per_week: 'Total intercontract work',
        is_consistent: 'Consistent',
        is_taken_over: '7th annex takeover',
        is_valid: 'Valid',
        last_modified_date: 'Last modified date',
        last_terms_start_date: 'Last terms',
        lastname: 'Lastname',
        level: 'Level',
        mailing_additional: 'Complement',
        mailing_city: 'City',
        mailing_country: 'Country',
        mailing_postal_code: 'Postal code',
        mailing_street: 'Street',
        manager_fullname: 'Manager',
        manager_id: 'Manager',
        match_legal_weekly_work: '<35H',
        match_max_daily_work: 'H/days',
        match_max_weekly_work: '<44H',
        match_min_daily_rest: 'Daily rest',
        match_min_weekly_rest: 'Weekly rest',
        min_weekly_hours_due: 'Minimal wekkly hours due',
        missing_reccuring_hours: 'Missing hours',
        mobile_phone: 'Mobile',
        nationality: 'Nationality',
        nb_worked_days: 'Worked days',
        night_worked_time: 'Night worked time',
        occupational_medecine: 'Occupational medicine',
        occupational_medecine_date: 'Date occupational medecine',
        occupational_medecine_file: 'Writen proof occupational medecine',
        occupational_medecine_reason: 'Reason occupational medecine',
        payfit_id: 'Payfit id',
        silae_id: 'Silae ID',
        cdd_etudiant: 'CDD étudiant',
        quartier_prioritaire_de_la_ville: 'Priority district from the city',
        phone: 'Phone',
        real_hours_computation: 'Real computation in Payfit',
        recurring_work_schedule_per_week: 'Weekly total',
        search: 'Search',
        seniority_date: 'Seniority date',
        site_address: 'Address',
        social_security_number: 'Social security number',
        residence_permit_number: 'Resident permit number',
        residence_permit_expiry_date: 'Resident permit expiry date',
        residence_permit_nature: 'Resident permit nature',
        spvie_id: 'Id SPVIE',
        status: 'Status',
        sunday_worked_time: 'Sunday worked time',
        tags: 'Qualifications',
        total_worked_time: 'Weekly total',
        transportation_help: 'Transportation help',
        traveltime: 'Traveltime',
        trial_period_renewal_signing: 'Trial period renewal signing',
        trial_period_renewal: 'Trial period renewal',
        type: 'Type',
        vacancy_time: 'Vacancy time',
      },
      form: {
        required: 'Required',
        consistency_level: 'Level %{level} must match %{function}',
        choose_cleaner: 'Please choose a Cleaner',
        empty_for_office: 'Empty for office workers',
        empty_for_team_leader: 'Empty for team leaders',
        min_hourly_rate:
          "Can't be < à %{hourly_rate} for level %{level} - %{classification}",
        max_hourly_rate: "Can't be > %{limit}",
        type: {
          former: 'Former employee',
          current: 'Current employee',
          applicant: 'Applicant',
        },
      },
      function: {
        cleaner: 'Cleaner',
        hotel_staff: 'Hotel Staff',
        event_staff: 'Event Staff',
        team_leader: 'Team leader',
        glazier: 'Glazier',
        handyman: 'Handyman',
        manager: 'Manager',
        customer_service: 'Customer service',
        office_employee: 'Office employee',
      },
      cards: {
        actives: 'Actives',
        history: 'History',
        titles: {
          cleaner_information: 'Cleaner information',
        },
      },
      mission: {
        title: 'Additional contract',
        object:
          'This mission order specifies the work sites and working hours ' +
          'of the employee on each of the sites. It is an annex to ' +
          'the employment contract.',
        cleanerInfo: 'Cleaner contact',
        workingTime: 'Permanent weekly working time: %{duration} hours',
        done: 'Done at %{city}, the %{date}',
        cleanerSign: 'The employee',
        cleanySign: 'Alexandre Bellity - Chief Executive',
        legal:
          'IMMONET is a limited company registered under French law to the ' +
          'RCS of Bobigny under the number 539 593 319 00053, whose ' +
          "registered office is at 6, rue de l'hippodrome, 93400 Saint-Ouen",
      },
      actions: {
        createLeave: 'Create a leave',
        download: 'Download',
        download_certificat: 'Work certificat',
        download_dismissalConvocation: '🔔 Dismissal convocation',
        download_endOfTrialPeriod: 'End of the trial period',
        download_trialPeriodRenewal: 'Trial period renewal',
        initDrive: 'Initialize Drive folder',
        interaction: 'Interaction',
        lrar: 'Send letter',
      },
      modal: {
        title: "Download Cleaners' hours for one month",
        download: 'Download',
        download_csv: 'Download hours',
        close: 'Close',
        choose_period: 'Choose a day from the month to compute',
        choose_date: 'Choose the date',
        choose_time: 'Choose the time',
      },
      tabs: {
        affectations: 'Affectations',
        timeline: 'Timeline',
        miscellanous: 'Miscellanous',
        documents: 'Documents',
      },
      timeline: {
        no_timeline: 'There is nothing to display yet',
      },
    },
    cleaner_contract: {
      name: 'Contract update |||| Contract updates',
      actions: {
        sign: 'Sign',
        cancelProcedure: 'Cancel',
      },
      aside: {
        title: 'Details',
        no_value: 'Please choose a Cleaner',
      },
      forms: {
        required: 'Required',
      },
      fields: {
        cleaner_id: 'Cleaner',
        classification: 'Classification',
        start_date: 'Start date',
        contract_type: 'Contract type',
        contract_subtype: 'Contract Subtype',
        duree_complement: 'Durée du complément',
        full_time: 'Full time',
        hourly_rate: 'Hourly rate',
        level: 'Level',
        contract_worked_time: 'Worked time',
        comment: 'Comment',
        detail: 'Contract details',
        procedure: 'Yousign procedure',
        procedure_status: {
          active: 'Active',
          draft: 'Draft',
          expired: 'Expired',
          finished: 'Finished',
          refused: 'Refused',
          unknown: 'Unknown',
        },
      },
    },
    cleaner_document: {
      dialog: {
        title: 'Upload a document',
        button: '♻️ Upload',
      },
      create: '♻️ Upload',
      name: 'Cleaner document |||| Cleaner documents',
      fields: {
        cleaner_id: 'Cleaner',
        comment: 'Comment',
        status: 'Status',
        subtype: 'Document type',
        type: 'Type',
        upload_date: 'Upload date',
        validation_date: 'Validation date',
        validator_id: 'Validator',
      },
      status: {
        APPROVED: 'Approved',
        PENDING: 'Pending',
        REJECTED: 'Rejected',
      },
      subtype: {
        acs_attestation: 'ACS attestation',
        advance_payment: 'Advance payment',
        avenant: 'Amendment to the contract',
        babysitting_attestation: 'Childcare certificate',
        contrat: 'Contract',
        birth_certificat: 'Birth certificat',
        cmu_attestation: 'CMU attestation',
        image: 'Image right certificate',
        depot_dossier_prefecture: 'Received prefecture folder',
        final_settlement: 'Received for final settlement',
        reconnaissance_travailleur_handicape: 'Disabled workers',
        family_book: 'Family book',
        reconnaissance_AT_MP: 'Reconnaissance AT-MP',
        home_receipt: 'Home attestation',
        host_attestation: 'Host attestation',
        host_id_card: 'Host id card',
        id_card_back: 'Id back',
        id_card_front: 'Id front',
        insurance_attestation: 'Health insurance attestation',
        insurance_refusal: 'Insurance refusal',
        insurance_subscription: 'Insurance subscription',
        bulletin_repris: 'Bulletin repris',
        invalidity_attestation: 'Invalidity attestation',
        attestation_etudiante: 'Attestation Etudiante',
        keys_hand_over: 'Key hand over',
        maternity_leave_cerfa: 'Maternity leave CERFA',
        maternity_leave_proof: 'Maternity leave proof',
        death_certificat: 'Death certificat',
        work_autorisation: 'Work autorisation',
        maternity_leave_request: 'Maternity leave request',
        pass: 'Navigo pass',
        passport: 'Passport',
        receipt: 'Receipt',
        resignation_letter: 'Resignation letter',
        rib: 'RIB',
        sick_leave: 'Sick leave',
        trial_end: 'Trial end',
        trial_renewal: 'Trial renewal',
        visa_receipt: 'Visa receipt',
        visa_recto: 'Visa front',
        visa_verso: 'Visa back',
        vitale_attestation: 'Security social card',
        visa_authentification: 'Authentification Title of Stay Prefecture',
        autre_absence: "Autre justificatif d'absence",
        vitale_card: 'Vitale card',
        waiver_letter: 'Waiver letter',
        work_injury: 'Work injury',
        // babysitting_attestation: 'Babysitting attestation',
        occupational_medecine_attestation: 'Occupational Medecine Attestation',
        occupational_medecine_convocation: 'Occupational medecine convocation',
        avertissement: 'Warning',
        AR_demission: 'Accusé réception de démission',
        EPAL: 'interview prior to dismissal',
        prefecture: 'Prefecture convocation',
        accuse_due: 'accuse DUE',
        medical_visit: 'Medical examination convocation',
        DPAE: 'DPAE',
        MED: 'Formal notice',
        notice_of_dismissal: 'Notice of dismissal',
        TAKEN_OVER: 'Taken over transfert',
        contract_transfer: 'Contract transfer',
        going_retirement: 'Leaving for Retirement',
      },
      type: {
        advance_payment: 'Advance',
        bank: 'Bank',
        contract: 'Contract',
        health_care: 'Health',
        home: 'Domcile',
        identity: 'Identity',
        key_retrieval: 'Key',
        leave: 'Leave certificat',
        occupational_medecine: 'Occupational medecine',
        lrar: 'Registered mail',
        transportation: 'Transportation',
      },
    },
    cleaner_letter: {
      name: 'Courrier Cleaner |||| Courriers Cleaners',
      fields: {
        cleaner_id: 'Cleaner',
        created_date: 'Date de création',
        description: 'Description',
        distributed_date: 'Date de distribution',
        extrernal_id: 'ID Seeuletter',
        first_distributed_date: 'Première date de distribution',
        owner_id: 'Envoyé par',
        postage_type: "Type d'envoi",
        sent_date: "Date d'envoi",
        status: 'Status',
        subject: 'Sujet',
        tracking_number: 'Numéro de suivi',
        type: 'Type',
        updated_date: 'Date de mise à jour',
      },
      values: {
        status: {
          ACCEPTED:
            "Cette lettre a été acceptée par le système d'impression et d'expédition.",
          CANCELED: 'Cette lettre a été annulée.',
          CREATED: 'Cette lettre a été créée.',
          DELIVERY_PROOF: 'Cette lettre a reçu une preuve de livraison.',
          DISTRIBUTED: 'Cette lettre a été distribuée.',
          ERROR: "Cette lettre est dans un état d'erreur.",
          FILLING_PROOF: 'Cette lettre a reçu une preuve de dépôt.',
          IN_TRANSIT: 'Cette lettre est en transit.',
          RETURNED_TO_SENDER: "Cette lettre a été retournée à l'expéditeur.",
          SENT: 'Cette lettre a été envoyée.',
          WAITING_TO_BE_WITHDRAWN:
            "Cette lettre est en attente d'être retirée par le destinataire.",
          WRONG_ADDRESS:
            'Cette lettre a reçu un événement "N\'habite pas à l\'adresse indiqué" de La Poste.',
        },
      },
    },
    client: {
      name: 'Client |||| Clients',
      fields: {
        account_id: 'Account',
        email: 'E-mail',
        firstname: 'Firstname',
        function: 'Function',
        lastname: 'Lastname',
        mobile_phone: 'Mobile',
        name: 'Name',
        phone: 'Phone',
      },
    },
    contract: {
      name: 'Contrat |||| Contrats',
      fields: {
        access: 'Access',
        account_id: 'Account',
        account_name: 'Account',
        consumables_delivery: 'Consumables',
        salesman_phone: 'Salesman',
        salesman_fullname: 'Salesman',
        billing_email: 'Billing',
        effective_date: 'Start',
        end_date: 'End',
        manager_id: 'Manager',
        manager_fullname: 'Manager',
        name: 'Name',
        operational_id: 'Day to day',
        ordernumber: 'Contract Cleany Number',
        phase: 'Phase',
        commercial_id: 'Buyer',
        search: 'Search',
        site_address: 'Address',
        site_type: 'Site type',
        status: 'Status',
        total_amount: 'Total amount',
        type: 'Type',
        importance: 'Importance',
        permanent_affectation_duration_per_week: 'Hours done',
        cleaning_duration_per_week: 'Hours sold',
      },
      importance: {
        standard: 'Standard',
        high: 'Priority',
      },
      tabs: {
        detail: 'Detail',
        affectation: 'Cleaner',
        specification: 'Specification',
        cleaning: 'Cleaning',
        glazing: 'Glazing',
      },
      edit: {
        title: 'Edit access',
      },
      button: {
        notification: 'Notify manager',
        invoice: 'Invoice',
        pre_computed_invoice: 'Pre-invoice',
      },
      notification: {
        mail_manager: {
          success: 'Mail sent to the manager',
          fail:
            'An error occured : no affecetation for this contract or internal server error',
        },
      },
    },
    contract_client: {
      name: 'Contract |||| Contracts',
      title: 'Contracts',
      fields: {
        billing: 'Billing',
        manager: 'Manager',
        operation: 'Operation',
        salesman: 'Salesman',
      },
      cards: {
        title_permanent: 'Permanent contract',
        title_ponctual: 'Ponctual contract',
        client: 'Client contact',
        cleany: 'Cleany contact',
        product: 'Products details',
      },
      actions: {
        leave: 'Leave',
        leaveForThisContract: 'Leave for this contract',
      },
      specifications: 'Specifications',
      see_specifications: 'See the specifications',
    },
    genepi: {
      name: 'Available cleaners',
      fields: {
        density: 'Density',
        total_worked_time: 'Weekly total',
        vacancy_time: 'Vacancy time',
        traveltime: 'Travel time',
      },
    },
    gocardless_payment: {
      name: 'Direct debit |||| Direct debits',
      fields: {
        amount: 'Amount',
        charge_date: 'Charge date',
        description: 'Description',
        mandate_id: 'Mandate id',
        status: 'Status',
      },
    },
    invoice: {
      name: 'Invoice |||| Invoices',
      last_invoice: 'Last invoice',
      see_all_invoices: 'See all invoices',
      detail_invoice: "Invoice's detail",
      no_invoice: 'No invoice',
      table_fields: {
        products: "Product's name",
        quantity: "Product's quantity",
        price: "Product's price",
      },
      fields: {
        account_name: 'Name',
        account_id: 'Account',
        billing_address: 'Billing address',
        frequency: 'frequency',
        invoice_number: 'N°',
        due_date: 'Date',
        cleaning_amount: 'Cleaning',
        glazzing_amount: 'Glazzing',
        consumable_amount: 'Supply',
        bin_amount: 'Miscellanous',
        other_amount: 'Other',
        amount: 'Total HT',
        total_tax: 'VAT',
        total_amount: 'Total TTC',
        status: 'Status',
        search: 'Search',
      },
      values: {
        status: {
          OPEN: 'To pay',
          CLOSED: 'Paid',
          CANCELLED: 'Cancelled',
          null: 'Not specified',
        },
      },
    },
    management: {
      name: 'Management',
      fields: {
        manager_fullname: 'Manager',
        manager_phone: 'Phone',
      },
    },
    ticket: {
      name: 'Ticket |||| Tickets',
      fields: {
        assignee_id: 'Owner',
        created_at: 'Creation',
        description: 'Description',
        organization: 'Organization',
        organization_id: 'Account',
        priority: 'Priority',
        requester_id: 'Requester',
        status: 'Status',
        subject: 'Subject',
        updated_at: 'Update',
      },
    },
    cleaner_leave: {
      name: 'Leave |||| Leaves',
      fields: {
        cleaner_id: 'Cleaner',
        contract_id: 'Contract',
        end_date: 'End date',
        processed: 'Processed',
        search: 'Search',
        start_date: 'Start date',
      },
      tabs: {
        current: 'Active leave |||| Active leaves',
        current_short: 'Active',
        history: 'Leaves history',
        history_short: 'History',
      },
    },
    leave: {
      name: 'Leave |||| Leaves',
      simple_name: 'Leave',
      actions: {
        create_replacement_all: 'Replace all',
        create_replacement_distribute: 'Distribute',
      },
      aside: {
        title: 'Affectations',
      },
      fields: {
        cleaner_id: 'Cleaner',
        cleaner_fullname: 'Cleaner',
        comment: 'Comment',
        contract_id: 'Contract',
        end_date: 'End date',
        half_day: 'Half day',
        half_start_time: 'Afternoon beginning time',
        half_end_time: 'Morning end time',
        leaves: 'Leaves',
        manager: 'Manager',
        no_affectation: 'No affectation',
        no_replacement: 'No replacement',
        processed: 'Processed',
        replacements: 'Replacements',
        search: 'Search',
        start_date: 'Start date',
      },
      forms: {
        required: 'Required',
        days_validation: 'At least one day should be selected',
        half_day_validation: 'The chosen time is not close to the day half',
        all: 'All',
      },
      create: {
        title: 'Create a leave',
      },
    },
    replacement: {
      name: 'Replacement |||| Replacements',
      simple_name: 'Replacement',
      fields: {
        end_date: 'End date',
        start_date: 'Start date',
        cleaner_id: 'Cleaner',
        cleaner_ids: 'Cleaners',
        affectations_to_replace:
          'Affectations to replace for this leave. To replace all affectations, leave all the box unchecked.',
        leave_dates: 'Leave dates',
        replace: 'Replace',
      },
      create: {
        title: 'New replacement',
        buttons: {
          details: 'Details',
        },
        aside: {
          title: 'Details',
        },
      },
    },
    scheduler: {
      name: 'Scheduler',
      granularity: {
        year: 'year',
        quarter: 'quarter',
        month: 'month',
        week: 'week',
        day: 'day',
        hour: 'hour',
        minute: 'minute',
      },
      fields: {
        granularity: 'Granularity',
        period: 'Period',
        next_execution_date: 'Next execution date',
      },
      buttons: {
        launch: 'Launch',
        active: 'Activate',
        disable: 'Disable',
      },
    },
    opportunity_detail: {
      name: 'Opportunitie |||| Opportunities',
    },
    job_status: {
      fields: {
        name: 'Name',
        start_datetime: 'Start',
        end_datetime: 'End',
        duration: 'Duration',
        status: 'Status',
      },
    },
    logs: {
      details: {
        delivered: 'Delivered',
        requested: 'Follows a request',
      },
      name: 'Log |||| Logs',
      no_interaction_logs: 'Aucune interaction',
      fields: {
        actions: 'Corrective actions',
        account: {
          name: 'Account name',
          address: 'Address',
        },
        affectation: {
          name: 'Site',
          address: 'Address',
        },
        answers: {
          positive: 'Positive points',
          improvement: 'Amelioration points',
        },
        cleaner: {
          name: 'Name',
        },
        contact: {
          fullname: 'Name',
          email: 'Email',
          phone: 'Phone',
        },
        owner: {
          name: 'Created by',
        },
        comment: 'Comment',
        contact_type: 'Contact method',
        date: 'Date',
        deadline: 'Delivery deadline',
        delivered: 'Delivered',
        description: 'Description',
        details: 'Details',
        distributed_date: 'Distributed date',
        first_distributed_date: 'First distributed date',
        global_rating: 'Note',
        hide_preparatory_comment: 'Hide preparatory comment',
        tracking_link: 'Tracking link',
        logType: 'Type',
        number_rooms: 'Number of rooms',
        preparatory_comment: 'Preparatory comment',
        provider: 'Provider',
        requested: 'Follows a request',
        salesforce_id: 'Salesforce id',
        scheduled_date: 'Date',
        sent_date: 'Sent date',
        show_preparatory_comment: 'Show preparatory comment',
        status: 'Status',
        subject: 'Subject',
        tags: 'Category',
        type: 'Type',
      },
      actions: {
        agent_request: 'Request a cleaner from pool',
        bill_issue_report: 'Report a bill issue',
        consumable_delivery_request: 'Request a consummable delivery',
        create_interaction: 'Declare a client exchange',
        create_quality: 'Create a quality control',
        create_visit: 'Log a visit',
        disciplinary_action: 'Request a disciplinary action',
        edit_interview: 'Interview',
        extra_hours_report: 'Report extra hours',
        glazing_request: 'Request a glazing',
        learning_request: 'Request a learning',
        declare_affectation: 'Declare a affectation',
        devis: '📑 Request a devis',
        interview: {
          NEW: 'Schedule interview',
          SCHEDULED: 'Prepare interview',
          PREPARED: 'Perform interview',
        },
        leave_report: 'Report an absence',
        payroll_issue: 'Report a payroll issue',
        product_delivery: 'Report a product delivery',
        product_delivery_request: 'Request a product delivery',
        quote_request: 'Request a quote',
        handyman_mission: 'Report a handyman mission',
      },
      dialog: {
        open: 'Details',
      },
      edit: {
        interview: {
          pointModel: 'Insert a line break between each point as follows',
          pointSample: 'Point',
        },
      },
      log_type: {
        cleanerLeave: 'Cleaner leave',
        cleanerContractAmendment: 'Contract amendment',
        cleanerContractEnd: 'Contract end',
        cleanerContractStart: 'Contract start',
        cleanerInteraction: 'Cleaner interaction',
        cleanerLetter: 'Recommended letter',
        cleanerAffectation: 'Affectation',
        cleanerTrialPeriodRenewal: 'Trial period renewal',
        deliveryRequest: 'Delivery request',
        delivery: 'Delivery',
        interaction: 'Interaction',
        interview: 'Interview',
        leave: 'Leave',
        quality: 'Quality',
        quote: 'Quote request',
        visit: 'Visit',
      },
      modal: {
        quality: {
          body: 'Are you sure to delete this quality control ?',
          cancel: 'Cancel',
          close: 'Close',
          delete: 'Delete',
          show: 'Show',
          title: 'Delete quality control',
        },
      },
      titles: {
        deliveryRequest: 'Delivery request |||| Delivery requests',
        delivery: 'Delivery |||| Deliveries',
        leave: 'Leave |||| Leaves',
        quote: 'Quote request |||| Quote requests',
        interaction: 'Interaction |||| Interactions',
        quality: 'Quality control |||| Quality controls',
        visit: 'Visit |||| Visits',
        interview: 'Cleaner interview |||| Cleaner interviews',
      },
      values: {
        logs_type: {
          deliveryRequest: 'Delivery request',
          delivery: 'Delivery',
          interaction: 'Interaction',
          interview: 'Interview',
          leave: 'Leave',
          quality: 'Quality',
          quote: 'Quote request',
          visit: 'Visit',
        },
        interview_status: {
          NEW: 'To schedule',
          SCHEDULED: 'To prepare',
          PREPARED: 'Ready',
          DONE: 'Done',
        },
      },
      form: {
        cancel: 'Cancel',
        contact_interaction: 'Contacted by',
      },
    },
    logsInterview: {
      form: {
        schedule_interview_error:
          "La date entrée ne correspond pas aux jours travaillés de l'affectation sélectionnée.",
      },
    },
    logsQuality: {
      name: 'Quality control |||| Quality controls',
      no_quality_logs: 'No quality control',
      no_room_checked: 'No room checked',
      rooms: {
        addTitle: 'Add a room',
        editTitle: 'Edit room',
        fields: {
          building: 'Area',
          roomType: 'Room type',
          roomName: 'Room name',
          comment: 'Additional comments',
          rating: 'Room rating',
          addCorrectiveAction: 'Add a corrective action',
          removeCorrectiveAction: 'Remove this corrective action',
          correctiveAction: 'Corrective action |||| Corrective actions',
        },
        add_room: 'Add a room',
        cancel: 'Cancel',
        add: 'Add',
        edit: 'Update',
      },
      specification: 'Specification',
      warningTitle: 'Warning',
      warningContent:
        'You already have an ongoing quality control. Please validate or abort it first.',
    },
    account_leave: {
      fields: {
        cleaner_id: 'Cleaner',
        contract_id: 'Contract',
        end_date: 'End date',
        processed: 'Processed',
        start_date: 'Start date',
      },
    },
    available_cleaners: {
      fields: {
        address: 'Address',
        contract_start_date: 'Contract start',
        density: 'Density',
        fullname: 'Fullname',
        manager_fullname: 'Manager',
        missing_reccuring_hours: 'Missing hours',
        tags: 'Qualifications',
        total_worked_time: 'Weekly total',
        traveltime: 'Travel time',
        vacancy_time: 'Vacancy time',
      },
    },
    presence_detection: {
      name: 'Presence detection',
      groups: {
        affectation: 'Affectation',
        device_movement: 'Sensor movement',
        mission_report: 'Mission report',
      },
      form: {
        submit: 'See attendance this week',
        validate: {
          no_account: 'An account must be selected',
        },
      },
      legend: {
        affectation_type: {
          title: 'Affectation type',
          permanent: 'Permanent',
          replacement: 'Replacement',
          ponctual: 'Ponctual',
          absence: 'Absent',
          intercontract: 'Intercontract',
        },
        confidence: {
          title: 'Sensor signal confidence',
          subtitle:
            'Sensor displayed data is computed from signal received from the sensor. This data can contain errors. The color allows to get a level of confidence regarding each data area',
          good: 'Sure',
          bad: 'Unsure',
        },
      },
    },
    invoice_incubator: {
      name: 'Pre-computed invoice |||| Pre-computed invoices',
      action: {
        create: 'Regularisation',
      },
      fields: {
        account_id: 'Account',
        billing_id: 'Billing account',
        description: 'Description',
        generated: 'Generated',
        invoice_number: 'Invoice number',
        month: 'Month',
        price: 'Price',
        product: 'Product',
        type: 'Type',
      },
    },
    issue_report: {
      name: 'Issue report |||| Issue reports',
      actions: {
        setToDone: 'Done',
      },
      fields: {
        affectation: {
          account: {
            name: 'Account',
          },
        },
        comment: 'Comment',
        created_at: 'Creation',
        issueType: {
          ELECTRICITY: 'Electricity',
          LEAK: 'Leak',
          SPOT: 'Abnormal spots',
          ELEVATOR: 'Elevator',
          CLIENT: 'Client task',
          OTHER: 'Other',
        },
        manager_fullname: 'Manager',
        mobile_phone: 'Phone',
        owner: {
          id: 'Cleaner',
          name: 'Cleaner',
        },
        photo: 'Photo',
        reason: {
          badAccess: 'Bad access',
          badAddress: 'Bad address',
          other: 'Other',
        },
        reason_cannot_enter: 'Reason preventing entrance',
        status: {
          DONE: 'Done',
          PENDING: 'Pending',
        },
        type: {
          prestation: 'Prestation',
          cannotEnter: 'Cannot Enter',
          affectationError: 'Affectation error',
        },
        updated_at: 'Update',
      },
      filters: {
        account: 'Account',
        owner: 'Owner',
        status: {
          DONE: 'Done',
          PENDING: 'Pending',
        },
        type: {
          prestation: 'Prestation',
          cannotEnter: 'Cannot Enter',
          affectationError: 'Affectation error',
        },
      },
    },
    provider: {
      name: 'Provider |||| Providers',
      fields: {
        id: 'Id',
        last_action: 'Last delivery',
        next_action: 'Next delivery',
        provider: 'Provider',
        provider_ref_id: 'Provider reference',
      },
    },
    provider_item: {
      name: 'Provider detail |||| Provider details',
      fields: {
        last_action: 'Last delivery',
        next_action: 'Next delivery',
        period: 'Frequency',
        provider_item_name: 'Item',
        provider_item_ref_id: 'Item reference',
        provider: 'Provider',
        type: 'Type',
      },
      title: {
        equipment: 'Equipments',
        supply: 'Consumables',
      },
    },
    provider_invoice: {
      name: 'Provider Invoice |||| Provider Invoices',
      actions: {
        upload: 'Create from an invoice',
      },
      dialog: {
        title: 'Generate invoice data from a document',
        upload_button: 'Upload',
        ok_button: 'Ok',
      },
      fields: {
        amortization: 'Amortization time (months)',
        amount: 'Amount',
        date: 'Date',
        provider_account_id: 'Provider',
        invoice_number: 'Invoice number',
        contract_id: 'Contract',
      },
      filters: {
        date: 'Date (from)',
      },
      unit: {
        month: 'months',
      },
    },
    action: {
      name: 'Action |||| Actions',
      fields: {
        account_id: 'Account',
        assignee_id: 'Assignee',
        category: 'Category',
        comment: 'Comment',
        creator_id: 'Creator',
        description: 'Description',
        done_date: 'Done date',
        due_date: 'Due date',
        status: 'Status',
        title: 'Subject',
        type: 'Type',
      },
      forms: {
        category: {
          DELIVERY: 'Delivery',
          AFFECTATION_CHECK: 'Affectation hours to check',
          NO_SHOW_CHECK: 'Possibly no one on site',
          OTHER: 'Other',
        },
        status: {
          KO: 'KO',
          OK: 'OK',
          PARTIAL: 'Partial',
          PENDING: 'Pending',
        },
        type: {
          CHECK: 'Check',
          TO_DO: 'To do',
        },
      },
      no_data: 'No action',
      title: {
        action: 'Actions',
      },
    },
  },
  errors: {
    contract: {
      no_mission: 'Mission not found !',
    },
  },
  format: {
    date: {
      period: 'From %{start_date} to %{end_date}',
      since: 'Since %{start_date}',
      format: 'MM/DD/YYYY',
      day: 'day',
      schedule: 'schedule',
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
    },
  },
  login: {
    username: 'Username',
    password: 'Password',
    sign_in: 'Sign in',
    cleaning:
      'Il est primordial de maintenir les espaces de travail propres pour le confort des collaborateurs et des clients.',
    glazing:
      'Nettoyage ponctuel ou récurrent de la vitrerie intérieure/extérieure accessible.',
    tasks:
      "Besoin d'aide pour réparer ou colmater ? Nos Helpers sont présents à tout moment.",
    supply: 'Tout ce dont vous avez besoin, au moment où vous en avez besoin.',
    snacks:
      "Commander des repas, des apéros ou organiser des évènements, c'est possible.",
    forgot_password_button: 'Forgotten password ?',
    mail_forgotten_password: 'Enter your email address :',
    confirmation: 'SEND',
    emailSended: 'e-mail sent',
    back: 'Back',
    password_reset: 'New password',
    password_reset2: 'Confirm',
    password_reset_success: 'Your password has been reset successfully !',
    different_passwords: 'different passwords',
    first_connection: 'First connection',
    form: {
      required: 'Required',
    },
  },
  notification: {
    legal_document: {
      success: 'Document created',
      fail: 'Missing informations: %{message}',
    },
    init_drive: {
      success: "Cleaner's drive initialized",
      fail: "Error initializing cleaner's drive",
    },
    invite_cleaner: {
      success: 'Invitation sent to %{invitationsSent} cleaner(s)',
      fail: '%{message} about the affectation',
    },
    invoice_download: {
      success: 'Invoice Saved',
      fail: 'Fail to download invoice',
    },
    issue_report: {
      setToDone: {
        success: 'Issue marked as solved',
        fail: 'Error during the issue edition',
      },
    },
    create_replacement: {
      success: 'Replacement created',
      fail: 'Failed to create replacement',
    },
    quality_logs: {
      success: 'Control summary succesfully sent',
      fail: 'Failed to send control summary',
      download_success: 'Control summary succesfully downloaded',
      download_fail: 'Failed to download control summary',
    },
    save_affectation_bundle: {
      success: '%{numberAffectation} Affectation(s) successfully created',
      fail: 'Error during affectation creation',
    },
    sign_contract: {
      success: 'E-signature procedure successfully created',
      fail: 'Failed to create e-signature procedure',
    },
    upload_document: {
      success: 'Document uploaded successfully',
      fail: 'Failed to upload document',
    },
    upload_invoice: {
      fail: 'Failed to upload pdf',
    },
  },
  sidebar: {
    buttons: {
      logout: 'Logout',
    },
  },
  zendesk: {
    name: 'Tickets',
    no_data: 'No ticket',
    dialog: {
      open: 'Details',
    },
    error: {
      notImplemented: 'Not Implemented',
    },
    priorities: {
      high: 'High',
      low: 'Low',
      normal: 'Normal',
      urgent: 'Urgent',
    },
    statuses: {
      closed: 'Closed',
      hold: 'Hold',
      new: 'New',
      open: 'Open',
      pending: 'Pending',
      solved: 'Solved',
    },
  },
  components: {
    button: {
      download: {
        text: 'Download',
      },
      leave: {
        createLeave: 'Create a leave',
        createLeaveForThisContract: 'Create a leave for this contract',
      },
      timeline: {
        details: 'Details',
      },
    },
    dialog: {
      close: 'Close',
      open: 'Open',
    },
    field: {
      absence_type: {
        UNAUTHORIZED_LEAVE_UNPAID: '✔️ Unauthorized leave (unpaid)',
        LEAVE_ABSENCE_PAID: '🤑 Leave absence (paid)',
        UNAUTHORIZED_LEAVE: '❌ Unauthorized leave (unpaid)',
        COMMUTE_INJURY: '🚇 Commute injury',
        WORK_INJURY: '⚠️ Work injury',
        TRAINING_LEAVE: '🎓 Training leave',
        PARENTAL_LEAVE: '🍼 Parental leave',
        PATERNITY_LEAVE: '👨‍🍼 Paternity leave',
        BIRTH_LEAVE: '👶 Birth leave',
        FAMILY_LEAVE: '💒 Family leave',
        UNPAYED_LEAVE: '🏝️ Unpayed leave',
        PAYED_LEAVE: '💸 Payed leave',
        SICK_CHILD: '🤒 Sick child',
        CLOSED_SITE: '🔒 Closed site',
        ILLNESS: '🤧 Illness',
        WORK_ILLNESS: '🩹 Work illness',
        MATERNITY_LEAVE: '🤰 Maternity leave',
        CONSERVATORY_LAID_OFF: '⌛ Conservatory laid off',
        DISCIPLINARY_LAID_OFF: '😠 Disciplinary laid off',
        SABBATICAL_LEAVE: '🤠 Sabbatical leave',
      },
      action_category: {
        DELIVERY: 'Delivery',
        AFFECTATION_CHECK: 'Affectation hours to check',
        NO_SHOW_CHECK: 'Possibly no one on site',
        OTHER: 'Other',
      },
      action_status: {
        KO: 'KO',
        OK: 'OK',
        PARTIAL: 'Partial',
        PENDING: 'Pending',
        REJECTED: 'Rejected',
      },
      action_type: {
        CHECK: 'Check',
        TO_DO: 'To do',
      },
      cleaner_contract_spec: {
        full_time: {
          true: 'Full time',
          false: 'Partial time',
        },
      },
      contract_end_reason: {
        INSUFFICIENT_HOURS: '⏰ Insufficient hours',
        WORKLOAD: '🤖 Workload',
        MANAGEMENT: '📋 Management',
        TOO_LONG_TRAVEL_TIME: '🚈 Too long travel time',
        PHYSICAL_WORK: '💪 Physical work',
        BETTER_OPPORTUNITY: '📈 Better opportunity',
        NO_REASON: '🤷‍♀️ No reason',
        ABUSIVE_BEHAVIOR: '😡 Abusive behavior',
        UNAUTHORIZED_LEAVES: '💤 Unauthorized leaves',
        RECURRINGLY_LATE: '🕤 Recurringly late',
        UNRESPECTED_SPECIFICATIONS: '😵‍💫 Unrespected specifications',
        MISSING_DOCUMENTS: '📂 Missing documents',
        PROFESSIONAL_INSUFFICIENCY: '😨 Professional insufficiency',
        MISSION_REFUSAL: '🤮 Mission refusal (7th annex)',
        ABANDONMENT_OF_POST: '🏃 Abandonment of post',
        SECURITY_RULES_BREAKING: '🦠 Security rules breaking',
        FORGED_DOCUMENTS: '📝 Forged documents',
        IDENTITY_THEFT: '🕵️‍♂️ Identity theft',
        THEFT_OR_DEGRADATION: '👎 Theft or degradation',
        VIOLENCE_OR_INSULT: '💢 Violence or insult',
        INSUBORDINATION: '🤬 Insubordination',
        INJURY_TO_EMPLOYER: '🚷 Injury to employer',
        null: 'None',
        undefined: 'None',
      },
      contract_end_type: {
        RESIGNATION: 'Resignation',
        TRIAL_PERIOD_END_1: 'End of trial period - Employeur',
        TRIAL_PERIOD_END_2: 'End of trial period - Salarié',
        rupture_amiable: 'rupture amiable',
        rupture_conventionnelle: 'rupture Conventionnelle',
        SIMPLE_MISCONDUCT_DISMISSAL: 'Simple misconduct dismissal',
        SERIOUS_MISCONDUCT_DISMISSAL: 'Serious misconduct dismissal',
        LICENCIEMENT_NON_PROFESSIONNELLE:
          'Licenciement inaptitude non professionnelle',
        LICENCIEMENT_PROFESSIONNELLE: 'Licenciement inaptitude professionnelle',
        GRAVE_MISCONDUCT_DISMISSAL: 'Grave misconduct dismissal',
        RETIREMENT: 'Retirement',
        FIXED_TERM_CONTRACT_END: 'FIXED_TERM_CONTRACT_END',
        TAKEN_OVER: 'TAKEN_OVER',
        null: 'None',
        undefined: 'None',
      },
      contact_type: {
        EMAIL: 'Email',
        PHONE: 'Phone',
        SMS: 'Sms',
        MEETING: 'Meeting',
      },
      direct_debit_status: {
        CANCELLED: 'Cancelled',
        CHARGED_BACK: 'Charged back',
        CUSTOMER_APPROVAL_DENIED: 'Denied',
        FAILED: 'Failed',
        PAID_OUT: 'Payed',
        PENDING_CUSTOMER_APPROVAL: 'Pending customer',
        PENDING_SUBMISSION: 'Pending submission',
      },
      issue_reason: {
        badAccess: 'Bad access',
        badAddress: 'Bad address',
        other: 'Other',
      },
      issue_type: {
        cannotEnter: 'Cannot enter on site',
        prestation: 'Prestation',
      },
      prestation_type: {
        CLEANING: 'Cleaning',
        GLAZING: 'Glazing',
        CARPET_CLEANING: 'Shampooing carpet',
        PERMANENCE: 'Permanence',
        null: 'Not specified',
        undefined: 'Undefined',
      },
      consistency_error: {
        CONFLICT: 'Schedule conflict',
        VACANCY_TOO_SHORT: 'Vacancy too short',
        null: 'No error',
      },
      invoice_status: {
        CLOSED: 'Payed',
        CANCELLED: 'Cancelled',
        OPEN: 'Open',
        INCOMPLET: 'Incomplet',
      },
      procedureStatus: {
        active: '⠀Active ⠀ ',
        draft: 'Started',
        expired: 'Expired',
        finished: 'Finished',
        refused: 'Refused',
        unknown: 'Not Sended',
      },
      cleaner_document: {
        APPROVED: 'Approved',
        PENDING: 'Pending',
        REJECTED: 'Rejected',
      },
      letter_status: {
        ACCEPTED:
          "Cette lettre a été acceptée par le système d'impression et d'expédition.",
        CANCELED: 'Cette lettre a été annulée.',
        CREATED: 'Cette lettre a été créée.',
        DELIVERY_PROOF: 'Cette lettre a reçu une preuve de livraison.',
        DISTRIBUTED: 'Cette lettre a été distribuée.',
        ERROR: "Cette lettre est dans un état d'erreur.",
        FILLING_PROOF: 'Cette lettre a reçu une preuve de dépôt.',
        IN_TRANSIT: 'Cette lettre est en transit.',
        RETURNED_TO_SENDER: "Cette lettre a été retournée à l'expéditeur.",
        SENT: 'Cette lettre a été envoyée.',
        WAITING_TO_BE_WITHDRAWN:
          "Cette lettre est en attente d'être retirée par le destinataire.",
        WRONG_ADDRESS:
          'Cette lettre a reçu un événement "N\'habite pas à l\'adresse indiqué" de La Poste.',
      },
      timeline_type: {
        cleanerLeave: 'Leaves',
        cleanerAffectation: 'Affectations',
        cleanerContractStart: 'Contract start',
        cleanerTrialPeriodRenewal: 'Trial period renewal',
        cleanerContractEnd: 'Contract end',
        cleanerContractAmendment: 'Amendment',
        cleanerLetter: 'Registered mail',
      },
      unit: {
        week: 'Weeks',
      },
      visit_tag: {
        START_UP: 'Start up',
        CLIENT_MEETING: 'Client meeting',
        QUALITY_CONTROL: 'Quality controle',
        NOTHING_TO_REPORT: 'Nothing to report',
        PROBLEMS: 'Problems',
        PRODUCT_DELIVERY: 'Product delivery',
        CLEANER_MEETING: 'Cleaner meeting',
        ACCESS_RECOVERY: 'Access recovery',
        GOOD_FEEDBACK: 'Good feedback',
        REPLACEMENT: 'Replacement',
        OTHER: 'Other',
        FULL_CLEANING: 'Full cleaning',
        SET_UP: 'Set up',
      },
    },
    cleaner_information: {
      title: 'Cleaner information',
      no_data: 'Please choose a cleaner to get informations',
    },
    leave_information: {
      title: 'Leave information',
      button: 'Go to absence replacement',
    },
    form: {
      contract_input: {
        all_contract: 'All contracts',
      },
      hint_space_checkbox: 'Choose corrective actions for the selected space',
      image_input: {
        placeholder: 'Drop your file here',
      },
      file_input: {
        placeholder: 'Drop your file here',
      },
      required: 'Required',
      time_validation: "Can't end before start",
      replacement_outside_leave_scope:
        'The replacement must occur during the leave',
      save: 'Save',
      validators: {
        email_or_phone_required: 'Email or phone is required',
        url_malformed: "The entered value doesn't match a URL",
      },
    },
    planning: {
      days: 'Days',
      schedule: 'Schedule',
      variable_hours: 'Variables daily hours',
    },
    replacement_completion: {
      ratio: '%{current}/%{max} hours replaced',
    },
  },
  payroll: {
    title: 'Payroll details',
    copy: 'Copy',
    copied: 'Copied',
    contract: {
      intro:
        'Your contract includes a total of %{total} hours this month. The detail of this one is as follows:',
      current:
        'Your contract includes %{contract_worked_time} hours per week since %{start_date}, giving a total of %{total} hours',
      old:
        'Your contract included %{contract_worked_time} hours per week from %{start_date} to %{end_date}, giving a total of %{total} hours',
    },
    permanent: {
      intro:
        'With your permanent assignments you have completed %{total} hours. The details of these are as follows:',
      single_day: 'on %{day}',
      multiple_day: 'at the rate of %{hours} hours per day on %{weekdays}',
      current:
        '-   A permanent assignment of %{duration_per_week} hours per week, %{days}, since %{start_date} at "%{account_name}, %{site_address}", giving a total of %{total} hours.',
      old:
        '-   A permanent assignment of %{duration_per_week} hours per week, %{days}, ended on %{end_date} at "%{account_name}, %{site_address}", giving a total of %{total} hours.',
    },
    punctual: {
      intro:
        'Avec vos affectations ponctuelles vous avez réalisé %{total} heures. Le détail de celles-ci est le suivant:',
      day:
        '-   Un %{type} chez "%{account_name}, %{site_address}" le %{start_date} d\'une durée de %{total} heures.',
      period:
        '-   Un %{type} chez "%{account_name}, %{site_address}" du %{start_date} au %{end_date} comprenant un total de %{total} heures.',
      ponctuel: 'ponctuel',
      remplacement: 'remplacement',
    },
    leave: {
      intro:
        'You have been absent for %{total} hours this month. The details of these absences are as follows:',
      day:
        '-   An absence at "%{account_name}, %{site_address}" the %{start_date} for a duration of %{total} hours.',
      period:
        '-   An absence at "%{account_name}, %{site_address}" from %{start_date} to %{end_date}, giving a total of %{total} hours.',
    },
    conclusion: {
      sum:
        'By adding all your assignments together you have completed a total of %{total} hours. ',
      sub:
        'By deducting your %{hours} hours of absence(s) you have achieved a total of %{total} hours. ',
      more:
        'These hours (%{total}h) being higher than the one of your contract (%{contract}h), you were therefore in overtime for %{overtime} hours this month.',
      equal:
        'These hours (%{total}h) being equal to the one of your contract (%{contract}h), you have therefore realized the integrality of your hours this month.',
      less:
        'These hours (%{total}h) being less than the one of your contract (%{contract}h) you have been on inter-contract for %{hours} hours this month.',
      summary: ' In total you have been paid for %{total} hours.',
      summary_details:
        ' In total, you have been paid for %{total} hours that are broken down as follows:',
      special_hours: '%{type} -> %{hours} hours.',
    },
    hours: {
      base_100: 'Basic hours paid at 100%',
      base_110: 'Additional hours paid at 110%',
      base_125: 'Overtime hours paid at 125%',
      base_150: 'Overtime hours paid at 150%',
      night_sunday_120: 'Night/Sunday hours paid at 120%',
      night_sunday_200: 'Night/Sunday hours paid at 200%',
    },
  },
};
