import React from 'react';
import Grid from '@material-ui/core/Grid';

import ConsumableCard from './ConsumableCard';
import EquipmentCard from './EquipmentCard';
import ProduitCard from './ProduitCard';

const TabConsumable = (props) => {
  return (
    <Grid container spacing={2}>
      <ConsumableCard {...props} />
      <EquipmentCard {...props} />
      <ProduitCard {...props} />
    </Grid>
  );
};

export default TabConsumable;
