import sha256 from 'sha256';
import 'whatwg-fetch';
import {
  AUTH_LOGIN,
  AUTH_CHECK,
  AUTH_ERROR,
  AUTH_GET_PERMISSIONS,
  AUTH_LOGOUT,
} from 'react-admin';

import { setUser, getUser, removeUser } from './user';

export default (type, params) => {
  switch (type) {
    case AUTH_LOGIN: {
      const { username, password } = params;
      const request = {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username.trim(),
          password: sha256(password),
          application: 'care',
        }),
      };
      localStorage.setItem('isAuthenticated', false);

      return fetch('authenticate', request)
        .then((response) => {
          if (response.status < 200 || response.status >= 300) {
            return Promise.reject(new Error(response.statusText));
          }
          return response.json();
        })
        .then(({ success, user, error }) => {
          if (!success) {
            return Promise.reject(error);
          }
          setUser(user);
          return Promise.resolve();
        });
    }
    case AUTH_CHECK: {
      const isAuth = !!getUser();

      if (isAuth) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('not authenticated'));
    }
    case AUTH_ERROR: {
      const { status } = params || {};
      if (status === 401 || status === 403) {
        return Promise.reject();
      }
      return Promise.resolve();
    }
    case AUTH_LOGOUT: {
      const request = {
        method: 'POST',
        credentials: 'same-origin',
      };
      return fetch('exit', request).then((response) => {
        if (response.status < 200 || response.status >= 300) {
          return Promise.reject(new Error(response.statusText));
        }
        removeUser();
        return Promise.resolve();
      });
    }
    case AUTH_GET_PERMISSIONS: {
      const user = getUser();
      return Promise.resolve(user);
    }
    default:
      return Promise.reject(new Error('Unknown method'));
  }
};
