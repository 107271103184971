import React from 'react';
import PropTypes from 'prop-types';
import ChatBubbleOutline from '@material-ui/icons/ChatBubbleOutline';
import IconButton from '@material-ui/core/IconButton';
import { openTypeform } from '../../../helpers';
import { getUser } from '../../../user';

const InteractionListButton = ({ record }) => {
  const user = getUser();
  return (
    <IconButton
      onClick={(event) => {
        event.stopPropagation();
        openTypeform(
          { ...record, user_id: user.id, user_name: user.fullname },
          'cleaner_interaction',
        );
      }}
    >
      <ChatBubbleOutline />
    </IconButton>
  );
};

InteractionListButton.propTypes = {
  record: PropTypes.object,
};

InteractionListButton.defaultProps = {
  record: {},
};

export default InteractionListButton;
