export const GET_CLEANER_TIMELINE = 'GET_CLEANER_TIMELINE';
export const SET_CLEANER_TIMELINE = 'SET_CLEANER_TIMELINE';
export const CLEAR_CLEANER_TIMELINE = 'CLEAR_CLEANER_TIMELINE';

export const getCleanerTimeline = (cleanerId, filters) => {
  return {
    type: GET_CLEANER_TIMELINE,
    payload: { cleanerId, filters },
  };
};

export const setCleanerTimeline = (timeline) => {
  return {
    type: SET_CLEANER_TIMELINE,
    payload: { timeline },
  };
};

export const clearCleanerTimeline = () => {
  return {
    type: CLEAR_CLEANER_TIMELINE,
  };
};
