import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';

import {
  frequencyReference,
  multiLineStringToArray,
  sortTableOfObjects,
} from '../../../../helpers';
import { NestedSimpleListItem } from '../../../../components';

export const buildMissions = (data) => {
  if (!data) {
    return null;
  }

  const missions = {};

  Object.keys(data).forEach((item) => {
    const descriptions = multiLineStringToArray(data[item].description);

    if (!data[item].espace || !descriptions) {
      return;
    }
    const espaces = data[item].espace.split(';');

    espaces.forEach((espace) => {
      let richEspace;
      if (!data[item].product) {
        richEspace = `Autre - ${espace}`;
      } else {
        richEspace = `${data[item].product} - ${espace}`;
      }

      if (!missions[richEspace]) {
        missions[richEspace] = {
          name: richEspace,
          des: {},
        };
      }

      const freq = frequencyReference[data[item].frequency];

      if (!missions[richEspace].des[freq]) {
        missions[richEspace].des[freq] = [];
      }

      missions[richEspace].des[freq].push(...descriptions);
    });
  });

  const tableMissions = [];

  Object.keys(missions).forEach((mission) => {
    tableMissions.push(missions[mission]);
  });

  tableMissions.sort(sortTableOfObjects('name'));
  return tableMissions;
};

const ListPrestations = ({ data }) => {
  if (!data) {
    return null;
  }

  const missions = buildMissions(data);

  return (
    <List>
      {missions.map((espace) => {
        return (
          <NestedSimpleListItem
            key={espace.name}
            text={espace.name}
            variant="primary"
            dense
            defaultOpen
          >
            {Object.keys(espace.des).map((key) => {
              return (
                <NestedSimpleListItem key={key} text={key} dense>
                  {espace.des[key].map((item) => {
                    return (
                      <NestedSimpleListItem
                        key={item}
                        text={item}
                        variant="light"
                        dense
                      />
                    );
                  })}
                </NestedSimpleListItem>
              );
            })}
          </NestedSimpleListItem>
        );
      })}
    </List>
  );
};

ListPrestations.propTypes = {
  data: PropTypes.object,
};

ListPrestations.defaultProps = {
  data: {},
};

export default ListPrestations;
