import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Dialog, DialogContent, IconButton } from '@material-ui/core';
import SpecificationIcon from '@material-ui/icons/ListAlt';
import { ReferenceManyField } from 'react-admin';

import Title from './Title';
import ListPrestations from './ListPrestations';

const useStyles = makeStyles((theme) => {
  return {
    content: {
      padding: theme.spacing(2),
    },
    actions: {
      margin: 0,
      padding: theme.spacing(1),
    },
  };
});

const SpecificationDialog = ({ className, icon, variant, ...props }) => {
  const classes = useStyles();
  const translate = useTranslate();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {icon ? (
        <IconButton
          classes={{ root: className }}
          color="primary"
          onClick={handleClickOpen}
        >
          <SpecificationIcon />
        </IconButton>
      ) : (
        <Button
          variant={variant}
          color="primary"
          onClick={handleClickOpen}
          startIcon={<SpecificationIcon />}
        >
          {translate('resources.account.fields.specification')}
        </Button>
      )}
      <Dialog onClose={handleClose} open={open} fullWidth maxWidth="md">
        <Title onClose={handleClose}>
          {translate('resources.account.card_title.specification')}
        </Title>
        <DialogContent className={classes.content} dividers>
          <ReferenceManyField
            {...props}
            addLabel={false}
            perPage={100}
            reference="prestation"
            target="contract_id"
          >
            <ListPrestations />
          </ReferenceManyField>
        </DialogContent>
      </Dialog>
    </div>
  );
};

SpecificationDialog.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.bool,
  variant: PropTypes.oneOf(['text', 'outlined', 'contained']),
};

SpecificationDialog.defaultProps = {
  className: undefined,
  icon: false,
  variant: 'text',
};

export default SpecificationDialog;
