import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CardHeader from '@material-ui/core/CardHeader';

const styles = createStyles({
  avatar: {
    marginRight: 0,
  },
});

const CardHeaderAvatar = ({ avatar, classes, ...props }) => {
  const isAvatar = !!avatar;

  return (
    <CardHeader
      {...props}
      avatar={isAvatar ? avatar : <div />}
      classes={isAvatar ? null : classes}
    />
  );
};

CardHeaderAvatar.propTypes = {
  avatar: PropTypes.node,
  classes: PropTypes.object,
};

CardHeaderAvatar.defaultProps = {
  avatar: undefined,
  classes: {},
};

export default withStyles(styles)(CardHeaderAvatar);
