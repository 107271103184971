import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getUser } from '../../../user';

const QualityButton = ({ className, record }) => {
  const user = getUser();
  return (
    <Fragment>
      <Route
        render={() => {
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  'https://quality-control.bubbleapps.io/?account_id=' +
                    `${record.id}` +
                    '&manager_id=' +
                    `${user.id}`,
                );
              }}
            >
              <ListItemText primary="⭐ Contrôle Qualité" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

QualityButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

QualityButton.defaultProps = {
  className: undefined,
  record: undefined,
};

export default QualityButton;
