import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {},
    content: {},
    item: {},
    title: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      fontWeight: 400,
    },
  };
});

const InputsBlock = ({
  children,
  classes: classesOverride,
  title,
  ...props
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Grid container spacing={2} className={classes.content}>
        {Children.map(children, (child) => {
          return (
            <Grid item xs={4} className={classes.item}>
              {cloneElement(child, props)}
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

InputsBlock.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object,
  title: PropTypes.string.isRequired,
};

InputsBlock.defaultProps = {
  classes: {},
};

export default InputsBlock;
