import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

export const buildPhones = (phone, mobile) => {
  if (!phone && !mobile) {
    return '';
  }
  if (mobile && phone) {
    return phone === mobile ? phone : `${phone}/${mobile}`;
  }
  return phone || mobile;
};

export const PhoneField = ({ record }) => {
  const phone = get(record, 'phone');
  const mobile = get(record, 'mobile_phone');

  const value = buildPhones(phone, mobile);

  return (
    <Typography component="span" variant="body2">
      {value}
    </Typography>
  );
};

PhoneField.propTypes = {
  record: PropTypes.object,
};

PhoneField.defaultProps = {
  record: {},
};

const EnhancedPhoneField = pure(PhoneField);

EnhancedPhoneField.defaultProps = {
  addLabel: true,
};

export default EnhancedPhoneField;
