import React from 'react';
import { DateInput, required, useTranslate } from 'react-admin';

import { makeStyles } from '@material-ui/core';
import { SwitchedInput, TimeInput } from '../../../../components';
import { parseDate, parseTime } from '../../../../helpers/date';
import { inInterval } from '../../validation';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      width: 'auto',
    },
    input: {
      width: 256,
      marginRight: theme.spacing(2),
    },
  };
});

const useStylesSwitchedInput = makeStyles({
  root: { minWidth: 'auto' },
  input: { width: 256 },
});

const EndDateInput = () => {
  const classes = useStyles();
  const classesSwitchedInput = useStylesSwitchedInput();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      <DateInput
        className={classes.input}
        source="end_date"
        parse={parseDate}
      />
      <SwitchedInput
        classes={classesSwitchedInput}
        inputDefaultValue="12:00:00"
        source="half_end_time"
        label={translate('resources.leave.fields.half_day')}
        validate={[required(), inInterval]}
        parse={parseTime}
      >
        <TimeInput />
      </SwitchedInput>
    </div>
  );
};

export default EndDateInput;
