import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';
import moment from 'moment';
import FileSaver from 'file-saver';
import { GET_HOURS_CSV } from '../action';
import fetchApi from './helpers/fetchApi';

export const buildFileName = (type) => {
  switch (type) {
    case 'real':
      return 'decompte_au_reel';
    case 'night_120':
      return 'heures_de_nuit';
    case 'sunday_120':
      return 'heures_le_dimanche';
    case 'overtime':
      return 'heures_supplementaires';
    default:
      return undefined;
  }
};

export const fetchPayroll = async ({ date, type }) => {
  return fetchApi(
    {
      url: '/payroll/cleaner',
      payload: { date, type },
    },
    async (response) => {
      const name = buildFileName(type);
      const file = await response.blob();
      FileSaver.saveAs(file, `${name}_${moment(date).format('YYYY-MM')}.csv`);
    },
  );
};

export function* createPayrollCsvHandler({ date }) {
  try {
    yield put({ type: FETCH_START });
    yield call(fetchPayroll, { date, type: 'real' });
    yield call(fetchPayroll, { date, type: 'night_120' });
    yield call(fetchPayroll, { date, type: 'sunday_120' });
    yield call(fetchPayroll, { date, type: 'overtime' });
    yield put({ type: FETCH_END });
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification(err.message, 'warning'));
  }
}

export default function* watch() {
  yield takeLatest(GET_HOURS_CSV, createPayrollCsvHandler);
}
