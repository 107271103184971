import React from 'react';
import PropTypes from 'prop-types';
import queryString from 'querystring';
import moment from 'moment';
import { CreateWithParamsButton } from '../../../../../components';

const getCreateValue = (record) => {
  return queryString.stringify({
    start_date: moment().format('YYYY-MM-DD'),
    cleaner_id: record.id,
    contract_type: record.contract_type,
    level: record.level,
    classification: record.classification,
    full_time: record.full_time,
    contract_worked_time: record.contract_worked_time,
  });
};

const Actions = ({ record }) => {
  const params = getCreateValue(record);

  return (
    <CreateWithParamsButton basePath="/cleaner_contract" params={params} />
  );
};

Actions.propTypes = {
  record: PropTypes.object,
};

Actions.defaultProps = {
  record: {},
};

export default Actions;
