import React from 'react';
import PropTypes from 'prop-types';
import MuiTab from '@material-ui/core/Tab';
import { useTranslate } from 'ra-core';

const Tab = ({
  basePath,
  children,
  classes,
  context,
  icon,
  label,
  record,
  resource,
  ...props
}) => {
  const translate = useTranslate();

  const renderHeader = () => {
    return (
      <MuiTab
        key={label}
        classes={classes}
        label={translate(label, { _: label })}
        icon={icon}
        {...props}
      />
    );
  };

  const renderContent = () => {
    return React.Children.map(children, (field) => {
      return React.cloneElement(field, {
        basePath,
        record,
        resource,
      });
    });
  };

  return context === 'header' ? renderHeader() : renderContent();
};

Tab.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object,
  contentClassName: PropTypes.string,
  children: PropTypes.node,
  context: PropTypes.oneOf(['header', 'content']),
  icon: PropTypes.element,
  label: PropTypes.string.isRequired,
};

export default Tab;
