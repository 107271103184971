import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

export const removeSeconds = (time) => {
  if (!time) {
    return null;
  }

  const timeWithoutSecond = time
    .split(':')
    .slice(0, 2)
    .join(':');

  return timeWithoutSecond;
};

const TimeField = ({ record, source, style, withSeconds, variant }) => {
  const value = get(record, source);

  if (withSeconds) {
    return <span style={style}>{get(record, source)}</span>;
  }
  return (
    <Typography component="span" variant={variant}>
      {removeSeconds(value)}
    </Typography>
  );
};

TimeField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  style: PropTypes.object,
  withSeconds: PropTypes.bool,
  variant: PropTypes.string,
};

TimeField.defaultProps = {
  record: {},
  style: {},
  withSeconds: false,
  variant: 'body2',
};

const PureTimeField = pure(TimeField);

PureTimeField.defaultProps = {
  addLabel: true,
};

export default PureTimeField;
