import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { useFormState } from 'react-final-form';
import {
  BooleanInput,
  DateInput,
  SimpleForm,
  TextInput,
  Button,
  required,
  translate as translateComponent,
} from 'react-admin';
import {
  AffectationTypeSelectInput,
  CatalogItemAutocompleteInput,
  CleanerAutocompleteInput,
  ConditionalInput,
  ContractAutocompleteInput,
  LeaveSelectInput,
  LeaveAutocompleteInput,
  TimeInput,
  Toolbar,
} from '../../../components';
import { PlanningInput } from '../../../containers';
import {
  dateValidation,
  parseDate,
  planningValidation,
} from '../../../helpers/date';
import { saveAffectationBundle } from '../../../action';
import { atLeastOneCleaner } from '../validation';
import { buildPlanning } from '../helper';

const validate = (values) => {
  console.log('VALUES', values);
  return Object.assign({}, dateValidation(values));
};

export const getMonday = (planning) => {
  if (planning.monday && planning.monday[0] && planning.monday[0].start_time) {
    return '1';
  }
  return '0';
};

export const getTuesday = (planning) => {
  if (
    planning.tuesday &&
    planning.tuesday[0] &&
    planning.tuesday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getWednesday = (planning) => {
  if (
    planning.wednesday &&
    planning.wednesday[0] &&
    planning.wednesday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getThursday = (planning) => {
  if (
    planning.thursday &&
    planning.thursday[0] &&
    planning.thursday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getFriday = (planning) => {
  if (planning.friday && planning.friday[0] && planning.friday[0].start_time) {
    return '1';
  }
  return '0';
};

export const getSaturday = (planning) => {
  if (
    planning.saturday &&
    planning.saturday[0] &&
    planning.saturday[0].start_time
  ) {
    return '1';
  }
  return '0';
};

export const getSunday = (planning) => {
  if (planning.sunday && planning.sunday[0] && planning.sunday[0].start_time) {
    return '1';
  }
  return '0';
};

export const getStartTime = (planning) => {
  if (planning.monday) {
    return planning.monday[0].start_time;
  }
  if (planning.tuesday) {
    return planning.tuesday[0].start_time;
  }
  if (planning.wednesday) {
    return planning.wednesday[0].start_time;
  }
  if (planning.thursday) {
    return planning.thursday[0].start_time;
  }
  if (planning.friday) {
    return planning.friday[0].start_time;
  }
  if (planning.saturday) {
    return planning.saturday[0].start_time;
  }
  if (planning.sunday) {
    return planning.sunday[0].start_time;
  }
  return '00:00:00';
};

export const getEndTime = (planning) => {
  if (planning.monday) {
    return planning.monday[0].end_time;
  }
  if (planning.tuesday) {
    return planning.tuesday[0].end_time;
  }
  if (planning.wednesday) {
    return planning.wednesday[0].end_time;
  }
  if (planning.thursday) {
    return planning.thursday[0].end_time;
  }
  if (planning.friday) {
    return planning.friday[0].end_time;
  }
  if (planning.saturday) {
    return planning.saturday[0].end_time;
  }
  if (planning.sunday) {
    return planning.sunday[0].end_time;
  }
  return '00:00:00';
};

const CheacknerInput = () => {
  const { values } = useFormState();
  console.log();
  return (
    <Button
      variant="contained"
      label="🔎 Affectattention 🔎"
      onClick={() => {
        window.open(
          `https://stats.cleany.fr/question/1053?cleaner=${
            values.cleaner_ids[0]
          }&debut=${getStartTime(values.planning)}&Vendredi=${getFriday(
            values.planning,
          )}&Dimanche=${getSunday(values.planning)}&Jeudi=${getThursday(
            values.planning,
          )}&Mercredi=${getWednesday(values.planning)}&Mardi=${getTuesday(
            values.planning,
          )}&Samedi=${getSaturday(values.planning)}&Lundi=${getMonday(
            values.planning,
          )}&Fin=${getEndTime(values.planning)}`,
          '_blank',
        );
      }}
      style={{
        marginLeft: 10,
      }}
    />
  );
};

const DaysInput = (props) => {
  return (
    <Fragment>
      <BooleanInput {...props} source="monday" />
      <BooleanInput {...props} source="tuesday" />
      <BooleanInput {...props} source="wednesday" />
      <BooleanInput {...props} source="thursday" />
      <BooleanInput {...props} source="friday" />
      <BooleanInput {...props} source="saturday" />
      <BooleanInput {...props} source="sunday" />
    </Fragment>
  );
};

const AffectationForm = ({ create, onSubmit, record, save, ...props }) => {
  console.log(onSubmit);
  const defaultValue = create ? { planning: buildPlanning(record) } : undefined;
  return (
    <SimpleForm
      {...props}
      initialValues={defaultValue}
      redirect="list"
      record={record}
      validate={validate}
      save={create ? onSubmit : save}
      toolbar={<Toolbar isDeletable={false} />}
    >
      <ContractAutocompleteInput validate={required()} />
      <CleanerAutocompleteInput
        multiple={create}
        validate={create ? atLeastOneCleaner : required()}
        name="cleaner"
      />
      <AffectationTypeSelectInput source="type" validate={required()} />
      <ConditionalInput when="type" is="Remplacement">
        <LeaveAutocompleteInput />
      </ConditionalInput>
      <ConditionalInput when="type" is="Ponctuel">
        <TextInput source="punctual_reason" />
      </ConditionalInput>
      <ConditionalInput when="type" is="Absence">
        <LeaveSelectInput source="absence_type" />
      </ConditionalInput>
      <CatalogItemAutocompleteInput
        validate={required()}
        family="Prestations horaires effectuées par Cleany"
      />
      <DateInput source="start_date" validate={required()} parse={parseDate} />
      <DateInput source="end_date" parse={parseDate} />
      {!create && <TimeInput source="start_time" validate={[required()]} />}
      {!create && <TimeInput source="end_time" validate={required()} />}
      {create ? (
        <PlanningInput source="planning" validate={planningValidation} />
      ) : (
        <DaysInput />
      )}
      <CheacknerInput />
    </SimpleForm>
  );
};

AffectationForm.propTypes = {
  create: PropTypes.bool,
  onSubmit: PropTypes.func,
  record: PropTypes.object,
  save: PropTypes.func,
  translate: PropTypes.func,
};

AffectationForm.defaultProps = {
  create: false,
  record: {},
  onSubmit: /* istanbul ignore next */ () => {},
  save: /* istanbul ignore next */ () => {},
  translate: /* istanbul ignore next */ () => {},
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, redirect) => {
      const payload = {
        record: {
          ...values,
          cleaner_ids: values.cleaner_ids || [values.cleaner_id],
        },
        redirect,
        basePath: ownProps.basePath,
      };

      dispatch(saveAffectationBundle(payload));
    },
  };
};

const enhance = compose(connect(null, mapDispatchToProps), translateComponent);

export default enhance(AffectationForm);
