import React, { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { Button, translate as translateComponent } from 'react-admin';
import { updateSchedulerJob as updateSchedulerJobAction } from '../../../action';

const LaunchButton = ({
  translate,
  updateSchedulerJob,
  record,
  variant,
  size,
}) => {
  const [disable, setDisable] = useState(
    record.next_execution_date && moment().isAfter(record.next_execution_date),
  );

  return (
    <Button
      disabled={disable}
      label={translate('resources.scheduler.buttons.launch')}
      variant={variant}
      size={size}
      onClick={() => {
        setDisable(true);
        updateSchedulerJob();
      }}
    />
  );
};

LaunchButton.propTypes = {
  translate: PropTypes.func,
  updateSchedulerJob: PropTypes.func,
  record: PropTypes.object,
  variant: PropTypes.string,
  size: PropTypes.string,
};

LaunchButton.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
  updateSchedulerJob: /* istanbul ignore next */ () => {},
  record: {},
  variant: 'contained',
  size: 'small',
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    updateSchedulerJob: () => {
      dispatch(
        updateSchedulerJobAction(ownProps.record.id, {
          active: true,
          next_execution_date: moment()
            .subtract(1, 'day')
            .format(),
        }),
      );
    },
  };
};

const enhance = compose(translateComponent, connect(null, mapDispatchToProps));

export default enhance(LaunchButton);
