import React from 'react';
import PropTypes from 'prop-types';
import { FileInput, FileField, useTranslate } from 'react-admin';

const RenderFileInput = ({ isMultiple, source }) => {
  const translate = useTranslate();

  return (
    <FileInput
      label=""
      multiple={isMultiple}
      source={source}
      placeholder={<p>{translate('components.form.file_input.placeholder')}</p>}
    >
      <FileField source={source} title="name" />
    </FileInput>
  );
};

RenderFileInput.propTypes = {
  isMultiple: PropTypes.bool,
  source: PropTypes.string,
};

RenderFileInput.defaultProps = {
  isMultiple: false,
  source: '',
};

export default RenderFileInput;
