import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  TextInput,
  BooleanInput,
  SelectInput,
  NumberInput,
  translate as translateComponent,
} from 'react-admin';

const FilterAccount = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput source="search" label="Recherche Compte" alwaysOn />
      <TextInput alwaysOn source="manager_fullname" />
      <BooleanInput source="has_recurring_contract" alwaysOn allowEmpty />

      <NumberInput source="number_of_employees" label="Effectif" />
      <NumberInput source="surface" label="Surface" />
      <SelectInput
        allowEmpty
        source="payment_method"
        choices={[
          {
            id: 'Chèque',
            name: 'Chèque',
          },
          {
            id: 'Prélèvement SEPA',
            name: 'Prélèvement SEPA',
          },
          {
            id: 'Virement',
            name: 'Virement',
          },
        ]}
      />
      <TextInput source="operational_fullname" label="Opérationnel"/>
      <TextInput source="salesman_fullname" label="Commercial"/>
    </Filter>
  );
};

FilterAccount.propTypes = {
  translate: PropTypes.func,
};

FilterAccount.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(FilterAccount);
