import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { clearAccountsInvoices } from '../../../../action';

import UploadDialog from './UploadDialog';
import UploadForm from './UploadForm';

const submitForm = () => {
  document
    .getElementById('invoiceProviderUpload')
    .dispatchEvent(new Event('submit', { cancelable: true }));
};

const UploadInvoiceDialog = ({
  accountsInvoices,
  className,
  loading,
  onClose,
  uploadErrors,
}) => {
  return (
    <UploadDialog
      accountsInvoices={accountsInvoices}
      className={className}
      errors={uploadErrors}
      loading={loading}
      form={<UploadForm />}
      onClose={onClose}
      submitForm={submitForm}
    />
  );
};

UploadInvoiceDialog.propTypes = {
  accountsInvoices: PropTypes.object,
  className: PropTypes.string,
  loading: PropTypes.number,
  onClose: PropTypes.func,
  uploadErrors: PropTypes.array,
};

UploadInvoiceDialog.defaultProps = {
  accountsInvoices: {},
  className: undefined,
  loading: 0,
  onClose: /* istanbul ignore next */ () => {},
  uploadErrors: [],
};

export const mapStateToProps = (state) => {
  return {
    loading: state.providerInvoices ? state.providerInvoices.loading : 0,
    accountsInvoices: state.providerInvoices
      ? state.providerInvoices.accountsInvoices
      : [],
    uploadErrors: state.providerInvoices
      ? state.providerInvoices.uploadErrors
      : '',
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    onClose: () => {
      dispatch(clearAccountsInvoices());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadInvoiceDialog);
