import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Typography } from '@material-ui/core';
import { useTranslate } from 'react-admin';

const LetterStatusField = ({ record, source }) => {
  const value = get(record, source);

  const translate = useTranslate();

  return (
    <Typography
     component="span" variant="body2"
      >
      {translate(`components.field.letter_status.${value}`,)}
      </Typography>
  );
};

LetterStatusField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

LetterStatusField.defaultProps = {
  record: {},
};

export default LetterStatusField;
