const multiLineStringToArray = (string) => {
  if (!string) {
    return [];
  }

  let array = [];

  const normalize = string.replace(/,\n/g, '\n');
  array = normalize.split('\n');

  return array;
};

export default multiLineStringToArray;
