import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getUser } from '../../../../user';

const GoToOneOffQuoteRequestTypeformButton = ({ className, record }) => {
  const user = getUser();
  const baseUrl = process.env.REACT_APP_ONE_OFF_QUOTE_REQUEST_TYPEFORM_URL;
  const managerFragment = `manager_email=${user.email}&manager_first_name=${user.firstname}&manager_last_name=${user.firstname} ${user.lastname}`;
  const operationalContactFragment = `operational_contact_email=${record.operational_email}&operational_contact_full_name=${record.operational_fullname}&operational_contact_telephone=${record.operational_mobile_phone}`;
  const clientLocationFragment = `client_location_name=${record.name}&client_location_address=${record.shipping_address}`;
  return (
    <Fragment>
      <Route
        render={() => {
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${baseUrl}#${managerFragment}&${operationalContactFragment}&${clientLocationFragment}`,
                );
              }}
            >
              <ListItemText primary="1️⃣ Demande de devis ponctuel" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

GoToOneOffQuoteRequestTypeformButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

GoToOneOffQuoteRequestTypeformButton.defaultProps = {
  className: undefined,
  record: undefined,
};

export default GoToOneOffQuoteRequestTypeformButton;
