import React from 'react';
import PropTypes from 'prop-types';
import { ReferenceManyField, useTranslate } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import ContractList from './ContractList';

const useStyles = makeStyles({
  drawer: {
    width: 350,
    zIndex: 1000,
  },
  title: {
    paddingLeft: 20,
    marginTop: 80,
  },
  default: {
    paddingLeft: 20,
    paddingTop: 20,
    paddingRight: 20,
  },
});

const Aside = ({ basePath, cleanerId, resource }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <Drawer
      variant="persistent"
      classes={{ paper: classes.drawer }}
      open
      anchor="right"
    >
      <Typography className={classes.title} variant="h5">
        {translate('resources.cleaner_contract.aside.title')}
      </Typography>
      {cleanerId ? (
        <ReferenceManyField
          basePath={basePath}
          resource={resource}
          record={{ cleanerId }}
          source="cleanerId"
          addLabel={false}
          reference="cleaner_contract"
          target="cleaner_id"
        >
          <ContractList />
        </ReferenceManyField>
      ) : (
        <Typography className={classes.default}>
          {translate('resources.cleaner_contract.aside.no_value')}
        </Typography>
      )}
    </Drawer>
  );
};

Aside.propTypes = {
  basePath: PropTypes.string,
  cleanerId: PropTypes.string,
  resource: PropTypes.string,
};

Aside.defaultProps = {
  basePath: '',
  cleanerId: null,
  resource: '',
};

export default Aside;
