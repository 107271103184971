import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, withStyles } from '@material-ui/core/styles';

import { ReplacementCompletionField } from '../../../../components';

const styles = createStyles({
  container: {
    paddingBottom: 4,
  },
  text: {
    'font-weight': 500,
  },
});

const ContractReplacementItemHeader = ({
  record,
  open,
  totalDuration,
  replacementDuration,
  classes,
}) => {
  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.container}
        >
          <Grid item xs={11}>
            <ListItemText>
              <TextField
                className={classes.text}
                record={record}
                source="opportunity_name"
                variant="subtitle1"
              />
            </ListItemText>
          </Grid>
          <Grid item xs={1}>
            {open ? <ExpandLess /> : <ExpandMore />}
          </Grid>
        </Grid>
      </Grid>
      {!!totalDuration && (
        <ReplacementCompletionField
          current={replacementDuration}
          max={totalDuration}
        />
      )}
    </Grid>
  );
};

ContractReplacementItemHeader.propTypes = {
  record: PropTypes.object,
  open: PropTypes.bool,
  totalDuration: PropTypes.number,
  replacementDuration: PropTypes.number,
  classes: PropTypes.object,
};

ContractReplacementItemHeader.defaultProps = {
  record: {},
  open: true,
  totalDuration: 0,
  replacementDuration: 0,
  classes: {},
};

export default withStyles(styles)(ContractReplacementItemHeader);
