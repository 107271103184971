import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import CloudUpload from '@material-ui/icons/CloudUpload';
import { Button } from 'react-admin';

const UploadDialog = ({
  className,
  form,
  openButtonText,
  record,
  submitForm,
  title,
  uploadButtonText,
}) => {
  const [open, setOpen] = useState(false);
  const invertOpenStatus = () => {
    setOpen(!open);
  };

  return (
    <Fragment>
      <Button
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
        label={openButtonText}
      />
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle color="primary" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          {React.cloneElement(form, {
            additionalActionOnSubmit: invertOpenStatus,
            record,
          })}
        </DialogContent>
        <DialogActions>
          <Button
            label={uploadButtonText}
            variant="contained"
            onClick={submitForm}
          >
            <CloudUpload />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

UploadDialog.propTypes = {
  className: PropTypes.string,
  submitForm: PropTypes.func,
  form: PropTypes.element,
  openButtonText: PropTypes.string,
  record: PropTypes.object,
  title: PropTypes.string,
  uploadButtonText: PropTypes.string,
};

UploadDialog.defaultProps = {
  className: undefined,
  submitForm: /* istanbul ignore next */ () => {},
  form: null,
  openButtonText: '',
  record: {},
  title: '',
  uploadButtonText: '',
};

export default UploadDialog;
