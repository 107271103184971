import React from 'react';
import { ReferenceManyField } from 'react-admin';

import { AffectationList } from '../../../../components';

const CleanerAffectationField = (props) => {
  return (
    <ReferenceManyField
      {...props}
      basePath="/affectation"
      target="cleaner_id"
      source="cleaner_id"
      reference="affectation"
      filter={{ active: true, absence_type: undefined }}
    >
      <AffectationList />
    </ReferenceManyField>
  );
};

export default CleanerAffectationField;
