import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@material-ui/core';

import ContractListItem from './ContractListItem';

const ContractList = ({ ids, data }) => {
  return (
    <List>
      {ids.map((id) => {
        return <ContractListItem key={id} record={data[id]} />;
      })}
    </List>
  );
};

ContractList.propTypes = {
  data: PropTypes.object,
  ids: PropTypes.array,
};

ContractList.defaultProps = {
  data: {},
  ids: [],
};

export default ContractList;
