export const UPLOAD_PROVIDER_INVOICE = 'SAGA/UPLOAD_PROVIDER_INVOICE';
export const SET_ACCOUNTS_INVOICES = 'SET_ACCOUNTS_INVOICES';
export const SET_INVOICE_UPLOAD_ERRORS = 'SET_INVOICE_UPLOAD_ERRORS';
export const CLEAR_ACCOUNTS_INVOICES = 'CLEAR_ACCOUNTS_INVOICES';
export const FETCH_ACCOUNTS_INVOICES = 'FETCH_ACCOUNTS_INVOICES';
export const FETCH_ERROR_ACCOUNTS_INVOICES = 'FETCH_ERROR_ACCOUNTS_INVOICES';

export const uploadProviderInvoice = (payload) => {
  return {
    type: UPLOAD_PROVIDER_INVOICE,
    payload,
  };
};

export const clearAccountsInvoices = () => {
  return {
    type: CLEAR_ACCOUNTS_INVOICES,
  };
};
