import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import get from 'lodash.get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

export const formatTime = (hours, minutes, seconds) => {
  const hoursFormated = hours >= 10 ? hours : `0${hours}`;
  const minutesFormated = minutes >= 10 ? minutes : `0${minutes}`;
  const secondsFormated = seconds >= 10 ? seconds : `0${seconds}`;
  return `${hoursFormated}:${minutesFormated}:${secondsFormated}`;
};

const TimeFromMillisecondsField = ({ source, record }) => {
  const milliseconds = get(record, source);
  if (!milliseconds) {
    return null;
  }
  const duration = moment.duration(milliseconds);
  const time = formatTime(
    duration.hours(),
    duration.minutes(),
    duration.seconds(),
  );
  return <Typography variant="body1">{time}</Typography>;
};

TimeFromMillisecondsField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

TimeFromMillisecondsField.defaultProps = {
  record: {},
};

const PureTimeFromMillisecondsField = pure(TimeFromMillisecondsField);

PureTimeFromMillisecondsField.defaultProps = {
  addLabel: true,
};

export default PureTimeFromMillisecondsField;
