import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import ContentAdd from '@material-ui/icons/Add';
import { Button } from 'react-admin';

const CreateButton = ({ pathname, initialValues }) => {
  return (
    <Button
      component={Link}
      label="ra.action.create"
      to={{
        pathname,
        state: { record: initialValues },
      }}
    >
      <ContentAdd />
    </Button>
  );
};

CreateButton.propTypes = {
  pathname: PropTypes.string.isRequired,
  initialValues: PropTypes.object,
};

CreateButton.defaultProps = {
  initialValues: {},
};

export default CreateButton;
