import React from 'react';
import { SelectInput, useTranslate } from 'react-admin';

const SubtypeSelectInput = (props) => {
  const translate = useTranslate();

  return (
    <SelectInput
      {...props}
      label={translate('resources.cleaner_document.fields.subtype')}
      source="subtype"
      choices={[
        {
          id: 'work_injury',
          name: 'resources.cleaner_document.subtype.work_injury',
        },
        {
          id: 'birth_certificat',
          name: 'resources.cleaner_document.subtype.birth_certificat',
        },
        {
          id: 'accuse_due',
          name: 'resources.cleaner_document.subtype.accuse_due',
        },
        {
          id: 'AR_demission',
          name: 'resources.cleaner_document.subtype.AR_demission',
        },
        {
          id: 'sick_leave',
          name: 'resources.cleaner_document.subtype.sick_leave',
        },
        {
          id: 'acs_attestation',
          name: 'resources.cleaner_document.subtype.acs_attestation',
        },
        {
          id: 'cmu_attestation',
          name: 'resources.cleaner_document.subtype.cmu_attestation',
        },
        {
          id: 'image',
          name: 'resources.cleaner_document.subtype.image',
        },
        {
          id: 'babysitting_attestation',
          name: 'resources.cleaner_document.subtype.babysitting_attestation',
        },
        {
          id: 'insurance_attestation',
          name: 'resources.cleaner_document.subtype.insurance_attestation',
        },
        {
          id: 'occupational_medecine_attestation',
          name:
            'resources.cleaner_document.subtype.occupational_medecine_attestation',
        },
        {
          id: 'invalidity_attestation',
          name: 'resources.cleaner_document.subtype.invalidity_attestation',
        },
        {
          id: 'attestation_etudiante',
          name: 'resources.cleaner_document.subtype.attestation_etudiante',
        },
        {
          id: 'vitale_attestation',
          name: 'resources.cleaner_document.subtype.vitale_attestation',
        },
        {
          id: 'host_attestation',
          name: 'resources.cleaner_document.subtype.host_attestation',
        },
        {
          id: 'visa_authentification',
          name: 'resources.cleaner_document.subtype.visa_authentification',
        },
        {
          id: 'autre_absence',
          name: 'resources.cleaner_document.subtype.autre_absence',
        },
        {
          id: 'avertissement',
          name: 'resources.cleaner_document.subtype.avertissement',
        },
        {
          id: 'bulletin_repris',
          name: 'resources.cleaner_document.subtype.bulletin_repris',
        },
        {
          id: 'insurance_subscription',
          name: 'resources.cleaner_document.subtype.insurance_subscription',
        },
        {
          id: 'pass',
          name: 'resources.cleaner_document.subtype.pass',
        },
        {
          id: 'vitale_card',
          name: 'resources.cleaner_document.subtype.vitale_card',
        },
        {
          id: 'maternity_leave_cerfa',
          name: 'resources.cleaner_document.subtype.maternity_leave_cerfa',
        },
        {
          id: 'death_certificat',
          name: 'resources.cleaner_document.subtype.death_certificat',
        },
        {
          id: 'maternity_leave_proof',
          name: 'resources.cleaner_document.subtype.maternity_leave_proof',
        },
        {
          id: 'id_card_front',
          name: 'resources.cleaner_document.subtype.id_card_front',
        },
        {
          id: 'id_card_back',
          name: 'resources.cleaner_document.subtype.id_card_back',
        },
        {
          id: 'contrat',
          name: 'resources.cleaner_document.subtype.contrat',
        },
        {
          id: 'EPAL',
          name: 'resources.cleaner_document.subtype.EPAL',
        },
        {
          id: 'prefecture',
          name: 'resources.cleaner_document.subtype.prefecture',
        },
        {
          id: 'medical_visit',
          name: 'resources.cleaner_document.subtype.medical_visit',
        },
        {
          id: 'DPAE',
          name: 'resources.cleaner_document.subtype.DPAE',
        },
        {
          id: 'going_retirement',
          name: 'resources.cleaner_document.subtype.going_retirement',
        },
        {
          id: 'advance_payment',
          name: 'resources.cleaner_document.subtype.advance_payment',
        },
        {
          id: 'work_autorisation',
          name: 'resources.cleaner_document.subtype.work_autorisation',
        },
        {
          id: 'maternity_leave_request',
          name: 'resources.cleaner_document.subtype.maternity_leave_request',
        },
        {
          id: 'waiver_letter',
          name: 'resources.cleaner_document.subtype.waiver_letter',
        },
        {
          id: 'insurance_refusal',
          name: 'resources.cleaner_document.subtype.insurance_refusal',
        },
        {
          id: 'family_book',
          name: 'resources.cleaner_document.subtype.family_book',
        },
        {
          id: 'receipt',
          name: 'resources.cleaner_document.subtype.receipt',
        },
        {
          id: 'trial_end',
          name: 'resources.cleaner_document.subtype.trial_end',
        },
        {
          id: 'home_receipt',
          name: 'resources.cleaner_document.subtype.home_receipt',
        },
        {
          id: 'resignation_letter',
          name: 'resources.cleaner_document.subtype.resignation_letter',
        },
        {
          id: 'MED',
          name: 'resources.cleaner_document.subtype.MED',
        },
        {
          id: 'notice_of_dismissal',
          name: 'resources.cleaner_document.subtype.notice_of_dismissal',
        },
        {
          id: 'passport',
          name: 'resources.cleaner_document.subtype.passport',
        },
        {
          id: 'host_id_card',
          name: 'resources.cleaner_document.subtype.host_id_card',
        },
        {
          id: 'depot_dossier_prefecture',
          name: 'resources.cleaner_document.subtype.depot_dossier_prefecture',
        },
        {
          id: 'final_settlement',
          name: 'resources.cleaner_document.subtype.final_settlement',
        },
        {
          id: 'reconnaissance_AT_MP',
          name: 'resources.cleaner_document.subtype.reconnaissance_AT_MP',
        },
        {
          id: 'reconnaissance_travailleur_handicape',
          name: 'resources.cleaner_document.subtype.reconnaissance_travailleur_handicape',
        },
        {
          id: 'visa_receipt',
          name: 'resources.cleaner_document.subtype.visa_receipt',
        },
        {
          id: 'trial_renewal',
          name: 'resources.cleaner_document.subtype.trial_renewal',
        },
        {
          id: 'keys_hand_over',
          name: 'resources.cleaner_document.subtype.keys_hand_over',
        },
        {
          id: 'rib',
          name: 'resources.cleaner_document.subtype.rib',
        },
        {
          id: 'visa_recto',
          name: 'resources.cleaner_document.subtype.visa_recto',
        },
        {
          id: 'visa_verso',
          name: 'resources.cleaner_document.subtype.visa_verso',
        },
        {
          id: 'TAKEN_OVER',
          name: 'resources.cleaner_document.subtype.TAKEN_OVER',
        },
        {
          id: 'contract_transfer',
          name: 'resources.cleaner_document.subtype.contract_transfer',
        },
      ]}
    />
  );
};

export default SubtypeSelectInput;
