import React from 'react';
import { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
  translate as translateComponent,
  BulkDeleteButton,
} from 'react-admin';

import { DrawerCreateEdit } from '../../containers';
import { ContractField, NumberWithUnitField } from '../../components';
import Edit from './Edit';
import Create from './Create';
import Filter from './detail/Filter';
import Actions from './detail/Actions';

const PostBulkActionButtons = props => (
    <Fragment>
        <BulkDeleteButton {...props} />
    </Fragment>
);

const ProviderInvoiceList = ({ translate, ...props }) => {
  return (
    <DrawerCreateEdit
      {...props}
      routePath="/provider_invoice"
      renderCreate={Create}
      renderEdit={Edit}
    >
      <List
        {...props}
        filters={<Filter />}
        hasCreate
        actions={<Actions />}
        bulkActionButtons={<PostBulkActionButtons />}
        perPage={25}
      >
        <Datagrid rowClick="edit">
          <NumberWithUnitField source="amount" unit="€ (HT)" />
          <DateField source="date" />
          <ReferenceField
            link={false}
            source="contract_id"
            reference="contract"
          >
            <ContractField />
          </ReferenceField>
          <ReferenceField
            link="show"
            source="provider_account_id"
            reference="account"
          >
            <TextField source="name" />
          </ReferenceField>
          <TextField source="invoice_number" />
          <NumberWithUnitField
            source="amortization"
            unit={translate('resources.provider_invoice.unit.month')}
          />
        </Datagrid>
      </List>
    </DrawerCreateEdit>
  );
};

ProviderInvoiceList.propTypes = {
  translate: PropTypes.func,
};

ProviderInvoiceList.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(ProviderInvoiceList);
