import React, { useState } from 'react';
import { translate as translateComponent, Button } from 'react-admin';
import PropTypes from 'prop-types';

const AnimatedTextButton = ({
  onClick,
  label,
  labelAnimated,
  Icon,
  IconAnimated,
  translate,
}) => {
  const [animated, setAnimated] = useState(false);
  return (
    <Button
      label={animated ? translate(labelAnimated) : translate(label)}
      onClick={() => {
        setAnimated(true);
        onClick();
        setTimeout(() => {
          setAnimated(false);
        }, 2000);
      }}
    >
      {animated ? <IconAnimated /> : <Icon />}
    </Button>
  );
};

AnimatedTextButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  labelAnimated: PropTypes.string,
  Icon: PropTypes.object.isRequired,
  IconAnimated: PropTypes.object.isRequired,
  translate: PropTypes.func,
};

AnimatedTextButton.defaultProps = {
  onClick: /* istanbul ignore next */ () => {},
  label: '',
  labelAnimated: '',
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(AnimatedTextButton);
