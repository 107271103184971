import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';

import CleanerAffectationItem from './CleanerAffectationItem';

const CleanerAffectationList = ({ cleanerIds, basePath }) => {
  return (
    <List disablePadding>
      {cleanerIds.map((cleanerId) => {
        return (
          <CleanerAffectationItem
            key={cleanerId}
            cleanerId={cleanerId}
            basePath={basePath}
          />
        );
      })}
    </List>
  );
};

CleanerAffectationList.propTypes = {
  basePath: PropTypes.string,
  cleanerIds: PropTypes.array,
};

CleanerAffectationList.defaultProps = {
  basePath: '',
  cleanerIds: [],
};

export default CleanerAffectationList;
