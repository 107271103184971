import React from 'react';
import PropTypes from 'prop-types';
import { withStyles, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = createStyles({
  root: {
    width: 400,
    height: 304,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

const LoadingImageField = ({ classes }) => {
  return (
    <div className={classes.root}>
      <CircularProgress />
    </div>
  );
};

LoadingImageField.propTypes = {
  classes: PropTypes.object,
};

LoadingImageField.defaultProps = {
  classes: {},
};

export default withStyles(styles)(LoadingImageField);
