import React from 'react';
import { Grid } from '@material-ui/core';

import PonctualCard from './PonctualCard';
import GlazingCard from './GlazingCard';

const TabAffectation = (props) => {
  return (
    <Grid container spacing={2}>
      <GlazingCard {...props} />
      <PonctualCard {...props} />
    </Grid>
  );
};

export default TabAffectation;
