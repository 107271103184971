import React from 'react';
import {
  AutocompleteInput,
  BooleanInput,
  DateInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useTranslate,
} from 'react-admin';
import { InputsBlock } from '../../../components';
import { nationalities, parseDate } from '../../../helpers';
import FunctionSelectInput from './FunctionSelectInput';
import ContractEndTypeInput from './ContractEndTypeInput';
import ContractEndReasonInput from './ContractEndReasonInput';
import DocumentReferenceInput from './DocumentReferenceInput';

const Form = (props) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props} redirect="show">
      <InputsBlock title={translate('resources.cleaner.card_title.civil')}>
        <SelectInput
          source="salutation"
          choices={[
            { id: 'Mr.', name: 'Mr.' },
            { id: 'Mrs.', name: 'Mrs.' },
          ]}
          defaultValue="Mr."
          validate={[required()]}
        />
        <TextInput source="firstname" validate={[required()]} />
        <TextInput source="lastname" validate={[required()]} />
        <FunctionSelectInput />
        <ReferenceInput
          filterToQuery={
            /* istanbul ignore next */
            (searchText) => {
              return { fullname: searchText };
            }
          }
          sort={{ field: 'fullname', order: 'ASC' }}
          filter={{
            'in(function)': [
              'Manager',
              'Admin',
              'Team Leader',
              'Office Employee',
              'Customer Service',
            ],
            employee_status: 'Salarié Actuel',
          }}
          perPage={50}
          source="manager_id"
          label="Manager"
          reference="cleaner"
          validate={[required()]}
        >
          <AutocompleteInput optionText="fullname" />
        </ReferenceInput>
        <TextInput source="email" />
        <TextInput source="mobile_phone" />
        <TextInput source="phone" />
        <TextInput source="mailing_street" />
        <TextInput source="mailing_additional" />
        <TextInput source="mailing_postal_code" />
        <TextInput source="mailing_city" />
        <BooleanInput source="quartier_prioritaire_de_la_ville" />
        <DateInput source="birthdate" parse={parseDate} />
        <TextInput source="birthplace" />
        <SelectInput
          source="nationality"
          choices={nationalities}
          defaultValue="France"
        />
        <TextInput source="social_security_number" />
        <TextInput source="residence_permit_number" />
        <DateInput source="residence_permit_expiry_date" />
        <TextInput source="residence_permit_nature" />
        <TextInput source="payfit_id" />
        <TextInput source="silae_id" />
        <TextInput source="drive_folder_id" />
      </InputsBlock>

      <InputsBlock
        title={translate('resources.cleaner.card_title.health_care')}
      >
        <BooleanInput source="health_insurance_exemption" />
        <SelectInput
          source="health_insurance_exemption_reason"
          allowEmpty
          emptyText="ra.action.clear_input_value"
          choices={[
            { id: 'ACS', name: 'ACS' },
            { id: 'Apprenti', name: 'Apprenti' },
            { id: 'CDD de moins de 12 mois', name: 'CDD de moins de 12 mois' },
            { id: 'CMU', name: 'CMU' },
            { id: 'Contrat collectif', name: 'Contrat collectif' },
            { id: 'Contrat individuel', name: 'Contrat individuel' },
            { id: 'Contrat loi Madelin', name: 'Contrat loi Madelin' },
          ]}
        />
        <DateInput
          parse={parseDate}
          source="health_insurance_exemption_end_date"
        />
        <DocumentReferenceInput
          source="health_insurance_exemption_file"
          type="health_care"
        />
        <SelectInput
          source="occupational_medecine_reason"
          allowEmpty
          emptyText="ra.action.clear_input_value"
          choices={[
            {
              id: "Visite de reprise arrêt maladie d'au moins 30 jours",
              name: "Visite de reprise arrêt maladie d'au moins 30 jours",
            },
            {
              id: "Visite de reprise accident du travail d'au moins 30 jours",
              name: "Visite de reprise accident du travail d'au moins 30 jours",
            },
            {
              id: 'Visite de reprise maladie professionnelle',
              name: 'Visite de reprise maladie professionnelle',
            },
            {
              id: 'Visite de reprise congé maternité',
              name: 'Visite de reprise congé maternité',
            },
            {
              id: "Visite initiale d'information et de prévention",
              name: "Visite initiale d'information et de prévention",
            },
            { id: 'Visite de suivi', name: 'Visite de suivi' },
          ]}
        />
        <DateInput parse={parseDate} source="occupational_medecine_date" />
        <DocumentReferenceInput
          source="occupational_medecine_file"
          type="occupational_medecine"
        />
        <TextInput source="spvie_id" />
      </InputsBlock>

      <InputsBlock title={translate('resources.cleaner.card_title.contract')}>
        <BooleanInput source="is_taken_over" />
        <BooleanInput source="cdd_etudiant" />
        <DateInput
          source="contract_start_date"
          parse={parseDate}
          validate={[required()]}
        />
        <DateInput
          source="seniority_date"
          parse={parseDate}
          validate={[required()]}
        />
        <DateInput source="trial_period_renewal_signing" parse={parseDate} />
        <DateInput source="contract_end_date" parse={parseDate} />
        <ContractEndTypeInput source="contract_end_type" />
        <ContractEndReasonInput source="contract_end_reason" />
      </InputsBlock>
    </SimpleForm>
  );
};

export default Form;
