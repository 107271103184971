import React from 'react';
import {
  SelectField,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  EmailField,
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import { TimeField } from '../../components';
import Filter from './detail/Filter';

export default (props) => {
  return (
    <List
      {...props}
      filter={{ type: 'Client' }}
      filters={<Filter />}
      filterDefaultValues={{ has_recurring_contract: true }}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
    >
      <CustomizableDatagrid
        defaultColumns={[
          'name',
          'manager_fullname',
          'operational_fullname',
          'recurring_contract_amount',
        ]}
        rowClick="show"
      >
        {/* {{{  default values */}
        <TextField source="name" />
        <TextField source="manager_fullname" />
        <TextField source="operational_fullname" />
        <NumberField
          source="recurring_contract_amount"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <SelectField source="recurring_contract_affectations_ok" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <DateField source="recurring_contract_start_date" />
        {/* }}} */}
        {/* {{{  site info */}
        <ReferenceField
          allowEmpty
          link="show"
          source="parent_id"
          reference="cleaner"
        >
          <TextField source="name" />
        </ReferenceField>
        <TextField source="description" />
        <TextField source="industry" />
        <NumberField source="number_of_employees" />
        <NumberField source="surface" />
        <TextField source="shipping_street" />
        <DateField source="shipping_postal_code" />
        <TextField source="shipping_city" />
        <TextField source="shipping_address" />
        <TextField source="tags" />
        <TextField source="drive_folder" />
        <TextField source="type" />
        <TextField source="website" />
        {/* }}} */}
        {/* {{{  access */}
        <TextField source="building_areas" />
        <TextField source="garbage_location" />
        <TextField source="storage_locker_location" />
        <TextField source="transit_stop" />
        <TextField source="access" />
        <SelectField source="duplicate_access" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        {/* }}} */}
        {/* {{{  satisfaction */}
        <NumberField source="quality_controls_mean" />
        <NumberField source="quality_controls_evolution" />
        <TextField source="last_survey_date" />
        <TextField source="last_survey_rating" />
        <TextField source="post_start_appreciation" />
        {/* }}} */}
        {/* {{{  billing */}
        <TextField source="trigram" />
        <SelectField source="bill_with_parent" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <TextField source="payment_method" />
        <TextField source="gcl_customer_id" />
        <TextField source="gcl_mandate_id" />
        <EmailField source="billing_email" />
        <TextField source="billing_address" />
        {/* }}} */}
        {/* {{{  contact */}
        <ReferenceField
          allowEmpty
          link="show"
          source="operational_id"
          reference="contact"
        >
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField
          allowEmpty
          link="show"
          source="salesman_id"
          reference="cleaner"
        >
          <TextField source="fullname" />
        </ReferenceField>
        {/* }}} */}
        {/* {{{  recurring contract stats */}
        <NumberField
          source="recurring_contract_cleaning_amount_per_month"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <TimeField source="recurring_contract_cleaning_duration_per_week" />
        <TimeField source="recurring_contract_permanent_affectation_duration_per_week" />
        <SelectField source="recurring_contract_consumables_ok" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <NumberField
          source="recurring_contract_consumables_amount_per_month"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <NumberField source="recurring_contract_consumables_quantity_per_month" />
        <NumberField
          source="recurring_contract_cost"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <NumberField
          source="recurring_contract_margin"
          options={{ style: 'currency', currency: 'EUR' }}
        />
        <NumberField
          source="recurring_contract_percent_margin"
          options={{ style: 'percent', maximumFractionDigits: 1 }}
        />
        {/* }}} */}
        <DateField source="created_date" />
        <DateField source="last_modified_date" />
      </CustomizableDatagrid>
    </List>
  );
};
