import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useGetManyReference, useNotify, useTranslate } from 'react-admin';
import { ContractAutocompleteInput, EmptyInput } from '../../../../components';

const getContractIds = (data) => {
  return Object.values(data).map((item) => {
    return item.contract_id;
  });
};

const CleanerContractsAutocompleteInput = ({ cleanerId }) => {
  const notify = useNotify();
  const translate = useTranslate();

  const { data, loaded, loading } = useGetManyReference(
    'affectation',
    'cleaner_id',
    cleanerId,
    { page: undefined, perPage: 50 },
    { field: 'start_time', order: 'ASC' },
    {
      active: true,
      'lte(start_date)': moment()
        .add(6, 'months')
        .format('YYYY-MM-DD'),
    },
    'leave',
    {
      onFailure: (error) => {
        return notify(
          typeof error === 'string'
            ? error
            : error.message || 'ra.notification.http_error',
          'warning',
        );
      },
    },
  );

  if (!loaded || loading || !data) {
    return <EmptyInput loading={loading} />;
  }

  const affectationIds = getContractIds(data);

  return (
    <ContractAutocompleteInput
      affectationIds={affectationIds}
      emptyMessage={translate('resources.leave.forms.all')}
    />
  );
};

CleanerContractsAutocompleteInput.propTypes = {
  cleanerId: PropTypes.string.isRequired,
};

export default CleanerContractsAutocompleteInput;
