import React from 'react';
import PropTypes from 'prop-types';
import { TopToolbar, CreateButton, ExportButton, Button } from 'react-admin';

const ListActions = ({
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
}) => {
  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}
      <CreateButton basePath={basePath} />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={currentSort}
        filter={filterValues}
        exporter={exporter}
      />
      <Button
        variant="contained"
        label="🌴 Congés"
        onClick={() => {
          window.open('https://stats.cleany.fr/question/241', '_blank');
        }}
        style={{
          marginLeft: 10,
        }}
      />
    </TopToolbar>
  );
};

ListActions.propTypes = {
  basePath: PropTypes.string,
  currentSort: PropTypes.shape({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  displayedFilters: PropTypes.object,
  exporter: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  filters: PropTypes.element,
  filterValues: PropTypes.object,
  resource: PropTypes.string,
  showFilter: PropTypes.func,
  total: PropTypes.number,
};

ListActions.defaultProps = {
  basePath: '',
  currentSort: null,
  displayedFilters: null,
  exporter: null,
  filterValues: {},
  filters: null,
  resource: '',
  showFilter: () => {},
  total: undefined,
};

export default ListActions;
