import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { GridCard, Tab, TabbedLayout } from '../../../../components';

import PonctualList from './PonctualList';

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
  content: {
    paddingTop: '0 !important',
  },
});

const PonctualCard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      classes={classes}
      title={translate(`resources.account.card_title.ponctual_contract`)}
    >
      <TabbedLayout xs={12}>
        <Tab label={translate('resources.account.tab.actifs')}>
          <PonctualList active />
        </Tab>
        <Tab label={translate('resources.account.tab.history')}>
          <PonctualList
            active={false}
            sort={{ field: 'start_date', order: 'DESC' }}
          />
        </Tab>
      </TabbedLayout>
    </GridCard>
  );
};

export default PonctualCard;
