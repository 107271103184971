import moment from 'moment';

export const dateValidation = (values) => {
  const error = {};
  if (!values) {
    return error;
  }

  const startDate = values.start_date;
  const endDate = values.end_date;

  if (!startDate || !endDate) {
    return error;
  }

  if (moment(startDate) > moment(endDate)) {
    error.end_date = 'components.form.time_validation';
  }

  return error;
};

export const days = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday',
];

export const removeSeconds = (time) => {
  if (!time) {
    return null;
  }

  const timeWithoutSecond = time
    .split(':')
    .slice(0, 2)
    .join(':');

  return timeWithoutSecond;
};

export const timeValidation = (value, values) => {
  if (!value || !values) {
    return undefined;
  }

  const startTime = moment.duration(values.start_time);
  const endTime = moment.duration(value);

  // const minutesTime = endTime.minutes();

  if (startTime > endTime) {
    return 'resources.affectation.forms.time_validation';
  }
  /*
  if (minutesTime % 5 !== 0) {
    return 'resources.affectation.forms.minutes_validation';
  }
  */
  return undefined;
};

export const getDayTimeError = (value) => {
  const error = value.reduce((acc, item) => {
    if (!item || !item.start_time || !item.end_time) {
      return 'resources.affectation.forms.time_required';
    }

    //const startTime = moment.duration(item.start_time);
    // const minutesTime = startTime.minutes();

    /*
    if (minutesTime % 5 !== 0) {
      return 'resources.affectation.forms.minutes_validation';
    } */

    const err = timeValidation(item.end_time, { start_time: item.start_time });

    return err;
  }, '');

  if (error) {
    return error;
  }

  return undefined;
};

export const planningValidation = (value) => {
  const error = {};

  if (!value) {
    error.day = 'resources.affectation.forms.planning_validation';
    return error;
  }

  const valuesSet = Object.keys(value).filter((key) => {
    return !!value[key].length;
  });

  if (!valuesSet || valuesSet.length < 1) {
    error.day = 'resources.affectation.forms.planning_validation';
    return error;
  }
  let isTimeError = false;
  const err = valuesSet.reduce((acc, item) => {
    const timeError = getDayTimeError(value[item]);
    if (timeError) {
      isTimeError = true;
      return { ...acc, [item]: timeError };
    }
    return acc;
  }, {});

  if (isTimeError) {
    error.time = err;
    return error;
  }

  return undefined;
};

export const parseDate = (value) => {
  if (value === '') {
    return null;
  }
  return moment(value).format('YYYY-MM-DD');
};

export const parseTime = (value) => {
  if (value === '') {
    return null;
  }
  return moment(value, 'HH:mm').format('HH:mm:ss');
};
