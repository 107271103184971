import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';
import Filter from './detail/Filter';

export default (props) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid>
        <TextField source="id" />
        <ReferenceField link="show" source="account_id" reference="account">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField link="show" source="provider" reference="account">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="provider_item_name" />
        <TextField source="type" />
        <NumberField source="quantity" />
        <NumberField source="period" />
        <DateField source="last_action" />
        <TextField source="last_action_description" />
        <DateField source="next_action" />
        <TextField source="next_action_description" />
      </Datagrid>
    </List>
  );
};
