import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

const rowStyle = (record, index, defaultStyle = {}) => {
  if (record.status === 'OK') {
    return {
      ...defaultStyle,
      borderLeftColor: green[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  if (record.status === 'PARTIAL') {
    return {
      ...defaultStyle,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  if (record.status === 'INTERRUPTED') {
    return {
      ...defaultStyle,
      borderLeftColor: orange[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  if (record.status === 'FAILED') {
    return {
      ...defaultStyle,
      borderLeftColor: red[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  if (record.status === 'RUNNING') {
    return {
      ...defaultStyle,
      borderLeftColor: blue[500],
      borderLeftWidth: 5,
      borderLeftStyle: 'solid',
    };
  }
  return defaultStyle;
};

export default rowStyle;
