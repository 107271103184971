import { isTeamLeader } from '../../../helpers';

export default (value, values) => {
  if (!values.level) {
    return undefined;
  }

  if (isTeamLeader(values.level)) {
    return value
      ? {
          message: 'resources.cleaner.form.empty_for_team_leader',
        }
      : undefined;
  }

  if (!value) {
    return {
      message: 'resources.cleaner.form.required',
    };
  }

  return undefined;
};
