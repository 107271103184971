import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SimpleForm, BooleanInput, TextInput } from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { CircularProgress } from '@material-ui/core';
// import { CreateAffectation } from '../../../components';

const blueStyle = {
  backgroundColor: '#326CB4',
  fontFamily: 'Roboto',
  fonSize: '0.8125rem',
  fontWeight: 500,
  lineHeight: 1.75,
  color: 'white',
  borderRadius: 5,
  border: 'none',
  marginBottom: 5,
  marginTop: 5,
  cursor: 'pointer',
};

const greyStyle = {
  backgroundColor: '#8e8e8e',
  fontFamily: 'Roboto',
  fonSize: '0.8125rem',
  fontWeight: 500,
  lineHeight: 1.75,
  color: 'white',
  borderRadius: 5,
  border: 'none',
  marginBottom: 5,
  marginTop: 5,
};

const redStyle = {
  backgroundColor: '#f44336',
  fontFamily: 'Roboto',
  fonSize: '0.8125rem',
  fontWeight: 500,
  lineHeight: 1.75,
  color: 'white',
  borderRadius: 5,
  border: 'none',
  marginBottom: 5,
  marginTop: 5,
};

const greenStyle = {
  backgroundColor: 'green',
  fontFamily: 'Roboto',
  fonSize: '0.8125rem',
  fontWeight: 500,
  lineHeight: 1.75,
  color: 'white',
  borderRadius: 5,
  border: 'none',
  marginBottom: 5,
  marginTop: 5,
};

const absenceOption = [
  {
    value: 'UNAUTHORIZED_LEAVE_UNPAID',
    name: '✔️ Absence autorisée (non payée)',
  },
  {
    value: 'LEAVE_ABSENCE_PAID',
    name: '🤑 Absence autorisée (rémunérée)',
  },
  {
    value: 'UNAUTHORIZED_LEAVE',
    name: '❌ Absence injustifiée (non payée)',
  },
  {
    value: 'COMMUTE_INJURY',
    name: '🚇 Accident de trajet',
  },
  {
    value: 'WORK_INJURY',
    name: '⚠️ Accident de travail',
  },
  {
    value: 'TRAINING_LEAVE',
    name: '🎓 Congé Formation',
  },
  {
    value: 'PARENTAL_LEAVE',
    name: '🍼 Congé parental',
  },
  {
    value: 'PATERNITY_LEAVE',
    name: '👨‍🍼 Congé paternité',
  },
  {
    value: 'BIRTH_LEAVE',
    name: '👶 Congé naissance',
  },
  {
    value: 'FAMILY_LEAVE',
    name: '💒 Congé pour événement familial',
  },
  {
    value: 'PAYED_LEAVE',
    name: '💸 Congés payés',
  },
  {
    value: 'UNPAID_LEAVE',
    name: "'🏝️ Congé sans solde",
  },
  {
    value: 'SICK_CHILD',
    name: '🤒 Enfant malade',
  },
  {
    value: 'CLOSED_SITE',
    name: '🔒 Fermeture de site',
  },
  {
    value: 'ILLNESS',
    name: '🤧 Maladie ordinaire',
  },
  {
    value: 'WORK_ILLNESS',
    name: '🩹 Maladie professionnelle',
  },
  {
    value: 'MATERNITY_LEAVE',
    name: '🤰 Maternité',
  },
  {
    value: 'CONSERVATORY_LAID_OFF',
    name: '⌛ Mise à pied à titre conservatoire',
  },
  {
    value: 'DISCIPLINARY_LAID_OFF',
    name: '😠 Mise à pied à titre disciplinaire',
  },
  {
    value: 'SABBATICAL_LEAVE',
    name: '🤠 Congé sabbatique',
  },
];

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const ProviderForm = ({ onCancel, record, ...props }) => {
  const [absence, setAbsence] = useState(0);
  const [absenceStyle, setAbsenceStyle] = useState(greyStyle);
  const [affectation, setAffectation] = useState(0);
  const [affectationStyle, setAffectationStyle] = useState(greyStyle);
  const [isLoadingAbs] = useState(false);
  const [isLoadingAff] = useState(false);
  const [absenceType, setAbsenceType] = useState('UNAUTHORIZED_LEAVE');
  const [items] = useState([]);
  const [itemChoice, setItemChoice] = useState('01t0Y000001C4C7QAK');
  const [errorLeave] = useState(null);
  const [errorAffectation] = useState(null);
  const [selectedContract] = useState(null);

  useEffect(() => {
    if (record.type === 'absence') {
      setAbsence(1);
      setAffectation(0);
    }

    if (record.type === 'ponctuel') {
      setAbsence(0);
      setAffectation(1);
    }

    if (record.type === 'permanent') {
      setAbsence(0);
      setAffectation(1);
    }

    if (record.type === 'remplacement') {
      setAbsence(1);
      setAffectation(0);
    }
  }, []);

  useEffect(() => {
    if (absence === 0) {
      setAbsenceStyle(greyStyle);
    }

    if (absence === 1) {
      setAbsenceStyle(blueStyle);
    }

    if (absence === 2) {
      setAbsenceStyle(greenStyle);
    }

    if (absence === 3) {
      setAbsenceStyle(redStyle);
    }
  }, [absence]);

  useEffect(() => {
    if (affectation === 0) {
      setAffectationStyle(greyStyle);
    }

    if (affectation === 1) {
      setAffectationStyle(blueStyle);
    }

    if (affectation === 2) {
      setAffectationStyle(greenStyle);
    }

    if (affectation === 3) {
      setAffectationStyle(redStyle);
    }
  }, [affectation]);

  return (
    <Fragment>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      {/* <CreateAffectation record={record} /> */}
      <div>
        {(record.type === 'remplacement' || record.type === 'absence') && (
          <>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              onClick={() => {
                console.log('click absence');
                if (
                  absence === 1 &&
                  isLoadingAbs === false &&
                  selectedContract !== null
                ) {
                  // createAbsence();
                }
              }}
              style={absenceStyle}
            >
              CREER ABSENCE
            </button>
            {errorLeave !== null && (
              <p style={{ width: 160, color: 'red', padding: 5 }}>
                {errorLeave}
              </p>
            )}
            <div>
              <select
                value={absenceType}
                onChange={(e) => {
                  setAbsenceType(e.target.value);
                }}
              >
                {absenceOption.map((item) => {
                  return (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <option value={item.value}>{item.name}</option>
                    </>
                  );
                })}
              </select>
            </div>
            {isLoadingAbs && (
              <div
                style={{
                  display: 'inline-block',
                  paddingLeft: 20,
                  paddingTop: 10,
                }}
              >
                <CircularProgress size={20} />
              </div>
            )}
          </>
        )}
      </div>
      <div>
        {record.type !== 'absence' && (
          <>
            {/* eslint-disable-next-line react/button-has-type */}
            <button
              onClick={() => {
                if (
                  affectation === 1 &&
                  isLoadingAff === false &&
                  selectedContract !== null
                ) {
                  // createAffectation();
                }
              }}
              style={affectationStyle}
            >
              CREER AFFECTATION
            </button>
            {errorAffectation !== null && <span>{errorAffectation}</span>}
            <div>
              <select
                value={itemChoice}
                onChange={(e) => {
                  setItemChoice(e.target.value);
                }}
                style={{ width: '230px' }}
              >
                {items.map((item) => {
                  return (
                    <>
                      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            {isLoadingAff && (
              <div
                style={{
                  display: 'inline-block',
                  paddingLeft: 20,
                  paddingTop: 15,
                }}
              >
                <CircularProgress size={20} />
              </div>
            )}
          </>
        )}
      </div>
      <SimpleForm {...sanitizeRestProps(props)} record={record}>
        <BooleanInput source="traite" />
        <TextInput fullWidth multiline source="commentaire" />
        <TextInput
          fullWidth
          multiline
          label="Commentaire RH"
          source="commentaire_validateur"
        />
      </SimpleForm>
    </Fragment>
  );
};

ProviderForm.propTypes = {
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

ProviderForm.defaultProps = {
  onCancel: /* istanbul ignore next */ () => {},
  record: {},
};

export default ProviderForm;
