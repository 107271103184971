import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import { translate as translateComponent } from 'react-admin';
import Typography from '@material-ui/core/Typography';

const TranslatedField = ({
  className,
  record,
  source,
  translate,
  translationPath,
  variant,
}) => {
  if (!get(record, source)) {
    return null;
  }
  return (
    <Typography className={className} component="span" variant={variant}>
      {translate(`${translationPath}.${get(record, source)}`)}
    </Typography>
  );
};

TranslatedField.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  translate: PropTypes.func,
  translationPath: PropTypes.string.isRequired,
  variant: PropTypes.string,
};

TranslatedField.defaultProps = {
  className: undefined,
  record: {},
  translate: /* istanbul ignore next */ () => {},
  variant: 'body2',
};

const PureTranslatedField = pure(translateComponent(TranslatedField));

PureTranslatedField.defaultProps = {
  addLabel: true,
};

export default PureTranslatedField;
