import React from 'react';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import PropTypes from 'prop-types';

const PeriodButton = ({ date, nextMonthFunc, prevMonthFunc, style }) => {
  const period = `${moment(date).format('MM/YYYY')}`;
  const periodStyle = {
    display: 'flex',
    alignItems: 'center',
    ...style,
  };

  return (
    <div style={periodStyle}>
      <IconButton
        className="backward"
        onClick={prevMonthFunc}
        style={{ backgroundColor: 'transparent' }}
      >
        <ArrowBack />
      </IconButton>
      <span className="month">{period}</span>
      <IconButton
        className="forward"
        onClick={nextMonthFunc}
        style={{ backgroundColor: 'transparent' }}
      >
        <ArrowForward />
      </IconButton>
    </div>
  );
};

PeriodButton.propTypes = {
  date: PropTypes.object,
  nextMonthFunc: PropTypes.func,
  prevMonthFunc: PropTypes.func,
  style: PropTypes.object,
};

PeriodButton.defaultProps = {
  date: {},
  nextMonthFunc: /* istanbul ignore next */ () => {},
  prevMonthFunc: /* istanbul ignore next */ () => {},
  style: {},
};

export default PeriodButton;
