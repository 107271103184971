import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const AccountAutocompleteInput = ({
  label,
  filter,
  fullWidth,
  helperText,
  resource,
  source,
  ...props
}) => {
  return (
    <ReferenceInput
      {...props}
      filterToQuery={
        /* istanbul ignore next */
        (searchText) => {
          return { search: searchText };
        }
      }
      sort={{ field: 'name', order: 'ASC' }}
      filter={filter}
      source={source}
      reference="account"
      resource={resource}
      label={label}
      allowEmpty
      emptyText="ra.action.clear_input_value"
    >
      <AutocompleteInput
        optionText="name"
        options={{ fullWidth }}
        helperText={helperText}
      />
    </ReferenceInput>
  );
};

AccountAutocompleteInput.propTypes = {
  filter: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.bool,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string,
};

AccountAutocompleteInput.defaultProps = {
  filter: {},
  fullWidth: true,
  helperText: undefined,
  label: undefined,
  resource: '',
  source: 'account_id',
};

export default AccountAutocompleteInput;
