import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const PrestationTypeSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        {
          id: 'CARPET_CLEANING',
          name: 'Shampoing moquette',
        },
        {
          id: 'CLEANING',
          name: 'Nettoyage',
        },
        {
          id: 'GLAZING',
          name: 'Vitrerie',
        },
        {
          id: 'PERMANENCE',
          name: 'Permanence',
        },
      ]}
    />
  );
};
PrestationTypeSelectInput.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  source: PropTypes.string.isRequired,
};

PrestationTypeSelectInput.defaultProps = {
  validate: [],
};

export default PrestationTypeSelectInput;
