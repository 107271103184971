import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from 'react-admin';
import { CleanerMenuActions } from '../../../../../components/index.js';
import RenewCDDButton from './RenewCDDButton.js';
import RemplacementButton from './ReplacementButton.js';
import AmendSchedulesButton from './AmendSchedulesButton.js';
import ComplementButton from './ComplementButton.js';
import { useQuery } from 'react-admin';


const ContractMenu = ({ className, record }) => {
  const translate = useTranslate();
  const formUrl = 'https://forms.cleany.fr/demande-de-contrat?';

  const { data: contracts } = useQuery({
    type: 'getManyReference',
    resource: 'cleaner_contract',
    payload: {
      target: 'cleaner_id',
      id: record.id,
      sort: { field: 'start_date', order: 'DESC' },
      pagination: { page: 1, perPage: 5 },
    },
  });

  return (
    <CleanerMenuActions
      className={className}
      label="Contrat"
      placeholder="📝 Demander un contrat"
      record={record}
    >
      {record.contract_type === 'CDI' ? (
        <AmendSchedulesButton translate={translate} formUrl={formUrl} />
      ) : (
        <></>
      )}
      {record.contract_type === 'CDD' || ( contracts?.length > 0 && contracts[0].contract_type === 'CDD') ? (
        <RenewCDDButton translate={translate} formUrl={formUrl} />
      ) : (
        <></>
      )}
      {record.contract_type === 'CDI' ? (
        <ComplementButton translate={translate} formUrl={formUrl} />
      ) : (
        <></>
      )}
      <RemplacementButton translate={translate} formUrl={formUrl} />
    </CleanerMenuActions>
  );
};

ContractMenu.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

ContractMenu.defaultProps = {
  className: undefined,
  record: {},
};

export default ContractMenu;
