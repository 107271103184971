import { showNotification } from 'react-admin';
import { takeLatest, call, put } from 'redux-saga/effects';
import { UPDATE_SCHEDULER_JOB } from '../action';

export const updateNextExecutionDate = async (id, payload) => {
  const url = `/api/scheduler?id=eq.${id}`;
  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
    method: 'PATCH',
    body: JSON.stringify(payload),
  });

  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
};

export function* runSchedulerJobHandler({ id, payload }) {
  try {
    yield call(updateNextExecutionDate, id, payload);
  } catch (error) {
    yield put(showNotification(error.message));
  }
}

export default function* watch() {
  yield takeLatest(UPDATE_SCHEDULER_JOB, runSchedulerJobHandler);
}
