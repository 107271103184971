import { takeLatest, call, put } from 'redux-saga/effects';
import { FETCH_START, FETCH_ERROR, FETCH_END } from 'react-admin';
import { GET_PAYROLL_EXPLANATION, setPayrollExplanation } from '../action';

export const fetchExplanation = async (cleanerId, date) => {
  const response = await fetch(
    `/payroll/explanation?cleanerId=${cleanerId}&date=${date.format(
      'YYYY-MM',
    )}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      credentials: 'same-origin',
      method: 'GET',
    },
  );
  if (response.status < 200 || response.status >= 300) {
    throw new Error(response.statusText);
  }
  return response.json();
};

export function* getPayrollExplanation({ cleanerId, date }) {
  try {
    yield put({ type: FETCH_START });
    const explanation = yield call(fetchExplanation, cleanerId, date);
    yield put({ type: FETCH_END });
    yield put(setPayrollExplanation(explanation, date));
  } catch (error) {
    yield put({ type: FETCH_ERROR });
  }
}

export default function* watch() {
  yield takeLatest(GET_PAYROLL_EXPLANATION, getPayrollExplanation);
}
