import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from 'react-admin';
import { CleanerMenuActions, GoogleFormMenuItem } from '../../../../components';
import PreaffectationButton from './PreaffectationButton.js';
import EntretienProfessionelButton from './EntretienProfessionelButton.js';
import EntretienEvaluationButton from './EntretienEvaluationButton.js';
import DuplicateCleanerButton from './DuplicateCleanerButton.js';

const ActionsMenu = ({ className, record }) => {
  const translate = useTranslate();
  return (
    <CleanerMenuActions
      className={className}
      label="Actions"
      placeholder="🔆 Actions"
      record={record}
    >
      <GoogleFormMenuItem type="payroll_issue" />
      <GoogleFormMenuItem type="learning_request" />
      <PreaffectationButton />
      <EntretienProfessionelButton translate={translate} />
      <EntretienEvaluationButton translate={translate} />
      <DuplicateCleanerButton translate={translate} />
    </CleanerMenuActions>
  );
};

ActionsMenu.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

ActionsMenu.defaultProps = {
  className: undefined,
  record: {},
};

export default ActionsMenu;
