import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'react-admin';
import Edit from './Edit';
import { DrawerCreateEdit } from '../../containers';
import DocumentDatagrid from './detail/Datagrid';
import Filter from './detail/Filter';
import Create from './Create';

const DocumentList = (props) => {
  return (
    <DrawerCreateEdit
      {...props}
      routePath="/client_document"
      renderEdit={Edit}
      renderCreate={Create}
    >
      <List
        {...props}
        hasCreate
        filters={<Filter />}
        sort={{ field: 'upload_date', order: 'DESC' }}
        bulkActionButtons={false}
        perPage={25}
      >
        <DocumentDatagrid origin={props.basePath} />
      </List>
    </DrawerCreateEdit>
  );
};

DocumentList.propTypes = {
  basePath: PropTypes.string,
};

DocumentList.defaultProps = {
  basePath: '',
};

export default DocumentList;
