import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import pure from 'recompose/pure';
import Typography from '@material-ui/core/Typography';

const URLParamField = ({ record, source, variant, url, text }) => {
  const param = get(record, source);
  const urlParam = `${url.replace(`{{${source}}}`, param)}`;
  return (
    <Typography component="span" variant={variant}>
      <a href={urlParam} rel="noopener noreferrer" target="_blank">
        {text || urlParam}
      </a>
    </Typography>
  );
};

URLParamField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  variant: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
};

URLParamField.defaultProps = {
  record: {},
  variant: 'body1',
  text: '',
  url: '',
};

const PureURLParamField = pure(URLParamField);

PureURLParamField.defaultProps = {
  addLabel: true,
};

export default PureURLParamField;
