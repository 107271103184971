import React from 'react';
import { Filter } from 'react-admin';
import { CleanerAutocompleteInput } from '../../../components';

const CleanrLetterFilter = (props) => {
  return (
    <Filter {...props}>
      <CleanerAutocompleteInput alwaysOn />
    </Filter>
  );
};

export default CleanrLetterFilter;
