import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { Button, translate as translateComponent } from 'react-admin';
import { getLegalDocument } from '../../../../action';

const DismissalConvocationDialog = ({
  className,
  translate,
  getLegalDocumentClick,
}) => {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(
    moment()
      .add(10, 'days')
      .format('YYYY-MM-DD'),
  );
  const [time, setTime] = useState(moment().format('HH:mm'));
  const invertOpenStatus = () => {
    setOpen(!open);
  };
  const onChangeDate = (newDate) => {
    setDate(newDate.target.value);
  };
  const onChangeTime = (newTime) => {
    setTime(newTime.target.value);
  };

  return (
    <Fragment>
      <Button
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
        label={translate(
          'resources.cleaner.actions.download_dismissalConvocation',
        )}
      ></Button>
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle color="primary" id="alert-dialog-title">
          {translate('resources.cleaner.actions.download_dismissalConvocation')}
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '10px', width: '40%' }}
            label={translate('resources.cleaner.modal.choose_date')}
            type="date"
            onChange={onChangeDate}
            defaultValue={date}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ width: '40%' }}
            label={translate('resources.cleaner.modal.choose_time')}
            type="time"
            onChange={onChangeTime}
            defaultValue={time}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="resources.cleaner.actions.download"
            variant="contained"
            onClick={() => {
              getLegalDocumentClick('dismissalConvocation', {
                appointment_datetime: `${date} ${time}`,
              });
              invertOpenStatus();
            }}
          >
            <CloudDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

DismissalConvocationDialog.propTypes = {
  className: PropTypes.string,
  translate: PropTypes.func.isRequired,
  getLegalDocumentClick: PropTypes.func.isRequired,
};

DismissalConvocationDialog.defaultProps = {
  className: undefined,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLegalDocumentClick: (documentType, others) => {
      const payload = {
        id: ownProps.record.id,
        fullname: ownProps.record.fullname,
        documentType,
        ...others,
      };
      dispatch(getLegalDocument(payload));
    },
  };
};

const enhance = compose(translateComponent, connect(null, mapDispatchToProps));

export default enhance(DismissalConvocationDialog);
