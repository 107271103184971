import { days as refDays } from '../../../helpers';

export const getWorkedTimeAndDays = (affectations) => {
  const workedDays = {};
  const workedTime = {};
  affectations.forEach((affectation) => {
    affectation.days.forEach((day) => {
      workedDays[day] = true;
      if (!workedTime[day])
        workedTime[day] = {
          start_time: affectation.startTime,
          end_time: affectation.endTime,
        };
    });
  });
  return { workedDays, workedTime };
};

export const buildAffectations = (affectations) => {
  return affectations.map((affectation) => {
    return refDays.reduce(
      (acc, day) => {
        const newValue = { ...acc };

        if (affectation[day]) {
          newValue.days.push(day);
        }
        return newValue;
      },
      {
        id: affectation.id,
        account_name: affectation.account_name,
        type: affectation.type,
        contract_id: affectation.contract_id,
        opportunity_name: affectation.opportunity_name,
        days: [],
        start_date: affectation.start_date,
        end_date: affectation.end_date,
        cleaner_fullname: affectation.cleaner_fullname,
        startTime: affectation.start_time,
        endTime: affectation.end_time,
        catalog_item_id: affectation.catalog_item_id,
        totalDuration: affectation.total_duration,
        manager_fullname: affectation.manager_fullname,
        leave_id: affectation.leave_id,
      },
    );
  });
};

export const buildPlanning = (
  affectations_,
  options = { isLeaveIdKey: false },
) => {
  const affectations = buildAffectations(affectations_);

  return affectations.reduce((acc, affectation) => {
    const newValue = { ...acc };

    const keyName = options.isLeaveIdKey
      ? affectation.leave_id
      : `${affectation.contract_id}_${affectation.catalog_item_id}`;

    if (!newValue[keyName]) {
      newValue[keyName] = {
        affectations: [],
        replacements: [],
        contract_id: affectation.contract_id,
        catalog_item_id: affectation.catalog_item_id,
        totalDuration: 0,
        replacementDuration: 0,
      };
    }

    if (affectation.type === 'Absence') {
      newValue[keyName].affectations.push(affectation);
      newValue[keyName].totalDuration += affectation.totalDuration;
    }
    if (affectation.type === 'Remplacement') {
      newValue[keyName].replacements.push(affectation);
      newValue[keyName].replacementDuration += affectation.totalDuration;
    }

    return newValue;
  }, {});
};

export const buildDays = (days, translate) => {
  return days.reduce((acc, day) => {
    if (!acc.length) {
      return translate(`format.date.days.${day}`);
    }
    return `${acc} ${translate(`format.date.days.${day}`)}`;
  }, '');
};

export const buildBundle = (values, contractsPlanning, leaveId) => {
  if (!contractsPlanning) {
    return [];
  }
  /* eslint-disable-next-line camelcase */
  const { start_date, end_date, cleaner_ids, ...contracts } = values;

  return Object.keys(contracts).map((key) => {
    return {
      contract_id: contractsPlanning[key].contract_id,
      planning: contracts[key].planning,
      type: 'Remplacement',
      cleaner_ids,
      leave_id: leaveId,
      start_date,
      end_date,
      catalog_item_id: contractsPlanning[key].catalog_item_id,
    };
  });
};

export const getLeaveDefaultCleanerIds = (relatedAffectations, cleanerIds) => {
  const firstLeaveAffectation = relatedAffectations.length
    ? relatedAffectations.find((affectation) => {
        return affectation.type === 'Absence';
      })
    : {};

  return cleanerIds.filter((cleanerId, index, self) => {
    return (
      cleanerId !== firstLeaveAffectation.cleaner_id &&
      self.indexOf(cleanerId) === index
    );
  });
};
