import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';

import ContractReplacementItem from './ContractReplacementItem';

const ContractReplacementList = ({ planning, ...props }) => {
  return (
    <List>
      {Object.keys(planning).map((contractId) => {
        const contractPlanning = planning[contractId];
        if (
          !contractPlanning ||
          !contractPlanning.affectations ||
          !contractPlanning.affectations.length
        ) {
          return null;
        }
        const { totalDuration, replacementDuration } = contractPlanning;
        const affectation = contractPlanning.affectations[0];
        return (
          <ContractReplacementItem
            {...props}
            key={affectation.contract_id}
            record={affectation}
            totalDuration={totalDuration}
            replacementDuration={replacementDuration}
          />
        );
      })}
    </List>
  );
};

ContractReplacementList.propTypes = {
  planning: PropTypes.object,
};

ContractReplacementList.defaultProps = {
  planning: {},
};

export default ContractReplacementList;
