import React from 'react';
import { useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { GridCard } from '../../../../components';

import ListInvoice from './ListInvoice';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const useStyles = makeStyles({
  headerRoot: {
    paddingBottom: 0,
  },
});

const InvoiceCard = (props) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <GridCard
      {...sanitizeRestProps(props)}
      classes={classes}
      xs={12}
      title={translate('resources.account.card_title.invoice')}
    >
      <ListInvoice xs={12} />
    </GridCard>
  );
};

export default InvoiceCard;
