import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import DatePeriodField from './DatePeriodField';
import TimePeriodField from './TimePeriodField';
import WeekdaysWorkedField from './WeekdaysWorkedField';

const LeaveInformationField = ({ className: classNameProp, ...props }) => {
  return (
    <div className={classNameProp}>
      <TextField {...props} source="cleaner_fullname" variant="subtitle1" />
      <DatePeriodField {...props} />
      <TimePeriodField {...props} />
      <WeekdaysWorkedField {...props} />
    </div>
  );
};

LeaveInformationField.propTypes = {
  className: PropTypes.string,
};

LeaveInformationField.defaultProps = {
  className: undefined,
};

export default LeaveInformationField;
