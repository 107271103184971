import React from 'react';
import PropTypes from 'prop-types';
import { LetterStatusField, URLParamField } from '../../../components';
import { Datagrid, DateField, ReferenceField, TextField, useTranslate } from 'react-admin';

const LettersDrawerList = ({ originRoute, ...props }) => {

  const translate = useTranslate();

  return (
      <Datagrid
        {...props}
      >
      <ReferenceField source="cleaner_id" reference="cleaner" link={false}>
        <TextField source="fullname" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="description" />
      <DateField source="sent_date" />
      <DateField source="first_distributed_date" />
      <LetterStatusField source="status" />
      <URLParamField
        url="https://www.seeuletter.com/app/dashboard/letters/{{external_id}}"
        source="external_id"
        label={translate('resources.logs.fields.tracking_link')}
        text="Suivi Seeuletter"
        variant="body2"
      />
      <URLParamField
        url="https://www.laposte.fr/outils/suivre-vos-envois?code={{tracking_number}}"
        source="tracking_number"
        label={translate('resources.logs.fields.tracking_link')}
        text="Suivi La Poste"
        variant="body2"
      />
      <ReferenceField
        allowEmpty
        source="owner_id"
        reference="cleaner"
        link="show"
      >
        <TextField source="fullname" />
      </ReferenceField>
      </Datagrid>
  );
};

LettersDrawerList.propTypes = {
  originRoute: PropTypes.string,
};

LettersDrawerList.defaultProps = {
  originRoute: '',
};

export default LettersDrawerList;
