import { createMuiTheme } from '@material-ui/core/styles';

export { default as images } from './images';

const myTheme = createMuiTheme({
  palette: {
    /* XXX: we use primary from graphical chart as secondary because react admin use secondary color for AppBarr */
    primary: { main: '#326CB4' },
    secondary: { main: '#123867' },
    tertiary: { main: '#00C29A' },
    white: '#fff',
    procedureStatus: {
      active:  '#3f51b5',
      draft: '#ffa726',
      expired: '#ab47bc',
      finished: '#66bb6a',
      refused: '#d32f2f',
      unknown: '#BDBDBD',
    },
    status: {
      success: '#00C29A',
      cancelled: '#BDBDBD',
      fail: '#EC8C8C',
      pending: '#FBBE65',
      incomplet: '#ffa000',
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.02)',
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginTop: '8px',
        marginBottom: '4px',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 'auto',
        margin: 8,
      },
      flexContainer: {
        borderRadius: 20,
        padding: 2,
        backgroundColor: '#F5F5F5',
      },
      fixed: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        minHeight: '30px',
        height: '30px',
        borderRadius: '15px',
        '&$selected': {
          backgroundColor: '#fff',
          boxShadow: '0 0 2px 0 rgba(0,0,0,.1)',
        },
      },
      textColorInherit: {
        opacity: 1,
        color: 'rgba(31,51,61,.4)',
        fontSize: 12,
        fontWeight: 800,
        '&$selected': {
          color: '#326CB4',
          fontSize: 12,
          fontWeight: 600,
        },
      },
    },
    RaListToolbar: {
      actions: {
        paddingTop: 16,
      },
    },
    RaTopToolbar: {
      root: {
        paddingTop: 16,
      },
    },
  },
});

export default myTheme;
