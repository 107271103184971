import React from 'react';
import { Edit } from 'react-admin';
import Form from './detail/Form';

export default (props) => {
  return (
    <Edit {...props}>
      <Form {...props} />
    </Edit>
  );
};
