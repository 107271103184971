import React from 'react';
import { required, SelectInput } from 'react-admin';

const FunctionSelectInput = (props) => {
  return (
    <SelectInput
      {...props}
      source="function"
      choices={[
        { id: 'Cleaner', name: 'resources.cleaner.function.cleaner' },
        {
          id: 'Hotel Staff',
          name: 'resources.cleaner.function.hotel_staff',
        },
        {
          id: 'Event Staff',
          name: 'resources.cleaner.function.event_staff',
        },
        { id: 'Team Leader', name: 'resources.cleaner.function.team_leader' },
        { id: 'Glazier', name: 'resources.cleaner.function.glazier' },
        {
          id: 'Handyman',
          name: 'resources.cleaner.function.handyman',
        },
        { id: 'Manager', name: 'resources.cleaner.function.manager' },
        {
          id: 'Customer Service',
          name: 'resources.cleaner.function.customer_service',
        },
        {
          id: 'Office Employee',
          name: 'resources.cleaner.function.office_employee',
        },
      ]}
      defaultValue="Cleaner"
      validate={[required()]}
    />
  );
};

export default FunctionSelectInput;
