import React from 'react';
import {
  DateField,
  Datagrid,
  NumberField,
  ReferenceManyField,
  TextField,
  ReferenceField,
  useTranslate,
} from 'react-admin';

import { GridCard, NumberWithUnitField, Pagination } from '../../../components';

const ConsumableCard = (props) => {
  const translate = useTranslate();

  return (
    <GridCard
      {...props}
      title={translate('resources.account.card_title.consumable')}
      xs={12}
    >
      <ReferenceManyField
        addLabel={false}
        reference="provider_item"
        target="account_id"
        filter={{ type: 'SUPPLY' }}
        sort={{ field: 'period, quantity', order: 'ASC, DESC' }}
        xs={12}
        pagination={<Pagination />}
      >
        <Datagrid>
          <ReferenceField link="show" source="provider" reference="account">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="provider_item_ref_id" />
          <TextField source="provider_item_name" />
          <NumberField source="quantity" />
          <NumberWithUnitField source="period" unit="week" translated />
          <DateField source="last_action" />
          <DateField source="next_action" />
        </Datagrid>
      </ReferenceManyField>
    </GridCard>
  );
};

export default ConsumableCard;
