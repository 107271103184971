import React from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  TextInput,
  SimpleForm,
  required,
  useTranslate,
} from 'react-admin';
import { FormSpy } from 'react-final-form';
import {
  CleanerAutocompleteInput,
  LeaveSelectInput,
  EmptyInput,
} from '../../../../components';

import StartDateInput from './StartDateInput';
import EndDateInput from './EndDateInput';
import CleanerContractsAutocompleteInput from './CleanerContractsAutocompleteInput';

const Form = ({ aside, ...props }) => {
  const translate = useTranslate();

  return (
    <SimpleForm {...props} redirect="show">
      <CleanerAutocompleteInput validate={[required()]} />
      <FormSpy subscription={{ valid: true, values: true }}>
        {({ values }) => {
          const cleanerId = values.cleaner_id ? values.cleaner_id : null;
          if (!cleanerId) {
            return (
              <EmptyInput
                variant="body1"
                label={translate('resources.cleaner.form.choose_cleaner')}
              />
            );
          }
          return <CleanerContractsAutocompleteInput cleanerId={cleanerId} />;
        }}
      </FormSpy>
      <StartDateInput />
      <EndDateInput />
      <LeaveSelectInput source="absence_type" validate={[required()]} />
      <BooleanInput source="processed" />
      <TextInput source="comment" />
      <FormSpy subscription={{ valid: true, values: true }}>
        {({ values }) => {
          const cleanerId = values.cleaner_id ? values.cleaner_id : null;

          return React.cloneElement(aside, { cleanerId });
        }}
      </FormSpy>
    </SimpleForm>
  );
};

Form.propTypes = {
  aside: PropTypes.element,
};

Form.defaultProps = {
  aside: null,
};

export default Form;
