import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useQuery } from 'react-admin';
import { getUser } from '../../../../../user';

const AmendSchedulesButton = ({ className, record, formUrl }) => {
  const { data: affectations } = useQuery({
    type: 'getManyReference',
    resource: 'affectation',
    payload: {
      target: 'cleaner_id',
      id: record.id,
      sort: { field: 'id', order: 'DESC' },
      pagination: { page: 1, perPage: 5 },
      filter: { type: 'Permanent', active: true },
    },
  });

  if (!affectations)
    return (
      <MenuItem>
        <ListItemText primary="loading" />
      </MenuItem>
    );

  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          return (
            <MenuItem
              disabled
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${`${formUrl}context=amend` +
                    `&cleaner_silae_id=${record.silae_id}` +
                    `&cleaner_id=${record.id}` +
                    `&cleaner_firstname=${record.firstname}` +
                    `&cleaner_lastname=${record.lastname}` +
                    `&cleaner_email=${record.email}` +
                    `&cleaner_phone=${record.mobile_phone}` +
                    `&cleaner_level=${record.level}` +
                    `&cleaner_contract_type=${record.contract_type}` +
                    `&cleaner_position=${record.function}` +
                    `&contract_start_date=${record.contract_end_date}` +
                    `&manager_fullname=${user.fullname}` +
                    `&manager_email=${user.email}` +
                    `&manager_id=${user.id}`}${affectations.map(
                    (affectation, index) => {
                      const days = [];

                      return (
                        `&affectation_${index + 1}_start_date=${
                          affectation.start_date
                        }` +
                        `&affectation_${index + 1}_end_date=${
                          affectation.end_date
                        }` +
                        `&affectation_${index + 1}_start_time=${
                          affectation.start_time
                        }` +
                        `&affectation_${index + 1}_end_time=${
                          affectation.end_time
                        }` +
                        `&affectation_${index + 1}_days=${(affectation.monday
                          ? days.push('Lundi')
                          : null,
                        affectation.tuesday ? days.push('Mardi') : null,
                        affectation.wednesday ? days.push('Mercredi') : null,
                        affectation.thursday ? days.push('Jeudi') : null,
                        affectation.friday ? days.push('Vendredi') : null,
                        affectation.saturday ? days.push('Samedi') : null,
                        affectation.sunday ? days.push('Dimanche') : null,
                        days.join(','))}` +
                        `&affectation_${index + 1}_account_name=${
                          affectation.account_name
                        }` +
                        `&affectation_${index + 1}_site_address=${
                          affectation.site_address
                        }`
                      );
                    },
                  )}`,
                );
              }}
            >
              <ListItemText primary="Avenant d'ajout/désistement d'heures" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

AmendSchedulesButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  formUrl: PropTypes.string,
};

AmendSchedulesButton.defaultProps = {
  className: undefined,
  record: undefined,
  formUrl: undefined,
};

export default AmendSchedulesButton;
