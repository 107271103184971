import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
} from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import {
  AffectationTypeField,
  Pagination,
  ScheduleField,
  TimeField,
} from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      marginTop: theme.spacing(1),
    },
  };
});

const ListAffectation = ({ className, sort, perPage, title, ...props }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      {title && <Typography variant="h6">{title}</Typography>}
      <ReferenceManyField
        {...props}
        className={classes.list}
        reference="affectation"
        target="contract_id"
        addLabel={false}
        sort={sort}
        xs={12}
        perPage={perPage}
        pagination={<Pagination />}
      >
        <Datagrid {...props} rowClick="show">
          <TextField source="cleaner_fullname" />
          <AffectationTypeField source="type" />
          <DateField source="start_date" />
          <DateField source="end_date" />
          <ScheduleField source="planning" />
          <TimeField source="duration_per_week" />
        </Datagrid>
      </ReferenceManyField>
    </div>
  );
};

ListAffectation.propTypes = {
  className: PropTypes.string,
  perPage: PropTypes.number,
  sort: PropTypes.object,
  title: PropTypes.string,
};

ListAffectation.defaultProps = {
  className: undefined,
  perPage: 10,
  sort: { field: 'start_date', order: 'DESC' },
  title: undefined,
};

export default ListAffectation;
