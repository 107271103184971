import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  TextField,
  Datagrid,
  NumberField,
  ArrayField,
  ReferenceField,
} from 'react-admin';
import { GridCard } from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(1),
    },
    content: {
      padding: '0 !important',
    },
  };
});

const ListDetail = ({ ...props }) => {
  const classes = useStyles();

  return (
    <GridCard {...props} classes={classes}>
      <ArrayField source="détail" emptyText="∅">
        <Datagrid>
          <ReferenceField
            source="product2id"
            reference="item"
            textAlign="center"
            label="Famille"
          >
            <TextField source="family" />
          </ReferenceField>
          <ReferenceField
            source="product2id"
            reference="item"
            label="Item"
            textAlign="center"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="product2id"
            reference="item"
            label="Description"
            textAlign="center"
          >
            <TextField source="description" />
          </ReferenceField>
          <NumberField source="unitprice" label="Prix Unitaire" />
          <NumberField source="quantity" label="Quantité" />
          <NumberField source="totalprice" label="Prix" />
        </Datagrid>
      </ArrayField>
    </GridCard>
  );
};

export default ListDetail;
