import React from 'react';
import { Datagrid, DateField, List, TextField, ReferenceField } from 'react-admin';
import {
  NumberWithUnitField,
  DownloadInvoiceButton,
  InvoiceStatusChipField,
} from '../../components';

import Expand from './shared/Expand';
import Filter from './details/Filter';

export default (props) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      filterDefaultValues={{ status: 'CLOSED' }}
      sort={{ field: 'due_date', order: 'DESC' }}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid rowClick="edit" expand={<Expand />}>
        <TextField source="invoice_number" />
        <ReferenceField
          allowEmpty
          link="show"
          source="account_id"
          reference="account"
          label="Compte"
        >
          <TextField source="name" />
        </ReferenceField>
        <DateField source="due_date" />
        <NumberWithUnitField unit="€" source="amount" />
        <NumberWithUnitField unit="€" source="total_tax" />
        <NumberWithUnitField unit="€" source="total_amount" />
        <InvoiceStatusChipField source="status" />
        <DownloadInvoiceButton label="" variant="icon" />
      </Datagrid>
    </List>
  );
};
