import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { TextField } from 'react-admin';

const ContractField = ({ record }) => {
  return (
    <TextField
      record={{
        contractName: `${record.account_name} ${record.type} ${moment(
          record.effective_date,
        ).format('DD/MM/YYYY')}${
          record.end_date
            ? ` - ${moment(record.end_date).format('DD/MM/YYYY')}`
            : ''
        }`,
      }}
      source="contractName"
    />
  );
};

ContractField.propTypes = {
  record: PropTypes.object,
};

ContractField.defaultProps = {
  record: {},
};

export default ContractField;
