import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslate } from 'react-admin';
import { openTypeform } from '../../helpers';

export const TypeformMenuItem = React.forwardRef(({ record, type }, ref) => {
  const translate = useTranslate();

  return (
    <MenuItem
      onClick={
        /* istanbul ignore next */ () => {
          openTypeform(record, type);
        }
      }
      ref={ref}
    >
      {translate(`resources.logs.actions.${type}`)}
    </MenuItem>
  );
});

TypeformMenuItem.propTypes = {
  record: PropTypes.object,
  type: PropTypes.string,
};

TypeformMenuItem.defaultProps = {
  record: {},
  type: '',
};

export default TypeformMenuItem;
