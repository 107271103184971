import {
  GET_LIST,
  GET_ONE,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  fetchUtils,
} from 'react-admin';

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */

const API_URL = 'mongoose';

export const convertDataProviderRequestToHTTP = (type, resource, params) => {
  let url = '';
  const options = {};
  options.headers = new Headers();
  options.headers.set('Access-Control-Allow-Origin', '*');
  options.credentials = 'same-origin';
  switch (type) {
    case GET_ONE: {
      url = `${API_URL}`;
      options.method = 'POST';
      options.body = JSON.stringify({
        resource,
        action: 'read',
        params: {
          _id: params.id,
        },
      });
      break;
    }
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      url = `${API_URL}`;
      const { page } = params.pagination;
      const { perPage } = params.pagination;
      options.method = 'POST';
      options.body = JSON.stringify({
        resource,
        action: 'list',
        params: {
          query: params.target
            ? {
                [params.target]: params.id,
                ...params.filter,
              }
            : params.filter,
          options: {
            sort: {
              [params.sort.field]: params.sort.order.toLowerCase(),
            },
            page,
            limit: perPage,
          },
        },
      });
      break;
    }
    case CREATE: {
      url = `${API_URL}`;
      options.method = 'POST';
      options.body = JSON.stringify({
        resource,
        action: 'create',
        params: params.data,
      });
      break;
    }
    case UPDATE: {
      url = `${API_URL}`;
      options.method = 'POST';
      options.body = JSON.stringify({
        resource,
        action: 'edit',
        params: params.data,
      });
      break;
    }
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
export const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params,
) => {
  const { json } = response;
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      return {
        data: json.docs.map((val) => {
          val.id = val._id; // eslint-disable-line no-param-reassign
          return val;
        }),
        total: json.total,
      };
    }
    case CREATE: {
      return { data: { id: json._id, ...params.data } };
    }
    default: {
      if (!json) {
        return { data: Object.assign({}, params.data) };
      }
      return { data: { id: json._id, ...json } };
    }
  }
};

export default (type, resource, params) => {
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params,
  );
  const { fetchJson } = fetchUtils;

  return fetchJson(url, options).then((response) => {
    return convertHTTPResponseToDataProvider(response, type, resource, params);
  });
};
