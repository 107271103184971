import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, List } from '@material-ui/core';

import NestedListItem from './NestedListItem';

const NestedList = ({
  action,
  basePath,
  children,
  collapse,
  dense,
  ids,
  data,
  resource,
}) => {
  return (
    <List>
      {ids.map((id) => {
        return (
          <Fragment key={id}>
            <NestedListItem
              basePath={basePath}
              resource={resource}
              record={data[id]}
              action={action}
              collapse={collapse}
              dense={dense}
            >
              {children}
            </NestedListItem>
            <Divider />
          </Fragment>
        );
      })}
    </List>
  );
};

NestedList.propTypes = {
  action: PropTypes.element,
  basePath: PropTypes.string,
  children: PropTypes.node,
  collapse: PropTypes.element,
  data: PropTypes.object,
  dense: PropTypes.bool,
  ids: PropTypes.array,
  resource: PropTypes.string,
};

NestedList.defaultProps = {
  action: null,
  basePath: '/',
  children: null,
  collapse: null,
  data: {},
  dense: false,
  ids: [],
  resource: undefined,
};

export default NestedList;
