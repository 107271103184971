import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Form } from 'react-final-form';
import {
  Button,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  useTranslate,
} from 'react-admin';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { inviteCleaner } from '../../../action';

const InviteForm = ({ handleClose, record }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();

  const onSubmit = (values) => {
    dispatch(
      inviteCleaner({
        affectation: record.id,
        cleanerIds: values.cleaner_ids,
      }),
    );
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        const selectedCleaners = values.cleaner_ids ? values.cleaner_ids : [];

        return (
          <form onSubmit={handleSubmit}>
            <DialogTitle id="form-dialog-title">
              {translate('resources.affectation.notify.invite_cleaner')}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                {translate('resources.affectation.notify.cleaners')}
              </DialogContentText>
              <ReferenceArrayInput
                filterToQuery={
                  /* istanbul ignore next */
                  (searchText) => {
                    return { fullname: searchText };
                  }
                }
                reference="cleaner"
                resource="cleaner"
                source="cleaner_ids"
                fullWidth
              >
                <AutocompleteArrayInput
                  optionText="fullname"
                  options={{
                    suggestionsContainerProps: {
                      /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                      style: { zIndex: 2000 },
                    },
                  }}
                />
              </ReferenceArrayInput>
            </DialogContent>
            <DialogActions>
              <Button
                label={translate(
                  `resources.affectation.notify.${
                    selectedCleaners.length
                      ? 'submit_with_cleaners'
                      : 'auto_select_cleaners'
                  }`,
                  {
                    nb_cleaners: selectedCleaners.length,
                  },
                )}
                onClick={() => {
                  handleSubmit();
                  handleClose();
                }}
              />
            </DialogActions>
          </form>
        );
      }}
    />
  );
};

InviteForm.propTypes = {
  handleClose: PropTypes.func,
  record: PropTypes.object,
};

InviteForm.defaultProps = {
  handleClose: /* istanbul ignore next */ () => {},
  record: {},
};

export default InviteForm;
