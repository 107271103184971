import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCreateEdit } from '../../../containers';
import Edit from '../../Document/Edit';
import DocumentDatagrid from '../../Document/detail/Datagrid';

const sanitizeRestProps = ({ hasList, hasEdit, hasShow, ...rest }) => {
  return rest;
};

const DocumentsDrawerList = ({ originRoute, ...props }) => {
  return (
    <DrawerCreateEdit
      {...props}
      routePath={originRoute}
      editPath="/edit_cleaner_document"
      renderEdit={Edit}
      redirect={() => {
        return originRoute;
      }}
    >
      <DocumentDatagrid
        {...sanitizeRestProps(props)}
        origin={`${originRoute}/edit_cleaner_document`}
      />
    </DrawerCreateEdit>
  );
};

DocumentsDrawerList.propTypes = {
  originRoute: PropTypes.string,
};

DocumentsDrawerList.defaultProps = {
  originRoute: '',
};

export default DocumentsDrawerList;
