import React from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  TextInput,
  SimpleForm,
  DateInput,
  required,
} from 'react-admin';
import { CleanerAutocompleteInput } from '../../../../components';
import { getUser } from '../../../../user';

const Form = ({ aside, ...props }) => {
  const user = getUser();
  const today = new Date();

  return (
    <SimpleForm {...props} redirect="list">
      <CleanerAutocompleteInput validate={[required()]} />
      <DateInput source="date" />
      <TextInput source="intitule" />
      <TextInput source="description" />
      <BooleanInput source="certifiante" />
      <BooleanInput source="interne" />
      <TextInput source="commentaire" />
      <TextInput source="lien_detail" />
      <TextInput source="validateur_id" disabled defaultValue={user.id} />
      <DateInput
        source="last_modification_date"
        label="Dernière Modification"
        disabled
        defaultValue={today}
      />
    </SimpleForm>
  );
};

Form.propTypes = {
  aside: PropTypes.element,
};

Form.defaultProps = {
  aside: null,
};

export default Form;
