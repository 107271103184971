import React from 'react';
import PropTypes from 'prop-types';
import { useReferenceManyFieldController } from 'react-admin';

import { LinearProgress, Grid } from '@material-ui/core';
import { buildPlanning } from '../../Replacement/helper';
import AccountItem from './AccountItem';

const AccountList = ({ basePath, resource, record, ...props }) => {
  const { loading, data } = useReferenceManyFieldController({
    basePath,
    resource,
    record,
    source: 'id',
    target: 'leave_id',
    reference: 'affectation',
    sort: {
      field: 'start_time,account_name',
      order: 'ASC,ASC',
    },
    page: 1,
    perPage: 50,
  });

  if (loading) {
    return (
      <Grid item xs={12}>
        <LinearProgress />
      </Grid>
    );
  }

  const planning = buildPlanning(Object.values(data));
  return Object.keys(planning).map((item) => {
    return (
      <AccountItem
        {...props}
        basePath={basePath}
        resource={resource}
        record={record}
        key={item}
        planning={planning[item]}
      />
    );
  });
};

AccountList.propTypes = {
  basePath: PropTypes.string,
  record: PropTypes.object,
  resource: PropTypes.string,
};

AccountList.defaultProps = {
  basePath: '/',
  record: {},
  resource: undefined,
};

export default AccountList;
