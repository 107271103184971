import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

export const optionRenderer = (choice) => {
  return `${choice.cleaner_fullname} - ${choice.account_name} - ${
    choice.type
  } - ${choice.start_date} ${
    choice.end_date ? `- ${choice.end_date}` : ''
  } - ${choice.start_time.substring(0, 5)} - ${choice.end_time.substring(
    0,
    5,
  )}`;
};

const AffectationAutocompleteInput = ({
  emptyMessage,
  fullWidth,
  resource,
  validate,
  ...props
}) => {
  return (
    <ReferenceInput
      {...props}
      filterToQuery={
        /* istanbul ignore next */
        (searchText) => {
          return { search: searchText };
        }
      }
      perPage={10}
      source="related_affectation_id"
      reference="affectation"
      sort={{ field: 'last_modified_date', order: 'DESC' }}
      resource={resource}
      allowEmpty
      emptyText="ra.action.clear_input_value"
      validate={validate}
    >
      <AutocompleteInput
        optionText={optionRenderer}
        options={{ placeholder: emptyMessage, fullWidth }}
      />
    </ReferenceInput>
  );
};

AffectationAutocompleteInput.propTypes = {
  emptyMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  resource: PropTypes.string,
  validate: PropTypes.func,
};

AffectationAutocompleteInput.defaultProps = {
  emptyMessage: '',
  fullWidth: true,
  resource: '',
  validate: /* istanbul ignore next */ () => {},
};

export default AffectationAutocompleteInput;
