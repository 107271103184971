import { call, put, takeLatest } from 'redux-saga/effects';
import { FETCH_END, FETCH_ERROR, FETCH_START } from 'react-admin';
import fetchApi from './helpers/fetchApi';

import { SET_AFFECTATIONS_LIST, GET_AFFECTATIONS_FROM_LEAVE } from '../action';

export function* getAffectations({ payload }) {
  try {
    yield put({ type: FETCH_START });
    const affectations = yield call(fetchApi, {
      url: `/api/affectation?leave_id=eq.${payload.leaveId}&type=in.(Absence,Remplacement)&order=id.desc`,
      method: 'GET',
    });
    yield put({ type: FETCH_END });
    yield put({
      type: SET_AFFECTATIONS_LIST,
      affectations,
    });
  } catch (err) {
    yield put({ type: FETCH_ERROR });
  }
}

export default function* watch() {
  yield takeLatest(GET_AFFECTATIONS_FROM_LEAVE, getAffectations);
}
