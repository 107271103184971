import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';
import { useField } from 'react-final-form';

export const AffectationTypeSelectInput = ({ source, validate, ...props }) => {
  const {
    input: { onChange },
    meta: { touched, error },
  } = useField(source);

  return (
    <SelectInput
      {...props}
      source={source}
      onChange={onChange}
      error={!!(touched && error)}
      helperText={touched && error}
      validate={validate}
      choices={[
        {
          id: 'Permanent',
          name: 'resources.affectation.forms.type.permanent',
        },
        {
          id: 'Remplacement',
          name: 'resources.affectation.forms.type.replacement',
        },
        {
          id: 'Ponctuel',
          name: 'resources.affectation.forms.type.ponctual',
        },
        {
          id: 'Absence',
          name: 'resources.affectation.forms.type.absence',
        },
        {
          id: 'Intercontrat',
          name: 'resources.affectation.forms.type.intercontract',
        },
        {
          id: 'Remplacement Saisonnier',
          name: 'resources.affectation.forms.type.saisonnier',
        },
        /* {
          id: 'Maintien Partiel',
          name: 'resources.affectation.forms.type.partiel',
        }, */
      ]}
    />
  );
};
AffectationTypeSelectInput.propTypes = {
  validate: PropTypes.oneOfType([PropTypes.array, PropTypes.func]),
  source: PropTypes.string.isRequired,
};

AffectationTypeSelectInput.defaultProps = {
  validate: [],
};

export default AffectationTypeSelectInput;
