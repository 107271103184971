import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import CloseIcon from '@material-ui/icons/Close';
import { createStyles, withStyles } from '@material-ui/core/styles';

import CleanerAffectationList from './CleanerAffectationList';
import ContractReplacementList from './ContractReplacementList';

const styles = createStyles({
  header: {
    paddingLeft: 20,
    marginTop: 55,
  },
  drawer: {
    width: 350,
    zIndex: 1000,
  },
});

const Aside = withStyles(styles)(
  ({
    basePath,
    classes,
    contractId,
    invertOpenState,
    leaveId,
    open,
    planning,
    selectedCleaners,
  }) => {
    const translate = useTranslate();

    return (
      <Drawer
        variant="persistent"
        classes={{ paper: classes.drawer }}
        open={open}
        anchor="right"
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          className={classes.header}
        >
          <Typography variant="h5">
            {translate('resources.replacement.create.aside.title')}
          </Typography>
          <IconButton onClick={invertOpenState}>
            <CloseIcon />
          </IconButton>
        </Grid>
        {contractId ? (
          <Fragment>
            <ContractReplacementList
              leaveId={leaveId}
              basePath={basePath}
              planning={planning ? { [contractId]: planning[contractId] } : {}}
            />
            <CleanerAffectationList
              basePath={basePath}
              cleanerIds={selectedCleaners}
            />
          </Fragment>
        ) : (
          <ContractReplacementList
            leaveId={leaveId}
            basePath={basePath}
            planning={planning}
          />
        )}
      </Drawer>
    );
  },
);

Aside.propTypes = {
  basePath: PropTypes.string,
  classes: PropTypes.object,
  contractId: PropTypes.string,
  invertOpenState: PropTypes.func,
  leaveId: PropTypes.number.isRequired,
  open: PropTypes.bool,
  selectedCleaners: PropTypes.array,
};

Aside.defaultProps = {
  basePath: '',
  classes: {},
  contractId: '',
  invertOpenState: /* istanbul ignore next */ () => {},
  open: false,
  selectedCleaners: [],
};

export default Aside;
