import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ManagerIcon from '@material-ui/icons/SupervisorAccount';

import {
  ReplacementButton,
  ReplacementCompletionField,
  IconedTextField,
} from '../../../../components';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row',
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(1),
      background: theme.palette.grey[100],
    },
    leftColumn: {
      background: 'color',
      width: '60%',
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    rightColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      marginBottom: theme.spacing(1),
    },
  };
});

const useStylesIconed = makeStyles((theme) => {
  return {
    root: {
      marginTop: theme.spacing(0.5),
    },
  };
});

const ContractHeader = ({
  leaveId,
  manager,
  title,
  totalDuration,
  replacementDuration,
  subtitle,
}) => {
  const classes = useStyles();
  const classesIconed = useStylesIconed();

  return (
    <div className={classes.root}>
      <div className={classes.leftColumn}>
        <Typography variant="h6">{title}</Typography>
        <Typography variant="body2" className={classes.subtitle}>
          {subtitle}
        </Typography>
        <IconedTextField
          classes={classesIconed}
          icon={<ManagerIcon />}
          record={{ manager }}
          source="manager"
        />
      </div>
      <div className={classes.rightColumn}>
        <ReplacementButton
          className={classes.button}
          leaveId={leaveId}
          variant="outlined"
        />
        {!!totalDuration && (
          <ReplacementCompletionField
            current={replacementDuration}
            max={totalDuration}
          />
        )}
      </div>
    </div>
  );
};

ContractHeader.propTypes = {
  leaveId: PropTypes.number,
  manager: PropTypes.string,
  title: PropTypes.string,
  totalDuration: PropTypes.number,
  replacementDuration: PropTypes.number,
  subtitle: PropTypes.string,
};

ContractHeader.defaultProps = {
  leaveId: undefined,
  manager: '',
  title: undefined,
  totalDuration: 0,
  replacementDuration: 0,
  subtitle: undefined,
};

export default ContractHeader;
