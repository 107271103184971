import React from 'react';
import PropTypes from 'prop-types';
import {
  BooleanInput,
  Filter,
  DateInput,
  translate as translateComponent,
} from 'react-admin';
import { CleanerAutocompleteInput } from '../../../components';

const LeaveFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <CleanerAutocompleteInput source="cleaner_id" alwaysOn />
      <BooleanInput
        alwaysOn
        label="resources.leave.fields.processed"
        source="processed"
      />
      <DateInput
        label="resources.affectation.filters.date"
        source="between(start_date, end_date)"
        alwaysOn
      />
    </Filter>
  );
};

LeaveFilter.propTypes = {
  translate: PropTypes.func,
};

LeaveFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(LeaveFilter);
