import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  DateInput,
  BooleanInput,
  translate as translateComponent,
  TextInput,
} from 'react-admin';
import {
  AccountAutocompleteInput,
  CleanerAutocompleteInput,
} from '../../../components';

const PreaffectationFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <CleanerAutocompleteInput alwaysOn source="cleaner_id" />
      <DateInput label="Date" source="between(start_date, end_date)" alwaysOn />
      <AccountAutocompleteInput alwaysOn label="Compte" source="account_id" />
      <CleanerAutocompleteInput alwaysOn label="Manager" source="manager_id" />
      <BooleanInput
        source="traite"
        initialValue="true"
        label="Traité ?"
        alwaysOn
      />
      <TextInput
        label="Commentaire RH"
        source="commentaire_validateur"
        alwaysOn
      />

      <BooleanInput source="monday" label="Lundi" />
      <BooleanInput source="tuesday" label="Mardi" />
      <BooleanInput source="wednesday" label="Mercredi" />
      <BooleanInput source="thursday" label="Jeudi" />
      <BooleanInput source="friday" label="Vendredi" />
      <BooleanInput source="saturday" label="Samedi" />
      <BooleanInput source="sunday" label="Dimanche" />
    </Filter>
  );
};

PreaffectationFilter.propTypes = {
  translate: PropTypes.func,
};

PreaffectationFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(PreaffectationFilter);
