import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  AutocompleteInput,
  DateInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  NumberInput,
  required,
} from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { CatalogItemAutocompleteInput } from '../../../components';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const parseDate = (value) => {
  if (value === '') {
    return null;
  }
  return moment(value).format('YYYY-MM');
};

const InvoiceIncubatorForm = ({ onCancel, record, ...props }) => {
  return (
    <Fragment>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm
        {...sanitizeRestProps(props)}
        record={{ ...record, generated: false }}
      >
        <DateInput source="month" parse={parseDate} />
        <ReferenceInput
          filterToQuery={(searchText) => {
            return { name: searchText };
          }}
          source="account_id"
          reference="account"
          validate={[required()]}
        >
          <AutocompleteInput
            source="name"
            options={{
              suggestionsContainerProps: {
                /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                style: { zIndex: 2000 },
              },
            }}
          />
        </ReferenceInput>
        <ReferenceInput
          filterToQuery={(searchText) => {
            return { name: searchText };
          }}
          source="billing_id"
          reference="account"
          validate={[required()]}
        >
          <AutocompleteInput
            source="name"
            options={{
              suggestionsContainerProps: {
                /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                style: { zIndex: 2000 },
              },
            }}
          />
        </ReferenceInput>
        <CatalogItemAutocompleteInput
          validate={[required()]}
          source="product"
          options={{
            suggestionsContainerProps: {
              /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
              style: { zIndex: 2000 },
            },
          }}
        />
        <TextInput source="description" />
        <NumberInput source="price" step={0.01} validate={[required()]} />
      </SimpleForm>
    </Fragment>
  );
};

InvoiceIncubatorForm.propTypes = {
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

InvoiceIncubatorForm.defaultProps = {
  onCancel: /* istanbul ignore next */ () => {},
  record: {},
};

export default InvoiceIncubatorForm;
