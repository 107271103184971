import React from 'react';
import { SelectInput } from 'react-admin';

const StatusSelectInput = (props) => {
  return (
    <SelectInput
      {...props}
      choices={[
        {
          id: 'APPROVED',
          name: 'resources.cleaner_document.status.APPROVED',
        },
        {
          id: 'REJECTED',
          name: 'resources.cleaner_document.status.REJECTED',
        },
        {
          id: 'PENDING',
          name: 'resources.cleaner_document.status.PENDING',
        },
      ]}
    />
  );
};

export default StatusSelectInput;
