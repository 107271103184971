import React from 'react';
import { Edit } from 'react-admin';
import PropTypes from 'prop-types';
import Form from './detail/Form';

const PreaffectationEdit = ({ basePath, ...props }) => {
  return (
    <Edit {...props} basePath={basePath} redirect="list">
      <Form {...props} redirect="list" />
    </Edit>
  );
};

PreaffectationEdit.propTypes = {
  basePath: PropTypes.string,
};

PreaffectationEdit.defaultProps = {
  basePath: '',
};

export default PreaffectationEdit;
