import React from 'react';
import PropTypes from 'prop-types';
import {
  AutocompleteInput,
  AutocompleteArrayInput,
  ReferenceInput,
  ReferenceArrayInput,
} from 'react-admin';

const CleanerAutocompleteInput = ({
  defaultValue,
  filter,
  fullWidth,
  helperText,
  label,
  multiple,
  resource,
  source,
  validate,
  ...props
}) => {
  return multiple ? (
    <ReferenceArrayInput
      {...props}
      defaultValue={defaultValue}
      filterToQuery={
        /* istanbul ignore next */
        (searchText) => {
          return { fullname: searchText };
        }
      }
      sort={{ field: 'fullname', order: 'ASC' }}
      filter={filter}
      source={source || 'cleaner_ids'}
      reference="cleaner"
      resource={resource}
      allowEmpty
      validate={validate}
      options={{ fullWidth }}
      label={label}
      autoComplete="off"
    >
      <AutocompleteArrayInput optionText="fullname" helperText={helperText} />
    </ReferenceArrayInput>
  ) : (
    <ReferenceInput
      defaultValue={defaultValue}
      filterToQuery={
        /* istanbul ignore next */
        (searchText) => {
          return { fullname: searchText };
        }
      }
      sort={{ field: 'fullname', order: 'ASC' }}
      filter={filter}
      source={source || 'cleaner_id'}
      reference="cleaner"
      resource={resource}
      allowEmpty
      emptyText="ra.action.clear_input_value"
      validate={validate}
      label={label}
    >
      <AutocompleteInput
        optionText="fullname"
        options={{ fullWidth }}
        helperText={helperText}
      />
    </ReferenceInput>
  );
};

CleanerAutocompleteInput.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  filter: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperText: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  resource: PropTypes.string,
  source: PropTypes.string,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

CleanerAutocompleteInput.defaultProps = {
  defaultValue: undefined,
  filter: {},
  fullWidth: true,
  helperText: undefined,
  label: undefined,
  multiple: false,
  resource: '',
  source: '',
  validate: /* istanbul ignore next */ () => {},
};

export default CleanerAutocompleteInput;
