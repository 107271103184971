import React from 'react';
import PropTypes from 'prop-types';
import { DrawerCreateEdit } from '../../../containers';
import Edit from '../../DocumentClient/Edit';
import DocumentDatagrid from '../../DocumentClient/detail/Datagrid';

const sanitizeRestProps = ({ hasList, hasEdit, hasShow, ...rest }) => {
  return rest;
};

const ClientDocumentsDrawerList = ({ originRoute, ...props }) => {
  return (
    <DrawerCreateEdit
      {...props}
      routePath={originRoute}
      editPath="/edit_client_document"
      renderEdit={Edit}
      redirect={() => {
        return originRoute;
      }}
    >
      <DocumentDatagrid
        {...sanitizeRestProps(props)}
        origin={`${originRoute}/edit_client_document`}
      />
    </DrawerCreateEdit>
  );
};

ClientDocumentsDrawerList.propTypes = {
  originRoute: PropTypes.string,
};

ClientDocumentsDrawerList.defaultProps = {
  originRoute: '',
};

export default ClientDocumentsDrawerList;
