// import {
//   queryParameters,
//   fetchJson,
// } from '@sebastien-cleany/admin-on-rest/lib/util/fetch';
import { stringify } from 'query-string';
import {
  GET_LIST,
  GET_ONE,
  GET_MANY,
  GET_MANY_REFERENCE,
  CREATE,
  UPDATE,
  DELETE,
  DELETE_MANY,
  fetchUtils,
} from 'react-admin';

const API_URL = 'api';

const operators = {
  between: /between\(([^,]+), ?([^,]+)\)/,
  lte: /\blte\(([^)]+)\)/,
  gte: /\bgte\(([^)]+)\)/,
  in: /\bin\(([^)]+)\)/,
  neq: /\bneq\(([^)]+)\)/,
};

/**
 * Maps admin-on-rest queries to a postgrest API
 *
 * The REST dialect is similar to the one of FakeRest
 * @see https://github.com/marmelab/FakeRest
 * @example
 * GET_MANY_REFERENCE
 *              => GET http://my.api.url/posts/2
 * GET_LIST     => GET http://my.api.url/posts?order=title.asc
 * GET_ONE      => GET http://my.api.url/posts?id=eq.123
 * GET_MANY     => GET http://my.api.url/posts?id=in.(123,456,789)
 * UPDATE       => PATCH http://my.api.url/posts?id=eq.123
 * CREATE       => POST http://my.api.url/posts
 * DELETE       => DELETE http://my.api.url/posts?id=eq.123
 * DELETE_MANY  => DELETE_MANY http://my.api.url/posts?id=in.(123,456,789)
 */
/* eslint no-param-reassign: ["error", { "props": false }] */
const convertFilters = (filters) => {
  const rest = {};

  Object.keys(filters).map((key) => {
    let operator;
    let operatorType;
    Object.keys(operators).forEach((item) => {
      const reg = operators[item].exec(key);
      if (reg) {
        operator = reg;
        operatorType = item;
      }
    });

    if (operator) {
      switch (operatorType) {
        case 'between': {
          const [, from, to] = operator;
          rest[from] = `lte.${filters[key].replace(/:/, '')}`;
          rest[to] = `gte.${filters[key].replace(/:/, '')}`;
          break;
        }
        case 'gte':
        case 'lte': {
          const [, filter] = operator;
          rest[filter] = `${operatorType}.${filters[key].replace(/:/, '')}`;
          break;
        }
        case 'in': {
          const [, filter] = operator;

          if (!filters[key] || !Array.isArray(filters[key])) {
            break;
          }

          rest[filter] = `${operatorType}.(${filters[key].toString()})`;
          break;
        }
        case 'neq': {
          const [, filter] = operator;
          rest[filter] = `${operatorType}.${filters[key]}`;
          break;
        }
        default:
          break;
      }
      return true;
    }
    switch (typeof filters[key]) {
      case 'string':
        if (key === 'search') {
          rest[key] = `ilike.*${filters[key]
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/ /g, '*')}*`;
        } else {
          rest[key] = `ilike.*${filters[key]}*`;
        }
        break;

      case 'boolean':
        rest[key] = `is.${filters[key]}`;
        break;

      case 'undefined':
        rest[key] = 'is.null';
        break;

      case 'number':
        rest[key] = `eq.${filters[key]}`;
        break;

      default:
        if (Array.isArray(filters[key])) {
          rest[key] = `cs.{${filters[key].join().replace(/:/, '')}}`;
          break;
        }
        rest[key] = `ilike.*${filters[key].toString()}*`;
        break;
    }
    return true;
  });
  return rest;
};

const convertData = (data) => {
  const converted = {};

  if (!data) {
    return data;
  }

  Object.keys(data).forEach((key) => {
    if (data[key] instanceof Array) {
      converted[key] = JSON.stringify(data[key])
        .replace('[', '{')
        .replace(']', '}');
      return;
    }

    converted[key] = data[key];
  });

  return converted;
};

/**
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} { url, options } The HTTP request parameters
 */
const convertDataProviderRequestToHTTP = (type, resource, params) => {
  let url = '';
  const options = {};
  options.headers = new Headers();
  options.headers.set('Access-Control-Allow-Origin', '*');
  options.credentials = 'same-origin';
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      const filters = {};

      Object.assign(filters, params.filter);
      const { field, order } = params.sort;
      const { page, perPage } = params.pagination;
      options.headers.set('Range-Unit', 'items');
      options.headers.set(
        'Range',
        `${(page - 1) * perPage}-${page * perPage - 1}`,
      );
      options.headers.set('Prefer', 'count=exact');

      if (type === GET_MANY_REFERENCE) {
        filters[params.target] = params.id;
      }

      const query = {};
      if (field) {
        const fieldParts = field.split(',');
        const orderParts = order.split(',');

        if (fieldParts.length !== orderParts.length) {
          throw new Error(
            `Sort should have the same number of field(${fieldParts.length}) and order(${orderParts.length})`,
          );
        }

        query.order = fieldParts
          .map((fieldPart, index) => {
            return `${fieldPart.trim()}.${orderParts[index]
              .trim()
              .toLowerCase()}`;
          })
          .join(',');
      }

      Object.assign(query, convertFilters(filters));
      url = `${API_URL}/${resource}?${stringify(query)}`;
      break;
    }
    case GET_ONE:
      url = `${API_URL}/${resource}?id=eq.${params.id}`;
      break;
    case GET_MANY: {
      url = `${API_URL}/${resource}?id=in.(${params.ids.join(',')})`;
      break;
    }
    case UPDATE:
      url = `${API_URL}/${resource}?id=eq.${params.id}`;
      options.method = 'PATCH';
      options.body = JSON.stringify(convertData(params.data));
      break;
    case CREATE:
      url = `${API_URL}/${resource}`;
      options.headers.set('Prefer', 'return=representation');
      options.method = 'POST';
      options.body = JSON.stringify(convertData(params.data));
      break;
    case DELETE:
      url = `${API_URL}/${resource}?id=eq.${params.id}`;
      options.method = 'DELETE';
      break;
    case DELETE_MANY:
      url = `${API_URL}/${resource}?id=in.(${params.ids.join(',')})`;
      options.method = 'DELETE';
      break;
    default:
      throw new Error(`Unsupported fetch action type ${type}`);
  }
  return { url, options };
};

/**
 * @param {Object} response HTTP response from fetch()
 * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
 * @param {String} resource Name of the resource to fetch, e.g. 'posts'
 * @param {Object} params The REST request params, depending on the type
 * @returns {Object} REST response
 */
const convertHTTPResponseToDataProvider = (
  response,
  type,
  resource,
  params,
) => {
  const { headers, json } = response;
  switch (type) {
    case GET_LIST:
    case GET_MANY_REFERENCE: {
      if (!headers.has('content-range')) {
        throw new Error(
          'The Content-Range header is missing in the HTTP Response. The simple REST client expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare Content-Range in the Access-Control-Expose-Headers header?',
        );
      }
      const maxInPage =
        parseInt(
          headers
            .get('content-range')
            .split('/')[0]
            .split('-')
            .pop(),
          10,
        ) + 1;
      return {
        data: json.map((x) => {
          return x;
        }),
        total:
          parseInt(
            headers
              .get('content-range')
              .split('/')
              .pop(),
            10,
          ) ||
          maxInPage ||
          0,
      };
    }
    case CREATE:
    case GET_ONE:
      if (!json.length) {
        return {};
      }
      return { id: json[0].id, data: { ...json[0] } };
    case DELETE:
      return { data: { id: params.id } };
    case DELETE_MANY:
      return { data: params.ids };
    default:
      if (!json) {
        return { data: Object.assign({}, params.data) };
      }
      return { data: json };
  }
};

/**
 * @param {string} type Request type, e.g GET_LIST
 * @param {string} resource Resource name, e.g. "posts"
 * @param {Object} payload Request parameters. Depends on the request type
 * @returns {Promise} the Promise for a REST response
 */
export default (type, resource, params) => {
  const { fetchJson } = fetchUtils;
  const { url, options } = convertDataProviderRequestToHTTP(
    type,
    resource,
    params,
  );
  return fetchJson(url, options).then((response) => {
    return convertHTTPResponseToDataProvider(response, type, resource, params);
  });
};
