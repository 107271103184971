import React, { useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { Create } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import Form from './detail/Form';
import LeaveInformation from './detail/LeaveInformation';
import Aside from './detail/Aside';
import Header from './detail/Header';
import ReplacementController from './detail/ReplacementController';
import { buildPlanning } from './helper';

const useStyles = makeStyles({
  itemOpen: {
    width: 'calc(100% - 350px)',
  },
  itemClose: {
    width: '100%',
  },
});

const CreateView = ({
  affectations,
  cleanerIds,
  contractId,
  leaveId,
  ...props
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);

  const invertOpenState = () => {
    setOpen(!open);
  };

  const planning = buildPlanning(affectations);

  return (
    <Grid container alignContent="center" spacing={8}>
      <Grid item className={open ? classes.itemOpen : classes.itemClose}>
        <Grid container direction="column">
          <Header onClick={invertOpenState} />
          <LeaveInformation {...props} leaveId={leaveId} />
          <Create {...props}>
            <Form
              affectations={affectations}
              cleanerIds={cleanerIds}
              planning={planning}
              leaveId={leaveId}
              aside={
                <Aside
                  {...props}
                  open={open}
                  invertOpenState={invertOpenState}
                  leaveId={parseInt(leaveId, 10)}
                  contractId={contractId}
                  planning={planning}
                />
              }
            />
          </Create>
        </Grid>
      </Grid>
    </Grid>
  );
};

CreateView.propTypes = {
  affectations: PropTypes.array,
  cleanerIds: PropTypes.array,
  contractId: PropTypes.string,
  leaveId: PropTypes.string,
  location: PropTypes.object,
};

CreateView.defaultProps = {
  affectations: [],
  cleanerIds: [],
  contractId: undefined,
  leaveId: undefined,
  location: { search: {} },
};

const ReplacementCreate = (props) => {
  const leaveId = queryString.parse(props.location.search).leave_id;
  const contractId = queryString.parse(props.location.search).contract_id;

  if (!leaveId || leaveId === 'undefined') {
    return null;
  }

  return (
    <ReplacementController contractId={contractId} leaveId={leaveId} {...props}>
      <CreateView />
    </ReplacementController>
  );
};

ReplacementCreate.propTypes = {
  location: PropTypes.object,
  classes: PropTypes.object,
};

ReplacementCreate.defaultProps = {
  location: { search: {} },
  classes: {},
};

export default ReplacementCreate;
