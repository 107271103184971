import React from 'react';
import PropTypes from 'prop-types';
import {
  Filter,
  DateInput,
  BooleanInput,
  TextInput,
  translate as translateComponent,
} from 'react-admin';
import {
  CleanerAutocompleteInput,
  AffectationTypeSelectInput,
  PrestationTypeSelectInput,
} from '../../../components';

const FilterAffectation = ({ translate, ...props }) => {
  return (
    <Filter {...props}>

      <CleanerAutocompleteInput alwaysOn source="cleaner_id" />
      <DateInput label="Date" source="between(start_date, end_date)" alwaysOn />
      <TextInput source="account_name"/>
      <AffectationTypeSelectInput source="type" />
      <BooleanInput source="active" initialValue="true" label="Actif ?" />
      <PrestationTypeSelectInput source="prestation_type" />
      <TextInput source="manager_fullname" />
      <TextInput source="operational_fullname" label="Opérationnel"/>
      <BooleanInput source="monday" label="Lundi" />
      <BooleanInput source="tuesday" label="Mardi" />
      <BooleanInput source="wednesday" label="Mercredi" />
      <BooleanInput source="thursday" label="Jeudi" />
      <BooleanInput source="friday" label="Vendredi" />
      <BooleanInput source="saturday" label="Samedi" />
      <BooleanInput source="sunday" label="Dimanche" />
    </Filter>
  );
};

FilterAffectation.propTypes = {
  translate: PropTypes.func,
};

FilterAffectation.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(FilterAffectation);
