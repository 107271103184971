import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';

import ListCard from './ListCard';

const CustomList = ({ data, ids, total, loading, ...props }) => {
  if (loading || total > 0) {
    return (
      <Grid style={{ paddingLeft: 16 }} container spacing={2} direction="row">
        {ids.map((id) => {
          return (
            <Grid item xs={6} key={id}>
              <ListCard record={data[id]} {...props} />
            </Grid>
          );
        })}
      </Grid>
    );
  }
  return null;
};

CustomList.propTypes = {
  data: PropTypes.object,
  ids: PropTypes.array,
  total: PropTypes.number,
  loading: PropTypes.bool,
};

CustomList.defaultProps = {
  data: {},
  ids: [],
  total: 0,
  loading: false,
};

export default CustomList;
