import createLegalDocument from './createLegalDocument';
import createPayrollCsv from './createPayrollCsv';
import createReplacement from './createReplacement';
import getAffectationsFromLeave from './getAffectationsFromLeave';
import getCleanerTimeline from './getCleanerTimeline';
import getDriveDocument from './getDriveDocument';
import getLeaveSiteCleaners from './getLeaveSiteCleaners';
import getPayrollExplanation from './getPayrollExplanation';
import inviteCleaner from './inviteCleaner';
import runSchedulerJob from './scheduler';
import saveAffectationBundle from './saveAffectationBundle';
import signContract from './signContract';
import uploadCleanerDocument from './uploadCleanerDocument';
import uploadClientDocument from './uploadClientDocument';
import uploadProviderInvoice from './uploadProviderInvoice';

export default [
  createLegalDocument,
  createReplacement,
  createPayrollCsv,
  getAffectationsFromLeave,
  getCleanerTimeline,
  getDriveDocument,
  getLeaveSiteCleaners,
  getPayrollExplanation,
  inviteCleaner,
  runSchedulerJob,
  saveAffectationBundle,
  signContract,
  uploadCleanerDocument,
  uploadClientDocument,
  uploadProviderInvoice,
];
