import React from 'react';
import PropTypes from 'prop-types';

import { useTranslate } from 'react-admin';
import { CleanerMenuActions } from '../../../../components/index.js';
import NewContract from './NewContract.js';

const ContractMenu = ({ className, record }) => {
  const translate = useTranslate();
  return (
    <CleanerMenuActions
      className={className}
      label="Contrat"
      placeholder="📝 Demander un contrat"
      record={record}
    >
      <NewContract translate={translate} />
    </CleanerMenuActions>
  );
};

ContractMenu.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

ContractMenu.defaultProps = {
  className: undefined,
  record: {},
};

export default ContractMenu;
