import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TimelineCard from './TimelineCard';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'relative',
      '&::before': {
        content: '""',
        position: 'absolute',
        backgroundColor: theme.palette.secondary.main,
        height: '100%',
        width: 3,
        marginLeft: 7,
        zIndex: -1,
      },
    },
    tagContainer: {
      backgroundColor: theme.palette.background.default,
      padding: '7px 0px',
    },
    monthTag: {
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.white,
      borderRadius: 3,
      padding: '2px 4px',
    },
  };
});

const Timeline = ({ events, onEventClick }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={1} className={classes.root}>
      {events.map((event, index) => {
        const previousDate = index ? events[index - 1].date : null;
        const currentDate = event.date;
        const monthChange =
          moment(previousDate).format('YYYY-MM') !==
          moment(currentDate).format('YYYY-MM');
        return (
          /* eslint-disable-next-line react/no-array-index-key */
          <Fragment key={event._id || index}>
            {monthChange && (
              <div className={classes.tagContainer}>
                <Typography
                  className={classes.monthTag}
                  component="span"
                  variant="body1"
                >
                  {moment(event.date).format('MMMM YYYY')}
                </Typography>
              </div>
            )}
            <TimelineCard event={event} onDetailsClick={onEventClick} />
          </Fragment>
        );
      })}
    </Grid>
  );
};

Timeline.propTypes = {
  events: PropTypes.array,
  onEventClick: PropTypes.func,
};

Timeline.defaultProps = {
  events: {},
  onEventClick: () => {},
};

export default Timeline;
