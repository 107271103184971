import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Typography, makeStyles, LinearProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: 'rgba(0, 0, 0, 0.02)',
      minHeight: 47,
      display: 'flex',
      alignItems: 'center',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(0.5),
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    helperText: {
      marginBottom: theme.spacing(1) + 18.75,
    },
    progress: {
      flex: 1,
    },
    text: {
      color: theme.palette.text.disabled,
    },
  };
});

const EmptyInput = ({ loading, helperText, label, variant }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, helperText && classes.helperText)}>
      {loading ? (
        <LinearProgress className={classes.progress} />
      ) : (
        <Typography className={classes.text} variant={variant}>
          {label}
        </Typography>
      )}
    </div>
  );
};

EmptyInput.propTypes = {
  loading: PropTypes.bool,
  helperText: PropTypes.bool,
  label: PropTypes.string,
  variant: PropTypes.string,
};

EmptyInput.defaultProps = {
  loading: false,
  helperText: true,
  label: '',
  variant: 'body2',
};

export default EmptyInput;
