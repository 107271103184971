import { call, put, takeLatest } from 'redux-saga/effects';
import {
  FETCH_END,
  FETCH_ERROR,
  FETCH_START,
  showNotification,
} from 'react-admin';
import { GET_DRIVE_DOCUMENT, SET_DRIVE_DOCUMENT } from '../action';
import fetchApi from './helpers/fetchApi';

export const convertBlobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

const getDriveDocumentRequest = async (payload) => {
  return fetchApi(
    {
      url: '/cleanerDocument/get',
      payload,
    },
    async (response) => {
      return response.text();
    },
  );
};

export function* getDriveDocumentHandler({ payload }) {
  try {
    yield put({ type: FETCH_START });
    const res = yield call(getDriveDocumentRequest, payload);
    yield put({ type: SET_DRIVE_DOCUMENT, payload: { file: res } });
    yield put({ type: FETCH_END });
  } catch (err) {
    yield put({ type: FETCH_ERROR });
    yield put(showNotification(err.message, 'warning'));
  }
}

export default function* watch() {
  yield takeLatest(GET_DRIVE_DOCUMENT, getDriveDocumentHandler);
}
