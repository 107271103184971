export default (list, record) => {
  const sameDay = record.start_date === record.end_date;

  const dayInJS = new Date(record.start_date);

  return sameDay
    ? list.filter(index => {
        return index === (dayInJS.getDay() === 0 ? 6 : dayInJS.getDay() - 1);
      })
    : list;
};
