import React from 'react';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import GridSimpleShowLayout from './GridSimpleShowLayout';

const GridCard = ({
  children,
  title,
  subtitle,
  action,
  avatar,
  xs,
  sm,
  md,
  lg,
  xl,
  defaultChildrenGrid,
  classes,
  ...props
}) => {
  return (
    <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} className={classes.root}>
      <Card>
        {title && (
          <CardHeader
            classes={{ root: classes.headerRoot }}
            title={title}
            subheader={subtitle}
            action={action}
            avatar={avatar}
          />
        )}
        <GridSimpleShowLayout {...props} classes={classes}>
          {children}
        </GridSimpleShowLayout>
      </Card>
    </Grid>
  );
};

GridCard.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  action: PropTypes.element,
  avatar: PropTypes.element,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  defaultChildrenGrid: PropTypes.shape({
    xs: PropTypes.number,
    sm: PropTypes.number,
    md: PropTypes.number,
    lg: PropTypes.number,
    xl: PropTypes.number,
  }),
  classes: PropTypes.object,
};

GridCard.defaultProps = {
  children: undefined,
  title: undefined,
  subtitle: undefined,
  action: null,
  avatar: null,
  xs: 12,
  sm: undefined,
  md: undefined,
  lg: undefined,
  xl: undefined,
  defaultChildrenGrid: {
    xs: 12,
    sm: 6,
    md: 3,
    lg: 2,
    xl: undefined,
  },
  classes: {},
};

export default GridCard;
