import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate } from 'react-admin';
import { Typography, makeStyles } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.error.main,
      paddingRight: theme.spacing(2),
    },
  };
});

const WarningContract = ({ contracts }) => {
  const classes = useStyles();
  const translate = useTranslate();

  if (!contracts || !contracts.length || contracts.length < 2) {
    return null;
  }

  return (
    <div className={classes.root}>
      <WarningIcon className={classes.icon} />
      <Typography variant="body1" color="error">
        {translate('resources.account.error.too_much_contracts', {
          contract_number: contracts.length,
        })}
      </Typography>
    </div>
  );
};

WarningContract.propTypes = {
  contracts: PropTypes.array,
};

WarningContract.defaultProps = {
  contracts: [],
};

export default WarningContract;
