import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

const ProviderAutocompleteInput = ({ source, validate }) => {
  return (
    <ReferenceInput
      filterToQuery={(searchText) => {
        return { name: searchText };
      }}
      resource="provider_invoice"
      source={source}
      reference="account"
      filter={{ type: 'Partenaire' }}
      sort={{ field: 'name', order: 'ASC' }}
      validate={validate}
    >
      <AutocompleteInput
        options={{
          suggestionsContainerProps: {
            /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
            style: { zIndex: 2000 },
          },
        }}
      />
    </ReferenceInput>
  );
};

ProviderAutocompleteInput.propTypes = {
  source: PropTypes.string.isRequired,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.array]),
};

ProviderAutocompleteInput.defaultProps = {
  validate: undefined,
};

export default ProviderAutocompleteInput;
