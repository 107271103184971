import React from 'react';
import PropTypes from 'prop-types';
import {
  DateInput,
  Filter,
  TextInput,
  translate as translateComponent,
} from 'react-admin';
import {
  AccountAutocompleteInput,
  ContractAutocompleteInput,
} from '../../../components';

const ProviderInvoiceFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <ContractAutocompleteInput source="contract_id" alwaysOn />
      <AccountAutocompleteInput
        source="provider_account_id"
        alwaysOn
        label={translate(
          'resources.provider_invoice.fields.provider_account_id',
        )}
      />
      <TextInput source="invoice_number" alwaysOn />
      <DateInput
        label="resources.provider_invoice.filters.date"
        source="between(date, date)"
        alwaysOn
      />
    </Filter>
  );
};

ProviderInvoiceFilter.propTypes = {
  translate: PropTypes.func,
};

ProviderInvoiceFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(ProviderInvoiceFilter);
