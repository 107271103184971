import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from 'react-admin';
import ContentAdd from '@material-ui/icons/Add';

const CreateWithParamsButton = ({ basePath, icon, label, params }) => {
  const url = `${basePath}/create?${params}`;

  return (
    <Button component={Link} to={url} label={label}>
      {icon}
    </Button>
  );
};

CreateWithParamsButton.propTypes = {
  basePath: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.element,
  params: PropTypes.string,
};

CreateWithParamsButton.defaultProps = {
  basePath: '',
  label: 'ra.action.create',
  icon: <ContentAdd />,
  params: null,
};

export default CreateWithParamsButton;
