import React from 'react';
import PropTypes from 'prop-types';
import { Filter, translate as translateComponent } from 'react-admin';
import {
  CleanerAutocompleteInput,
  ContractTypeSelectInput,
  EchellonSelectInput,
  SubTypeContractSelectInput,
} from '../../../components';

const CleanerContractFilter = ({ translate, ...props }) => {
  return (
    <Filter {...props}>
      <CleanerAutocompleteInput alwaysOn source="cleaner_id" />
      <ContractTypeSelectInput source="contract_type" />
      <EchellonSelectInput source="level" />
      <SubTypeContractSelectInput source="contract_subtype" />
    </Filter>
  );
};

CleanerContractFilter.propTypes = {
  translate: PropTypes.func,
};

CleanerContractFilter.defaultProps = {
  translate: /* istanbul ignore next */ () => {},
};

export default translateComponent(CleanerContractFilter);
