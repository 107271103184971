import React from 'react';
import PropTypes from 'prop-types';
import PlanningLine from './PlanningLine';

const Planning = ({ planning }) => {
  return (
    <div>
      {planning.map((item) => {
        return (
          <PlanningLine
            key={item.id}
            days={item.days}
            endTime={item.endTime}
            startTime={item.startTime}
          />
        );
      })}
    </div>
  );
};

Planning.propTypes = {
  planning: PropTypes.array,
};

Planning.defaultProps = {
  planning: [],
};

export default Planning;
