import postgrestProvider from './ra-data-postgrest';
import mongooseProvider from './ra-data-mongoose';

const mongooseResources = [
  'availability',
  'issue_report',
  'logs',
  'mission_report',
];

export default (type, resource, params) => {
  if (mongooseResources.includes(resource)) {
    return mongooseProvider(type, resource, params);
  }

  return postgrestProvider(type, resource, params);
};
