import React from 'react';
import {
  Datagrid,
  DateField,
  List,
  ReferenceField,
  TextField,
} from 'react-admin';
import Filter from './detail/Filter';

import { DrawerCreateEdit } from '../../containers';
import Edit from './Edit';
import Create from './Create';

export default (props) => {
  return (
    <DrawerCreateEdit
      {...props}
      routePath="/provider"
      renderCreate={Create}
      renderEdit={Edit}
    >
      <List
        {...props}
        hasCreate
        filters={<Filter />}
        bulkActionButtons={false}
        perPage={25}
      >
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <ReferenceField link="show" source="account_id" reference="account">
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField link="show" source="provider" reference="account">
            <TextField source="name" />
          </ReferenceField>
          <TextField source="provider_ref_id" />
          <DateField source="last_action" />
          <DateField source="next_action" />
        </Datagrid>
      </List>
    </DrawerCreateEdit>
  );
};
