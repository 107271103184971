import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { ReferenceManyField, TextField, SimpleList } from 'react-admin';

import {
  DatePeriodField,
  TimePeriodField,
  WeekdaysWorkedField,
} from '../../../../components';

const ContractReplacementField = ({ leaveId, ...props }) => {
  return (
    <ReferenceManyField
      {...props}
      target="contract_id"
      source="contract_id"
      reference="affectation"
      filter={{ leave_id: leaveId, type: 'Remplacement' }}
      loaded
    >
      <SimpleList
        link={false}
        primaryText={(record) => {
          return (
            <Fragment>
              <TextField
                record={record}
                source="cleaner_fullname"
                variant="subtitle1"
              />
              <DatePeriodField record={record} variant="subtitle1" />
              <TimePeriodField record={record} variant="subtitle1" />
            </Fragment>
          );
        }}
        secondaryText={(record) => {
          return <WeekdaysWorkedField record={record} />;
        }}
      />
    </ReferenceManyField>
  );
};

ContractReplacementField.propTypes = {
  leaveId: PropTypes.number.isRequired,
};

export default ContractReplacementField;
