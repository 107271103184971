import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useTranslate } from 'react-admin';
import { Typography } from '@material-ui/core';

const CleanerContractSpecificationField = ({ record, variant }) => {
  const translate = useTranslate();
  const level = get(record, 'level');
  const isFullTime = get(record, 'full_time');
  let subtype = get(record, 'contract_subtype');
  if (subtype === 'remplacement') {
    subtype = 'CDD de remplacement';
  }
  if (subtype === 'accroissement') {
    subtype = "CDD d'accroisement temporaire d'activité";
  }
  if (subtype === 'complement_heure') {
    subtype = "CDI de complément d'heure";
  }
  if (subtype === 'CDD_en_CDI') {
    subtype = "CDI - pérenisation d'un CDD";
  }
  const fullTime = translate(
    `components.field.cleaner_contract_spec.full_time.${isFullTime}`,
  );

  return (
    <Typography variant={variant}>
      {`${subtype} - ${level} - ${fullTime}`}
    </Typography>
  );
};

CleanerContractSpecificationField.propTypes = {
  record: PropTypes.object,
  variant: PropTypes.string,
};

CleanerContractSpecificationField.defaultProps = {
  record: {},
  variant: 'body2',
};

export default CleanerContractSpecificationField;
