import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      color: theme.palette.grey[600],
      marginRight: theme.spacing(1),
    },
  };
});

const IconedTextField = ({
  classes: classesOverride,
  icon,
  source,
  ...props
}) => {
  const classes = useStyles({ classes: classesOverride });

  return (
    <div className={classes.root}>
      {cloneElement(icon, { className: classes.icon })}
      <TextField {...props} source={source} />
    </div>
  );
};

IconedTextField.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.element.isRequired,
  source: PropTypes.string,
};

IconedTextField.defaultProps = {
  classes: {},
  source: {},
};

export default IconedTextField;
