import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'react-admin';

export const ContractEndTypeInput = ({ source, validate, ...props }) => {
  return (
    <SelectInput
      {...props}
      source={source}
      allowEmpty
      emptyText="ra.action.clear_input_value"
      validate={validate}
      choices={[
        {
          id: 'RESIGNATION',
          name: 'components.field.contract_end_type.RESIGNATION',
        },
        {
          id: 'RETIREMENT',
          name: 'components.field.contract_end_type.RETIREMENT',
        },
        {
          id: 'FIXED_TERM_CONTRACT_END',
          name: 'components.field.contract_end_type.FIXED_TERM_CONTRACT_END',
        },
        {
          id: 'TAKEN_OVER',
          name: 'components.field.contract_end_type.TAKEN_OVER',
        },
        {
          id: 'TRIAL_PERIOD_END_1',
          name: 'components.field.contract_end_type.TRIAL_PERIOD_END_1',
        },
        {
          id: 'TRIAL_PERIOD_END_2',
          name: 'components.field.contract_end_type.TRIAL_PERIOD_END_2',
        },
        {
          id: 'rupture_amiable',
          name: 'components.field.contract_end_type.rupture_amiable',
        },
        {
          id: 'rupture_conventionnelle',
          name: 'components.field.contract_end_type.rupture_conventionnelle',
        },
        {
          id: 'SIMPLE_MISCONDUCT_DISMISSAL',
          name:
            'components.field.contract_end_type.SIMPLE_MISCONDUCT_DISMISSAL',
        },
        {
          id: 'SERIOUS_MISCONDUCT_DISMISSAL',
          name:
            'components.field.contract_end_type.SERIOUS_MISCONDUCT_DISMISSAL',
        },
        {
          id: 'GRAVE_MISCONDUCT_DISMISSAL',
          name: 'components.field.contract_end_type.GRAVE_MISCONDUCT_DISMISSAL',
        },
        {
          id: 'LICENCIEMENT_NON_PROFESSIONNELLE',
          name:
            'components.field.contract_end_type.LICENCIEMENT_NON_PROFESSIONNELLE',
        },
        {
          id: 'LICENCIEMENT_PROFESSIONNELLE',
          name:
            'components.field.contract_end_type.LICENCIEMENT_PROFESSIONNELLE',
        },
      ]}
    />
  );
};

ContractEndTypeInput.propTypes = {
  validate: PropTypes.array,
  addLabel: PropTypes.bool,
  source: PropTypes.string.isRequired,
};

ContractEndTypeInput.defaultProps = {
  validate: [],
  addLabel: false,
};

export default ContractEndTypeInput;
