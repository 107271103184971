import React from 'react';
import PropTypes from 'prop-types';
import { useMediaQuery } from '@material-ui/core';
import {
  Datagrid,
  DateField,
  TextField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
} from 'react-admin';
import {
  ScheduleField,
  TimeField,
  AffectationTypeField,
} from '../../../../../components';

const AffectationList = ({ active, filter, sort, ...props }) => {
  const isSmall = useMediaQuery((theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <ReferenceManyField
      {...props}
      reference="affectation"
      target="cleaner_id"
      addLabel={false}
      filter={{
        ...filter,
        active,
      }}
      sort={sort}
      xs={12}
      perPage={10}
      pagination={<Pagination />}
    >
      {isSmall ? (
        <Datagrid {...props} rowClick="show">
          <ReferenceField
            allowEmpty
            link="show"
            source="account_id"
            reference="account"
            label="Compte"
          >
            <TextField source="name" />
          </ReferenceField>
          <AffectationTypeField source="type" />
          <TextField source="manager_fullname" />
          <DateField source="start_date" />
          <ScheduleField source="planning" />
        </Datagrid>
      ) : (
        <Datagrid {...props} rowClick="show">
          <ReferenceField
            allowEmpty
            link="show"
            source="account_id"
            reference="account"
            label="Compte"
          >
            <TextField source="name" />
          </ReferenceField>
          <AffectationTypeField source="type" />
          <TextField source="manager_fullname" />
          <DateField source="start_date" />
          <DateField source="end_date" />
          <ScheduleField source="planning" />
          <TimeField source="duration_per_week" />
        </Datagrid>
      )}
    </ReferenceManyField>
  );
};

AffectationList.propTypes = {
  active: PropTypes.bool,
  filter: PropTypes.object,
  sort: PropTypes.object,
};

AffectationList.defaultProps = {
  active: false,
  filter: {},
  sort: { field: 'start_date', order: 'DESC' },
};

export default AffectationList;
