import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { Button, translate as translateComponent } from 'react-admin';
import { getLegalDocument } from '../../../../action';

const RenouvellementPE = ({ className, getLegalDocumentClick }) => {
  const [datePE, setPE] = useState('29/03/2022');
  const [open, setOpen] = useState(false);
  const invertOpenStatus = () => {
    setOpen(!open);
  };
  const OnChangePE = (newPE) => {
    setPE(newPE.target.value);
  };

  return (
    <Fragment>
      <MenuItem
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
      >
        <ListItemText primary="Renouvellement de période d'essai" />
      </MenuItem>
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle color="primary" id="alert-dialog-title">
          Renouvellement de Période d&apos;essai
        </DialogTitle>

        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '10px', width: '40%' }}
            label="Date de la fin de PE"
            defaultValue={datePE}
            onChange={OnChangePE}
            InputLabelProps={{
              shrink: true,
            }}
          />
          ⚠️ Attention a bien mettre la date de fin de PE en prenant en compte
          les absences du cleaner
        </DialogContent>
        <DialogActions>
          <Button
            label="resources.cleaner.actions.download"
            variant="contained"
            onClick={() => {
              getLegalDocumentClick('trialPeriodRenewal', {
                datePE: `${datePE}`,
              });
              invertOpenStatus();
            }}
          >
            <CloudDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

RenouvellementPE.propTypes = {
  className: PropTypes.string,
  getLegalDocumentClick: PropTypes.func.isRequired,
};

RenouvellementPE.defaultProps = {
  className: undefined,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLegalDocumentClick: (documentType, others) => {
      const payload = {
        id: ownProps.record.id,
        fullname: ownProps.record.fullname,
        documentType,
        ...others,
      };
      dispatch(getLegalDocument(payload));
    },
  };
};

const enhance = compose(translateComponent, connect(null, mapDispatchToProps));

export default enhance(RenouvellementPE);
