import React from 'react';
import PropTypes from 'prop-types';
import { AutocompleteInput, ReferenceInput } from 'react-admin';

export const optionRenderer = (choice) => {
  if (choice === null) {
    return '';
  }

  if (choice !== null) {
    return `${choice.fullname} - ${choice.start_date} - ${choice.end_date} ${
      choice.contract_id ? `- ${choice.contract_id}` : ''
    }`;
  }
  return '';
};

const LeaveAutocompleteInput = ({
  emptyMessage,
  fullWidth,
  resource,
  validate,
  ...props
}) => {
  return (
    <ReferenceInput
      {...props}
      filterToQuery={
        /* istanbul ignore next */
        (searchText) => {
          return { fullname: searchText };
        }
      }
      perPage={10}
      source="leave_id"
      reference="leave_info"
      sort={{ field: 'id', order: 'DESC' }}
      resource={resource}
      validate={validate}
      allowEmpty
      emptyText="ra.action.clear_input_value"
    >
      <AutocompleteInput
        optionText={optionRenderer}
        options={{ placeholder: emptyMessage, fullWidth }}
      />
    </ReferenceInput>
  );
};

LeaveAutocompleteInput.propTypes = {
  emptyMessage: PropTypes.string,
  fullWidth: PropTypes.bool,
  resource: PropTypes.string,
  validate: PropTypes.func,
};

LeaveAutocompleteInput.defaultProps = {
  emptyMessage: '',
  fullWidth: true,
  resource: '',
  validate: /* istanbul ignore next */ () => {},
};

export default LeaveAutocompleteInput;
