import React from 'react';
import PropTypes from 'prop-types';
import {
  Datagrid,
  DateField,
  ReferenceManyField,
  TextField,
  ReferenceField,
} from 'react-admin';
import { Typography, useMediaQuery } from '@material-ui/core';
import {
  AffectationTypeField,
  Pagination,
  ScheduleField,
  TimeField,
  PhoneCallField,
} from '../../../../components';

const ListAffectation = ({
  active,
  filter: filter_,
  perPage,
  sort,
  title,
  type,
  ...props
}) => {
  const filter = {
    active,
    contract_type: 'Récurrent',
    ...filter_,
  };

  const isSmall = useMediaQuery((theme) => {
    return theme.breakpoints.down('sm');
  });

  return (
    <>
      {title && <Typography variant="h6">{title}</Typography>}
      <ReferenceManyField
        {...props}
        reference="affectation"
        target="account_id"
        addLabel={false}
        filter={filter}
        sort={sort}
        xs={3}
        perPage={perPage}
        pagination={<Pagination />}
      >
        {isSmall ? (
          <Datagrid {...props}>
            <ReferenceField
              allowEmpty
              link="show"
              source="cleaner_id"
              reference="cleaner"
              label="Cleaner"
            >
              <TextField source="fullname" />
            </ReferenceField>
            <PhoneCallField source="cleaner_phone" text="☎️" label="Appel" />
            <AffectationTypeField source="type" />
            <ScheduleField source="planning" />
          </Datagrid>
        ) : (
          <Datagrid {...props} rowClick="show">
            <ReferenceField
              allowEmpty
              link="show"
              source="cleaner_id"
              reference="cleaner"
              label="Cleaner"
            >
              <TextField source="fullname" />
            </ReferenceField>
            <AffectationTypeField source="type" />
            <DateField source="start_date" />
            <DateField source="end_date" />
            <ScheduleField source="planning" />
            <TimeField source="duration_per_week" />
          </Datagrid>
        )}
      </ReferenceManyField>
    </>
  );
};

ListAffectation.propTypes = {
  active: PropTypes.bool,
  filter: PropTypes.object,
  perPage: PropTypes.number,
  sort: PropTypes.object,
  title: PropTypes.string,
  type: PropTypes.string,
};

ListAffectation.defaultProps = {
  active: false,
  filter: {},
  perPage: 10,
  sort: { field: 'start_date', order: 'DESC' },
  title: undefined,
  type: '',
};

export default ListAffectation;
