import React from 'react';
import PropTypes from 'prop-types';
import { Responsive, TextInput, useLogin, useTranslate } from 'react-admin';
import { Form } from 'react-final-form';
import { CircularProgress, Button } from '@material-ui/core';

import { largeStyles, mediumStyles, smallStyles } from './loginStyle';
import SplashScreen from './SplashScreen';

export const validateLog = (values) => {
  const errors = {};

  if (!values.username) {
    errors.username = 'login.form.required';
  }
  if (!values.password) {
    errors.password = 'login.form.required';
  }
  return errors;
};

const LoginForm = ({ style }) => {
  const login = useLogin();
  const translate = useTranslate();

  return (
    <Form
      validate={validateLog}
      onSubmit={(values) => {
        login(values);
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form style={style.formStyle} onSubmit={handleSubmit}>
            <TextInput
              fullWidth
              variant="standard"
              type="email"
              source="username"
              options={{
                style: { marginBottom: 25 },
              }}
            />
            <TextInput
              fullWidth
              variant="standard"
              type="password"
              source="password"
            />
            <Button
              style={style.button}
              fullWidth
              variant="contained"
              color="primary"
              type="submit"
              disabled={submitting}
              startIcon={
                submitting && <CircularProgress size={25} thickness={2} />
              }
            >
              {translate('login.sign_in')}
            </Button>

            {/* TODO: no page /forgotten */}
            <div style={style.linkContainer}>
              <a
                target="_self"
                href="https://app.cleany.fr/#/forgotten"
                style={style.resetButton}
              >
                <div>{translate('login.forgot_password_button')}</div>
              </a>
              <a
                target="_self"
                href="https://app.cleany.fr/#/forgotten"
                style={style.resetButton}
              >
                <div>{translate('login.first_connection')}</div>
              </a>
            </div>
          </form>
        );
      }}
    />
  );
};

LoginForm.propTypes = {
  style: PropTypes.object,
};

LoginForm.defaultProps = {
  style: null,
};

const MyLoginPage = (props) => {
  return (
    <SplashScreen {...props}>
      <Responsive
        small={<LoginForm {...props} style={smallStyles} />}
        medium={<LoginForm {...props} style={mediumStyles} />}
        large={<LoginForm {...props} style={largeStyles} />}
      />
    </SplashScreen>
  );
};

export default MyLoginPage;
