import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  SelectInput,
  ReferenceInput,
  Edit,
  AutocompleteInput,
  TextInput,
  SimpleForm,
  DateInput,
} from 'react-admin';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { URLParamField } from '../../components';
import { Toolbar } from '../../components';
import { DriveImageField } from '../../containers';
import { ConditionalInput, SubtypeClientSelectInput } from '../../components';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

export const DocumentForm = ({ onCancel, record, ...props }) => {
  const defaultValues = {
    last_modified_date: moment().format('YYYY-MM-DD'),
  };

  return (
    <Fragment>
      <div style={{ textAlign: 'right' }}>
        <IconButton onClick={onCancel}>
          <CloseIcon />
        </IconButton>
      </div>
      <SimpleForm
        {...sanitizeRestProps(props)}
        toolbar={<Toolbar isDeletable={false} />}
        record={{ ...record, ...defaultValues }}
      >
        <DriveImageField source="drive_id" />
        <SelectInput
          source="type"
          choices={[
            { id: 'contract_end', name: 'Fin de contrat' },
            { id: 'provider', name: 'Fournisseur' },
            { id: 'contract', name: 'Contrat' },
          ]}
        />
        <SubtypeClientSelectInput />
        <ConditionalInput when="subtype" is="provider_contract">
          <ReferenceInput
            filterToQuery={(searchText) => {
              return { name: searchText };
            }}
            source="provider_id"
            reference="account"
            filter={{ type: 'Partenaire' }}
            sort={{ field: 'name', order: 'ASC' }}
            label="Fournisseur"
          >
            <AutocompleteInput
              source="name"
              options={{
                suggestionsContainerProps: {
                  /* XXX: no other way to give style to the AutocompleList. react-admin not passing classes to AutocompleList in AutocompleInput */
                  style: { zIndex: 2000 },
                },
              }}
            />
          </ReferenceInput>
        </ConditionalInput>
        <TextInput source="drive_id" />
        <DateInput source="document_date" />
        <TextInput source="comment" />
        <URLParamField
          url="https://drive.google.com/file/d/{{drive_id}}/view"
          source="drive_id"
          label="Télécharger"
          textAlign="center"
          text="📩"
          variant="body2"
          emptyText="∅"
        />
      </SimpleForm>
    </Fragment>
  );
};

DocumentForm.propTypes = {
  onCancel: PropTypes.func,
  record: PropTypes.object,
};

DocumentForm.defaultProps = {
  onCancel: /* istanbul ignore next */ () => {},
  record: {},
};

export default (props) => {
  return (
    <Edit {...props}>
      <DocumentForm {...props} />
    </Edit>
  );
};
