import React from 'react';
import PropTypes from 'prop-types';
import {
  SelectField,
  CloneButton,
  DateField,
  List,
  NumberField,
  ReferenceField,
  TextField,
} from 'react-admin';
import CustomizableDatagrid from 'ra-customizable-datagrid';
import Filter from './detail/Filter';
import { TimeField, TranslatedField } from '../../components';

const AffectationList = ({ basePath, ...props }) => {
  return (
    <List
      {...props}
      filters={<Filter />}
      sort={{ field: 'start_date', order: 'DESC' }}
      bulkActionButtons={false}
      perPage={25}
      basePath={basePath}
    >
      <CustomizableDatagrid
        defaultColumns={[
          'cleaner_id',
          'account_id',
          'start_date',
          'end_date',
          'active',
          'start_time',
          'end_time',
          'type',
        ]}
        rowClick="show"
      >
        <CloneButton label="" />
        <ReferenceField source="cleaner_id" reference="cleaner" link="show">
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField source="account_id" reference="account" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="type" />
        <DateField source="start_date" />
        <DateField source="end_date" />
        <SelectField source="active" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <ReferenceField source="contract_id" reference="contract">
          <TextField source="name" />
        </ReferenceField>
        <TranslatedField
          translationPath="components.field.prestation_type"
          source="prestation_type"
        />
        <TextField source="punctual_reason" />
        <ReferenceField source="leave_id" reference="leave">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="related_affectation_id" reference="affectation">
          <TextField source="id" />
        </ReferenceField>
        <TranslatedField
          translationPath="components.field.absence_type"
          source="absence_type"
        />
        <TextField source="status" />
        <TimeField source="start_time" />
        <TimeField source="end_time" />
        <SelectField source="monday" label="Lundi"  choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="tuesday" label="Mardi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="wednesday" label="Mercredi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="thursday" label="Jeudi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="friday" label="Vendredi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="saturday" label="Samedi" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
          <SelectField source="sunday" label="Dimanche" choices={[
          { id: true, name: '✅' },
          { id: false, name: '❌' }]} />
        <NumberField source="nb_worked_days" />
        <TimeField source="duration" />
        <TimeField source="duration_per_week" />
        <TextField source="transit_stop" />
        <ReferenceField source="manager_id" reference="cleaner">
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField source="operational_id" reference="client">
          <TextField source="fullname" />
        </ReferenceField>
        <ReferenceField
          source="catalog_item_id"
          reference="item"
          link={false}
          allowEmpty
        >
          <TextField source="name" />
        </ReferenceField>
      </CustomizableDatagrid>
    </List>
  );
};

AffectationList.propTypes = {
  basePath: PropTypes.string,
};

AffectationList.defaultProps = {
  basePath: '',
};

export default AffectationList;
