import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getUser } from '../../../../user';

const PreaffectationButton = ({ className, record }) => {
  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  'https://app-manager.bubbleapps.io/affectation?cleaner_id=&account_id=' +
                    `${record.id}` +
                    '&email=' +
                    `${user.email}`,
                );
              }}
            >
              <ListItemText primary="📣 Déclarer une Pré-affectation" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

PreaffectationButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
};

PreaffectationButton.defaultProps = {
  className: undefined,
  record: undefined,
};

export default PreaffectationButton;
