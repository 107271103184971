import React from 'react';
import { CreateButton } from 'react-admin';
import { ActionsContainer } from '../../../components';
import UploadInvoiceDialog from './UploadInvoiceDialog';

const ProviderInvoiceActions = (props) => {
  return (
    <ActionsContainer {...props}>
      <CreateButton />
      <UploadInvoiceDialog />
    </ActionsContainer>
  );
};

export default ProviderInvoiceActions;
