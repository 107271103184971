import React from 'react';
import PropTypes from 'prop-types';
import { CreateButton, DateInput, useTranslate } from 'react-admin';
import { makeStyles } from '@material-ui/styles';
import { Filter } from '../../../../components';

const EDIT_PATH = '/edit_invoice_incubator';

const removeDaysFromDate = (value) => {
  return value.slice(0, 7);
};

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'baseline',
    },
    item: {
      marginLeft: theme.spacing(1),
    },
  };
});

const Action = ({ initialValues, onChange, originRoute }) => {
  const classes = useStyles();
  const translate = useTranslate();

  return (
    <div className={classes.root}>
      <CreateButton
        className={classes.item}
        basePath={`${originRoute}${EDIT_PATH}`}
        label={translate('resources.invoice_incubator.action.create')}
      />
      <Filter initialValues={initialValues} onChange={onChange}>
        <DateInput source="month" parse={removeDaysFromDate} />
      </Filter>
    </div>
  );
};

Action.propTypes = {
  initialValues: PropTypes.object,
  onChange: PropTypes.func,
  originRoute: PropTypes.string.isRequired,
};

Action.defaultProps = {
  initialValues: {},
  onChange: () => {},
};

export default Action;
