import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { getUser } from '../../../../user';

const EntretienProfessionelButton = ({ className, record, translate }) => {
  const translatedPosition = translate(
    `resources.cleaner.function.${record.function
      .toLowerCase()
      .replace(/\s/, '_')}`,
  );

  return (
    <Fragment>
      <Route
        render={() => {
          const user = getUser();
          const formUrl = 'https://forms.cleany.fr/entretien-professionnel?';
          return (
            <MenuItem
              className={className}
              variant="contained"
              onClick={() => {
                window.open(
                  `${formUrl}fullname=${record.fullname}` +
                    `&function=${translatedPosition}` +
                    `&seniorityDate=${new Date(record.seniority_date).toLocaleDateString(
                      'fr-FR',
                    )}` +
                    `&managerFullname=${user.fullname}` +
                    `&interviewDate=${new Date().toLocaleDateString('fr-FR')}` +
                    `&cleanerEmail=${record.email}` +
                    `&managerEmail=${record.manager_email}`,
                );
              }}
            >
              <ListItemText primary="Faire un entretien professionel" />
            </MenuItem>
          );
        }}
      />
    </Fragment>
  );
};

EntretienProfessionelButton.propTypes = {
  className: PropTypes.string,
  record: PropTypes.object,
  translate: PropTypes.func,
};

EntretienProfessionelButton.defaultProps = {
  className: undefined,
  record: undefined,
  translate: /* istanbul ignore next */ () => {},
};

export default EntretienProfessionelButton;
