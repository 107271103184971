import React from 'react';
import PropTypes from 'prop-types';

import {
  DateField,
  TextField,
  ReferenceManyField,
  Pagination,
  Datagrid,
} from 'react-admin';
import Typography from '@material-ui/core/Typography';

import 'moment/locale/fr';
import moment from 'moment';

import { GridCard, ShowGrid, TimeField } from '../../components';
import ShowActions from './detail/ShowActions';

import Period from './detail/Period';
import TaskAvatar from './detail/TaskAvatar';
import rowStyle from './detail/rowStyle';

const Description = ({ record: { description } }) => {
  return (
    <Typography xs={12} color="textSecondary">
      {description}
    </Typography>
  );
};

Description.propTypes = {
  record: PropTypes.shape({
    description: PropTypes.string,
  }),
};

Description.defaultProps = {
  record: { description: '' },
};

const Task = ({ record, ...props }) => {
  return (
    <GridCard
      avatar={<TaskAvatar record={record} />}
      title={record.label}
      subtitle={`Prochaine execution ${moment(record.next_execution_date)
        .locale('fr')
        .fromNow()}`}
      record={record}
      {...props}
    >
      <Description xs={12} />
      <Period xs={12} />
    </GridCard>
  );
};

Task.propTypes = {
  record: PropTypes.object,
};

Task.defaultProps = {
  record: {},
};

const Show = ({ ...props }) => {
  return (
    <ShowGrid {...props} actions={<ShowActions />} titleSource="label">
      <Task />
      <GridCard xs={12}>
        <ReferenceManyField
          pagination={<Pagination />}
          reference="job_status"
          target="name"
          addLabel={false}
          sort={{
            field: 'start_datetime',
            order: 'DESC',
          }}
          xs={12}
        >
          <Datagrid rowStyle={rowStyle}>
            <DateField showTime source="start_datetime" />
            <DateField showTime source="end_datetime" />
            <TimeField withSeconds source="duration" />
            <TextField source="status" />
            <TextField source="message" />
          </Datagrid>
        </ReferenceManyField>
      </GridCard>
    </ShowGrid>
  );
};

export default Show;
