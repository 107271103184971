import React from 'react';
import { Create } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import Form from './detail/Form';
import Aside from './detail/Aside';

const useStyles = makeStyles({
  left: {
    width: 'calc(100% - 350px)',
  },
});

const LeaveCreate = (props) => {
  const classes = useStyles();

  return (
    <Create {...props} className={classes.left}>
      <Form aside={<Aside />} />
    </Create>
  );
};

export default LeaveCreate;
