import React from 'react';
import PropTypes from 'prop-types';

export const buildTitle = (record, source) => {
  if (!record || !source) {
    return undefined;
  }

  if (typeof source === 'string') {
    return record[source];
  }
  if (source.length > 0) {
    return source
      .map((item) => {
        if (record[item]) {
          return record[item];
        }
        return undefined;
      })
      .filter((value) => {
        return value;
      })
      .join(' - ');
  }

  return undefined;
};

const TitleFromRecord = ({ record, source }) => {
  const title = buildTitle(record, source);

  return <span>{title}</span>;
};

TitleFromRecord.propTypes = {
  record: PropTypes.object,
  source: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
};

TitleFromRecord.defaultProps = {
  record: {},
  source: undefined,
};

export default TitleFromRecord;
