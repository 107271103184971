export const GET_PAYROLL_EXPLANATION = 'SAGA/GET_PAYROLL_EXPLANATION';

export const getPayrollExplanation = (cleanerId, date) => {
  return {
    type: GET_PAYROLL_EXPLANATION,
    cleanerId,
    date,
  };
};

export const SET_PAYROLL_EXPLANATION = 'SAGA/SET_PAYROLL_EXPLANATION';

export const setPayrollExplanation = (explanation, date) => {
  return {
    type: SET_PAYROLL_EXPLANATION,
    explanation,
    date,
  };
};

export const CLEAR_PAYROLL_EXPLANATION = 'SAGA/CLEAR_PAYROLL_EXPLANATION';

export const clearPayrollExplanation = () => {
  return {
    type: CLEAR_PAYROLL_EXPLANATION,
  };
};
