import React from 'react';
import PropTypes from 'prop-types';
import {
  Show as ShowRA,
  useTranslate,
  TabbedShowLayout,
  Tab,
  TabbedShowLayoutTabs,
} from 'react-admin';
import { useCallback } from 'react';

import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import GradeIcon from '@material-ui/icons/Grade';
import PunctualIcon from '@material-ui/icons/LooksOne';
import BusinessIcon from '@material-ui/icons/Business';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';

import { useMediaQuery } from '@material-ui/core';
import { TitleFromRecord } from '../../components';

import ShowActions from './detail/ShowActions';
import TabCleaning from './detail/TabCleaning';
import TabConsumable from './detail/TabConsumable';
import TabInvoice from './detail/TabInvoice';
// import TabMiscellanous from './detail/TabMiscellanous';
import TabPonctual from './detail/TabPonctual';
// import TabGlazing from './detail/TabGlazing';
import TabFollowup from './detail/TabFollowup';

const sanitizeRestProps = ({
  hasShow,
  hasEdit,
  hasList,
  hasCreate,
  ...props
}) => {
  return props;
};

const Show = ({ location, match, ...props }) => {
  const translate = useTranslate();

  const pathWithSuffix = useCallback(
    (path) => {
      const suffix = location.pathname
        .split('/')
        .slice(4)
        .join('/');

      return suffix.indexOf(path) !== -1 ? suffix : path;
    },
    [location],
  );

  const isSmall = useMediaQuery((theme) => {
    return theme.breakpoints.down('sm');
  });

  const displayLabel = (textTodisplay) => {
    if (isSmall) {
      return '';
    }
    return textTodisplay;
  };

  const displayIcon = (textTodisplay) => {
    if (!isSmall) {
      return null;
    }
    return textTodisplay;
  };

  return (
    <ShowRA
      {...props}
      component="div"
      actions={<ShowActions />}
      title={<TitleFromRecord source="name" />}
    >
      <TabbedShowLayout
        tabs={<TabbedShowLayoutTabs variant="fullWidth" centered />}
      >
        <Tab
          label={displayLabel(translate('resources.account.tabs.cleaning'))}
          icon={displayIcon(<BusinessIcon />)}
        >
          <TabCleaning />
        </Tab>
        {/*
        <Tab
          label={translate('resources.account.tabs.glazing')}
          path={pathWithSuffix('glazing')}
        >
          <TabGlazing />
        </Tab>
        */}
        <Tab
          label={displayLabel(translate('resources.account.tabs.ponctual'))}
          path={pathWithSuffix('ponctual')}
          icon={displayIcon(<PunctualIcon />)}
        >
          <TabPonctual />
        </Tab>
        <Tab
          label={displayLabel(translate('resources.account.tabs.consumable'))}
          path={pathWithSuffix('consumable')}
          icon={displayIcon(<LocalShippingIcon />)}
        >
          <TabConsumable />
        </Tab>
        {/*
        <Tab
          label={translate('resources.account.tabs.miscellanous')}
          path={pathWithSuffix('miscellanous')}
        >
        <TabMiscellanous {...sanitizeRestProps(props)} match={match} />
        </Tab>
        */}
        <Tab
          label={displayLabel('Suivi')}
          path={pathWithSuffix('suivi')}
          icon={displayIcon(<GradeIcon />)}
        >
          <TabFollowup {...sanitizeRestProps(props)} match={match} />
        </Tab>
        <Tab
          label={displayLabel(translate('resources.account.tabs.invoice'))}
          path={pathWithSuffix('invoice')}
          icon={displayIcon(<EuroSymbolIcon />)}
        >
          <TabInvoice {...sanitizeRestProps(props)} match={match} />
        </Tab>
      </TabbedShowLayout>
    </ShowRA>
  );
};

Show.propTypes = {
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
};

Show.defaultProps = {
  match: {},
};

export default Show;
