import { days } from '../../../helpers/date';

const buildPlanning = (record) => {
  if (!record) {
    return {};
  }

  const planning = days.reduce((acc, day) => {
    if (record[day]) {
      return {
        ...acc,
        [day]: [{ start_time: record.start_time, end_time: record.end_time }],
      };
    }
    return acc;
  }, {});

  return planning;
};

export default buildPlanning;
