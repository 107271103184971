import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import CloudDownload from '@material-ui/icons/CloudDownload';
import { Button, translate as translateComponent } from 'react-admin';
import { getLegalDocument } from '../../../../action';

const TakenOver = ({ className, getLegalDocumentClick }) => {
  const [idCompte, setId] = useState('012345678910');
  const [conge, setConge] = useState(10);
  const [open, setOpen] = useState(false);
  const invertOpenStatus = () => {
    setOpen(!open);
  };
  const onChangeId = (newId) => {
    setId(newId.target.value);
  };
  const onChangeConge = (newConge) => {
    setConge(newConge.target.value);
  };

  return (
    <Fragment>
      <MenuItem
        className={className}
        variant="contained"
        onClick={invertOpenStatus}
        label="Annexe 7: Entreprise"
      >
        <ListItemText primary="Entreprise Rentrante" />
      </MenuItem>
      <Dialog
        aria-labelledby="alert-dialog-title"
        open={open}
        onClose={invertOpenStatus}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle color="primary" id="alert-dialog-title">
          Départ en annexe 7
        </DialogTitle>
        <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
          <TextField
            style={{ marginBottom: '10px', width: '40%' }}
            label="Id du compte"
            defaultValue={idCompte}
            onChange={onChangeId}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            style={{ marginBottom: '10px', width: '40%' }}
            type="number"
            label="Jours de Congés"
            defaultValue={conge}
            onChange={onChangeConge}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            label="resources.cleaner.actions.download"
            variant="contained"
            onClick={() => {
              getLegalDocumentClick('takenOver', {
                idCompte: `${idCompte}`,
                conge: `${conge}`,
              });
              invertOpenStatus();
            }}
          >
            <CloudDownload />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

TakenOver.propTypes = {
  className: PropTypes.string,
  getLegalDocumentClick: PropTypes.func.isRequired,
};

TakenOver.defaultProps = {
  className: undefined,
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getLegalDocumentClick: (documentType, others) => {
      const payload = {
        id: ownProps.record.id,
        fullname: ownProps.record.fullname,
        documentType,
        ...others,
      };
      dispatch(getLegalDocument(payload));
    },
  };
};

const enhance = compose(translateComponent, connect(null, mapDispatchToProps));

export default enhance(TakenOver);
